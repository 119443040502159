import store from '../store/store'

class Utils {
  productName (product) {
    if (store.state.contractorManagement.settings.dynamics && product.item.mfg_name === 'Dynamic') {
      return product.name.replace('Dynamic', store.state.contractorManagement.settings.dynamic_mfg_name)
    } else {
      return product.name
    }
  }

  productMfgName (mfgName) {
    if (store.state.contractorManagement.settings.dynamics && mfgName === 'Dynamic') {
      return store.state.contractorManagement.settings.dynamic_mfg_name
    } else {
      return mfgName
    }
  }

  itemName (item) {
    if (store.state.contractorManagement.settings.dynamics && item.mfg_name === 'Dynamic') {
      return item.name.replace('Dynamic', store.state.contractorManagement.settings.dynamic_mfg_name)
    } else {
      return item.name
    }
  }

  itemDescription (item) {
    if (store.state.contractorManagement.settings.dynamics && item.mfg_name === 'Dynamic') {
      return item.description.replace('Dynamic', store.state.contractorManagement.settings.dynamic_mfg_name)
    } else {
      return item.description
    }
  }
}

export default new Utils()
