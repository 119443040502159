import Vue from 'vue'
import DayJs from 'vue-dayjs'
import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'

const DayJsOpts = {
  lang: 'en',
  filters: { ago: 'ago' },
  directives: { countdown: 'countdown' }
}

Vue.use(DayJs, DayJsOpts)
Vue.prototype.$dayjs.extend(calendar)
Vue.prototype.$dayjs.extend(relativeTime)
Vue.prototype.$dayjs.extend(quarterOfYear)
Vue.prototype.$dayjs.extend(localizedFormat)

export default DayJs
