import { add, show, update, destroy, primary, authorize, capture, test } from '@/api/payment'

export default {
  addPayment ({commit}, state) {
    return new Promise((resolve, reject) => {
      add(state)
        .then((response) => {
          commit('setPayment', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePayment ({commit}, data) {
    const {id, payment} = data
    return new Promise((resolve, reject) => {
      update(id, payment)
        .then((response) => {
          commit('updatePayment', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setPrimary ({commit}, data) {
    const {id, payment} = data
    return new Promise((resolve, reject) => {
      primary(id, payment)
        .then((response) => {
          commit('setPayments', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPayments ({commit}) {
    return new Promise((resolve, reject) => {
      show()
        .then((response) => {
          commit('setPayments', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removePayment ({commit}, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((response) => {
          commit('removePayment', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  authorizePayment (context, data) {
    return new Promise((resolve, reject) => {
      authorize(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  capturePayment (context, data) {
    return new Promise((resolve, reject) => {
      capture(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  testPayment (context, data) {
    return new Promise((resolve, reject) => {
      test(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
