<template>
  <v-app>
    <Header />

    <auto-logout />

    <MainView />

    <Footer />
  </v-app>
</template>

<script>
export default {
  components: {
    MainView: () => import('../components/MainView'),
    Footer: () => import('../components/MainFooter'),
    Header: () => import('../components/navbar/Header'),
    AutoLogout: () => import('@/components/AutoLogout')
  }
}
</script>
