import api from './request'

export function add (data) {
  return api({
    url: '/customer/add',
    method: 'post',
    data
  })
}

export function addCustomers (data) {
  return api({
    url: '/customers/uploadList',
    method: 'post',
    data
  })
}

export function addAssignedProduct (data) {
  return api({
    url: '/customers/assignProduct',
    method: 'post',
    data
  })
}

export function removeAssignedProduct (data) {
  return api({
    url: '/customers/removeProduct',
    method: 'post',
    data
  })
}

export function approvePending (data) {
  return api({
    url: '/customers/pending/approve',
    method: 'post',
    data
  })
}

export function merge (id, data) {
  return api({
    url: `/customers/pending/merge/${id}`,
    method: 'put',
    data
  })
}

export function show (id) {
  return api({
    url: `/customers/${id}`,
    method: 'get'
  })
}

export function get (query) {
  return api({
    url: '/customers',
    method: 'get',
    params: query
  })
}

export function getOrders (data) {
  return api({
    url: '/orders',
    method: 'get',
    params: data
  })
}

export function getBatchDetails (id, data) {
  return api({
    url: `/customers/batches/${id}`,
    method: 'get',
    params: data
  })
}

export function getBatch (query) {
  return api({
    url: '/customers/batches',
    method: 'get',
    params: query
  })
}

export function getScheduledOrders (id) {
  return api({
    url: `/customers/${id}/scheduled`,
    method: 'get'
  })
}

export function getAssignedProducts (id) {
  return api({
    url: `/customer/${id}/products`,
    method: 'get'
  })
}

export function getReminders (id) {
  return api({
    url: `/customers/${id}/reminders`,
    method: 'get'
  })
}

export function getOrderHistory (id) {
  return api({
    url: `/customers/${id}/orders`,
    method: 'get'
  })
}

export function update (id, data) {
  return api({
    url: `/customers/${id}`,
    method: 'put',
    data
  })
}

export function updateValidation (data) {
  return api({
    url: '/customers/validations/update',
    method: 'post',
    data
  })
}

export function destroy (id) {
  return api({
    url: `/customers/${id}`,
    method: 'delete'
  })
}

export function search (data) {
  return api({
    url: '/customers/search',
    method: 'get',
    params: data
  })
}

export function getTaxRate (id) {
  return api({
    url: `/customers/tax/${id}`,
    method: 'get'
  })
}
