/*const getManufacturers = () => {
  const mfgLocalStorage = localStorage.getItem('manufacturers')

  if (mfgLocalStorage === null) return []

  return JSON.parse(mfgLocalStorage)
}*/

const getProducts = () => {
  const local = localStorage.getItem('products')

  if (local === null) return []

  return JSON.parse(local)
}

const state = {
  products: getProducts(),
  manufacturers: [],
  inProgress: false,
  assignProduct: false,
  selectedProduct: {},
  types: [
    { value: 'pleated', text: 'Pleated' },
    { value: 'fiberglass', text: 'Fiberglass' },
    { value: 'expandable', text: 'Expandable' },
    { value: 'polarized', text: 'Polarized' }
  ],
  merv: [8, 10, 11, 13, 14, 15, 16],
  length: [
    { value: 8, text: '8"' },
    { value: 10, text: '10"' },
    { value: 12, text: '12"' },
    { value: 12.5, text: '12-1/2"' },
    { value: 13, text: '13"' },
    { value: 14, text: '14"' },
    { value: 14.5, text: '14-1/2"' },
    { value: 15, text: '15"' },
    { value: 16, text: '16"' },
    { value: 16.375, text: '16-3/8"' },
    { value: 16.5, text: '16-1/2"' },
    { value: 17, text: '17"' },
    { value: 17.5, text: '17-1/2"' },
    { value: 18, text: '18"' },
    { value: 19, text: '19"' },
    { value: 19.75, text: '19-3/4"' },
    { value: 20, text: '20"' },
    { value: 21, text: '21"' },
    { value: 21.5, text: '21-1/2"' },
    { value: 22, text: '22"' },
    { value: 23, text: '23"' },
    { value: 24, text: '24"' },
    { value: 24.5, text: '24-1/2"' },
    { value: 25, text: '25"' },
    { value: 25.5, text: '25-1/2"' },
    { value: 28, text: '28"' },
    { value: 30, text: '30"' }
  ],
  width: [
    { value: 10, text: '10"' },
    { value: 12, text: '12"' },
    { value: 14, text: '14"' },
    { value: 15, text: '15"' },
    { value: 16, text: '16"' },
    { value: 18, text: '18"' },
    { value: 20, text: '20"' },
    { value: 21, text: '21"' },
    { value: 21.5, text: '21-1/2"' },
    { value: 22, text: '22"' },
    { value: 22.5, text: '22-1/2"' },
    { value: 23, text: '23"' },
    { value: 23.5, text: '23-1/2"' },
    { value: 24, text: '24"' },
    { value: 24.5, text: '24-1/2"' },
    { value: 25, text: '25"' },
    { value: 26, text: '26"' },
    { value: 27, text: '27"' },
    { value: 27.5, text: '27-1/2"' },
    { value: 28, text: '28"' },
    { value: 30, text: '30"' },
    { value: 30.625, text: '30-5/8"' },
    { value: 32, text: '32"' },
    { value: 34, text: '34"' },
    { value: 35, text: '35"' },
    { value: 36, text: '36"' }
  ],
  thickness: [
    { value: 1, text: '1"' },
    { value: 2, text: '2"' },
    { value: 3, text: '3"' },
    { value: 3.5, text: '3-1/2"' },
    { value: 4, text: '4"' },
    { value: 4.5, text: '4-1/2"' },
    { value: 5, text: '5"' },
    { value: 6, text: '6"' }
  ],
  lengthOptions: [],
  widthOptions: [],
  thicknessOptions: []
}

export default state
