<template>
    <div>
      <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
              <v-card-text>
                  <h1 class="pt-5 text-center font-black">Add Customer</h1>
              </v-card-text>
              <v-card-text>
                  <v-autocomplete
                      v-model="customer"
                      :loading="isLoading"
                      :items="customers"
                      :search-input.sync="search"
                      flat
                      hide-details
                      item-text="full_name"
                      item-value="id"
                      return-object
                      @change="customerSelected"
                      prepend-icon="mdi-magnify">
                    <template v-slot:selection="data">
                      {{ data.item.full_name }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ data.item.full_name }}
                          <v-spacer></v-spacer>
                          {{ data.item.shipping_address.street1 }} {{ data.item.shipping_address.street2 }}, {{ data.item.shipping_address.city }}, {{ data.item.shipping_address.state.name }} {{ data.item.shipping_address.zip }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                      <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item-content>
                              <v-list-item-subtitle class="ml-5">
                                  Customer not found? <v-btn small text @click="newCustomer">Create Customer</v-btn>
                              </v-list-item-subtitle>
                          </v-list-item-content>
                      </template>
                  </v-autocomplete>
              </v-card-text>
            <v-card-text v-if="showAddressSelect">
              <v-autocomplete
                v-model="addressId"
                :items="addresses"
                label="Select shipping address"
                item-value="id"
                flat
                hide-details
                prepend-icon="mdi-home"
              >
                <template v-slot:selection="data">
                  {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ data.item.full_name }}
                      <v-spacer></v-spacer>
                      {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item-content>
                    <v-list-item-subtitle class="ml-5">
                      Address not found? <v-btn small text @click="newAddress">Create Address</v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn text @click="newCustomer" color="blue darken-1">ADD CUSTOMER</v-btn>
                <v-btn v-if="settings.st_active" text @click="enterJobNumber" color="blue darken-1">ADD BY JOB NUMBER</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel">EXIT</v-btn>
                <v-btn color="blue darken-1" text @click="addCustomer">ADD</v-btn>
            </v-card-actions>
          </v-card>
          <customer-modal ref="addCustomer"></customer-modal>
      </v-dialog>

      <v-dialog
        v-model="showEnterJobNumber"
        max-width="500px"
        >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Customer By Job Number</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense v-model="jobNumber" label="Job Number"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="showEnterJobNumber = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="addCustomerByJobNumber"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showNewAddressForm"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Address</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <vuetify-google-autocomplete
                    id="autocomplete"
                    ref="address"
                    placeholder="Enter address for validation"
                    v-on:no-results-found="noResults"
                    v-on:placechanged="getAddressData"
                  >
                    <template #append>
                      <v-icon small>mdi-map-search</v-icon>
                    </template>
                  </vuetify-google-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model="address.street1" label="Street 1"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model="address.street2" label="Street 2"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model="address.city" label="City"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="address.state_id"
                    :items="states"
                    :filter="statesFilter"
                    item-text="text"
                    item-value="value"
                    label="State"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    dense
                    v-model="address.zip"
                    label="Zip"
                    :rules="zipRules"
                    maxlength="5"
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="closeAdd"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="addAddress"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CustomerModal: () => import('@/views/customer/components/CustomerModal')
  },
  data () {
    return {
      dialog: false,
      createCustomer: false,
      customers: [],
      customer: {},
      addressId: null,
      zipRules: [v => v.length <= 5 || 'Max 5 characters'],
      address: {
        id: null,
        fname: '',
        lname: '',
        company: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: null,
        model_type: 'customer'
      },
      search: null,
      isLoading: false,
      data: {},
      options: {},
      resolve: null,
      reject: null,
      jobNumber: '',
      loader: null,
      loading: false,
      showAddressSelect: false,
      showNewAddressForm: false,
      showEnterJobNumber: false
    }
  },
  computed: {
    isModalActiveLocal: {
      get () {
        return this.isModalActive
      },
      set (val) {
        if (!val) {
          this.$emit('close-modal')
        }
      }
    },
    ...mapState('cartManagement', {
      cart: state => state.cart
    }),
    ...mapState('contractorManagement', {
      settings: state => state.settings
    }),
    ...mapState('addressManagement', {
      addresses: state => state.addresses
    }),
    ...mapState({
      states: state => state.stateOptions
    })
  },
  watch: {
    search (val) {
      if (!val) return

      if (val.length < 3) return

      if (this.customers.length > 0) return

      if (this.isLoading) return

      this.isLoading = true

      this.loadCustomers(val)
    }
  },
  methods: {
    ...mapActions('cartManagement', {
      addCartCustomer: 'addCustomerToCart'
    }),
    ...mapActions('customerManagement', {
      addNewCustomer: 'addCustomer',
      fetch: 'searchCustomers',
      fetchTaxRate: 'getCustomerTaxRate'
    }),
    ...mapActions('addressManagement', {
      add: 'addAddress',
      fetchAddresses: 'fetchAddresses'
    }),
    init () {
      if (this.data !== undefined) return

      this.customer = {}
    },
    open (options) {
      this.options = Object.assign(this.options, options)
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    },
    loadCustomers (searchText) {
      this.isLoading = true
      this.fetch({keyword: searchText})
        .then((response) => {
          response.map((customer) => {
            const obj = {
              id: customer.id,
              fname: customer.fname,
              lname: customer.lname,
              full_name: customer.full_name,
              company_name: customer.company_name,
              shipping_address: customer.shipping_address,
              scheduled_orders: customer.scheduled_orders,
              items: [],
              tax: 0
            }
            this.customers.push(obj)
          })
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadAddresses () {
      this.fetchAddresses({model_id: this.customer.id, model_type: 'customer'})
        .then(() => {
          this.showAddressSelect = true
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    customerSelected () {
      this.addressId = this.customer.default_shipping
      this.loadAddresses()
    },
    enterJobNumber () {
      this.jobNumber = ''
      this.showEnterJobNumber = true
    },
    addCustomerByJobNumber () {
      this.loading = true
      this.addNewCustomer({job_number: this.jobNumber})
        .then((response) => {
          this.customer = response
          this.customers.push(response)
          this.showEnterJobNumber = false
          this.customerSelected()
        })
        .catch((error) => {
          if (error.data) {
            if (error.data.message === 'Server Error') {
              this.$toast.error('Your request could not be completed. If the problem continues, please notify our customer service department.')
            } else {
              this.$toast.error(error.data.error)
            }
          } else {
            this.$toast.error(error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    addCustomer () {
      if (!this.customer.id || !this.addressId) {
        this.$toast.error('No customer/address selected.')
      } else {
        this.addCartCustomer({cart_id: this.cart.id, customer_id: this.customer.id, address_id: this.addressId})
          .then(() => {
            this.customer = {}
            this.customers = []
            this.search = null
            this.addressId = null
            this.showAddressSelect = false
            this.showNewAddressForm = false
            if (!this.options.allowMultiple) {
              this.cancel()
            }
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    newAddress () {
      this.address = {
        id: null,
        fname: '',
        lname: '',
        company: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: this.customer.id,
        model_type: 'customer'
      }
      this.showNewAddressForm = true
    },
    closeAdd () {
      this.showNewAddressForm = false
    },
    addAddress () {
      this.address.fname = this.customer.fname
      this.address.lname = this.customer.lname
      this.address.company = this.customer.company_name

      this.add(this.address)
        .then((response) => {
          this.addressId = response.id
          this.showNewAddressForm = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    statesFilter (item, queryText) {
      const name = item.text.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    getAddressData (addressData) {
      const state = this.states.find((s) => s.abbreviation === addressData.administrative_area_level_1)
      this.address.zip = addressData.postal_code
      this.address.street1 = addressData.name
      this.address.city = addressData.locality
      this.address.state_id = state.value
    },
    noResults () {
      this.$toast.error(this.$refs.address)
      this.$toast.error(this.$refs.address.autocompleteText)
    },
    newCustomer () {
      const options = {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        title: 'Add Customer',
        add: true,
        edit: false,
        addToCart: true,
        allowMultiple: true,
        showAutocomplete: true
      }
      this.$refs.addCustomer.open({}, options)
        .then((response) => {
          this.$toast.success(response.message)
        })
        .catch((error) => { this.$toast.error(error) })
    },
    toggleModal (val = false) {
      this.createCustomer = val
    }
  }
}
</script>
