<template>
  <v-container id="customers-pending-schedule-view">
    <v-alert
      v-if="errorMessages.length > 0"
      prominent
      type="error"
    >
      <span v-for="(message, index) in errorMessages" :key="index">
        {{ message }} <br>
      </span>
    </v-alert>
    <material-card
      color="primary"
      icon="mdi-calendar-plus"
    >
      <template #title>
        Customers Pending Schedule
      </template>

      <v-data-table
        item-key="id"
        :headers="headers"
        :items="customers"
        :loading="loading"
        loading-text="Loading Customers... Please wait"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        show-expand
        class="mr-2 ml-2">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              placeholder="Search"
              hide-details
              v-model="search"
              clearable
              append-icon="mdi-magnify"
              single-line
              dense />
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.customer`]="{ item }">
          <span class="font-weight-bold">{{ item.customer.fname }} {{ item.customer.lname }}</span><br>
          {{ item.customer.shipping_address.street1 }} {{ item.customer.shipping_address.street2 }} <br>
          {{ item.customer.shipping_address.city }}, {{ item.customer.shipping_address.state.name }} {{ item.customer.shipping_address.zip }}
        </template>
        <template v-slot:[`item.products`]="{ item }">
          {{ filterProducts(item.products).length }} Unscheduled Products
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            icon
            class="mr-2"
            @click="createSchedule(item)"
            >
            <v-icon>mdi-calendar-plus</v-icon>
          </v-btn>
          <v-btn
            small
            icon
            class="mr-2"
            @click="$router.push({ name: 'customer_detail', params: { id: item.customer.id } })"
          >
            <v-icon>mdi-account-search</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table
              class="m-2"
              dense
            >
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">ID</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Mfg</th>
                  <th class="text-left">Part#</th>
                  <th class="text-left">Case Qty</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Status</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="product in item.products"
                  :key="product.id"
                >
                  <td>{{ product.id }}</td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.mfg_name }}</td>
                  <td>{{ product.mfg_pn }}</td>
                  <td>{{ product.purchase_qty }}</td>
                  <td>{{ product.price }}</td>
                  <td>
                    <v-chip
                      small
                      :color="statusColorMap[product.status]">
                      {{ orderStatusMap[product.status] }}
                    </v-chip>
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      @click="removeAssignedProduct(item.customer_id, product.id)"
                      >
                      <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <template v-slot:no-data>
          No customers pending schedule found.
        </template>
      </v-data-table>
    </material-card>

    <v-dialog
      v-model="createNewSchedule"
      max-width="700px"
    >
      <v-card>
        <v-stepper
          alt-labels
          elevation="0"
          v-model="currentStep"
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="currentStep > 1"
              editable
              step="1"
            >
              Product Selection
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              editable
              :complete="currentStep > 2"
            >
              Schedule Selection
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="3"
              editable
              :complete="currentStep > 3"
            >
              Address Selection
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4">
              Select Payment
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
            >
              <v-card elevation="0">
                <v-card-text>
                  <v-card-text>
                    <v-select
                      dense
                      v-model="product"
                      :items="products"
                      item-text="name"
                      item-value="id"
                      label="Select Assigned Product"
                      return-object
                    ></v-select>
                  </v-card-text>
                  <v-card-text>
                    <v-text-field
                      dense
                      type="number"
                      v-model="selectedQuantity"
                      label="Quantity"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      @click="selectProduct"
                    >
                      Add Product
                    </v-btn>
                  </v-card-actions>

                  <v-card v-if="selectedProducts.length > 0" outlined>
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in selectedProducts"
                          :key="index"
                        >
                          <v-list-item-content>
                            {{ item.name | cartProduct }} X {{ item.qty }}
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon class="mr-2" @click="removeSelection(index)">mdi-delete-forever-outline</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="selectedProducts.length === 0"
                    color="primary"
                    @click="scheduleSelection"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content
              step="2"
            >
              <v-card elevation="0">
                <v-card-text>
                  <v-select
                    v-model="scheduledOrder.frequency"
                    :items="frequencyOptions"
                    label="Frequency"
                  ></v-select>
                </v-card-text>
                <v-card-text>
                  <v-menu
                    ref="menu"
                    v-model="nextDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="scheduledOrder.next"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="scheduledOrder.next"
                        label="Next Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="scheduledOrder.next"
                      no-title
                      scrollable
                      @input="$refs.menu.save(scheduledOrder.next)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="currentStep = 3"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card elevation="0">
                <v-card-text>
                  <v-autocomplete
                    v-model="scheduledOrder.ship_address_id"
                    :items="addresses"
                    label="Select shipping address"
                    item-value="id"
                    flat
                    dense
                    hide-details
                    class="mb-5"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ data.item.full_name }}
                          <v-spacer></v-spacer>
                          {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item-content>
                        <v-list-item-subtitle class="ml-5">
                          Address not found? <v-btn small text @click="newAddress">Create Address</v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="currentStep = 4"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content
              step="4"
            >
              <v-card elevation="0">
                <v-card-text>
                  <v-select
                    v-model="scheduledOrder.payment_method"
                    :items="paymentMethods"
                    item-text="text"
                    item-value="value"
                    label="Select payment option"
                    dense
                    single-line
                    @change="selectPayment"
                  ></v-select>
                </v-card-text>
                <v-card-text v-if="selectPaymentOption">
                  <v-select
                    v-model="scheduledOrder.payment_id"
                    :items="payments"
                    label="Select Payment Method"
                    item-value="id"
                    dense
                    flat
                    @change="paymentMethodSelected"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.card_name }}
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ item.card_name }}
                          <v-spacer></v-spacer>
                          {{ item.card_type }} {{ item.card_number }} {{ item.card_expiry_formatted }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item-content>
                        <v-list-item-subtitle class="ml-5">
                          Payment method not found?
                          <v-btn small text @click="toggleNewPaymentModal(true)">Add New Payment Method</v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>

                  <v-autocomplete
                    v-if="showPaymentAddressSelection"
                    v-model="scheduledOrder.bill_address_id"
                    :items="addresses"
                    label="Select shipping address"
                    item-value="id"
                    flat
                    dense
                    hide-details
                    class="mt-5 mb-5"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ data.item.full_name }}
                          <v-spacer></v-spacer>
                          {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item-content>
                        <v-list-item-subtitle class="ml-5">
                          Address not found? <v-btn small text @click="newAddress">Create Address</v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="addSchedule"
                  >
                    Add
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showNewAddressForm"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Address</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <vuetify-google-autocomplete
                  id="autocomplete"
                  ref="address"
                  placeholder="Enter address for validation"
                  v-on:no-results-found="noResults"
                  v-on:placechanged="getAddressData"
                >
                  <template #append>
                    <v-icon small>mdi-map-search</v-icon>
                  </template>
                </vuetify-google-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="address.street1" label="Street 1"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="address.street2" label="Street 2"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="address.city" label="City"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="address.state_id"
                  :items="states"
                  :filter="statesFilter"
                  item-text="text"
                  item-value="value"
                  label="State"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  dense
                  v-model="address.zip"
                  label="Zip"
                  :rules="zipRules"
                  maxlength="5"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="showNewAddressForm = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addAddress"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <add-payment-modal :isModalActive="addNewPayment" @close-modal="toggleNewPaymentModal"></add-payment-modal>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'CustomersPending',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    AddPaymentModal: () => import('@/views/components/AddPaymentModal')
  },
  data () {
    return {
      loading: true,
      search: '',
      errorMessages: [],
      expanded: [],
      selectedCustomer: {},
      nextDateMenu: false,
      selectedId: null,
      products: [],
      frequencyOptions: [
        {text: 'Does not repeat', value: 0},
        {text: 'Repeat Annually', value: 360},
        {text: 'Repeat Semi-Annually', value: 180},
        {text: 'Repeat Every 3 Months', value: 90},
        {text: 'Repeat Every 2 Months', value: 60},
        {text: 'Repeat Monthly', value: 30}
      ],
      paymentMethods: [{text: 'Credit Card', value: 'CC'}],
      scheduledOrder: {},
      product: {},
      selectedQuantity: 1,
      selectedProducts: [],
      defaultScheduledOrder: {
        contractor_id: undefined,
        customer_id: undefined,
        products: [],
        next: '',
        frequency: '',
        payment_method: '',
        payment_id: undefined,
        ship_address_id: undefined,
        bill_address_id: undefined,
        site_id: 3
      },
      zipRules: [v => v.length <= 5 || 'Max 5 characters'],
      address: {
        id: null,
        fname: '',
        lname: '',
        company: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: null,
        model_type: 'customer'
      },
      currentStep: 1,
      createNewSchedule: false,
      addNewPayment: false,
      showNewAddressForm: false,
      selectPaymentOption: false,
      showPaymentAddressSelection: false,
      statusColorMap: {
        SCHEDULED: 'success',
        PENDING_APPROVAL: 'primary',
        CANCELLED: 'red',
        UNSCHEDULED: 'red'
      }
    }
  },
  filters: {
    tag (value) {
      if (value.length > 0) {
        return 'Attention Needed'
      } else {
        return 'Valid'
      }
    },
    cartProduct (val) {
      return val.length > 60 ? `${val.slice(0, 60)}...` : val
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('customerManagement', {
      customers: state => state.pendingCustomers
    }),
    ...mapState('paymentManagement', {
      payments: state => state.payments
    }),
    ...mapState('contractorManagement', {
      contractor: state => state.contractor
    }),
    ...mapState({
      orderStatusMap: state => state.orderStatusMap,
      states: state => state.stateOptions
    }),
    ...mapState('addressManagement', {
      addresses: state => state.addresses
    }),
    headers () {
      return [
        { text: 'ID', align: ' d-none', value: 'id' },
        { text: 'Customer', value: 'customer', width: 450 },
        { text: 'Address', value: 'customer.shipping_address', align: ' d-none' },
        { text: 'Date Added', value: 'created_at' },
        { text: 'Product Count', value: 'products' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      add: 'addScheduled'
    }),
    ...mapActions('paymentManagement', {
      fetchPayment: 'fetchPayments'
    }),
    ...mapActions('customerManagement', {
      fetch: 'fetchCustomerBatch',
      deleteAssignedProduct: 'removeProduct'
    }),
    ...mapActions('contractorManagement', {
      fetchContractor: 'fetchContractor'
    }),
    ...mapActions('addressManagement', {
      addAddress: 'addAddress',
      fetchAddresses: 'fetchAddresses'
    }),
    loadContractor () {
      this.fetchContractor(this.user.model_id)
        .then(() => {
          this.defaultScheduledOrder.contractor_id = this.user.model_id
          this.loadPayment()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    loadPayment () {
      this.fetchPayment()
        .then(() => {
          this.setPaymentMethods()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    loadCustomers () {
      const obj = {
        id: this.$route.params.id,
        type: {
          batch_type: 'scheduled'
        }
      }

      this.fetch(obj)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    loadAddresses () {
      this.fetchAddresses({model_id: this.scheduledOrder.customer_id, model_type: 'customer'})
        .then(() => {
          this.toggleNewScheduleModal(true)
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    removeAssignedProduct (id, product_id) {
      this.deleteAssignedProduct({id, product_id})
        .then(() => {
          this.loadCustomers()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    selectPayment () {
      this.selectPaymentOption = this.scheduledOrder.payment_method === 'CC'
      if (this.selectPaymentOption) {
        this.loadPayment()
      }
    },
    setPaymentMethods () {
      if (this.contractor.settings.credit) {
        this.paymentMethods.push({text: 'Net Terms', value: 'NET30'})
      }
    },
    paymentMethodSelected () {
      this.showPaymentAddressSelection = true
    },
    selectProduct () {
      const index = this.selectedProducts.findIndex((o) => o.product_id === this.product.id)
      if (index !== -1) {
        this.$toast.error('Product already exists on current/previous scheduled order')
      } else {
        const obj = {
          product_id: this.product.id,
          name: this.product.name,
          quantity: this.selectedQuantity
        }

        this.product = {}
        this.selectedQuantity = 1

        this.selectedProducts.push(obj)
      }
    },
    scheduleSelection () {
      this.scheduledOrder.products = this.selectedProducts
      this.currentStep = 2
    },
    paymentSelection () {
      this.currentStep = 3
    },
    removeSelection (index) {
      this.selectedProducts.splice(index, 1)
    },
    filterProducts (products) {
      let tempProducts = []
      tempProducts = products.filter((product) => {
        return product.status === 'UNSCHEDULED'
      })

      return tempProducts
    },
    createSchedule (data) {
      this.products = data.products.filter((product) => {
        return product.status === 'UNSCHEDULED'
      })
      if (this.products.length === 0) {
        this.$toast.error('Customer has 0 assigned products. Please assign a product to the customer before creating a schedule.')
      } else {

        Object.assign(this.scheduledOrder, this.defaultScheduledOrder)
        this.scheduledOrder.customer_id = data.customer.id
        this.loadAddresses()
      }
    },
    toggleNewPaymentModal (val = false) {
      this.addNewPayment = val
    },
    toggleNewScheduleModal (val = false) {
      this.createNewSchedule = val
    },
    closeAdd () {
      this.selectedProducts = []
      Object.assign(this.scheduledOrder, this.defaultScheduledOrder)
      this.currentStep = 1

      this.toggleNewScheduleModal()
    },
    addSchedule () {
      if (this.scheduledOrder.payment_method === '') {
        this.$toast.error('Payment method is required. If one is not available, you will need to create it in the Billing section of your Account')
      } else {
        this.add(this.scheduledOrder)
          .then(() => {
            this.closeAdd()
            this.loadCustomers()
            this.$toast.success('Scheduled order created!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    newAddress () {
      this.address = {
        id: null,
        fname: '',
        lname: '',
        company: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: this.customer.id,
        model_type: 'customer'
      }
      this.showNewAddressForm = true
    },
    addAddress () {
      this.address.fname = this.customer.fname
      this.address.lname = this.customer.lname
      this.address.company = this.customer.company_name

      this.addAddress(this.address)
        .then((response) => {
          this.scheduledOrder.ship_address_id = response.id
          this.showNewAddressForm = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    statesFilter (item, queryText) {
      const name = item.text.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    getAddressData (addressData) {
      const state = this.states.find((s) => s.abbreviation === addressData.administrative_area_level_1)
      this.address.zip = addressData.postal_code
      this.address.street1 = addressData.name
      this.address.city = addressData.locality
      this.address.state_id = state.value
    },
    noResults () {
      this.$toast.error(this.$refs.address)
      this.$toast.error(this.$refs.address.autocompleteText)
    },
    resetDefault () {
      this.defaultScheduledOrder = {
        contractor_id: this.user.model_id,
        customer_id: undefined,
        products: [],
        qty: 1,
        next: '',
        frequency: '',
        payment_method: '',
        payment_id: undefined,
        ship_address_id: undefined,
        bill_address_id: undefined
      }
    }
  },
  created () {
    this.loadCustomers()
    this.loadContractor()
  }
}
</script>

<style scoped>

</style>
