export default {
  orders: [
    {
      to: '/orders/open',
      title: 'Open Orders',
      icon: 'mdi-cart',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/orders/create',
      title: 'New Order',
      icon: 'mdi-cart-plus',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/orders/list',
      title: 'Order History',
      icon: 'mdi-format-list-checkbox',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/orders/scheduled/pending',
      title: 'Pending Approval',
      icon: 'mdi-calendar-alert',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/orders/scheduled/approved',
      title: 'Scheduled',
      icon: 'mdi-calendar-check',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/orders/service-titan/pending',
      title: 'Pending Service Titan',
      icon: 'mdi-calendar-alert',
      meta: {
        serviceTitan: true
      }
    },
    {
      to: '/orders/batches',
      title: 'Order Batches',
      icon: 'mdi-calendar-plus',
      meta: {
        serviceTitan: false
      }
    }
  ],
  customers: [
    {
      to: '/customers/list',
      title: 'Customers',
      icon: 'mdi-account-multiple',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/customers/pending/verification',
      title: 'Pending Verification',
      icon: 'mdi-account-check',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/customers/pending/products',
      title: 'Pending Products',
      icon: 'mdi-playlist-plus',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/customers/pending/schedule',
      title: 'Pending Schedule',
      icon: 'mdi-calendar-plus',
      meta: {
        serviceTitan: false
      }
    }
  ],
  account: [
    {
      to: '/account/company',
      title: 'Company',
      icon: 'mdi-office-building',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/account/billing',
      title: 'Billing',
      icon: 'mdi-wallet',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/account/users',
      title: 'Users',
      icon: 'mdi-account-group',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/account/files',
      title: 'Files',
      icon: 'mdi-file-document-outline',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/account/settings',
      title: 'Settings',
      icon: 'mdi-cogs',
      meta: {
        serviceTitan: false
      }
    },
    {
      to: '/account/reports',
      title: 'Reports',
      icon: 'mdi-chart-bar',
      meta: {
        serviceTitan: false
      }
    }
  ]
}
