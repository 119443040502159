const mutations = {
  setStates (state, payload) {
    state.states = payload
  },
  setDrawer: (state, payload) => (state.drawer = payload),
  toggleDrawer: state => (state.drawer = !state.drawer),
  setSnack (state, snack) {
    state.snack = snack
  }
}

export default mutations
