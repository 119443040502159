var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasUser
    ? _c(
        "div",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.orders,
              search: _vm.search,
              "fixed-header": "",
              height: _vm.height,
              "item-key": "id",
              loading: _vm.loading,
              "loading-text": "Loading... Please wait",
              "single-select": false,
              "show-select": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "white" } },
                        [
                          _vm.showTitle
                            ? _c("v-toolbar-title", [
                                _vm._v("Service Titan Pending Approval")
                              ])
                            : _vm._e(),
                          _vm.showTitle
                            ? _c("v-divider", {
                                staticClass: "mx-4",
                                attrs: { inset: "", vertical: "" }
                              })
                            : _vm._e(),
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Search",
                              "hide-details": "",
                              clearable: "",
                              "append-icon": "mdi-magnify",
                              dense: ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _c("v-divider", {
                            staticClass: "mx-4",
                            attrs: { inset: "", vertical: "" }
                          }),
                          _c(
                            "v-menu",
                            {
                              ref: "scheduledMenu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dates,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto"
                              },
                              on: {
                                "update:returnValue": function($event) {
                                  _vm.dates = $event
                                },
                                "update:return-value": function($event) {
                                  _vm.dates = $event
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: ""
                                                },
                                                model: {
                                                  value: _vm.dateRangeText,
                                                  callback: function($$v) {
                                                    _vm.dateRangeText = $$v
                                                  },
                                                  expression: "dateRangeText"
                                                }
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3332817968
                              ),
                              model: {
                                value: _vm.menu,
                                callback: function($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu"
                              }
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: {
                                    "no-title": "",
                                    scrollable: "",
                                    range: ""
                                  },
                                  model: {
                                    value: _vm.dates,
                                    callback: function($$v) {
                                      _vm.dates = $$v
                                    },
                                    expression: "dates"
                                  }
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.menu = false
                                        }
                                      }
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: { click: _vm.setDates }
                                    },
                                    [_vm._v(" OK ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var attrs = ref.attrs
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                  icon: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-dots-vertical")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1603716123
                              )
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { tile: false, flat: "", nav: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    { on: { click: _vm.approveOrders } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-cart-check")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Approve Selected")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { on: { click: _vm.cancelOrders } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-cancel")])],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Cancel Selected")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.customer_po",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-edit-dialog",
                        {
                          attrs: {
                            large: "",
                            "return-value": item.customer_po
                          },
                          on: {
                            "update:returnValue": function($event) {
                              return _vm.$set(item, "customer_po", $event)
                            },
                            "update:return-value": function($event) {
                              return _vm.$set(item, "customer_po", $event)
                            },
                            save: function($event) {
                              return _vm.savePO(item)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function() {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Add PO#",
                                        "single-line": ""
                                      },
                                      model: {
                                        value: item.customer_po,
                                        callback: function($$v) {
                                          _vm.$set(item, "customer_po", $$v)
                                        },
                                        expression: "item.customer_po"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          item.customer_po
                            ? _c("span", [_vm._v(_vm._s(item.customer_po))])
                            : _c("span", [_vm._v("Click to add PO#")])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.customer",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(item.customer.full_name))
                      ]),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(item.shipping_address.street1) +
                          " " +
                          _vm._s(item.shipping_address.street2) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(item.shipping_address.city) +
                          ", " +
                          _vm._s(item.shipping_address.state.name) +
                          " " +
                          _vm._s(item.shipping_address.zip) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.total",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" $" + _vm._s(item.total) + " ")]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-menu",
                        {
                          attrs: { bottom: "", right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mb-2 mr-5",
                                            attrs: {
                                              dark: "",
                                              icon: "",
                                              color: "primary"
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-dots-vertical")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.approveOrder(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-cart-check")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [_vm._v("Approve")])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelOrder(item.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-cancel")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [_vm._v("Cancel")])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.openOrder(item.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-eye-outline")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v("View Order Details")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.openCustomer(item.customer_id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-account-search")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v("View Customer")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "no-data",
                  fn: function() {
                    return [_vm._v(" No pending orders found. ")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _c("confirmation-modal", { ref: "confirm" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }