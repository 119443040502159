var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "files-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-file-document-outline" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Files ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-simple-table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-left" }, [_vm._v("File")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("File Type")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Description")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Download")])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.files, function(file, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(file.name))]),
                  _c("td", [_vm._v(_vm._s(file.extension))]),
                  _c("td", [_vm._v(_vm._s(file.description))]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            icon: "",
                            outlined: "",
                            href: "https://filterondemand.com/" + file.href,
                            download: ""
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-download")])],
                        1
                      )
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }