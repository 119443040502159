<template>
  <v-container id="scheduled-orders-list-view">
    <material-card
      color="primary"
      icon="mdi-account-multiple"
    >
      <template #title>
        Scheduled Orders
      </template>

      <scheduled-orders :show-title="false"></scheduled-orders>
    </material-card>
  </v-container>
</template>

<script>
export default {
  name: 'OrdersScheduled',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    ScheduledOrders: () => import('../components/ScheduledOrders')
  }
}
</script>

<style scoped>

</style>
