<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="reminders"
      :search="search"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="openAddReminder"
          >
            <v-icon>mdi-calendar-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ $utils.itemName(item) }}
      </template>

      <template v-slot:[`item.frequency`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.frequency"
          @save="saveFrequency(item)"
          >
          {{ item.frequency }} days
          <template v-slot:input>
            <v-select
              v-model="item.frequency"
              :items="frequencyOptions"
              label="Frequency"
              ></v-select>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.next`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.next_date"
          @save="saveNextDate(item)"
          >
          {{ item.next_date }}
          <template v-slot:input>
            <v-menu
              ref="menu"
              v-model="nextDateMenu"
              :close-on-content-click="false"
              :return-value.sync="item.next_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.next_date"
                  label="Next Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.next_date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(item.next_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editReminder(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          class="mr-2"
          @click="deleteReminder(item.id)"
        >
          mdi-delete-outline
        </v-icon>
      </template>
      <template v-slot:no-data>
        No filter change reminders found.
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogAdd"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Reminder</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="editedReminder.product_id"
                  :items="products"
                  item-text="name"
                  item-value="id"
                  label="Select Assigned Product"
                  single-line
                  ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="editedReminder.frequency"
                  :items="frequencyOptions"
                  label="Frequency"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-menu
                  ref="menu"
                  v-model="nextDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="editedReminder.next_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedReminder.next_date"
                      label="Next Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedReminder.next_date"
                    no-title
                    scrollable
                    @input="$refs.menu.save(editedReminder.next_date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeAdd"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="addReminder"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit Reminder</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="editedReminder.frequency"
                  :items="frequencyOptions"
                  label="Frequency"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-menu
                  ref="menu"
                  v-model="nextDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="editedReminder.next_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedReminder.next_date"
                      label="Next Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedReminder.next_date"
                    no-title
                    scrollable
                    @input="$refs.menu.save(editedReminder.next_date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-modal ref="confirm" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    showToolbar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      loading: true,
      search: '',
      frequencyOptions: [30, 60, 90, 180, 360],
      nextDateMenu: false,
      dialog: false,
      dialogAdd: false,
      editedReminder: {
        id: null,
        product_id: null,
        site_id: 3,
        frequency: null,
        next: null,
        active: true
      },
      defaultReminder: {
        id: null,
        product_id: null,
        site_id: 3,
        frequency: null,
        next: null,
        active: true
      },
      products: []
    }
  },
  computed: {
    ...mapState('reminderManagement', {
      reminders: state => state.reminders
    }),
    ...mapState('customerManagement', {
      customer: state => state.customer
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    headers () {
      return [
        {
          text: 'ID',
          value: 'id',
          align: ' d-none'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Frequency',
          value: 'frequency'
        },
        {
          text: 'Next Date',
          value: 'next_date'
        },
        {
          text: 'Products',
          value: 'products',
          align: ' d-none'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    disableAdd () {
      return !!this.customer.email
    }
  },
  methods: {
    ...mapActions('reminderManagement', {
      fetch: 'fetch',
      update: 'update',
      delete: 'delete',
      add: 'add'
    }),
    ...mapActions('customerManagement', {
      fetchProducts: 'fetchAssignedProducts'
    }),
    init () {
      this.fetch({customer_id: this.$route.params.id})
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$toast.error(error.data.error)
        })
    },
    loadAssignedProducts () {
      this.fetchProducts(this.$route.params.id)
        .then((response) => {
          this.products = response
          this.loading = false
        })
        .catch(error => {
          this.$toast.error(error)
          this.loading = false
        })
    },
    saveFrequency (reminder) {
      this.update({id: reminder.id, reminder})
        .then(() => {
          this.$toast.success('Reminder frequency updated!')
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    saveNextDate (reminder) {
      this.update({id: reminder.id, reminder})
        .then(() => {
          this.$toast.success('Reminder next date updated!')
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    editReminder (reminder) {
      this.editedReminder = {
        id: reminder.id,
        frequency: reminder.frequency,
        next_date: reminder.next_date
      }
      this.dialog = true
    },
    closeAdd () {
      this.editedReminder = this.defaultReminder
      this.dialogAdd = false
    },
    openAddReminder () {
      if (this.products.length === 0) {
        this.$toast.error('Customer has 0 assigned products. Please assign a product to the customer before creating a reminder.')
      } else if (this.customer.email === null || this.customer.email === '') {
        this.$toast.error('Customer email is required to create filter change reminders.')
      } else {
        this.dialogAdd = true
      }
    },
    addReminder () {
      const newReminder = {
        customer_id: this.$route.params.id,
        contractor_id: this.customer.contractor_id,
        fullname: this.customer.full_name,
        recipient: this.customer.email,
        product_id: this.editedReminder.product_id,
        site_id: this.editedReminder.site_id,
        frequency: this.editedReminder.frequency,
        next_date: this.editedReminder.next_date,
        active: true
      }
      this.add(newReminder)
        .then(() => {
          this.closeAdd()
          this.$toast.success('Reminder created!')
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    async deleteReminder (id) {
      if (await this.$refs.confirm.open('Delete Reminder?', 'Are you sure you want to delete this reminder?', { color: 'red ' })) {
        this.delete(id)
          .then(() => {
            this.$toast.success('Reminder deleted!')
          })
          .catch((error) => {
            this.$toast.error(error)
          })
        this.closeDelete()
      }
    },
    save () {
      this.update(this.editedReminder)
        .then(() => {
          this.dialog = false
          this.$toast.success('Reminder updated!')
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    close () {
      this.dialog = false
      this.editedReminder = this.defaultReminder
    },
    closeDelete () {
      this.dialogDelete = false
      this.editedReminder = this.defaultReminder
    }
  },
  created () {
    this.init()
    this.loadAssignedProducts()
  }
}
</script>

<style lang="scss" scoped>

</style>
