<template>
  <v-container id="users-view">
    <material-card
      color="primary"
      icon="mdi-account-group"
    >
      <template #title>
        Users
      </template>

      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        loading-text="Loading Users... Please wait"
        class="mr-2 ml-2">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              placeholder="Search"
              hide-details
              v-model="search"
              clearable
              append-icon="mdi-magnify"
              dense/>
            <v-spacer></v-spacer>
<!--            <v-btn color="secondary" dark class="mb-2 mr-5" @click="$refs.users_file.click()">
              Upload Users
            </v-btn>
            <input
              type="file"
              @change="file_selected"
              style="display: none;"
              ref="users_file"
              accept=".csv">-->
            <v-btn
              :disabled="!user.admin_access"
              color="primary"
              dark
              class="mb-2"
              @click="createDialog=true"
            >
              Add User
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small icon class="mr-2" :disabled="!canEdit(item.id)" @click="editUser(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn small icon :disabled="!canDelete(item.id)" @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </material-card>

    <confirmation-modal ref="confirm" />

    <v-dialog v-model="createDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create User</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="newUserForm"
            v-model="newUserValid"
            lazy-validation
          >
            <v-text-field
              v-model="newUser.name"
              label="Name"
              :rules="nameRules"
              required
            ></v-text-field>

            <v-text-field
              :rules="emailRules"
              v-model="newUser.email"
              label="Email"
              required
            ></v-text-field>

            <v-text-field
              :counter="8"
              :rules="passwordRules"
              label="Password"
              v-model="newUser.password"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>

            <v-text-field
              :counter="8"
              :rules="passwordRules.concat(newPasswordConfirmationRule)"
              label="Confirm Password"
              v-model="newUser.password_confirm"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>

            <v-checkbox v-model="newUser.admin_access" label="Is Admin?"></v-checkbox>

            <v-checkbox v-model="newUser.active" label="Is Active?"></v-checkbox>

          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAdd">Cancel</v-btn>
          <v-btn color="blue darken-1" text :disabled="!newUserValid" @click="add">Add User</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit User</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="editUserForm"
            v-model="editUserValid"
            lazy-validation
          >
            <v-text-field
              v-model="editedUser.name"
              label="Name"
              :rules="nameRules"
              required
            ></v-text-field>

            <v-text-field
              :rules="emailRules"
              v-model="editedUser.email"
              label="Email"
              required
            ></v-text-field>

            <div v-if="this.user.admin_access">
              <v-checkbox v-model="editedUser.admin_access" label="Is Admin?"></v-checkbox>
            </div>

            <v-checkbox v-model="editedUser.active" label="Is Active?"></v-checkbox>

            <v-checkbox v-model="resetPassword" label="Reset Password?"></v-checkbox>

            <v-text-field
              v-if="resetPassword"
              :counter="8"
              :rules="passwordRules"
              label="Password"
              v-model="editedUser.password"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>

            <v-text-field
              v-if="resetPassword"
              :counter="8"
              :rules="passwordRules.concat(editPasswordConfirmationRule)"
              label="Confirm Password"
              v-model="editedUser.password_confirm"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEdit">Cancel</v-btn>
          <v-btn color="blue darken-1" text :disabled="!editUserValid" @click="edit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="usersUploadListLoadingDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading users list
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'

export default {
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      query: {
        page: 1,
        limit: 15,
        keyword: '',
        sort_col: 'id',
        model_id: '',
        status: 'active',
        model_type: 'contractor',
        sort_direction: 'asc'
      },
      newUserValid: false,
      editUserValid: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Invalid email address'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be at least 8 characters'
      ],
      nameRules: [v => !!v || 'Name is required'],
      resetPassword: false,
      editDialog: false,
      createDialog: false,
      editedUser: {},
      newUser: {},
      defaultUser: {
        id: undefined,
        name: '',
        email: '',
        role: 'contractor',
        site_id: 3,
        site_access: [3],
        user_type: 'contractor',
        active: true,
        model_id: undefined,
        admin_access: false,
        password: '',
        password_confirm: ''
      },
      uploadedUsers: [],
      roleOptions: [
        {
          text: 'Admin',
          value: 'admin'
        },
        {
          text: 'Manager',
          value: 'manager'
        },
        {
          text: 'Editor',
          value: 'editor'
        },
        {
          text: 'User',
          value: 'user'
        }
      ],
      search: '',
      show: false,
      model_id: undefined,
      primary_role: 'contractor',
      loading: false,
      usersUploadListLoadingDialog: false
    }
  },
  watch: {
    options: {
      handler () {
        this.fetchUsers()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('userManagement', {
      users: state => state.users
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('contractorManagement', {
      contractor: state => state.contractor
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: ' d-none',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name',
          width: 450
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Date Added',
          value: 'created_at'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    editPasswordConfirmationRule () {
      return () => (this.editedUser.password === this.editedUser.password_confirm) || 'Password must match'
    },
    newPasswordConfirmationRule () {
      return () => (this.newUser.password === this.newUser.password_confirm) || 'Password must match'
    }
  },
  methods: {
    ...mapActions('userManagement', {
      fetch: 'fetchUsers',
      addUser: 'add',
      update: 'update',
      batch: 'addUsers',
      deleteUser: 'delete'
    }),
    ...mapActions('contractorManagement', {
      fetchContractor: 'fetchContractor'
    }),
    ...mapMutations('auth', {
      updateCurrentUser: 'updateUser'
    }),
    init () {
      this.query.model_id = this.user.model_id
      this.defaultUser.model_id = this.user.model_id
      this.defaultUser.model_type = 'contractor'

      Object.assign(this.newUser, this.defaultUser)
      Object.assign(this.editedUser, this.defaultUser)

      this.fetchUsers()
    },
    fetchUsers () {
      this.fetch(this.query)
        .then(() => {
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    editUser (user) {
      this.editedUser = {
        id: user.id,
        name: user.name,
        email: user.email,
        role: 'contractor',
        site_id: user.site_id,
        user_type: 'contractor',
        active: user.active,
        model_id: user.model_id,
        admin_access: user.admin_access,
        password: '',
        password_confirm: ''
      }

      this.resetPassword = false

      this.editDialog = true
    },
    closeEdit () {
      this.$refs.editUserForm.reset()
      this.editDialog = false
    },
    edit () {
      this.update({
        id: this.editedUser.id,
        user: this.editedUser
      })
        .then((response) => {
          if (this.user.id === this.editedUser.id) {
            this.updateCurrentUser(response)
          }
          this.$toast.success('User has been updated.')
          this.closeEdit()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    closeAdd () {
      this.$refs.newUserForm.reset()
      this.createDialog = false
    },
    add () {
      this.addUser(this.newUser)
        .then(() => {
          this.$toast.success('User has been added.')
          this.closeAdd()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    async confirmDelete (user) {
      if (await this.$refs.confirm.open('Are you sure?', `Are you sure you want to delete ${user.name}?`, { color: 'red' })) {
        this.deleteUser(user.id)
          .then(() => {
            this.$toast.success('User has been removed.')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    file_selected (input) {
      if (input.target.files && input.target.files[0]) {
        this.handleFileUpload(input.target.files[0])
      }
    },
    handleFileUpload (file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('site_id', 3)

      this.usersUploadListLoadingDialog = true

      this.batch(formData)
        .then(() => {
          this.$toast.success('Users uploaded.')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.usersUploadListLoadingDialog = false
        })
    },
    canEdit (id) {
      return this.user.id === id || this.user.admin_access
    },
    canDelete (id) {
      return this.user.id !== id && this.user.admin_access
    }
  },
  created () {
    this.init()
  }
}
</script>
