var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "scheduled-order-address-panel" } },
    [
      !_vm.loading
        ? _c("panel", {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(" Customer Address - "),
                      _c(
                        "span",
                        { staticClass: "px-2 subtitle text-decoration-none" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "customer_detail",
                                  params: { id: _vm.order.customer.id }
                                }
                              }
                            },
                            [_vm._v("View Customer Record")]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "v-form",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "6", md: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          disabled: true,
                                          label: "First Name"
                                        },
                                        model: {
                                          value: _vm.addressLocal.fname,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addressLocal,
                                              "fname",
                                              $$v
                                            )
                                          },
                                          expression: "addressLocal.fname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "6", md: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          disabled: true,
                                          label: "Last Name"
                                        },
                                        model: {
                                          value: _vm.addressLocal.lname,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addressLocal,
                                              "lname",
                                              $$v
                                            )
                                          },
                                          expression: "addressLocal.lname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          disabled: true,
                                          label: "Street 1"
                                        },
                                        model: {
                                          value: _vm.addressLocal.street1,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addressLocal,
                                              "street1",
                                              $$v
                                            )
                                          },
                                          expression: "addressLocal.street1"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          disabled: true,
                                          label: "Street 2"
                                        },
                                        model: {
                                          value: _vm.addressLocal.street2,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addressLocal,
                                              "street2",
                                              $$v
                                            )
                                          },
                                          expression: "addressLocal.street2"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4", md: "4" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          disabled: true,
                                          label: "City"
                                        },
                                        model: {
                                          value: _vm.addressLocal.city,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addressLocal,
                                              "city",
                                              $$v
                                            )
                                          },
                                          expression: "addressLocal.city"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4", md: "4" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          disabled: true,
                                          label: "State"
                                        },
                                        model: {
                                          value: _vm.addressLocal.state.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addressLocal.state,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "addressLocal.state.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4", md: "4" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          disabled: true,
                                          label: "Zip Code"
                                        },
                                        model: {
                                          value: _vm.addressLocal.zip,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addressLocal,
                                              "zip",
                                              $$v
                                            )
                                          },
                                          expression: "addressLocal.zip"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-sm-caption float-right"
                                      },
                                      [
                                        _vm._v(
                                          "*To edit customer address, go to customer record page."
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3953079137
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }