import state from './moduleCartManagementState'
import mutations from './moduleCartManagementMutations'
import actions from './moduleCartManagementActions'
import getters from './moduleCartManagementGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
