var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.entries(_vm.customer).length > 0
    ? _c(
        "div",
        { staticClass: "customer-validation-dialog" },
        [
          _c(
            "v-dialog",
            {
              style: { zIndex: _vm.options.zIndex },
              attrs: { "max-width": "1000px" },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  return _vm.cancel($event)
                },
                "click:outside": _vm.cancel
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        dark: "",
                        color: _vm.options.color,
                        dense: "",
                        flat: ""
                      }
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "white--text" }, [
                        _vm._v("Customer Validation")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "ma-5" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "center",
                              attrs: { cols: "12", md: "12" }
                            },
                            [
                              _c("h1", { staticClass: "font-black" }, [
                                _vm._v("Potential Customer Validation Errors")
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "center red--text font-weight-bold",
                              attrs: { cols: "12", md: "12" }
                            },
                            [
                              _vm._v(" Error flagged as: "),
                              _vm._l(_vm.customer.validation_errors, function(
                                validation,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _vm._v(_vm._s(validation.error)),
                                  _c("br")
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            align: "center",
                            justify: "center",
                            dense: ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("customer-validation-card", {
                                attrs: {
                                  "customer-data": _vm.customer,
                                  "min-height": _vm.options.height,
                                  "max-width": _vm.options.width
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function() {
                                        return [_vm._v(" New Customer ")]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "actions",
                                      fn: function() {
                                        return [
                                          _vm.customer.validation_errors[
                                            _vm.errorIndex
                                          ].error === "Incorrect Address" &&
                                          !_vm.customer.validation_errors[
                                            _vm.errorIndex
                                          ].data
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openEditAddress(
                                                        _vm.customer
                                                          .shipping_address
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Edit")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectNewCustomer()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.newCustomerButtonText
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2916754817
                                )
                              })
                            ],
                            1
                          ),
                          _vm.customer.validation_errors[_vm.errorIndex]
                            .error === "Incorrect Address" &&
                          _vm.customer.validation_errors[_vm.errorIndex].data
                            ? _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("customer-validation-card", {
                                    attrs: {
                                      "customer-data":
                                        _vm.customer.validation_errors[
                                          _vm.errorIndex
                                        ].data,
                                      "min-height": _vm.options.height,
                                      "max-width": _vm.options.width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function() {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.customer
                                                      .validation_errors[
                                                      _vm.errorIndex
                                                    ].error
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "actions",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectSuggestedAddress(
                                                        _vm.errorIndex
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Select Suggested")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3481945084
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.customer.validation_errors[_vm.errorIndex]
                            .error !== "Incorrect Address"
                            ? _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("customer-validation-card", {
                                    attrs: {
                                      "customer-data":
                                        _vm.customer.validation_errors[
                                          _vm.errorIndex
                                        ].associated,
                                      "min-height": _vm.options.height,
                                      "max-width": _vm.options.width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function() {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.customer
                                                      .validation_errors[
                                                      _vm.errorIndex
                                                    ].error
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "actions",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectExistingCustomer(
                                                        _vm.errorIndex
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Select")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.mergeCustomers(
                                                        _vm.errorIndex
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Merge")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1022903182
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.customer.validation_errors.length > 1
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-pagination", {
                                    attrs: {
                                      length: _vm.total,
                                      "total-visible": 5
                                    },
                                    on: {
                                      input: _vm.selectError,
                                      next: _vm.nextError,
                                      previous: _vm.previousError
                                    },
                                    model: {
                                      value: _vm.page,
                                      callback: function($$v) {
                                        _vm.page = $$v
                                      },
                                      expression: "page"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: _vm.validationBtnColor },
                          on: { click: _vm.cancel }
                        },
                        [_c("span", [_vm._v("Fix Later")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.editAddress
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "700px" },
                  model: {
                    value: _vm.editAddress,
                    callback: function($$v) {
                      _vm.editAddress = $$v
                    },
                    expression: "editAddress"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "text-h5" }, [
                          _vm._v("Update Address")
                        ])
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            [
                              _vm.invalid
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            { attrs: { type: "error" } },
                                            [
                                              _vm._v(
                                                " Invalid address entered. "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("google-auto-complete", {
                                        on: {
                                          "google-address": _vm.addressFound
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          label: "First Name"
                                        },
                                        model: {
                                          value: _vm.address.fname,
                                          callback: function($$v) {
                                            _vm.$set(_vm.address, "fname", $$v)
                                          },
                                          expression: "address.fname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          label: "Last Name"
                                        },
                                        model: {
                                          value: _vm.address.lname,
                                          callback: function($$v) {
                                            _vm.$set(_vm.address, "lname", $$v)
                                          },
                                          expression: "address.lname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { dense: "", label: "Company" },
                                        model: {
                                          value: _vm.address.company,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "company",
                                              $$v
                                            )
                                          },
                                          expression: "address.company"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { dense: "", label: "Street 1" },
                                        model: {
                                          value: _vm.address.street1,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "street1",
                                              $$v
                                            )
                                          },
                                          expression: "address.street1"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { dense: "", label: "Street 2" },
                                        model: {
                                          value: _vm.address.street2,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "street2",
                                              $$v
                                            )
                                          },
                                          expression: "address.street2"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { dense: "", label: "City" },
                                        model: {
                                          value: _vm.address.city,
                                          callback: function($$v) {
                                            _vm.$set(_vm.address, "city", $$v)
                                          },
                                          expression: "address.city"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.states,
                                          filter: _vm.statesFilter,
                                          "item-text": "text",
                                          "item-value": "value",
                                          label: "State",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.address.state_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "state_id",
                                              $$v
                                            )
                                          },
                                          expression: "address.state_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          label: "Zip",
                                          rules: _vm.zipRules,
                                          maxlength: "5",
                                          counter: ""
                                        },
                                        model: {
                                          value: _vm.address.zip,
                                          callback: function($$v) {
                                            _vm.$set(_vm.address, "zip", $$v)
                                          },
                                          expression: "address.zip"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red darken-1", text: "" },
                              on: { click: _vm.closeEdit }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: { click: _vm.update }
                            },
                            [_vm._v(" Save ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("confirmation-modal", { ref: "confirm" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }