export default {
  authRequest (state) {
    state.status = 'loading'
  },
  authSuccess (state, payload) {
    state.status = 'success'
    state.token = payload.token
    state.user = payload.user
  },
  authError (state) {
    state.status = 'error'
  },
  setUser (state, payload) {
    state.user = payload
  },
  updateUser (state, payload) {
    state.user = payload
  },
  logout (state) {
    state.status = ''
    state.token = ''
  }
}
