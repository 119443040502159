<template>
  <div v-if="hasUser">
    <v-data-table
      :headers="headers"
      :items="orders"
      :search="search"
      fixed-header
      :height="height"
      item-key="id"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Recent Orders</v-toolbar-title>
          <v-divider class="mx-4" inset vertical/>
          <v-text-field
            placeholder="Search"
            hide-details
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            dense/>
          <v-spacer></v-spacer>
          <v-menu
            ref="scheduledMenu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                class="mt-4"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
              :max="maxDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="setDates"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <template v-if="orders.length > 0" v-slot:extension>
            <v-btn
              color="primary"
              @click="exportToExcel"
            >
              EXPORT
            </v-btn>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:[`item.customer`]="{ item }">
        <span class="font-weight-bold">{{ item.customer_name }}</span><br>
        {{ item.shipping_address.street1 }} {{ item.shipping_address.street2 }} <br>
        {{ item.shipping_address.city }}, {{ item.shipping_address.state.name }} {{ item.shipping_address.zip }}
      </template>

      <template v-slot:[`item.customer_po`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.customer_po"
          @save="savePO(item)"
        >
          <span v-if="item.customer_po">{{ item.customer_po }}</span>
          <span v-else>Click to add PO#</span>
          <template v-slot:input>
            <v-text-field
              v-model="item.customer_po"
              label="Add PO#"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        ${{ item.total }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="statusColorMap[item.status]">
          {{ orderStatusMap[item.status] }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openOrder(item.id)">
              <v-list-item-icon>
                <v-icon>mdi-eye-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Open Order Record</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openCustomer(item.customer.id)">
              <v-list-item-icon>
                <v-icon>mdi-account-search</v-icon>
              </v-list-item-icon>
              <v-list-item-title>View Customer</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-if="isEnabled('no-data')" v-slot:no-data>
        No recent orders found.
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'

export default {
  props: {
    height: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      query: {
        paginated: false,
        start: '',
        end: '',
        site_id: 3,
        contractor_id: null,
        load_customer: true,
        load_shipping_address: true,
        load_products: false
      },
      fullwidth: 0,
      dateFormat: 'MM/DD/YYYY',
      maxDate: undefined,
      menu: false,
      dates: [],
      loading: true,
      search: '',
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      exportHeaders: [
        'ID',
        'Order Date',
        'Customer',
        'Street',
        'Apt/Unit#',
        'City',
        'State',
        'Zip Code',
        'PO#',
        'Total',
        'Status',
        'Created By'
      ],
      exportData: [
        'id',
        'created_at',
        'customer_name',
        'street1',
        'street2',
        'city',
        'abbreviation',
        'zip',
        'customer_po',
        'total',
        'status',
        'created_by'
      ]
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('orderManagement', {
      orders: state => state.orders
    }),
    ...mapState('contractorManagement', {
      contractor: state => state.contractor
    }),
    ...mapState({
      orderStatusMap: state => state.orderStatusMap,
      statusColorMap: state => state.orderStatusColorMap
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: true
        },
        {
          text: 'Order Date',
          value: 'created_at'
        },
        {
          text: 'Name',
          value: 'customer_name',
          align: ' d-none'
        },
        {
          text: 'Customer',
          value: 'customer'
        },
        {
          text: 'Address',
          value: 'shipping_address',
          align: ' d-none'
        },
        {
          text: 'PO#',
          value: 'customer_po'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Created By',
          value: 'created_by'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    dateRangeText () {
      return `${this.query.start} - ${this.query.end}`
    },
    hasUser () {
      return Object.entries(this.user).length > 0
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      fetch: 'fetchOrders',
      update: 'update'
    }),
    init () {
      this.query.contractor_id = this.user.model_id

      const startDate = this.$moment().subtract(7, 'days').startOf('days')
      const endDate = this.$moment().endOf('days')

      this.maxDate = this.$moment().format('YYYY-MM-DD')
      this.dates = [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]

      this.query.start = startDate.format(this.dateFormat)
      this.query.end = endDate.format(this.dateFormat)

      this.fetchOrders()
    },
    fetchOrders () {
      this.query.contractor_id = this.user.model_id

      this.fetch(this.query)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
          this.loading = false
        })
    },
    setDates () {
      this.menu = false
      this.query.start = this.$moment(this.dates[0]).format(this.dateFormat)
      this.query.end = this.$moment(this.dates[1]).format(this.dateFormat)

      this.loading = true
      this.fetchOrders()
    },
    openOrder (id) {
      this.$router.push({name: 'order_details', params: {id}})
    },
    openCustomer (id) {
      this.$router.push({name: 'customer_detail', params: {id}})
    },
    savePO (order) {
      this.update({id: order.id, payload: { customer_po: order.customer_po }})
        .then(() => {
          this.$toast.success('PO# Updated!')
        })
        .catch((error) => {
          this.$toast.error(error)
        })
    },
    isEnabled (slot) {
      const records = this.orders.length

      if (slot === 'no-data' && records === 0) {
        return true
      }

      return slot === 'body' && records > 0
    },
    exportToExcel () {
      import('@/plugins/Export2Excel').then(excel => {
        const data = this.formatJson(this.exportData, this.orders)

        excel.export_json_to_excel({
          header: this.exportHeaders,
          data,
          filename: 'OrderHistory',
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        const addressProps = ['street1', 'street2', 'city', 'abbreviation', 'zip']
        if (addressProps.includes(j)) {
          return this.getAddress(j, v)
        } else {
          return v[j]
        }
      }))
    },
    getAddress (key, data) {
      if (key === 'abbreviation') {
        return data.shipping_address.state[key]
      }

      return data.shipping_address[key]
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.vue-daterange-picker ::v-deep .reportrange-text {
  border: none;
  border-bottom: 1px solid #ccc !important;
  min-height: 48px;
  min-width: 350px;
  line-height: 38px;
}
</style>
