<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 115px; right: -35px; border-radius: 8px; z-index: 1;"
      >
      <v-icon large>
        mdi-cogs
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
      >
      <v-card
        class="text-center mb-0"
        width="350"
        >
        <v-card-text>
          <strong class="mb-3 d-inline-block">Statistics Settings</strong>

          <v-row
            align="center"
            no-gutters
            v-for="(statistic, i) in user.settings.statistics"
            :key="`statistics-${i}`"
            >

            <v-col cols="auto">
              {{ settingsMap[statistic.name] }}
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="statistic.value"
                class="ma-0 pa-0"
                color="primary"
                hide-details
                @change="updateSettings()"
                />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <strong class="mt-3 mb-3 d-inline-block">Datatables Settings</strong>

          <v-row
            align="center"
            no-gutters
            v-for="(datatable, i) in settings"
            :key="`datatables-${i}`"
            >

            <v-col cols="auto">
              {{ settingsMap[datatable.name] }}
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="datatable.value"
                class="ma-0 pa-0"
                color="primary"
                hide-details
                @change="updateSettings()"
                />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Settings',
  data () {
    return {
      menu: false,
      settingsMap: {
        orders_mtd: 'Orders MTD',
        orders_wtd: 'Orders WTD',
        orders_ytd: 'Orders YTD',
        customers_added: 'Customers Added',
        scheduled_orders: 'Scheduled Orders',
        pending_customers: 'Customers Pending Approval',
        wtd_order_dollars: 'WTD Order $',
        reminders_scheduled: 'Reminders Scheduled',
        customers_without_products: 'Customers w/o Products Assigned',
        show_recent_orders: 'Show recent order history',
        show_scheduled_orders: 'Show scheduled orders next 30 days',
        show_unapproved_service_titan: 'Show pending ServiceTitan'
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    settings () {
      let tempSettings = this.user.settings.datatables

      tempSettings = tempSettings.filter((item) => {
        if (!this.user.model_settings.st_integration && item.name === 'show_unapproved_service_titan') {
          return false
        } else {
          return true
        }
      })

      return tempSettings
    }
  },
  methods: {
    ...mapActions('auth', {
      fetchSettings: 'fetchSettings',
      update: 'updateSettings'
    }),
    updateSettings () {
      this.update({id: this.user.id, settings: this.user.settings})
        .then(() => {
          this.$emit('update-settings')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  }
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
