<template>
  <v-card
    outlined
  >
    <div class="border-bottom px-3 py-2">
      <v-card-title class="align-start p-0">
        <slot name="title" />

        <template v-if="title">
          {{ title }}
        </template>

        <div class="text-subtitle-1 mb-n4">
          <slot name="subtitle" />

          <template v-if="subtitle">
            {{ subtitle }}
          </template>
        </div>
      </v-card-title>
    </div>

    <slot />

    <v-card-text v-if="content">
      <slot name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    subtitle: String,
    title: String,
    content: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
/*.border-bottom {
  border-color: rgba(197, 197, 197, 0.925);
  border-bottom-style: solid;
  border-width: 0.5px;
}
.p-0 {
  padding: 0;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important
}*/
</style>
