export default {
  setAddress (state, payload) {
    state.address = payload
  },
  setAddresses (state, payload) {
    state.addresses = payload
  },
  addAddress (state, payload) {
    state.addresses.push(Object.assign({}, payload))
  },
  updateAddress (state, payload) {
    const index = state.addresses.findIndex((r) => r.id === payload.id)
    if (index > -1) {
      state.addresses.splice(index, 1, payload)
    }
  },
  removeAddress (state, id) {
    const index = state.addresses.findIndex((r) => r.id === id)
    state.addresses.splice(index, 1)
  }
}
