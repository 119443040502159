<template>
  <v-container v-if="hasUser" id="customers-list-view">
    <material-card
      color="primary"
      icon="mdi-account-multiple"
    >
      <template #title>
        Customers
      </template>

      <v-data-table
        :headers="headers"
        :items="customers"
        :loading="loading"
        :items-per-page="query.limit"
        :page.sync="query.page"
        :server-items-length="total"
        :options.sync="options"
        hide-default-footer
        loading-text="Loading Customers... Please wait"
        class="mr-2 ml-2">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              placeholder="Search"
              hide-details
              v-model="query.keyword"
              clearable
              @input="handleSearch"
              @click:clear="clearSearch"
              append-icon="mdi-magnify"
              dense />
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="showActive"
              label="Active Customers Only?"
              class="mt-5"
              @change="toggleCustomerStatus"
              ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2 mr-5"
              @click.stop="openAddCustomer"
            >
              Add Customer
            </v-btn>
            <v-btn
              color="secondary"
              dark
              @click="$refs.customers_file.click()"
              class="mb-2"
              >
              Upload List
            </v-btn>
            <input
              type="file"
              @change="file_selected"
              style="display: none;"
              ref="customers_file"
              accept=".csv">
          </v-toolbar>
        </template>
        <template v-slot:[`item.shipping_address`]="{ item }">
          {{ item.shipping_address.street1 }} {{ item.shipping_address.street2 }} <br>
          {{ item.shipping_address.city }}, {{ item.shipping_address.state.name }} {{ item.shipping_address.zip }}
        </template>
        <template v-slot:[`item.validation_errors`]="{ item }">
          <v-chip
            v-if="item.validation_errors.length === 0"
            class="ma-2"
            color="green"
            text-color="white"
            small
            >
            <v-avatar left>
              <v-icon small>mdi-thumb-up</v-icon>
            </v-avatar>
            Verified
          </v-chip>
          <v-chip
            v-else
            v-for="(validation, index) in item.validation_errors"
            :key="index"
            class="ma-2"
            color="red"
            text-color="white"
            small
          >
            <v-avatar left>
              <v-icon small>mdi-alert-box-outline</v-icon>
            </v-avatar>
            {{ validation.error }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            class="ma-2"
            :color="getStatusColor(item.status)"
            text-color="white"
            small
            >
            {{ item.status | capitalize }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="item.validation_errors.length > 0" @click="openValidation(item)">
                <v-list-item-icon>
                  <v-icon>mdi-account-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>View Validation</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openCustomer(item.id)">
                <v-list-item-icon>
                  <v-icon>mdi-account-search</v-icon>
                </v-list-item-icon>
                <v-list-item-title>View Customer</v-list-item-title>
              </v-list-item>
              <v-list-item @click="updateCustomer(item)">
                <v-list-item-icon>
                  <v-icon v-if="item.active">mdi-account-cancel</v-icon>
                  <v-icon v-else>mdi-account-reactivate</v-icon>
                </v-list-item-icon>
                <v-list-item-title v-if="item.active">Deactivate Customer</v-list-item-title>
                <v-list-item-title v-else>Activate Customer</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          No customers found.
        </template>
      </v-data-table>
      <div class="v-data-footer" style="border-top: thin solid rgba(0, 0, 0, .12);">
        <div class="v-data-footer__select">
          <span>Rows per page:</span>
          <v-select
            v-model="query.limit"
            :items="itemsPerPageOptions"
            style="margin: 15px 5px 1px 34px;"
            @change="loadCustomers"
          ></v-select>
        </div>
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          total-visible="5"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="loadCustomers"
        ></v-pagination>
      </div>
<!--      <div class="d-flex justify-end pt-2">
        <div>
          <v-select
            v-model="query.limit"
            :items="itemsPerPageOptions"
            label="Items per page"
            @change="loadCustomers"
          ></v-select>
        </div>
        <div>
          <v-pagination
            v-model="query.page"
            :length="pageCount"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="loadCustomers"
          ></v-pagination>
        </div>
      </div>-->
    </material-card>

    <customer-validation-modal ref="customerValidation"></customer-validation-modal>
    <customers-validation-modal ref="validations"></customers-validation-modal>
    <customer-modal ref="addCustomer"></customer-modal>
    <confirmation-modal ref="confirm"></confirmation-modal>
  </v-container>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    CustomerValidationModal: () => import('../components/CustomerValidationModal'),
    CustomersValidationModal: () => import('../components/CustomersValidationModal'),
    CustomerModal: () => import('./components/CustomerModal'),
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      query: {
        page: 1,
        limit: 15,
        contractor_id: '',
        active: '',
        keyword: '',
        paginated: true,
        load_shipping_address: true,
        sort_col: 'created_at',
        sort_direction: 'desc'
      },
      statusOptions: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false }
      ],
      loading: false,
      showLoading: false,
      total: 0,
      pageCount: 0,
      options: {},
      itemsPerPageOptions: [5, 10, 15, 25, 50, 100],
      search: '',
      validationErrors: false,
      customerValidationErrors: [],
      zipRules: [v => v.length <= 5 || 'Max 5 characters'],
      selectedCustomer: {},
      showActive: false
    }
  },
  filters: {
    capitalize (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    options: {
      handler () {
        this.loadCustomers()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('customerManagement', {
      customers: state => state.customers
    }),
    ...mapState('contractorManagement', {
      contractor: state => state.contractor
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('cartManagement', {
      validation: state => state.validationCustomers
    }),
    ...mapState({
      states: state => state.stateOptions
    }),
    headers () {
      return [
        { text: 'ID', align: ' d-none', value: 'id', sortable: false },
        { text: 'Customer', value: 'full_name', sortable: false },
        { text: 'Address', value: 'shipping_address', sortable: false },
        { text: 'Date Added', value: 'created_at', sortable: false },
        { text: 'Tags', value: 'validation_errors', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    },
    hasUser () {
      return Object.entries(this.user).length > 0
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      update: 'updateCustomer',
      add: 'addCustomer',
      delete: 'removeCustomer',
      batch: 'addCustomers',
      fetch: 'fetchCustomers'
    }),
    ...mapActions({
      fetchStates: 'fetchStates'
    }),
    ...mapMutations('customerManagement', {
      addNewCustomer: 'addCustomer'
    }),
    loadStates () {
      this.loading = true
      this.fetchStates()
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    statesFilter (item, queryText) {
      const name = item.text.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    getStatusColor (status) {
      if (status === 'Active') {
        return 'green'
      } else {
        return 'red'
      }
    },
    loadCustomers () {
      this.query.contractor_id = this.user.model_id
      this.loading = true

      this.fetch(this.query)
        .then((response) => {
          this.total = response.meta.total
          this.pageCount = response.meta.last_page
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch () {
      if (this.query.keyword) {
        if (this.query.keyword.length > 3) {
          this.query.page = 1

          this.loadCustomers()
        }
      }
    },
    clearSearch () {
      this.query.keyword = ''
      this.query.page = 1

      this.loadCustomers()
    },
    openCustomer (id) {
      this.$router.push({name: 'customer_detail', params: {id}})
    },
    addCustomer (customer) {
      this.add(customer)
        .then((response) => {
          if (response.validation_errors.length > 0) {
            response['validation_status'] = 'Pending'
            this.openValidation(response)
          } else {
            this.loadCustomers()
            this.$toast.success('Customer has been added.')
          }
        })
        .catch(error => {
          this.$toast.error(error.data.error)
        })
    },
    async updateCustomer (selectedCustomer) {
      const statusMessages = {
        status: !selectedCustomer.active,
        success: `Customer account ${ selectedCustomer.active ? 'deactivated' : 'activated'}`,
        question: `Are you sure you want to ${ selectedCustomer.active ? 'deactivate' : 'activate'} this customer?`
      }

      if (await this.$refs.confirm.open('Update Customer', statusMessages.question, { color: 'red' })) {
        this.update({id: selectedCustomer.id, customer: {site_id: 3, active: statusMessages.status}})
          .then(() => {
            this.$toast.success(statusMessages.success)
          })
          .catch((error) => {
            this.$toast.error(error)
          })
      }
    },
    openValidation (customer) {
      const options = {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        addToCart: false
      }
      this.$refs.customerValidation.open(customer, options)
        .then((response) => {
          if (response) {
            this.loadCustomers()
          }
        })
        .catch((error) => { this.$toast.error(error) })
    },
    openAddCustomer () {
      const options = {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        title: 'Add Customer',
        add: true,
        edit: false,
        addToCart: false,
        allowMultiple: true,
        showAutocomplete: true
      }
      this.$refs.addCustomer.open({}, options)
        .then((response) => {
          if (response.success) {
            this.$toast.success(response.message)
          } else {
            this.$toast.error(response.message)
          }
        })
        .catch((error) => { this.$toast.error(error) })
    },
    toggleCustomerStatus () {
      if (this.showActive) {
        this.query.active = true
      } else {
        this.query.active = ''
      }

      this.loadCustomers()
    },
    file_selected (input) {
      if (input.target.files && input.target.files[0]) {
        this.handleFileUpload(input.target.files[0])
      }
    },
    handleFileUpload (file) {
      const formData = new FormData()
      formData.append('file', file)

      this.batch(formData)
        .then((response) => {
          this.$swal({
            position: 'top-end',
            title: response.message,
            toast: true,
            icon: 'success',
            timer: 5000,
            timerProgressBar: true
          })
          /*response.forEach((customer) => {
            if (customer.validation_errors.length > 0) {
              this.customerValidationErrors.push(customer)
            }
          })

          if (this.customerValidationErrors.length > 0) {
            const options = {
              addToCart: false,
              width: 344,
              height: 200
            }
            this.$refs.validations.open(this.customerValidationErrors, options)
              .then((response) => {
                this.$toast.success(response.message)
              })
              .catch((error) => {
                this.$toast.error(error)
              })
          } else {
            this.$toast.success('Customers uploaded.')
          }*/
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    }
  }
}
</script>
