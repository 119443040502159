<template>
  <v-container id="files-view">
    <material-card
      color="primary"
      icon="mdi-file-document-outline"
    >
      <template #title>
        Files
      </template>

      <v-simple-table>
        <thead>
        <tr>
          <th class="text-left">File</th>
          <th class="text-left">File Type</th>
          <th class="text-left">Description</th>
          <th class="text-left">Download</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(file, index) in files" :key="index">
          <td>{{ file.name }}</td>
          <td>{{ file.extension }}</td>
          <td>{{ file.description }}</td>
          <td>
<!--
            <v-btn
              icon
              class="ma-2"
              outlined
              :href="`${publicPath}/${file.href}`"
              download
              >
              <v-icon>mdi-download</v-icon>
            </v-btn>
-->
            <v-btn
                icon
                class="ma-2"
                outlined
                :href="`https://filterondemand.com/${file.href}`"
                download
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </material-card>
  </v-container>
</template>

<script>
export default {
  name: 'Files',
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      publicPath: this.$appConfig.appURL,
      files: [
        {
          name: 'Customer Upload',
          description: 'Template used for uploading list of customers',
          href: 'customer_template.csv',
          extension: '.csv'
        },
        {
          name: 'Customer with Products Upload',
          description: 'Template used for uploading list of customers with products',
          href: 'customer_with_products_template.csv',
          extension: '.csv'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
