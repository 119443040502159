<template>
  <div id="auto-logout-container">
    <v-dialog
      v-model="showWarningDialog"
      width="500"
      >
      <v-card>
        <v-card-title class="headline">
          Are you still here?
        </v-card-title>
        <v-card-text>
          Your session will expire in 15 minutes.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showWarningDialog=false"
            >
            Keep Active
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'AutoLogout',
  data () {
    return {
      events: ['click', 'scroll', 'keypress', 'load'],
      warningTimer: null,
      logoutTimer: null,
      showWarning: false,
      showWarningAlert: false,
      showWarningDialog: false
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn'
    })
  },
  methods: {
    ...mapActions('auth', {
      logoutUser: 'logout'
    }),
    resetTimer () {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
      this.setTimers()
    },
    setTimers () {
      this.warningTimer = setTimeout(this.warningMessage, 45 * 60 * 1000)
      this.logoutTimer = setTimeout(this.logout, 60 * 60 * 1000)

      this.showWarning = false
    },
    warningMessage () {
      this.showWarningDialog = true
    },
    logout () {
      if (this.isLoggedIn && this.$router.currentRoute.name !== 'login') {
        this.logoutUser()
          .then(() => {
            this.$router.push({name: 'login'})
          })
          .catch((error) => {
            this.$toast.error(error)
          })
      }
    }
  },
  mounted () {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer)
    }, this)

    this.setTimers()
  },
  destroyed () {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer)
    }, this)

    this.resetTimer()
  }
}
</script>

<style scoped>

</style>
