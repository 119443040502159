import { render, staticRenderFns } from "./OrderActionsPanel.vue?vue&type=template&id=7b0e545c&scoped=true&"
import script from "./OrderActionsPanel.vue?vue&type=script&lang=js&"
export * from "./OrderActionsPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b0e545c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSelect,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/forge/demo.filterondemand.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b0e545c')) {
      api.createRecord('7b0e545c', component.options)
    } else {
      api.reload('7b0e545c', component.options)
    }
    module.hot.accept("./OrderActionsPanel.vue?vue&type=template&id=7b0e545c&scoped=true&", function () {
      api.rerender('7b0e545c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orders/components/OrderActionsPanel.vue"
export default component.exports