import api from './request'

export function addProduct (data) {
  return api({
    url: '/products',
    method: 'post',
    data
  })
}

export function showProduct (id) {
  return api({
    url: `/products/${id}`,
    method: 'get'
  })
}

export function getProducts (query) {
  return api({
    url: '/products/list',
    method: 'get',
    params: query
  })
}

export function updateProduct (id, data) {
  return api({
    url: `/products/${id}`,
    method: 'post',
    data
  })
}

export function destroyProduct (id) {
  return api({
    url: `/products/${id}`,
    method: 'delete'
  })
}

export function getMfg (query) {
  return api({
    url: '/manufacturers',
    method: 'get',
    params: query
  })
}

export function getDimensions () {
  return api({
    url: '/item/dimensions',
    method: 'get'
  })
}

export function requestCustom (data) {
  return api({
    url: '/email',
    method: 'post',
    data
  })
}
