import state from './moduleCommentManagementState'
import mutations from './moduleCommentManagementMutations'
import actions from './moduleCommentManagementActions'
import getters from './moduleCommentManagementGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
