import state from './moduleOrderManagementState'
import mutations from './moduleOrderManagementMutations'
import actions from './moduleOrderManagementActions'
import getters from './moduleOrderManagementGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
