import { add, show, previous, addCustomer, addProduct, update, updateCustomer, updateProduct, destroy,
  destroyCustomer, destroyProduct, upload } from '@/api/cart'

export default {
  createCart ({commit}) {
    return new Promise((resolve, reject) => {
      add()
        .then((response) => {
          commit('addCart', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCart ({commit}, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((response) => {
          commit('setCart', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPreviousCarts ({commit}) {
    return new Promise((resolve, reject) => {
      previous()
        .then((response) => {
          commit('setCarts', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addCustomerToCart ({commit}, payload) {
    return new Promise((resolve, reject) => {
      addCustomer(payload)
        .then((response) => {
          commit('addCartCustomer', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addCustomersToCart (context, payload) {
    return new Promise((resolve, reject) => {
      upload(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*addProductToCart ({getters, commit}, payload) {
    const { product, customer_id } = payload
    const index = getters.customerCartItems.findIndex(item => item.id === product.id)

    if (index > -1) {
      commit('incrementProductQuantity', {customer_id, product})
    } else {
      commit('addProductToCart', product)
    }
  },*/
  addProductToCart ({commit}, payload) {
    return new Promise((resolve, reject) => {
      addProduct(payload)
        .then((response) => {
          commit('addCartCustomerItem', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update ({commit}, payload) {
    const { id, cart } = payload
    return new Promise((resolve, reject) => {
      update(id, cart)
        .then((response) => {
          commit('setCart', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCartCustomer ({commit}, payload) {
    const { id, customer } = payload
    return new Promise((resolve, reject) => {
      updateCustomer(id, customer)
        .then((response) => {
          commit('updateCartCustomer', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCartProduct ({commit}, payload) {
    const { id, product } = payload
    return new Promise((resolve, reject) => {
      updateProduct(id, product)
        .then((response) => {
          commit('updateCartCustomerItem', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeCart ({commit}, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((response) => {
          commit('updateCart', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeCustomer ({commit}, payload) {
    return new Promise((resolve, reject) => {
      destroyCustomer(payload)
        .then((response) => {
          commit('setCart', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeProduct ({commit}, id) {
    return new Promise((resolve, reject) => {
      destroyProduct(id)
        .then((response) => {
          commit('setCart', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  selectCartCustomer ({commit}, customer) {
    return new Promise((resolve) => {
      commit('setSelectedCustomer', customer)
      resolve()
    })
  }
}
