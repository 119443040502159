var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "reports-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-chart-bar" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Reports ")]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.loading
            ? _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "4" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.reports,
                                  "item-text": "public_name",
                                  "item-value": "id",
                                  label: "Select Report"
                                },
                                on: { change: _vm.selectReport },
                                model: {
                                  value: _vm.selectedReportId,
                                  callback: function($$v) {
                                    _vm.selectedReportId = $$v
                                  },
                                  expression: "selectedReportId"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.showDateSelector
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4", md: "4" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "scheduledMenu",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.dates,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          _vm.dates = $event
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          _vm.dates = $event
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                          readonly: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dateRangeText,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.dateRangeText = $$v
                                                          },
                                                          expression:
                                                            "dateRangeText"
                                                        }
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4113305070
                                      ),
                                      model: {
                                        value: _vm.menu,
                                        callback: function($$v) {
                                          _vm.menu = $$v
                                        },
                                        expression: "menu"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            "no-title": "",
                                            scrollable: "",
                                            range: "",
                                            max: _vm.maxDate
                                          },
                                          model: {
                                            value: _vm.dates,
                                            callback: function($$v) {
                                              _vm.dates = $$v
                                            },
                                            expression: "dates"
                                          }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.menu = false
                                                }
                                              }
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary"
                                              },
                                              on: { click: _vm.setDates }
                                            },
                                            [_vm._v(" OK ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.formValid
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4", md: "4" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-4 ml-2",
                                      attrs: {
                                        small: "",
                                        color: "primary",
                                        loading: _vm.reportLoading
                                      },
                                      on: { click: _vm.run }
                                    },
                                    [_vm._v(" RUN REPORT ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showReport
            ? _c("v-data-table", {
                attrs: {
                  headers: _vm.tableHeaders,
                  items: _vm.reportData,
                  "fixed-header": "",
                  loading: _vm.reportLoading,
                  "loading-text": "Loading... Please wait"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c(
                            "v-toolbar",
                            { attrs: { flat: "", color: "white" } },
                            [
                              _vm.reportData.length > 0
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.exportToExcel }
                                    },
                                    [_vm._v(" EXPORT ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3199409868
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }