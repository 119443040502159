import api from './request'

export function add (data) {
  return api({
    url: '/addresses',
    method: 'post',
    data
  })
}

export function show (id) {
  return api({
    url: `/addresses/${id}`,
    method: 'get'
  })
}

export function get (data) {
  return api({
    url: '/addresses',
    method: 'get',
    params: data
  })
}

export function update (id, data) {
  return api({
    url: `/addresses/${id}`,
    method: 'put',
    data
  })
}

export function destroy (id) {
  return api({
    url: `/addresses/${id}`,
    method: 'delete'
  })
}

export function validate (data) {
  return api({
    url: '/addresses/validate',
    method: 'post',
    data
  })
}
