export default {
  setCart (state, payload) {
    state.cart = payload
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  addCart (state, payload) {
    state.cart = payload
    localStorage.setItem('cart', JSON.stringify(state.cart))
    state.carts.push(payload)
  },
  setCarts (state, payload) {
    state.carts = payload
  },
  removeCart (state) {
    state.cart = {}
    localStorage.removeItem('cart')
  },
  setValidationCustomers (state, payload) {
    state.validationCustomers = payload
    localStorage.setItem('validation_customers', JSON.stringify(payload))
  },
  addCartCustomer (state, payload) {
    state.cart.customers.push(payload)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  addCartCustomerItem (state, payload) {
    state.selectedCustomer.items.push(payload)
    const index = state.cart.customers.findIndex((c) => c.id === payload.cart_customer_id)
    state.cart.customers.splice(index, payload)
    localStorage.setItem('cart', JSON.stringify(state.cart))
    localStorage.setItem('selected_customer', JSON.stringify(state.selectedCustomer))
  },
  updateCart (state, payload) {
    state.cart = payload
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  updateCartCustomer (state, payload) {
    const index = state.cart.customers.findIndex((c) => c.id === payload.id)

    if (index > -1) {
      state.cart.customers.splice(index, payload)
    } else {
      state.cart.customers.push(payload)
    }
    localStorage.setItem('cart', JSON.stringify(state.cart))

    if (state.selectedCustomer.id === payload.id) {
      state.selectedCustomer = payload
      localStorage.setItem('selected_customer', JSON.stringify(state.selectedCustomer))
    }
  },
  updateCartCustomerItem (state, payload) {
    const index = state.cart.customers.findIndex((c) => c.id === payload.cart_customer_id)
    const itemIndex = state.cart.customers[index].items.findIndex((i) => i.id === payload.id)
    state.cart.customers[index].items.splice(itemIndex, payload)

    if (state.cart.customers[index].id === state.selectedCustomer.id) {
      state.selectedCustomer = state.cart.customers[index]
      localStorage.setItem('selected_customer', JSON.stringify(state.selectedCustomer))
    }
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  setSelectedCustomer (state, payload) {
    state.selectedCustomer = payload

    localStorage.setItem('selected_customer', JSON.stringify(payload))
  },
  removeSelectedCustomer () {
    localStorage.removeItem('selected_customer')
  },
  setCustomerTaxRate (state, payload) {
    state.selectedCustomer.tax = payload
    localStorage.setItem('selected_customer', JSON.stringify(state.selectedCustomer))

    const index = state.cart.customers.findIndex(i => i.id === state.selectedCustomer.id)
    state.cart.customers.splice(index, state.selectedCustomer)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  addCustomerToCart (state, payload) {
    state.cart.customers.push(Object.assign({}, payload))
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  removeCustomerFromCart (state, id) {
    const index = state.cart.customers.findIndex((c) => c.id === id)
    state.cart.customers.splice(index, 1)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  removeCustomersFromCart (state) {
    state.cart.customers = []
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  removeProductFromCustomerCart (state, customer_id, product_id) {
    const index = state.cart.customers.findIndex(i => i.id === customer_id)
    const productIndex = state.selectedCustomer.items.findIndex(i => i.id === product_id)
    state.selectedCustomer.items.splice(productIndex, 1)
    state.cart.customers.splice(index, state.selectedCustomer)
    localStorage.setItem('selected_customer', state.selectedCustomer)
    localStorage.setItem('cart', state.cart)
  },
  removeProductFromCart (state, customer_id, product_id) {
    const index = state.cart.customers.findIndex(i => i.id === customer_id)
    const productIndex = state.cart.customers[index].items.findIndex(i => i.id === product_id)
    state.cart.customers[index].items.splice(productIndex, 1)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  addProductToCart (state, payload) {
    state.selectedCustomer.items.push(Object.assign({}, payload))
    localStorage.setItem('selected_customer', JSON.stringify(state.selectedCustomer))

    const index = state.cart.customers.findIndex(i => i.id === state.selectedCustomer.id)
    state.cart.customers.splice(index, state.selectedCustomer)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  addScheduledDateToCart (state, payload) {
    state.selectedCustomer.shipDate = payload.shipDate
    state.selectedCustomer.frequency = payload.frequency
    state.selectedCustomer.complete = true
    localStorage.setItem('selected_customer', JSON.stringify(state.selectedCustomer))

    const index = state.cart.customers.findIndex(i => i.id === state.selectedCustomer.id)
    state.cart.customers.splice(index, state.selectedCustomer)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  incrementProductQuantity (state, payload) {
    if (state.selectedCustomer.id === payload.customer_id) {
      const productIndex = state.selectedCustomer.items.findIndex((c) => c.id === payload.product.id)
      const cartItem = state.selectedCustomer.items[productIndex]
      cartItem.quantity++
      state.selectedCustomer.items[productIndex] = cartItem
      localStorage.setItem('selected_customer', JSON.stringify(state.selectedCustomer))

      const customerIndex = state.cart.customers.findIndex((c) => c.id === payload.customer_id)
      state.cart.customers.splice(customerIndex, state.selectedCustomer)
      localStorage.setItem('cart', JSON.stringify(state.cart))
    } else {
      const customerIndex = state.cart.customers.findIndex((c) => c.id === payload.customer_id)
      const productIndex = state.cart.customers[customerIndex].items.findIndex((c) => c.id === payload.product.id)
      const cartItem = state.cart.customers[customerIndex].items[productIndex]
      cartItem.quantity++
      state.cart.customers[customerIndex].items.splice(productIndex, cartItem)
      localStorage.setItem('cart', JSON.stringify(state.cart))
    }
  }
}
