import { add, get, show, update, destroy } from '@/api/comment'

export default {
  addComment ({commit}, state) {
    return new Promise((resolve, reject) => {
      add(state)
        .then((response) => {
          commit('addComment', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateComment ({commit}, data) {
    const {id, payment} = data
    return new Promise((resolve, reject) => {
      update(id, payment)
        .then((response) => {
          commit('updateComment', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchComment (context, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchComments ({commit}, data) {
    return new Promise((resolve, reject) => {
      get(data)
        .then((response) => {
          commit('setComments', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeComment ({commit}, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((response) => {
          commit('removeComment', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
