<template>
  <v-container id="customers-pending-approval-view">
    <v-alert
      v-if="errorMessages.length > 0"
      prominent
      type="error"
      >
      <span v-for="(message, index) in errorMessages" :key="index">
        {{ message }} <br>
      </span>
    </v-alert>
    <material-card
      color="primary"
      icon="mdi-account-check"
    >
      <template #title>
        Customers Pending Verification
      </template>

      <v-data-table
        v-model="selected"
        :single-select="false"
        show-select
        item-key="id"
        :headers="headers"
        :items="customers"
        :loading="loading"
        loading-text="Loading Customers... Please wait"
        :search="search"
        class="mr-2 ml-2">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              placeholder="Search"
              hide-details
              v-model="search"
              clearable
              append-icon="mdi-magnify"
              single-line
              dense />
            <v-spacer></v-spacer>
            <v-menu
              bottom
              left
              min-width="200"
              offset-y
              origin="top right"
              transition="scale-transition"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  :disabled="selected.length === 0"
                  class="mr-5"
                  min-width="0"
                  text
                  outlined
                  color="accent"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Actions
                </v-btn>
              </template>

              <v-list
                :tile="false"
                flat
                nav
              >
                <v-list-item @click="approveSelected">
                  <v-list-item-title>Approve</v-list-item-title>
                </v-list-item>
                <v-list-item @click="approveAddCart">
                  <v-list-item-title>Approve & Add To Cart</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteSelected">
                  <v-list-item-title>Decline</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:[`item.customer`]="{ item }">
          <span class="font-weight-bold">{{ item.customer.full_name }}</span><br>
          {{ item.customer.shipping_address.street1 }} {{ item.customer.shipping_address.street2 }} <br>
          {{ item.customer.shipping_address.city }}, {{ item.customer.shipping_address.state.name }} {{ item.customer.shipping_address.zip }}
        </template>
        <template v-slot:[`item.customer.tags`]="{ item }">
          <v-chip
            v-for="(validation, index) in item.customer.validation_errors"
            :key="index"
            class="ma-2"
            color="red"
            text-color="white"
            small
          >
            {{ validation.error }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openValidation(item.customer)">
                <v-list-item-icon>
                  <v-icon>mdi-account-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>View Validation</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push({ name: 'customer_detail', params: { id: item.customer.id } })">
                <v-list-item-icon>
                  <v-icon>mdi-account-search</v-icon>
                </v-list-item-icon>
                <v-list-item-title>View Customer</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          No customers pending verification found.
        </template>
      </v-data-table>
    </material-card>

    <customer-validation-modal ref="customerValidation"></customer-validation-modal>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'

export default {
  name: 'CustomersPending',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    CustomerValidationModal: () => import('../components/CustomerValidationModal')
  },
  data () {
    return {
      loading: true,
      selected: [],
      search: '',
      errorMessages: []
    }
  },
  filters: {
    tag (value) {
      if (value.length > 0) {
        return 'Attention Needed'
      } else {
        return 'Valid'
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('cartManagement', {
      cart: state => state.cart
    }),
    ...mapState('customerManagement', {
      customers: state => state.pendingCustomers
    }),
    hasCart () {
      return Object.entries(this.cart).length > 0
    },
    headers () {
      return [
        { text: 'ID', align: ' d-none', value: 'id' },
        { text: 'Customer', value: 'customer', width: 450 },
        { text: 'Address', value: 'customer.shipping_address', align: ' d-none'},
        { text: 'Date Added', value: 'created_at' },
        { text: 'Issues', value: 'customer.tags' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      approve: 'approveCustomerPending',
      fetch: 'fetchCustomerBatch',
      destroy: 'removeCustomer'
    }),
    ...mapActions('cartManagement', {
      addCartCustomer: 'addCustomerToCart'
    }),
    ...mapMutations('customerManagement', {
      setPendingCustomers: 'setPendingCustomers'
    }),
    loadCustomers () {
      this.loading = true
      this.fetch({id: this.$route.params.id, type: {batch_type: 'approval'}})
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    approveSelected () {
      this.approve({customers: this.selected})
        .then(() => {
          this.loadCustomers()
          this.$toast.success('Approve action complete')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    approveAddCart () {
      if (this.hasCart) {
        this.approve({customers: this.selected, add_to_cart: true})
          .then((response) => {
            response.forEach((customer) => {
              this.addCartCustomer(customer)
            })

            if (response.errors.length > 0) {
              this.errorMessages = response.errors
            }

            this.loadCustomers()
            this.$toast.success('Selected customers approved and added to cart')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      } else {
        this.$toast.error('No cart found. Please select cart option from orders page.')
      }
    },
    deleteSelected () {
      for (let i = 0; i < this.selected.length; i++) {
        this.destroy(this.selected[i].customer_id)
          .then(() => {
            const index = this.customers.findIndex((o) => o.id === this.selected[i].id)
            this.customers.splice(index, 1)
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
      this.$toast.success('Delete action complete')
    },
    openValidation (customer) {
      const options = {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        addToCart: false
      }
      this.$refs.customerValidation.open(customer, options)
        .then((response) => {
          if (response.success) {
            this.loadCustomers()
          }
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  },
  created () {
    this.loadCustomers()
  }
}
</script>

<style scoped>

</style>
