var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasUser
    ? _c(
        "v-container",
        { attrs: { id: "scheduled-orders-list-view" } },
        [
          _c(
            "material-card",
            {
              attrs: { color: "primary", icon: "mdi-account-multiple" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Orders Batches ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                4281684682
              )
            },
            [
              _c("v-data-table", {
                staticClass: "mr-2 ml-2",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.batches,
                  "server-items-length": _vm.total,
                  options: _vm.options,
                  loading: _vm.loading,
                  "loading-text": "Loading Orders Batches... Please wait"
                },
                on: {
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c(
                            "v-toolbar",
                            { attrs: { flat: "", color: "white" } },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  ref: "scheduledMenu",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value": _vm.dates,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto"
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      _vm.dates = $event
                                    },
                                    "update:return-value": function($event) {
                                      _vm.dates = $event
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-4",
                                                    staticStyle: {
                                                      "max-width": "30%"
                                                    },
                                                    attrs: {
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value: _vm.dateRangeText,
                                                      callback: function($$v) {
                                                        _vm.dateRangeText = $$v
                                                      },
                                                      expression:
                                                        "dateRangeText"
                                                    }
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1191431884
                                  ),
                                  model: {
                                    value: _vm.menu,
                                    callback: function($$v) {
                                      _vm.menu = $$v
                                    },
                                    expression: "menu"
                                  }
                                },
                                [
                                  _c(
                                    "v-date-picker",
                                    {
                                      attrs: {
                                        "no-title": "",
                                        scrollable: "",
                                        range: "",
                                        max: _vm.maxDate
                                      },
                                      model: {
                                        value: _vm.dates,
                                        callback: function($$v) {
                                          _vm.dates = $$v
                                        },
                                        expression: "dates"
                                      }
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function($event) {
                                              _vm.menu = false
                                            }
                                          }
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: { click: _vm.setDates }
                                        },
                                        [_vm._v(" OK ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exportBatch(item.id)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-export")])],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [_vm._v(" No orders batches found. ")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }