import { getUser, login, broadcasting, forgot, reset, requestAccount, getUserSettings, updateUserSettings, logout } from '@/api/auth'

export default {
  login ({ commit }, payload) {
    commit('authRequest')
    const { email, password, site_id, remember } = payload
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password, site_id, remember })
        .then(response => {
          const token = response.data.token
          const user = response.data.user
          localStorage.setItem('access_token', token)
          localStorage.setItem('user', JSON.stringify(user))
          commit('authSuccess', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('authError')
          localStorage.removeItem('access_token')
          reject(error)
        })
    })
  },
  broadcastingAuthorize (context, payload) {
    return new Promise((resolve, reject) => {
      broadcasting(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  forgotPassword (context, payload) {
    return new Promise((resolve, reject) => {
      forgot(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  resetPassword (context, payload) {
    return new Promise((resolve, reject) => {
      reset(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  requestAccount (context, payload) {
    return new Promise((resolve, reject) => {
      requestAccount(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  logout ({ commit }) {
    const user = localStorage.getItem('user')
    if (user) {
      return new Promise((resolve, reject) => {
        return logout()
          .then(() => {
            commit('logout')
            localStorage.removeItem('access_token')
            localStorage.removeItem('selected_customer')
            localStorage.removeItem('cart')
            localStorage.removeItem('settings')
            localStorage.removeItem('user')
            resolve(true)
          })
          .catch((error) => {
            commit('logout')
            localStorage.removeItem('access_token')
            localStorage.removeItem('selected_customer')
            localStorage.removeItem('cart')
            localStorage.removeItem('settings')
            localStorage.removeItem('user')
            reject(error)
          })
      })
    }
  },
  fetchSettings ({ commit }, id) {
    return new Promise((resolve, reject) => {
      getUserSettings(id)
        .then((response) => {
          commit('setUserSettings', response.data.settings)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSettings ({ commit }, data) {
    const { id, settings } = data
    return new Promise((resolve, reject) => {
      updateUserSettings(id, settings)
        .then((response) => {
          commit('updateUser', response.data)

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      getUser()
        .then(response => {
          localStorage.setItem('user', JSON.stringify(response.data))
          commit('setUser', response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
