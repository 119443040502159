var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customers.length > 0
    ? _c(
        "div",
        { staticClass: "customer-validation-dialog" },
        [
          _c(
            "v-dialog",
            {
              style: { zIndex: _vm.options.zIndex },
              attrs: { "max-width": "1000px" },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  return _vm.cancel($event)
                },
                "click:outside": _vm.cancel
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        dark: "",
                        color: _vm.options.color,
                        dense: "",
                        flat: ""
                      }
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "white--text" }, [
                        _vm._v("Customer Validation")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "ma-5" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "center",
                              attrs: { cols: "12", md: "12" }
                            },
                            [
                              _c("h1", { staticClass: "font-black" }, [
                                _vm._v("Potential Customer Validation Errors")
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "center red--text font-weight-bold",
                              attrs: { cols: "12", md: "12" }
                            },
                            [
                              _vm._v(" Error flagged as: "),
                              _vm._l(
                                _vm.customers[_vm.index].validation_errors,
                                function(validation, index) {
                                  return _c("span", { key: index }, [
                                    _vm._v(_vm._s(validation.error)),
                                    _c("br")
                                  ])
                                }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            align: "center",
                            justify: "center",
                            dense: ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("customer-validation-card", {
                                attrs: {
                                  "customer-data": _vm.customers[_vm.index],
                                  "min-height": _vm.options.height,
                                  "max-width": _vm.options.width
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function() {
                                        return [_vm._v(" New Customer ")]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "actions",
                                      fn: function() {
                                        return [
                                          _vm.customers[_vm.index]
                                            .validation_errors.length > 0
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectNewCustomer(
                                                        _vm.index
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Select")]
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2260508289
                                )
                              })
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.customers[_vm.index].validation_errors,
                            function(validation, validationIndex) {
                              return _c(
                                "v-col",
                                { key: validationIndex, attrs: { cols: "4" } },
                                [
                                  validation.error !== "Incorrect Address"
                                    ? _c("customer-validation-card", {
                                        attrs: {
                                          "customer-data":
                                            validation.associated,
                                          "min-height": _vm.options.height,
                                          "max-width": _vm.options.width
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "title",
                                              fn: function() {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(validation.error) +
                                                      " "
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "actions",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectExistingCustomer(
                                                            _vm.index,
                                                            validationIndex
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Select")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.mergeCustomers(
                                                            _vm.index,
                                                            validationIndex
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Merge")]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }
                          )
                        ],
                        2
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-pagination", {
                                attrs: {
                                  length: _vm.total,
                                  "total-visible": 5
                                },
                                on: {
                                  input: _vm.selectCustomer,
                                  next: _vm.nextCustomer,
                                  previous: _vm.previousCustomer
                                },
                                model: {
                                  value: _vm.page,
                                  callback: function($$v) {
                                    _vm.page = $$v
                                  },
                                  expression: "page"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm.validationComplete
                        ? _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "success" },
                              on: { click: _vm.accept }
                            },
                            [_vm._v(" All Done! ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "red" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(" Fix Later ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("customer-modal", {
            attrs: {
              "customer-data": _vm.customer,
              "is-modal-active": _vm.showEditCustomer
            },
            on: {
              "edit-customer": _vm.editCustomer,
              "close-modal": _vm.toggleEditCustomerModal
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }