<template>
  <div id="scheduled-order-actions-panel">
    <panel>
      <template #title>
        Order Actions
      </template>

      <template #content>
        <v-select
          v-model="orderAction"
          :items="actions"
          label="Choose An Action"
          dense
          return-object
          @change="performAction"
        >
        </v-select>
      </template>
    </panel>

    <confirmation-modal ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ScheduledOrderActionsPanel',
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    Panel: () => import('@/components/Panel')
  },
  data () {
    return {
      actions: [],
      orderAction: { }
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.scheduledOrder
    })
  },
  methods: {
    ...mapActions('orderManagement', {
      update: 'updateScheduled',
      snooze: 'snoozeScheduled'
    }),
    ...mapMutations('orderManagement', {
      loadOrder: 'setScheduledOrder'
    }),
    init () {
      if (this.order.status === 'PENDING_APPROVAL') {
        this.actions.push({text: 'Approve', value: 'SCHEDULED'})
        this.actions.push({text: 'Snooze', value: 'SNOOZE'})
      }
      if (this.order.status === 'CANCELLED') {
        this.actions.push({text: 'Activate', value: 'PENDING_APPROVAL'})
      }
      if (this.order.status === 'SCHEDULED') {
        this.actions.push({text: 'Snooze', value: 'SNOOZE'})
        this.actions.push({text: 'Deactivate', value: 'PENDING_APPROVAL'})
        this.actions.push({text: 'Cancel', value: 'CANCELLED'})
      }
    },
    performAction () {
      if (this.orderAction.value === 'SNOOZE') {
        this.snoozeSchedule()
      } else {
        this.updateSchedule()
      }
    },
    async updateSchedule () {
      if (await this.$refs.confirm.open('Update Schedule', `Are you sure you want to ${this.orderAction.text} this order?`, { color: 'red' })) {
        this.update({id: this.order.id, payload: {status: this.orderAction.value}})
          .then((response) => {
            this.loadOrder(response.data)
            this.$toast.success(`Order ${this.orderAction.text} successful!`)
            this.orderAction = {}
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    async snoozeSchedule () {
      if (await this.$refs.confirm.open('Snooze Schedule', 'Are you sure you want to delay this order 30 days?', { color: 'red' })) {
        this.snooze(this.order.id)
          .then((response) => {
            this.loadOrder(response.data)
            this.$toast.success('Scheduled order snoozed for 30 days!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>

</style>
