const getCustomers = () => {
  const customersLocalStorage = localStorage.getItem('customers')

  if (customersLocalStorage === null) {
    return []
  }

  return JSON.parse(customersLocalStorage)
}

export default {
  customers: getCustomers(),
  customer: null,
  selectedCustomers: [],
  pendingCustomers: []
}
