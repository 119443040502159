<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orders"
      :search="search"
      single-expand
      :expanded.sync="expanded"
      show-expand
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="openAddSchedule"
          >
            <v-icon>mdi-calendar-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="statusColorMap[item.status]">
          {{ orderStatusMap[item.status] }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ name: 'scheduled_order_details', params: { id: item.id } })"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit scheduled order</span>
        </v-tooltip>

        <v-tooltip v-if="item.status !== 'CANCELLED'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="snoozeSchedule(item.id)"
            >
              mdi-alarm
            </v-icon>
          </template>
          <span>Snooze scheduled order 30 days</span>
        </v-tooltip>

        <v-tooltip v-if="item.status !== 'CANCELLED'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="cancelOrder(item.id)"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <span>Cancel scheduled order</span>
        </v-tooltip>

        <v-tooltip v-if="item.status === 'CANCELLED'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="activateOrder(item.id)"
              >
              mdi-check
            </v-icon>
          </template>
          <span>Activate scheduled order</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table
            class="m-2"
            dense
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Name</th>
                <th class="text-left">Mfg</th>
                <th class="text-left">Part#</th>
                <th class="text-left">Case Qty</th>
                <th class="text-left">Order Qty</th>
                <th class="text-left">Price</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="lineItem in item.products"
                :key="lineItem.id"
              >
                <td>{{ lineItem.product_id }}</td>
                <td>{{ $utils.productName(lineItem.product) }}</td>
                <td>{{ $utils.productMfgName(lineItem.product.item.manufacturer.name) }}</td>
                <td>{{ lineItem.product.item.mfg_pn }}</td>
                <td>{{ lineItem.product.purchase_qty }}</td>
                <td>{{ lineItem.quantity }}</td>
                <td>${{ lineItem.price }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:no-data>
        No scheduled orders found.
      </template>
    </v-data-table>

    <v-dialog
      v-model="createNewSchedule"
      max-width="700px"
      >
      <v-card>
        <v-stepper
          alt-labels
          elevation="0"
          v-model="currentStep"
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="currentStep > 1"
              editable
              step="1"
            >
              Product Selection
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              editable
              :complete="currentStep > 2"
            >
              Schedule Selection
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="3"
              editable
              :complete="currentStep > 3"
            >
              Shipping Address
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4">
              Select Payment
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
            >
              <v-card elevation="0">
                <v-card-text>
                  <v-card-text>
                    <v-select
                      dense
                      v-model="product"
                      :items="products"
                      item-text="name"
                      item-value="id"
                      label="Select Assigned Product"
                      return-object
                    ></v-select>
                  </v-card-text>
                  <v-card-text>
                    <v-text-field
                      dense
                      type="number"
                      v-model="selectedQuantity"
                      label="Quantity"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      @click="selectProduct"
                    >
                      Add Product
                    </v-btn>
                  </v-card-actions>

                  <v-card v-if="selectedProducts.length > 0" outlined>
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in selectedProducts"
                          :key="index"
                        >
                          <v-list-item-content>
                            {{ item.quantity }} x {{ item.name | truncate(60) }}
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon class="mr-2" size="small" @click="removeSelection(index)">mdi-delete-forever-outline</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="selectedProducts.length === 0"
                    color="primary"
                    @click="scheduleSelection"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content
              step="2"
              >
              <v-card elevation="0">
                <v-card-text>
                  <v-select
                    v-model="selectedFrequency"
                    :items="frequencyOptions"
                    label="Frequency"
                    @change="selectFrequency"
                    :menu-props="{ maxHeight: 350 }"
                  ></v-select>
                </v-card-text>
                <v-card-text v-if="customFrequency">
                  <v-text-field
                    type="number"
                    v-model="scheduledOrder.frequency"
                    label="Custom frequency (days)"
                    outlined
                  ></v-text-field>
                </v-card-text>
                <v-card-text>
                  <v-menu
                    ref="menu"
                    v-model="nextDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="scheduledOrder.next"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="scheduledOrder.next"
                        label="Next Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="scheduledOrder.next"
                      no-title
                      scrollable
                      @input="$refs.menu.save(scheduledOrder.next)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="currentStep = 3"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content
              step="3"
            >
              <v-card elevation="0">
                <v-card-text>
                  <v-autocomplete
                    v-model="scheduledOrder.ship_address_id"
                    :items="customer.addresses"
                    label="Select shipping address"
                    item-value="id"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ data.item.full_name }}
                          <v-spacer></v-spacer>
                          {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="currentStep = 4"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content
              step="4"
            >
              <v-card elevation="0">
                <v-card-text>
                  <v-select
                    v-model="scheduledOrder.payment_method"
                    :items="paymentMethods"
                    item-text="text"
                    item-value="value"
                    label="Select payment option"
                    dense
                    single-line
                    @change="selectPayment"
                  ></v-select>
                </v-card-text>
                <v-card-text v-if="selectPaymentOption">
                  <v-select
                    v-model="scheduledOrder.payment_id"
                    :items="payments"
                    label="Select Payment Method"
                    item-value="id"
                    dense
                    single-line
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.card_name }}
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ item.card_name }}
                          <v-spacer></v-spacer>
                          {{ item.card_type }} {{ item.card_number }} {{ item.card_expiry_formatted }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item-content>
                        <v-list-item-subtitle class="ml-5">
                          Payment method not found?
                          <v-btn small text @click="toggleNewPaymentModal(true)">Add New Payment Method</v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-card-text>
                <v-card-text>
                  <v-autocomplete
                    v-model="scheduledOrder.bill_address_id"
                    :items="contractor.addresses"
                    label="Select billing address"
                    item-value="id"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ data.item.full_name }}
                          <v-spacer></v-spacer>
                          {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="addSchedule"
                  >
                    Add
                  </v-btn>
                  <v-btn
                    text
                    @click="closeAdd"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <add-payment-modal :isModalActive="addNewPayment" @add-payment="loadPayment" @close-modal="toggleNewPaymentModal"></add-payment-modal>
    <confirmation-modal ref="confirm" />
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'

export default {
  props: {
    showToolbar: {
      type: Boolean,
      default: false
    },
    customer_id: {
      type: Number,
      default: undefined
    },
    contractor_id: {
      type: Number,
      default: undefined
    }
  },
  components: {
    AddPaymentModal: () => import('@/views/components/AddPaymentModal'),
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      expanded: [],
      loading: true,
      search: '',
      statusColorMap: {
        SCHEDULED: 'success',
        PENDING_APPROVAL: 'primary',
        CANCELLED: 'red',
        COMPLETE: 'success'
      },
      nextDateMenu: false,
      products: [],
      frequencyOptions: [
        {text: 'Does not repeat', value: 0},
        {text: 'Repeat Annually', value: 360},
        {text: 'Repeat Semi-Annually', value: 180},
        {text: 'Repeat Every 3 Months', value: 90},
        {text: 'Repeat Every 2 Months', value: 60},
        {text: 'Repeat Monthly', value: 30},
        {text: 'Custom', value: -1}
      ],
      selectedFrequency: '',
      customFrequency: false,
      paymentMethods: [{text: 'Credit Card', value: 'CC'}],
      scheduledOrder: {},
      product: {},
      selectedQuantity: 1,
      selectedProducts: [],
      defaultScheduledOrder: {
        customer_id: undefined,
        products: [],
        next: '',
        frequency: '',
        payment_method: '',
        payment_id: undefined,
        ship_address_id: undefined,
        bill_address_id: null
      },
      currentStep: 1,
      createNewSchedule: false,
      addNewPayment: false,
      selectPaymentOption: false
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('paymentManagement', {
      payments: state => state.payments
    }),
    ...mapState('customerManagement', {
      customer: state => state.customer
    }),
    ...mapState('orderManagement', {
      orders: state => state.scheduledOrders
    }),
    ...mapState('contractorManagement', {
      contractor: state => state.contractor,
      settings: state => state.settings
    }),
    ...mapState({
      orderStatusMap: state => state.orderStatusMap
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: true
        },
        {
          text: 'Ship Date',
          value: 'next'
        },
        {
          text: 'Products',
          value: 'products',
          align: ' d-none'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    paymentsNotFound () {
      return Object.entries(this.payment).length !== 0
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      add: 'addScheduled',
      fetchOrders: 'fetchScheduledOrders',
      snooze: 'snoozeScheduled',
      update: 'updateScheduled'
    }),
    ...mapActions('paymentManagement', {
      fetchPayment: 'fetchPayments'
    }),
    ...mapActions('customerManagement', {
      fetchProducts: 'fetchAssignedProducts'
    }),
    ...mapActions('contractorManagement', {
      fetchContractor: 'fetchContractor'
    }),
    init () {
      this.resetDefault()
      this.loadOrders()
      this.loadAssignedProducts()
      this.loadContractor()
    },
    toggleNewPaymentModal (val = false) {
      this.addNewPayment = val
    },
    loadOrders () {
      this.fetchOrders({customer_id: this.$route.params.id, load_products: true})
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.$toast.error(error)
          this.loading = false
        })
    },
    loadContractor () {
      this.fetchContractor(this.user.model_id)
        .then(() => {
          this.loadPayment()
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    loadAssignedProducts () {
      this.fetchProducts(this.$route.params.id)
        .then((response) => {
          this.products = response
          this.loading = false
        })
        .catch(error => {
          this.$toast.error(error)
          this.loading = false
        })
    },
    loadPayment () {
      this.fetchPayment()
        .then(() => {
          this.setPaymentMethods()
        })
        .catch(error => {
          this.$toast.error(error.data.error)
        })
    },
    selectFrequency () {
      if (this.selectedFrequency < 0) {
        this.customFrequency = true
      } else {
        this.scheduledOrder.frequency = this.selectedFrequency
      }
    },
    selectPayment () {
      this.selectPaymentOption = this.scheduledOrder.payment_method === 'CC'
    },
    selectProduct () {
      const index = this.selectedProducts.findIndex((o) => o.product_id === this.product.id)
      if (this.isProductScheduled(this.product.id)) {
        this.$toast.error('Product already exists on previously scheduled order')
      } else if (index === -1) {
        const obj = {
          product_id: this.product.id,
          name: this.product.name,
          quantity: this.selectedQuantity
        }

        this.product = {}
        this.selectedQuantity = 1

        this.selectedProducts.push(obj)
      } else {
        this.$toast.error('Product already added!')
      }
    },
    scheduleSelection () {
      this.scheduledOrder.products = this.selectedProducts
      this.currentStep = 2
    },
    paymentSelection () {
      this.currentStep = 3
    },
    removeSelection (index) {
      this.selectedProducts.splice(index, 1)
    },
    setPaymentMethods () {
      if (this.contractor.settings.credit) {
        this.paymentMethods.push({text: 'Net Terms', value: 'NET30'})
      }
    },
    snoozeSchedule (id) {
      this.snooze(id)
        .then(() => {
          this.$toast.success('Scheduled order snoozed for 30 days!')
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    async activateOrder (id) {
      if (await this.$refs.confirm.open('Activate Order?', 'Are you sure you want to activate this order?', { color: 'red' })) {
        this.update({id, payload: {status: 'PENDING_APPROVAL'}})
          .then(() => {
            this.$toast.success('Scheduled order activated!')
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      }
    },
    async cancelOrder (id) {
      if (await this.$refs.confirm.open('Cancel Order?', 'Are you sure you want to cancel this order?', { color: 'red' })) {
        this.update({id, payload: {status: 'CANCELLED'}})
          .then(() => {
            this.$toast.success('Scheduled order deleted!')
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      }
    },
    closeAdd () {
      this.selectedProducts = []
      this.currentStep = 1
      Object.assign(this.scheduledOrder, this.defaultScheduledOrder)
      //this.dialogAdd = false
      this.createNewSchedule = false
    },
    openAddSchedule () {
      if (this.products.length === 0) {
        this.$toast.error('Customer has 0 assigned products. Please assign a product to the customer before creating a schedule.')
      } else {

        Object.assign(this.scheduledOrder, this.defaultScheduledOrder)
        this.createNewSchedule = true
      }
    },
    addSchedule () {
      if (this.scheduledOrder.payment_method === '') {
        this.$toast.error('Payment option is required. If one is not available, you will need to create it in the Billing section of your Account')
        return
      }

      if (this.scheduledOrder.payment_method === 'CC' && !this.scheduledOrder.payment_id) {
        this.$toast.error('Payment method is required. If one is not available, you will need to create it in the Billing section of your Account')
        return
      }

      /*if (this.scheduledOrder.frequency === -1 && !this.customFrequency) {
        this.$toast.error('Frequency not set. Please select a frequency option.')
        return
      }*/

      if (!this.customFrequency) {
        this.scheduledOrder.frequency = this.selectedFrequency
      }

      this.add(this.scheduledOrder)
        .then(() => {
          this.closeAdd()
          this.$toast.success('Scheduled order created!')
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    resetDefault () {
      this.defaultScheduledOrder = {
        customer_id: this.customer.id,
        products: [],
        next: '',
        frequency: '',
        payment_method: '',
        payment_id: undefined,
        site_id: 3,
        contractor_id: this.user.model_id
      }
    },
    isProductScheduled (id) {
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].products) {
          for (let j = 0; j < this.orders[i].products.length; j++) {
            if (this.orders[i].products[j].product_id === id && this.orders[i].products[j].status !== 'CANCELLED') {
              return true
            }
          }
        }
      }
      return false
    },
    getProductName (lineItem) {
      if (this.settings.dynamics && lineItem.product.item.manufacturer.name === 'Dynamic') {
        return lineItem.product.name.replace('Dynamic', this.settings.dynamic_mfg_name)
      } else {
        return lineItem.product.name
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

</style>
