<template>
  <div id="cancel-dialog">
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
      @click:outside="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pa-4">
          <v-form>
            <v-textarea
              outlined
              auto-grow
              v-model="note"
              label="Add note"
              rows="3"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click.native="cancel">CANCEL</v-btn>
          <v-btn color="primary darken-1" text @click.native="accept">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      note: '',
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200
      }
    }
  },
  methods: {
    open (title, options) {
      this.note = ''
      this.dialog = true
      this.title = title
      this.options = Object.assign(this.options, options)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    accept () {
      this.resolve(this.note)
      this.dialog = false
    },
    cancel () {
      this.reject()
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
