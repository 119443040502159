<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="products"
      :search="search"
      fixed-header
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="openAddProduct"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        ${{ item.price }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="statusColorMap[item.status]">
          {{ statusMap[item.status] }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="confirmDelete(item.id)"
        >
          mdi-delete-outline
        </v-icon>
      </template>
      <template v-slot:no-data>
        No assigned products found.
      </template>
    </v-data-table>

    <confirmation-modal ref="confirm" />
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    showToolbar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      products: [],
      loading: true,
      dialogDelete: false,
      deleteID: null,
      search: '',
      statusMap: {
        SCHEDULED: 'Scheduled',
        UNSCHEDULED: 'Unscheduled'
      },
      statusColorMap: {
        SCHEDULED: 'success',
        UNSCHEDULED: 'primary'
      }
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: true
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Mfg',
          value: 'mfg'
        },
        {
          text: 'Part#',
          value: 'mfg_pn'
        },
        {
          text: 'Case Qty',
          value: 'purchase_qty'
        },
        {
          text: 'Price',
          value: 'price'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      fetch: 'fetchAssignedProducts',
      delete: 'removeProduct'
    }),
    ...mapMutations('itemManagement', {
      assignProduct: 'setAssignStatus'
    }),
    init () {
      this.fetch(this.$route.params.id)
        .then((response) => {
          this.products = response
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async confirmDelete (id) {
      if (await this.$refs.confirm.open('Delete Product Assignment', 'Are you sure you want to remove this product?', { color: 'red' })) {
        this.delete({id: this.$route.params.id, product_id: id})
          .then(() => {
            const index = this.products.findIndex((p) => p.id === this.deleteID)
            this.products.splice(index, 1)
            this.$toast.success('Assigned product deleted!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    openAddProduct () {
      this.assignProduct(true)
      this.$router.push({name: 'products'})
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

</style>
