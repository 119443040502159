<template>
  <v-container id="service-titan-pending-orders-list-view">
    <material-card
      color="primary"
      icon="mdi-account-multiple"
    >
      <template #title>
        Pending Service Titan Orders
      </template>

      <pending-order-history :show-title="false" :days="30" :service_titan="true" :status="status" />
    </material-card>

    <confirmation-modal ref="confirm" />

  </v-container>
</template>

<script>
export default {
  name: 'OrdersPending',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    PendingOrderHistory: () => import('@/views/components/PendingOrderHistory')
  },
  data () {
    return {
      status: 'PENDING_APPROVAL'
    }
  }
}
</script>

<style lang="scss">

</style>
