<template>
  <v-container id="orders-list-view">
    <material-card
      color="primary"
      icon="mdi-account-multiple"
    >
      <template #title>
        Orders
      </template>

      <order-history v-if="Object.entries(user).length > 0" :height="height"></order-history>
    </material-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OrdersList',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    OrderHistory: () => import('../components/OrderHistory')
  },
  data () {
    return {
      height: undefined
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    })
  }
}
</script>

<style scoped>

</style>
