var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "customer-detail-view" } },
    [
      _vm.showData
        ? _c(
            "div",
            [
              _c(
                "material-card",
                {
                  attrs: { color: "primary", icon: "mdi-account-details" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.customer.fname) +
                                " " +
                                _vm._s(_vm.customer.lname) +
                                " "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2101316535
                  )
                },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "First Name" },
                                    model: {
                                      value: _vm.local.fname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.local, "fname", $$v)
                                      },
                                      expression: "local.fname"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Last Name" },
                                    model: {
                                      value: _vm.local.lname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.local, "lname", $$v)
                                      },
                                      expression: "local.lname"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Company" },
                                    model: {
                                      value: _vm.local.company_name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.local, "company_name", $$v)
                                      },
                                      expression: "local.company_name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Email" },
                                    model: {
                                      value: _vm.local.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.local, "email", $$v)
                                      },
                                      expression: "local.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Account Number" },
                                    model: {
                                      value: _vm.local.account_number,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.local,
                                          "account_number",
                                          $$v
                                        )
                                      },
                                      expression: "local.account_number"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.customer.addresses,
                                      label: "Select primary address",
                                      "item-value": "id"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.street1) +
                                                  " " +
                                                  _vm._s(data.item.street2) +
                                                  ", " +
                                                  _vm._s(data.item.city) +
                                                  ", " +
                                                  _vm._s(data.item.state.name) +
                                                  " " +
                                                  _vm._s(data.item.zip) +
                                                  " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-subtitle",
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.full_name
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("v-spacer"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.street1
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            data.item.street2
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            data.item.city
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            data.item.state.name
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            data.item.zip
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1516072458
                                    ),
                                    model: {
                                      value: _vm.local.default_shipping,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.local,
                                          "default_shipping",
                                          $$v
                                        )
                                      },
                                      expression: "local.default_shipping"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-5",
                                  attrs: { color: "red", outlined: "" },
                                  on: { click: _vm.resetCustomer }
                                },
                                [_vm._v(" RESET ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { outlined: "", color: "primary" },
                                  on: { click: _vm.handleUpdate }
                                },
                                [_vm._v(" UPDATE ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "my-5" },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { focusable: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [_vm._v("Addresses")]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("Addresses", {
                                on: {
                                  reload: function($event) {
                                    return _vm.loadCustomer()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Order History")
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [_c("OrderHistory")],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Scheduled Orders")
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [_c("ScheduledOrders")],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Filter Change Reminders")
                          ]),
                          _c("v-expansion-panel-content", [_c("Reminders")], 1)
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Assigned Products")
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [_c("AssignedProducts")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.hasError
                ? _c("material-card", {
                    attrs: { color: "primary", icon: "mdi-account-details" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      364149379
                    )
                  })
                : _vm._e()
            ],
            1
          ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }