<template>
  <div id="scheduled-order-actions-panel">
    <panel>
      <template #title>
        Order Actions
      </template>

      <template #content>
        <v-select
          :items="frequencyOptions"
          v-model="frequency"
          :menu-props="{ maxHeight: 350 }"
        ></v-select>
        <v-text-field
          v-if="frequency < 0"
          type="number"
          v-model="customFrequency"
          label="Custom frequency (days)"
          outlined
        ></v-text-field>
        <v-btn
          :disabled="frequency === originalFrequency"
          block
          color="primary"
          @click="updateFrequency"
        >
          Update Ship Date
        </v-btn>
      </template>
    </panel>

    <confirmation-modal ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ScheduledOrderFrequencyPanel',
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    Panel: () => import('@/components/Panel')
  },
  data () {
    return {
      frequencyOptions: [
        {text: 'Does not repeat', value: 0},
        {text: 'Repeat Annually', value: 360},
        {text: 'Repeat Semi-Annually', value: 180},
        {text: 'Repeat Every 3 Months', value: 90},
        {text: 'Repeat Every 2 Months', value: 60},
        {text: 'Repeat Monthly', value: 30},
        {text: 'Custom', value: -1}
      ],
      customFrequency: null,
      frequency: null,
      originalFrequency: null
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.scheduledOrder
    })
  },
  methods: {
    ...mapActions('orderManagement', {
      update: 'updateScheduled'
    }),
    ...mapMutations('orderManagement', {
      loadOrder: 'setScheduledOrder'
    }),
    init () {
      this.frequency = [0, 30, 60, 90, 180, 360].includes(this.order.frequency) ? this.order.frequency : -1
      this.originalFrequency = this.frequency
      this.customFrequency = this.frequency === -1 ? this.order.frequency : null
    },
    async updateFrequency () {
      if (await this.$refs.confirm.open('Update Schedule', 'Are you sure you want to update scheduled frequency?', { color: 'red' })) {
        const freq = this.frequency < 0 ? this.customFrequency : this.frequency
        this.update({ id: this.order.id, payload: { frequency: freq } })
          .then((response) => {
            this.loadOrder(response.data)
            this.$toast.success('Schedule frequency update successful!')
            this.init()
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>

</style>
