
const state = {
  site_id: 3,
  states: [],
  stateOptions: [
    {text: 'Alabama', abbreviation: 'AL', value: 1},
    {text: 'Alaska', abbreviation: 'AK', value: 2},
    {text: 'Arizona', abbreviation: 'AZ', value: 3},
    {text: 'Arkansas', abbreviation: 'AR', value: 4},
    {text: 'California', abbreviation: 'CA', value: 5},
    {text: 'Colorado', abbreviation: 'CO', value: 6},
    {text: 'Connecticut', abbreviation: 'CT', value: 7},
    {text: 'Delaware', abbreviation: 'DE', value: 8},
    {text: 'District of Columbia', abbreviation: 'DC', value: 9},
    {text: 'Florida', abbreviation: 'FL', value: 10},
    {text: 'Georgia', abbreviation: 'GA', value: 11},
    {text: 'Hawaii', abbreviation: 'HI', value: 12},
    {text: 'Idaho', abbreviation: 'ID', value: 13},
    {text: 'Illinois', abbreviation: 'IL', value: 14},
    {text: 'Indiana', abbreviation: 'IN', value: 15},
    {text: 'Iowa', abbreviation: 'IA', value: 16},
    {text: 'Kansas', abbreviation: 'KS', value: 17},
    {text: 'Kentucky', abbreviation: 'KY', value: 18},
    {text: 'Louisiana', abbreviation: 'LA', value: 19},
    {text: 'Maine', abbreviation: 'ME', value: 20},
    {text: 'Maryland', abbreviation: 'MD', value: 21},
    {text: 'Massachusetts', abbreviation: 'MA', value: 22},
    {text: 'Michigan', abbreviation: 'MI', value: 23},
    {text: 'Minnesota', abbreviation: 'MN', value: 24},
    {text: 'Mississippi', abbreviation: 'MS', value: 25},
    {text: 'Missouri', abbreviation: 'MO', value: 26},
    {text: 'Montana', abbreviation: 'MT', value: 27},
    {text: 'Nebraska', abbreviation: 'NE', value: 28},
    {text: 'Nevada', abbreviation: 'NV', value: 29},
    {text: 'New Hampshire', abbreviation: 'NH', value: 30},
    {text: 'New Jersey', abbreviation: 'NJ', value: 31},
    {text: 'New Mexico', abbreviation: 'NM', value: 32},
    {text: 'New York', abbreviation: 'NY', value: 33},
    {text: 'North Carolina', abbreviation: 'NC', value: 34},
    {text: 'North Dakota', abbreviation: 'ND', value: 35},
    {text: 'Ohio', abbreviation: 'OH', value: 36},
    {text: 'Oklahoma', abbreviation: 'OK', value: 37},
    {text: 'Oregon', abbreviation: 'OR', value: 38},
    {text: 'Pennsylvania', abbreviation: 'PA', value: 39},
    {text: 'Rhode Island', abbreviation: 'RI', value: 40},
    {text: 'South Carolina', abbreviation: 'SC', value: 41},
    {text: 'South Dakota', abbreviation: 'SD', value: 42},
    {text: 'Tennessee', abbreviation: 'TN', value: 43},
    {text: 'Texas', abbreviation: 'TX', value: 44},
    {text: 'Utah', abbreviation: 'UT', value: 45},
    {text: 'Vermont', abbreviation: 'VT', value: 46},
    {text: 'Virginia', abbreviation: 'VA', value: 47},
    {text: 'Washington', abbreviation: 'WA', value: 48},
    {text: 'West Virginia', abbreviation: 'WV', value: 49},
    {text: 'Wisconsin', abbreviation: 'WI', value: 50},
    {text: 'Wyoming', abbreviation: 'WY', value: 51}
  ],
  paymentTypes: ['PURCHASE', 'REFUND', 'VOID', 'NET30'],
  paymentTypesMap: {
    PURCHASE: 'Purchase',
    REFUND: 'Refund',
    VOID: 'Void',
    NET30: 'Net30'
  },
  orderStatuses: ['all', 'PENDING', 'PROCESSING', 'SHIPPED', 'CANCELLED', 'UNPAID', 'REFUNDED', 'PENDING_APPROVAL', 'DEMO'],
  orderStatusMap: {
    all: 'All',
    PENDING: 'Pending',
    PROCESSING: 'Processing',
    SHIPPED: 'Shipped',
    CANCELLED: 'Cancelled',
    UNPAID: 'Unpaid',
    REFUNDED: 'Refunded',
    PENDING_APPROVAL: 'Pending Approval',
    SCHEDULED: 'Scheduled',
    UNSCHEDULED: 'Unscheduled',
    BACKORDER: 'Backorder',
    DEMO: 'Demo',
    COMPLETE: 'Complete'
  },
  orderStatusColorMap: {
    PENDING: 'primary',
    PROCESSING: 'warning',
    SHIPPED: 'success',
    UNPAID: 'warning',
    REFUNDED: 'red',
    PENDING_APPROVAL: 'primary',
    CANCELLED: 'red',
    DEMO: 'warning',
    BACKORDER: 'red',
    COMPLETE: 'success'
  },
  shippingCarriers: ['all', 'FEDEX', 'FEDEX_SMARTPOST', 'USPS', 'UPS', 'PITNEY'],
  shippingCarrierMap: {
    all: 'All',
    FEDEX: 'FedEx',
    FEDEX_SMARTPOST: 'Smartpost',
    USPS: 'USPS',
    UPS: 'UPS',
    PITNEY: 'Pitney Bowes'
  },
  drawer: false,
  snack: ''
}

export default state
