export default {
  isInCart: (state) => (id) => {
    const cartItem = state.selectedCustomer.items.find((item) => item.product_id === id)

    return !!cartItem
  },
  getCustomerById: (state) => (id) => {
    return state.cart.customers.find((customer) => customer.id === id)
  },
  getCustomerCartSubtotal (state) {
    return state.selectedCustomer.items.reduce((subtotal, product) => subtotal + (product.price * product.quantity), 0)
  },
  getCustomerDeliveryFee (state) {
    return state.selectedCustomer.delivery_fee
  },
  getCustomerCartTax (state, getters) {
    if (getters.getCustomerCartSubtotal > 0) {
      const subtotal = getters.getCustomerCartSubtotal
      const tax = subtotal * state.selectedCustomer.tax_rate
      return tax.toFixed(2)
    } else {
      return 0
    }
  },
  getCustomerCartTotal (state, getters) {
    if (getters.getCustomerCartSubtotal > 0) {
      const subtotal = parseFloat(getters.getCustomerCartSubtotal)
      const tax = parseFloat(getters.getCustomerCartTax)
      return subtotal + parseFloat(tax) + parseFloat(getters.getCustomerDeliveryFee)
    } else {
      return 0
    }
  },
  customerCartSubtotal: (state, getters) => (customerId) => {
    const customer = getters.getCustomerById(customerId)

    return customer.items.reduce((subtotal, product) => subtotal + (product.price * product.quantity), 0)
  },
  customerCartTax: (state, getters) => (customerId) => {
    const customer = getters.getCustomerById(customerId)
    const subtotal = customer.items.reduce((subtotal, product) => subtotal + (product.price * product.quantity), 0)

    return subtotal * customer.tax_rate
  },
  customerCartTotal: (state, getters) => (customerId) => {
    const customer = getters.getCustomerById(customerId)
    const subtotal = customer.items.reduce((subtotal, item) => subtotal + (item.price * item.quantity), 0)

    return subtotal + (subtotal * customer.tax_rate)
  },
  customerCartItems (state) {
    return state.selectedCustomer.items
  },
  cartSubtotal (state, getters) {
    if (state.cart.customers.length > 0) {
      let subtotal = 0
      state.cart.customers.forEach((customer) => {
        if (customer.items.length > 0) {
          subtotal += getters.customerCartSubtotal(customer.id)
        }
      })

      return subtotal
    } else {
      return 0
    }
  },
  cartTax (state, getters) {
    if (state.cart.customers.length > 0) {
      let taxTotal = 0

      state.cart.customers.forEach((customer) => {
        const subtotal = getters.customerCartSubtotal(customer.id)
        taxTotal += subtotal * customer.tax
      })

      return taxTotal
    } else {
      return 0
    }
  },
  cartTotal (state, getters) {
    if (Object.entries(state.cart).length > 0) {
      if (state.cart.customers.length > 0) {
        let cartTotal = 0
        state.cart.customers.forEach((customer) => {
          if (customer.items.length > 0) {
            cartTotal += getters.customerCartTotal(customer.id)
          }
        })

        return cartTotal
      } else {
        return 0
      }
    } else {
      return 0
    }
  }
}
