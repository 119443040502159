<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    fixed
  >
    <v-list
      nav
    >
      <v-list-item
        v-for="(item, index) in filterMenuItems"
        :key="index"
        :to="item.to"
        :active-class="`primary white--text`"
        class="text-decoration-none"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import navItems from './side-nav-items'
import { mapState } from 'vuex'

export default {
  name: 'Drawer',
  data () {
    return {
      selectedItem: 0,
      mini: false,
      drawer: null,
      items: navItems[this.$router.currentRoute.meta.parent]
    }
  },
  computed: {
    ...mapState('contractorManagement', {
      settings: state => state.settings
    }),
    filterMenuItems () {
      const settings = this.settings
      let tempItems = this.items
      tempItems = tempItems.filter((item) => {
        if (item.meta.serviceTitan) {
          return settings.st_active
        }
        return true
      })

      return tempItems
    }
  }
}
</script>

<style scoped>

</style>
