var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Header"),
      _c("drawer"),
      _c(
        "v-main",
        { attrs: { id: "orders" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [_c("router-view", { key: _vm.$route.path })],
            1
          )
        ],
        1
      ),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }