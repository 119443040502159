import state from './moduleReportManagementState.js'
import mutations from './moduleReportManagementMutations.js'
import actions from './moduleReportManagementActions.js'
import getters from './moduleReportManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
