export default {
  setPayment (state, payment) {
    state.payment = payment
  },
  setPayments (state, payments) {
    state.payments = payments
  },
  updatePayment (state, payment) {
    const index = state.payments.findIndex((u) => u.id === payment.id)
    state.payments.splice(index, 1, payment)
  },
  removePayment (state, id) {
    const index = state.payments.findIndex((u) => u.id === id)
    state.payments.splice(index, 1)
  }
}
