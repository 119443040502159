<template>
  <div id="order-items-panel">
    <panel>
      <template #title>
        Order Items
      </template>

      <template #content>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Price</th>
              <th class="text-left">Qty</th>
              <th class="text-left">Subtotal</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(item, i) in order.products"
              :key="i"
            >
              <td>{{ $utils.productName(item.product) }}</td>
              <td>${{ item.list_price }}</td>
              <td>{{ item.quantity }}</td>
              <td>${{ item.list_price }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider class="m-0 p-0"></v-divider>
        <v-row class="d-flex justify-end mt-2">
          <v-col class="text-right" cols="2">
            Items Subtotal:
          </v-col>
          <v-col class="text-right" cols="2">
            ${{ order.subtotal }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end mt-0">
          <v-col class="text-right" cols="2">
            Sales Tax:
          </v-col>
          <v-col class="text-right" cols="2">
            ${{ order.tax }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end mt-0">
          <v-col class="text-right" cols="2">
            Order Total:
          </v-col>
          <v-col class="text-right" cols="2">
            ${{ order.total }}
          </v-col>
        </v-row>
      </template>
    </panel>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderItemsPanel',
  components: {
    Panel: () => import('@/components/Panel')
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    })
  }
}
</script>

<style scoped>

</style>
