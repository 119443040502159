<template>
  <div>
    <v-card>
      <v-card-text class="pa-5">
        <div class="d-flex align-center">
          <v-btn
            fab
            color="info"
            elevation="0"
            >
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
          <div class="ml-2 mr-1">
            <h2 class="headline font-weight-light">{{ value }}</h2>
            <h5 class="subtitle-2 font-weight-regular">{{ title }}</h5>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'StatisticsCard',
  props: {
    value: {
      type: [String, Number],
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped>

</style>
