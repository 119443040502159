<template>
  <div id="products-list-view">
    <div id="product-filter-component">
      <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        fixed
        permanent
      >
        <v-layout align-center justify-space-between column fill-height>
          <v-list
            flat
            subheader
          >
            <v-subheader>Category</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-select
                    v-model="query.category_id"
                    :items="categoryOptions"
                    label="Select Category"
                    @change="selectCategory"
                    dense
                    hide-details
                    outlined/>
              </v-list-item-content>
            </v-list-item>

            <div v-if="query.category_id !== 15">
              <v-subheader>Size</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-select
                    label="Length"
                    outlined
                    dense
                    hide-details
                    @change="dirty = true"
                    v-model="query.length"
                    :items="lengthOptions"/>
                  <v-select
                    label="Width"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                    @change="dirty = true"
                    v-model="query.width"
                    :items="widthOptions"/>
                  <v-select
                    label="Thickness"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                    @change="dirty = true"
                    v-model="query.thickness"
                    :items="thicknessOptions"/>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-subheader>Manufacturer</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-select
                  v-model="query.mfg_id"
                  :items="sortedManufacturers"
                  item-text="name"
                  item-value="id"
                  label="Select Manufacturer"
                  @change="dirty = true"
                  dense
                  hide-details
                  outlined/>
              </v-list-item-content>
            </v-list-item>

            <div v-if="query.category_id !== 15">
              <v-subheader>Merv Rating</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-select
                      v-model="query.mervs"
                      :items="mervOptions"
                      label="Select Merv Rating"
                      @change="dirty = true"
                      outlined
                      multiple
                      hide-details
                      dense />
                </v-list-item-content>
              </v-list-item>
            </div>

<!--            <v-subheader>Show Only</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                  @change="dirty = true"
                  dense
                  hide-details
                  label="My Favorites"
                  class="m-0"
                ></v-checkbox>
              </v-list-item-content>
            </v-list-item>-->

            <v-divider v-if="dirty" />

            <v-list-item>
              <v-list-item-content>
                <v-btn
                  v-if="dirty"
                  color="primary"
                  outlined
                  @click="handleFilter"
                >
                  SEARCH
                </v-btn>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-btn
                  v-if="dirty"
                  color="red"
                  outlined
                  @click="resetFilter"
                >
                  RESET FILTER
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-btn
            class="mb-3"
            color="primary"
            justify-end
            exact
            outlined
            @click.stop="showCustomRequest = true"
          >
            REQUEST CUSTOM FILTER
          </v-btn>
        </v-layout>
      </v-navigation-drawer>
    </div>

    <v-container fluid>
      <v-card>
<!--        <v-data-table
          :headers="headers"
          :items="products"
          :search="search"
          :loading="loading"
          :items-per-page="limit"
          :page.sync="page"
          class="mr-2 ml-2">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Products</v-toolbar-title>
              <v-divider class="mx-4" inset vertical/>
              <v-text-field
                placeholder="Search"
                hide-details
                v-model="search"
                clearable
                @click:clear="clearSearch"
                append-icon="mdi-magnify"
                dense />
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`product.item.image`]="{ product }">
            <v-img :src="product.item.image" :height="90" :width="90" contain></v-img>
          </template>
          <template v-slot:[`product.name`]="{ product }">
            <span class="primary&#45;&#45;text" style="cursor: pointer;" @click.stop="viewProduct(product)">{{product.name}}</span>
          </template>
          <template v-slot:[`product.list_price`]="{ product }">
            ${{ product.list_price }}
          </template>
          <template v-slot:[`item.actions`]="{ product }">
            <v-btn
              v-if="assignProduct"
              icon
              color="primary"
              class="mr-2"
              @click="assignProductToCustomer(product)"
              >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              color="primary"
              class="mr-2"
              @click="addSchedule(product)"
              :disabled="isDisabled"
            >
              <v-icon>mdi-cart-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>-->
        <v-data-table
          :headers="headers"
          :items="products"
          :search="query.keyword"
          :loading="loading"
          :items-per-page="query.limit"
          :page.sync="query.page"
          :server-items-length="total"
          :options.sync="options"
          class="mr-2 ml-2">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Products</v-toolbar-title>
              <v-divider class="mx-4" inset vertical/>
              <v-text-field
                placeholder="Search"
                hide-details
                v-model="query.keyword"
                clearable
                @keydown.enter="handleSearch"
                @click:clear="clearSearch"
                append-icon="mdi-magnify"
                dense />
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.image="{ item }">
            <v-img :src="item.image" :height="90" :width="90" contain></v-img>
          </template>
          <template v-slot:item.name="{ item }">
            <span class="primary--text" style="cursor: pointer;" @click.stop="viewProduct(item)">{{ $utils.itemName(item) }}</span>
          </template>
          <template v-slot:item.mfg_name="{ item }">
            {{ $utils.productMfgName(item.mfg_name) }}
          </template>
          <template v-slot:item.list_price="{ item }">
            ${{ item.list_price }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="assignProduct"
              icon
              color="primary"
              class="mr-2"
              @click="assignProductToCustomer(item)"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
            <v-btn
              v-if="showAddToCartButton"
              icon
              color="primary"
              class="mr-2"
              @click="addSchedule(item)"
              :disabled="isDisabled"
            >
              <v-icon>mdi-cart-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <order-summary v-if="showSummary" v-on:add-cart-item="addFromSummary" />

    <v-dialog v-model="orderScheduleDialog" persistent max-width="600px">
      <v-card>
        <v-card-text>
          <h1 class="pt-5 text-center font-black">Schedule Shipment</h1>
        </v-card-text>
        <v-card-text>
          <v-text-field
            type="number"
            v-model="quantity"
            label="Quantity"
            outlined
            min="1"
          ></v-text-field>
          <v-menu
            ref="menu"
            v-model="shippingMenu"
            :close-on-content-click="false"
            :return-value.sync="shipDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="shipDate"
                label="Ship Date"
                append-icon="mdi-calendar"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="shipDate"
              no-title
              scrollable
              :min="minDate"
              @input="$refs.menu.save(shipDate)"
            >
            </v-date-picker>
          </v-menu>

          <v-select
            :items="frequencyOptions"
            v-model="frequency"
            outlined
            label="Select shipment frequency"
            :menu-props="{ maxHeight: 350 }"
          ></v-select>
          <v-text-field
            v-if="frequency < 0"
            type="number"
            v-model="customFrequency"
            label="Custom frequency (days)"
            outlined
            ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeOrderScheduleDialog">Cancel</v-btn>
          <v-btn :disabled="!validAddToCart" color="blue darken-1" text @click="addToCart">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCustomRequest"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Request Custom Filter</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-model="customAlert"
            close-text="Close"
            dark
            dismissible
            :type="alertType"
          >
            {{ alertMessage }}
          </v-alert>
          <v-form
            ref="customRequestForm"
            v-model="customRequestValid"
            >
            <v-text-field
              label="Length"
              type="number"
              min="0"
              v-model="customFilter.length"
            ></v-text-field>
            <v-text-field
              label="Width"
              type="number"
              min="0"
              v-model="customFilter.width"
            ></v-text-field>
            <v-text-field
              label="Thickness"
              type="number"
              min="0"
              v-model="customFilter.thickness"
            ></v-text-field>
            <v-select
              :items="['8', '11', '13']"
              label="Merv Rating"
              v-model="customFilter.merv"
              multiple
            ></v-select>
          </v-form>
<!--          <v-container>
            <v-alert
              v-model="customAlert"
              close-text="Close"
              dark
              dismissible
              :type="alertType"
              >
              {{ alertMessage }}
            </v-alert>
            <v-row>
              <v-col
                cols="12"
                >
                <v-text-field
                  label="Length"
                  v-model="customFilter.length"
                  required
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Width"
                  v-model="customFilter.width"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Thickness"
                  v-model="customFilter.thickness"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  :items="['8', '11', '13']"
                  label="Merv Rating"
                  v-model="customFilter.merv"
                  required
                  ></v-select>
              </v-col>
            </v-row>
          </v-container>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="closeRequest"
            >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!customRequestValid"
            color="primary"
            text
            :loading="customRequestLoader"
            @click="submitCustomRequest"
            >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="Object.entries(selectedProduct).length > 0"
      v-model="showProductDetails"
      max-width="900"
      height="600"
    >
      <v-card>
        <v-container class="p-5">
          <v-row>
            <v-col cols="4">
              <v-img
                class="p-2"
                contain
                :src="selectedProduct.image"
              ></v-img>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-card-title>
                  <span class="h6">{{ $utils.itemName(selectedProduct) }}</span>
                </v-card-title>

                <v-col cols="12" sm="12" md="12" lg="12">
                  <span class="font-weight-bold">${{selectedProduct.list_price}}</span> from <span class="primary--text">{{ $utils.productMfgName(selectedProduct.mfg_name) }}</span>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6">
                  <v-text-field v-model="quantity" type="number" dense outlined label="Qty" min="1"></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6">
                  <v-btn
                    v-if="assignProduct"
                    size="medium"
                    color="primary"
                    @click="assignProductToCustomer(selectedProduct)"
                  >
                    Assign Product
                  </v-btn>
                  <v-btn
                    v-else
                    size="medium"
                    color="primary"
                    @click="addSchedule(selectedProduct)"
                    :disabled="!showAddToCartButton"
                  >
                    ADD TO ORDER
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="border-top">
                <v-col cols="4" sm="4" md="4">
                  <strong>Case Quantity</strong>
                  <p class="text--secondary">{{selectedProduct.purchase_qty}}</p>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <strong>Product ID</strong>
                  <p class="text--secondary">{{selectedProduct.id}}</p>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <strong>Part Number</strong>
                  <p class="text--secondary">{{selectedProduct.mfg_pn}}</p>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <p v-html="selectedProduct.description"></p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="closeProduct"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {
    OrderSummary: () => import('./components/OrderSummary')
  },
  data () {
    return {
      query: {
        mfg_id: '',
        length: '',
        width: '',
        thickness: '',
        type: '',
        category_id: '',
        mervs: [],
        page: 1,
        site_id: 3,
        limit: 10,
        sort_col: 'id',
        sort_direction: 'asc',
        keyword: ''
      },
      total: 0,
      loading: false,
      mini: false,
      drawer: null,
      dirty: false,
      showCustomRequest: false,
      customRequestValid: true,
      customRequestLoader: false,
      customFilter: {
        name: '',
        email: '',
        contractor: '',
        length: 0,
        width: 0,
        thickness: 0,
        merv: [],
        site_id: 3,
        type: 'custom'
      },
      requiredRules: [v => !!v || 'Field is required'],
      products: [],
      options: {},
      product: {
        id: null,
        name: '',
        price: 0,
        cost: 0,
        mfg_name: '',
        purchase_qty: 0,
        quantity: 1,
        shipping: 0
      },
      customAlert: false,
      alertType: 'success',
      alertMessage: '',
      showProductDetails: false,
      selectedProduct: {},
      shippingMenu: false,
      minDate: null,
      shipDate: '',
      quantity: 1,
      frequency: undefined,
      customFrequency: undefined,
      frequencyOptions: [
        {text: 'Does not repeat', value: 0},
        {text: 'Repeat Annually', value: 360},
        {text: 'Repeat Semi-Annually', value: 180},
        {text: 'Repeat Every 3 Months', value: 90},
        {text: 'Repeat Every 2 Months', value: 60},
        {text: 'Repeat Monthly', value: 30},
        {text: 'Custom', value: -1}
      ],
      categoryOptions: [
        { value: 28, text: 'Filters' },
        { value: 15, text: 'UV Lights' }
      ],
      orderScheduleDialog: false
    }
  },
  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('cartManagement', {
      cart: state => state.cart,
      cartCustomer: state => state.selectedCustomer
    }),
    ...mapState('itemManagement', {
      manufacturers: state => state.manufacturers,
      lengthOptions: state => state.length,
      widthOptions: state => state.width,
      thicknessOptions: state => state.thickness,
      mervOptions: state => state.merv,
      typeOptions: state => state.types,
      inProgress: state => state.inProgress,
      assignProduct: state => state.assignProduct
    }),
    ...mapState('customerManagement', {
      customer: state => state.customer
    }),
    ...mapState('contractorManagement', {
      settings: state => state.settings
    }),
    isDisabled () {
      return this.cartCustomer === undefined
    },
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: false
        },
        {
          text: 'Image',
          sortable: false,
          filterable: false,
          value: 'image',
          width: 100
        },
        {
          text: 'Name',
          value: 'name',
          width: 450,
          sortable: false
        },
        {
          text: 'Mfg',
          value: 'mfg_name',
          sortable: false
        },
        {
          text: 'Part#',
          value: 'mfg_pn',
          sortable: false
        },
        {
          text: 'Case Qty',
          value: 'purchase_qty',
          sortable: false
        },
        {
          text: 'Price',
          value: 'list_price',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    hasProducts () {
      return Object.keys(this.products).length > 0
    },
    validAddToCart () {
      const product = {
        id: this.selectedProduct.id,
        quantity: this.quantity,
        frequency: this.frequency === -1 ? this.customFrequency : this.frequency,
        next: this.shipDate
      }

      return !(!product.id || product.quantity === 0 || typeof product.frequency === 'undefined' || !product.next)
    },
    showSummary () {
      if (this.assignProduct) return false

      if (!this.cart) return false

      if (!this.cart.customers) return false

      return this.cart.customers.length > 0
    },
    showAddToCartButton () {
      if (this.assignProduct) return false

      if (Object.entries(this.cartCustomer).length === 0) return false

      return !!this.cartCustomer
    },
    filteredManufacturers () {
      let tempMfg = this.manufacturers

      tempMfg = tempMfg.filter((item) => {
        if (item.id === 9) {
          return this.settings.dynamics
        } else {
          return true
        }
      })

      const index = tempMfg.findIndex((m) => m.id === 9)
      if (index > -1) {
        tempMfg[index].name = this.settings.dynamic_mfg_name
      }

      return tempMfg
    },
    sortedManufacturers () {
      let sortedMfg = this.manufacturers

      sortedMfg = sortedMfg.sort((a, b) => {
        const fa = a.name.toLowerCase()
        const fb = b.name.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 0
        }

        return 0
      })

      return sortedMfg
    }
  },
  methods: {
    ...mapActions('cartManagement', {
      addProductToCart: 'addProductToCart'
    }),
    ...mapActions('itemManagement', {
      fetch: 'fetchProducts',
      fetchManufacturers: 'fetchManufacturers',
      customRequest: 'requestCustomFilter'
    }),
    ...mapActions('customerManagement', {
      setProduct: 'addProduct'
    }),
    ...mapMutations('itemManagement', {
      assign: 'setAssignStatus'
    }),
    fetchData () {
      this.products = []
      this.loading = true

      this.fetch(this.query)
        .then((response) => {
          if (!response.success) {
            this.$toast.error(response.error)
          }
          this.products = response.data.data
          this.total = response.data.total
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadManufacturers () {
      const obj = {
        contractor_id: this.user.model_id,
        category_id: this.query.category_id
      }
      this.fetchManufacturers(obj)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error)
        })
    },
    addToCart () {
      if (this.frequency === -1 && !this.customFrequency) {
        this.$toast.error('Frequency not set. Please select a frequency option.')
      } else {
        const product = {
          id: this.selectedProduct.id,
          quantity: this.quantity,
          frequency: this.frequency === -1 ? this.customFrequency : this.frequency,
          next: this.shipDate
        }

        this.addProductToCart({product, cart_customer_id: this.cartCustomer.id})
          .then(() => {
            this.selectedProduct = {}
            this.quantity = 1
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
          .finally(() => {
            this.orderScheduleDialog = false
          })
      }
    },
    assignProductToCustomer (item) {
      const obj = {
        customer_id: this.customer.id,
        product_id: item.id
      }
      this.setProduct(obj)
        .then(() => {
          this.assign(false)
          //const id = this.customer.id
          this.$toast.success('Product has been assigned.')
          //this.$router.push({name: 'customer_detail', params: {id}})
          this.$router.back()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    resetFilter () {
      this.query.mfg_id = ''
      this.query.length = ''
      this.query.width = ''
      this.query.thickness = ''
      this.query.type = ''
      this.query.mervs = []
      this.query.category_id = ''

      this.dirty = false
      this.query.page = 1

      this.fetchData()
    },
    handleSearch () {
      if (this.query.keyword) {
        if (this.query.keyword.length > 3) {
          this.query.page = 1

          this.fetchData()
        }
      }
    },
    clearSearch () {
      this.query.keyword = ''
      this.query.page = 1

      this.fetchData()
    },
    handleFilter () {
      this.query.page = 1
      this.dirty = true

      this.fetchData()
    },
    selectCategory () {
      this.dirty = true
      this.loadManufacturers()
    },
    closeRequest () {
      this.$refs.customRequestForm.resetValidation()
      this.customFilter = {
        length: '',
        width: '',
        thickness: '',
        merv: '',
        type: ''
      }
      this.showCustomRequest = false
    },
    submitCustomRequest () {
      this.customRequestLoader = true
      this.customRequest(this.customFilter)
        .then((response) => {
          this.customAlert = true
          this.alertType = 'success'
          this.alertMessage = response.message
          this.customFilter = {
            name: '',
            email: '',
            contractor: '',
            length: 0,
            width: 0,
            thickness: 0,
            merv: [],
            site_id: 3,
            type: 'custom'
          }
          this.$refs.customRequestForm.resetValidation()
        })
        .catch((error) => {
          this.customAlert = true
          this.alertType = 'error'
          this.alertMessage = error.data ? error.data.error : error
        })
        .finally(() => {
          this.customRequestLoader = false
        })
    },
    viewProduct (item) {
      this.selectedProduct = item
      this.quantity = 1
      this.showProductDetails = true
    },
    closeProduct () {
      this.selectedProduct = {}
      this.showProductDetails = false
    },
    addFromSummary (product) {
      this.addSchedule(product)
    },
    addSchedule (product) {
      this.shipDate = ''
      this.frequency = undefined
      this.showProductDetails = false
      this.selectedProduct = product
      this.orderScheduleDialog = true
    },
    closeOrderScheduleDialog () {
      this.frequency = undefined
      this.customFrequency = undefined
      this.shipDate = ''
      this.quantity = 1
      this.orderScheduleDialog = false
    }
  },
  created () {
    this.minDate = this.$moment().add(1, 'days').format('YYYY-MM-DD')
    if (this.manufacturers.length === 0) {
      this.loadManufacturers()
    }
  }
}
</script>

<style scoped>

</style>
