import state from './moduleCustomerManagementState'
import mutations from './moduleCustomerManagementMutations'
import actions from './moduleCustomerManagementActions'
import getters from './moduleCustomerManagementGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
