<template>
  <v-container v-if="hasUser" id="scheduled-orders-list-view">
    <material-card
      color="primary"
      icon="mdi-account-multiple"
    >
      <template #title>
        Orders Batches
      </template>

      <v-data-table
        :headers="headers"
        :items="batches"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        loading-text="Loading Orders Batches... Please wait"
        class="mr-2 ml-2"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <v-menu
              ref="scheduledMenu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-4"
                  style="max-width: 30%"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                scrollable
                range
                :max="maxDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="setDates"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            icon
            class="mr-2"
            @click="exportBatch(item.id)"
          >
            <v-icon>mdi-export</v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <!--          <v-btn color="primary" @click="loadBatches">No data found! Click to retry</v-btn>-->
          No orders batches found.
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'OrdersBatches',
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      query: {
        contractor_id: '',
        page: 1,
        limit: 10,
        sort_col: 'id',
        sort_direction: 'asc',
        start: '',
        end: '',
        site_id: 3
      },
      total: 0,
      options: {
        sortBy: ['id'],
        sortDesc: [false]
      },
      loading: false,
      dateFormat: 'MM/DD/YYYY',
      maxDate: undefined,
      menu: false,
      dates: [],
      batches: [],
      fileLinks: []
    }
  },
  filters: {
    tag (value) {
      if (value.length > 0) {
        return 'Attention Needed'
      } else {
        return 'Valid'
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    headers () {
      return [
        {
          text: 'Batch#',
          value: 'id'
        },
        {
          text: 'Date',
          value: 'created_at'
        },
        {
          text: 'Created By',
          value: 'created_by'
        },
        {
          text: '#Orders',
          value: 'orders_count'
        },
        {
          text: 'Sales Tax',
          value: 'sales_tax'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Link',
          value: 'export_link',
          align: ' d-none'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    dateRangeText () {
      return `${this.query.start} - ${this.query.end}`
    },
    hasUser () {
      return Object.entries(this.user).length > 0
    }
  },
  watch: {
    options: {
      handler () {
        this.loadBatches()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      fetch: 'fetchBatches',
      export: 'exportBatch'
    }),
    init () {
      const startDate = this.$moment().subtract(7, 'days').startOf('days')
      const endDate = this.$moment().endOf('days')

      this.maxDate = this.$moment().format('YYYY-MM-DD')
      this.dates = [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]

      this.query.start = startDate.format(this.dateFormat)
      this.query.end = endDate.format(this.dateFormat)
    },
    setDates () {
      this.menu = false
      this.query.start = this.$moment(this.dates[0]).format(this.dateFormat)
      this.query.end = this.$moment(this.dates[1]).format(this.dateFormat)
      this.loadBatches()
    },
    loadBatches () {
      this.loading = true
      this.query.contractor_id = this.user.model_id

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.query.limit = itemsPerPage
      this.query.page = page
      this.query.sort_col = sortBy.length === 1 ? sortBy[0] : 'id'
      this.query.sort_direction = sortDesc[0] ? 'desc' : 'asc'

      this.fetch(this.query)
        .then((response) => {
          this.batches = response.data
          this.total = response.meta.total
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    exportBatch (id) {
      this.export(id)
        .then((response) => {
          const blob = new Blob([response], { type: 'application/xlsx'})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'BatchReport.xlsx'
          link.click()
        })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>

</style>
