<template>
  <div id="order-summary-component">
    <v-navigation-drawer
      v-if="selectedCustomer"
      v-model="drawer"
      app
      clipped
      fixed
      right
      width="325"
    >
      <v-layout
        align-center
        justify-space-between
        column
        fill-height
      >
        <v-list
          flat
          subheader
        >
          <div>
            <v-subheader>Select Order List Customer</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-select
                  v-model="selectedCustomer"
                  :items="cart.customers"
                  label="Select Customer"
                  item-value="id"
                  dense
                  return-object
                  outlined
                  @change="selectCustomer"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.customer.fname }} {{ item.customer.lname }}
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ item.customer.fname }} {{ item.customer.lname }}
                        <v-spacer></v-spacer>
                        {{ item.customer.shipping_address.street1 }} {{ item.customer.shipping_address.street2 }} <br>
                        {{ item.customer.shipping_address.city }}, {{ item.customer.shipping_address.state.abbreviation }} {{ item.customer.shipping_address.zip }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>

                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item-content>
                      <v-list-item-subtitle class="ml-5">
                        Customer not found?
                        <v-btn small text @click="addNewCustomer">Add Customer</v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-list-item-content>
            </v-list-item>
          </div>

          <v-subheader>Order Summary</v-subheader>

          <v-divider class="p-0 m-0" />

          <div v-if="showOrderSummary">
            <v-list-item three-line>
              <v-list-item-content style="text-align: left;">
                <v-list-item-title>
                  {{ cartCustomer.customer.fname }} {{ cartCustomer.customer.lname }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ cartCustomer.customer.shipping_address.street1 }} {{ cartCustomer.customer.shipping_address.street2 }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>{{ cartCustomer.customer.shipping_address.city }},
                  {{ cartCustomer.customer.shipping_address.state.name }} {{ cartCustomer.customer.shipping_address.zip }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="p-0 m-0" />

            <div v-if="cartCustomer.items.length > 0">
              <v-list-item
                v-for="(item, index) in cartCustomer.items"
                :key="index"
              >
                <v-list-item-content>
                  {{ item.quantity }} x {{ item.product.name | cartProduct }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon class="mr-2" size="small" @click="removeItems(item)">mdi-delete</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider class="p-0 m-0" />

              <v-list-item>
                <v-list-item-content>
                  SubTotal: <br>
                  Sales Tax: <br>
                  <span v-if="cartCustomer.delivery_fee > 0">Retail Delivery Fees: <br></span>
                  Total:
                </v-list-item-content>
                <v-list-item-action>
                  <money-format :value="subtotal" locale="en" currency-code="USD"></money-format>
                  <money-format :value="parseFloat(tax)" locale="en" currency-code="USD"></money-format>
                  <money-format v-if="cartCustomer.delivery_fee > 0" :value="parseFloat(cartCustomer.delivery_fee)" locale="en" currency-code="USD"></money-format>
                  <money-format :value="total" locale="en" currency-code="USD"></money-format>
                </v-list-item-action>
              </v-list-item>
            </div>

            <div v-if="assignedProducts.length > 0">
              <v-card
                outlined
                class="mb-2 mt-2"
              >
                <v-card-title>Currently Assigned Products</v-card-title>

                <v-list
                  flat
                  dense
                >
                  <template v-for="(product, index) in assignedProducts">
                    <v-list-item :key="product.id">
                      <v-list-item-content>
                        {{ product.name }}
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          icon
                          color="primary"
                          class="mr-2"
                          @click="addToCart(product)"
                          :disabled="productInCart(product.id)"
                        >
                          <v-icon>mdi-cart-plus</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="!isLast(assignedProducts, index)" :key="index" class="ml-4 mr-4" />
                  </template>
                </v-list>
              </v-card>
            </div>

            <div v-if="filteredScheduledOrders.length > 0">
              <v-card
                outlined
                class="mb-2 mt-2"
              >
                <v-card-title>Currently Scheduled Products</v-card-title>

                <v-list
                  flat
                  dense
                >
                  <template v-for="(product, index) in filteredScheduledOrders">
                    <v-list-item :key="product.id">
                      <v-list-item-content>
                        {{ product.name }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="!isLast(filteredScheduledOrders, index)" :key="index" class="ml-4 mr-4" />
                  </template>
                </v-list>
              </v-card>
            </div>
          </div>
        </v-list>

        <div v-if="showOrderSummary" class="d-flex flex-column align-end">
          <div>
            <v-btn
              block
              class="mb-3"
              color="primary"
              outlined
              width="300"
              @click="backToOrders"
            >
              Back To Order List
            </v-btn>
          </div>
        </div>
      </v-layout>
    </v-navigation-drawer>

    <add-customer-modal ref="addCustomerModal"></add-customer-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import MoneyFormat from 'vue-money-format'

export default {
  name: 'OrderSummary',
  components: {
    AddCustomerModal: () => import('@/views/components/AddCustomerModal'),
    MoneyFormat
  },
  data () {
    return {
      customer_is_added: false,
      dates: [],
      menu: false,
      selectedCustomer: {},
      addCartCustomer: false,
      drawer: null,
      assignedProducts: []
    }
  },
  filters: {
    cartProduct (val) {
      return val.length > 40 ? `${val.slice(0, 40)}...` : val
    }
  },
  computed: {
    ...mapState('cartManagement', {
      cart: state => state.cart,
      cartCustomer: state => state.selectedCustomer
    }),
    ...mapState({ states: state => state.stateOptions }),
    ...mapGetters('cartManagement', {
      subtotal: 'getCustomerCartSubtotal',
      tax: 'getCustomerCartTax',
      total: 'getCustomerCartTotal',
      productInCart: 'isInCart'
    }),
    filteredScheduledOrders () {
      let tempOrders = this.selectedCustomer.customer.scheduled_orders

      tempOrders = tempOrders.filter((order) => {
        return order.status === 'SCHEDULED'
      })

      return tempOrders
    },
    isDisabled () {
      return false
    },
    showOrderSummary () {
      if (this.cartCustomer) {
        return Object.entries(this.cartCustomer).length > 0
      }

      return false
    }
  },
  methods: {
    ...mapMutations('cartManagement', {
      setSelectedCustomer: 'setSelectedCustomer',
      removeCustomerProduct: 'removeProductFromCustomerCart'
    }),
    ...mapActions('cartManagement', {
      addCustomerToCart: 'addCustomerToCart',
      removeCustomerFromCart: 'removeCustomer',
      removeProductFromCart: 'removeProduct'
    }),
    ...mapActions('customerManagement', {
      fetchAssignedProducts: 'fetchAssignedProducts'
    }),
    init () {
      Object.assign(this.selectedCustomer, this.cartCustomer)
      this.loadAssignedProducts()
    },
    addCustomer (value) {
      this.addCustomerToCart(value)
        .then((response) => {
          this.selectCustomer(response)
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    removeItems (item) {
      this.removeProductFromCart(item.id)
      this.removeCustomerProduct(this.selectedCustomer.id, item.id)
    },
    addToCart (item) {
      this.$emit('add-cart-item', item)
    },
    selectCustomer (customer) {
      this.assignedProducts = []
      this.setSelectedCustomer(customer)
      this.loadAssignedProducts()
    },
    loadAssignedProducts () {
      this.fetchAssignedProducts(this.selectedCustomer.customer_id)
        .then((response) => {
          this.assignedProducts = response
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    editCustomer (customer) {
      this.newCustomerData = customer
      this.toggleModal(true)
    },
    removeCustomer (customer) {
      this.removeCustomer(customer.id)
    },
    toggleAddModal (val = false) {
      this.addCartCustomer = val
    },
    addNewCustomer () {
      const options = {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        title: 'Add Customer',
        add: true,
        edit: false,
        addToCart: true,
        allowMultiple: true,
        showAutocomplete: true
      }
      this.$refs.addCustomerModal.open(options)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    backToOrders () {
      this.setSelectedCustomer({})
      this.$router.push({name: 'create'})
    },
    isLast (array, index) {
      const lastIndex = array.length - 1
      return index === lastIndex
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.tanked {
  position: absolute;
  bottom: 10px;
}
</style>
