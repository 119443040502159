const config = {
  apiBaseUrl: process.env.VUE_APP_BASE_API,
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_GEOCODING_API_KEY,
  appURL: process.env.VUE_APP_URL,
  pusherKey: process.env.MIX_PUSHER_APP_KEY,
  pusherCluster: process.env.MIX_PUSHER_APP_CLUSTER
}

export {
  config
}

export default {
  install (Vue) {
    Vue.appConfig = config
    Vue.prototype.$appConfig = config
  }
}
