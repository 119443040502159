const getUser = () => {
  const user = localStorage.getItem('user')

  if (!user) {
    return {}
  }

  return JSON.parse(user)
}

/*const getToken = () => {
  return localStorage.getItem('access_token')
}*/

/*const isUserLoggedIn = () => {
  const access_token = localStorage.getItem('access_token')
  return !!access_token
}*/

export default {
  token: localStorage.getItem('access_token') || '',
  user: getUser(),
  status: ''
}
