<template>
  <div class="scheduled-order-details-view">
    <v-container v-if="!loading">
      <v-row>
        <v-col>
          <v-card elevation="0">
            <div>
              <span class="title">Scheduled Order# {{ $route.params.id }} Details</span> <br>
              <span class="subtitle-2">Status: {{ orderStatusMap[order.status] }}</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <v-row>
            <v-col cols="12">
              <CustomerAddressPanel />
            </v-col>

            <v-col cols="12">
              <OrderItemsPanel />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <OrderActionsPanel />
            </v-col>

            <v-col cols="12">
              <ShipDatePanel />
            </v-col>

            <v-col cols="12">
              <OrderFrequencyPanel />
            </v-col>

            <v-col cols="12">
              <PaymentMethodPanel />
            </v-col>

            <v-col cols="12">
              <OrderNotesPanel />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'ScheduledOrderDetails',
  components: {
    CustomerAddressPanel: () => import('./components/scheduled/CustomerAddressPanel'),
    OrderItemsPanel: () => import('./components/scheduled/OrderItemsPanel'),
    OrderActionsPanel: () => import('./components/scheduled/OrderActionsPanel'),
    OrderNotesPanel: () => import('./components/scheduled/OrderNotesPanel'),
    PaymentMethodPanel: () => import('./components/scheduled/PaymentMethodPanel'),
    ShipDatePanel: () => import('./components/scheduled/ShipDatePanel'),
    OrderFrequencyPanel: () => import('./components/scheduled/OrderFrequencyPanel')
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.scheduledOrder
    }),
    ...mapState({
      orderStatusMap: state => state.orderStatusMap
    }),
    editable () {
      return this.order.status === 'PENDING'
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      fetch: 'fetchScheduledOrder'
    }),
    ...mapActions({
      'fetchStates': 'fetchStates'
    }),
    loadStates () {
      this.fetchStates().then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    loadOrder () {
      this.fetch(this.$route.params.id)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  },
  created () {
    this.loadStates()
    this.loadOrder()
  }
}
</script>

<style scoped>

</style>
