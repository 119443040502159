<template>
  <div id="order-actions-panel">
    <panel>
      <template #title>
        Order Actions
      </template>

      <template #content>
        <v-select
          v-model="orderAction"
          :items="actions"
          label="Choose An Action"
          dense
          @change="performAction"
        >
        </v-select>
      </template>
    </panel>

    <confirmation-modal ref="confirm" />

    <v-dialog v-model="dialogPO" max-width="500px">
      <v-card>
        <v-card-title>Update PO#</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="poNumber"
            dense
            ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogPO = false">Cancel</v-btn>
          <v-btn color="primary darken-1" text @click="updatePO">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <return-request-modal ref="returnRequestModal" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    ReturnRequestModal: () => import('@/views/components/ReturnRequestModal'),
    Panel: () => import('@/components/Panel')
  },
  data () {
    return {
      actions: [],
      orderAction: null,
      dialogCancel: false,
      allowDownload: false,
      dialogPO: false,
      poNumber: ''
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    }),
    ...mapState({
      states: state => state.states
    }),
    editable () {
      return this.order.status === 'PENDING' || this.order.status === 'PENDING_APPROVAL' || this.order.status === 'UNPAID'
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      update: 'update'
    }),
    init () {
      this.actions.push('Update PO#')

      if (this.order.status === 'PENDING_APPROVAL') {
        this.actions.push('Approve Order')
      }

      if (this.editable) {
        this.actions.push('Cancel Order')
      }

      if (this.order.status === 'SHIPPED') {
        this.actions.push('Request Return')
      }

      if (this.allowDownload) {
        this.actions.push('Download Invoice')
      }
    },
    performAction () {
      if (this.orderAction === 'Cancel Order') {
        this.cancelOrder()
      }
      if (this.orderAction === 'Download Invoice') {
        this.downloadInvoice()
      }
      if (this.orderAction === 'Update PO#') {
        this.poNumber = this.order.customer_po
        this.dialogPO = true
      }
      if (this.orderAction === 'Approve Order') {
        this.approveOrder()
      }
      if (this.orderAction === 'Request Return') {
        this.requestReturn()
      }
    },
    downloadInvoice () {
      this.$toast.error('Invoice download is currently unavailable!')
    },
    requestReturn () {
      this.$refs.returnRequestModal.open(this.order)
        .then(() => {
          this.orderAction = null
        })
        .catch((error) => {
          this.orderAction = null
          if (error) {
            this.$toast.error(error.data ? error.data.error : error)
          }
        })
    },
    async approveOrder () {
      if (await this.$refs.confirm.open('Approve Order', 'Are you sure you want to approve this order?', { color: 'success' })) {
        this.update({id: this.order.id, payload: { status: 'PENDING' }})
          .then(() => {
            this.dialogCancel = false
            this.$toast.success('Order has been approved!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    async cancelOrder () {
      if (await this.$refs.confirm.open('Cancel Order', 'Are you sure you want to cancel this order?', { color: 'red' })) {
        this.update({id: this.order.id, payload: { status: 'CANCELLED', reason: 'Customer requested cancel' }})
          .then(() => {
            this.dialogCancel = false
            this.$toast.success('Order has been cancelled!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    updatePO () {
      this.update({id: this.order.id, payload: {customer_po: this.poNumber}})
        .then(() => {
          this.orderAction = null
          this.dialogPO = false
          this.$toast.success('PO# updated')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>

</style>
