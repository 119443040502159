var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-address-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(" Customer Address - "),
                _c(
                  "span",
                  { staticClass: "px-2 subtitle text-decoration-none" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "customer_detail",
                            params: { id: _vm.order.customer.id }
                          }
                        }
                      },
                      [_vm._v("View Customer Record")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-form",
                  [
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "6", md: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: !_vm.editable,
                                    label: "First Name"
                                  },
                                  model: {
                                    value: _vm.addressLocal.fname,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addressLocal, "fname", $$v)
                                    },
                                    expression: "addressLocal.fname"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "6", md: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: !_vm.editable,
                                    label: "Last Name"
                                  },
                                  model: {
                                    value: _vm.addressLocal.lname,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addressLocal, "lname", $$v)
                                    },
                                    expression: "addressLocal.lname"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12", md: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: !_vm.editable,
                                    label: "Street 1"
                                  },
                                  model: {
                                    value: _vm.addressLocal.street1,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addressLocal, "street1", $$v)
                                    },
                                    expression: "addressLocal.street1"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12", md: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: !_vm.editable,
                                    label: "Street 2"
                                  },
                                  model: {
                                    value: _vm.addressLocal.street2,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addressLocal, "street2", $$v)
                                    },
                                    expression: "addressLocal.street2"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4", md: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: !_vm.editable,
                                    label: "City"
                                  },
                                  model: {
                                    value: _vm.addressLocal.city,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addressLocal, "city", $$v)
                                    },
                                    expression: "addressLocal.city"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4", md: "4" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    disabled: !_vm.editable,
                                    items: _vm.states,
                                    filter: _vm.statesFilter,
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "State"
                                  },
                                  model: {
                                    value: _vm.addressLocal.state_id,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addressLocal,
                                        "state_id",
                                        $$v
                                      )
                                    },
                                    expression: "addressLocal.state_id"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4", md: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: !_vm.editable,
                                    label: "Zip Code"
                                  },
                                  model: {
                                    value: _vm.addressLocal.zip,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addressLocal, "zip", $$v)
                                    },
                                    expression: "addressLocal.zip"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.editable
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-end" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      disabled: !_vm.isDirty,
                                      color: "primary"
                                    },
                                    on: { click: _vm.updateAddress }
                                  },
                                  [_vm._v(" UPDATE ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }