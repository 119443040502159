var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "settings-wrapper" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-2 px-4",
          staticStyle: {
            position: "fixed",
            top: "115px",
            right: "-35px",
            "border-radius": "8px",
            "z-index": "1"
          },
          attrs: {
            id: "settings",
            color: "rgba(0, 0, 0, .3)",
            dark: "",
            flat: "",
            link: "",
            "min-width": "100"
          }
        },
        [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-cogs ")])],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            activator: "#settings",
            bottom: "",
            "content-class": "v-settings",
            left: "",
            "nudge-left": "8",
            "offset-x": "",
            origin: "top right",
            transition: "scale-transition"
          },
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "text-center mb-0", attrs: { width: "350" } },
            [
              _c(
                "v-card-text",
                [
                  _c("strong", { staticClass: "mb-3 d-inline-block" }, [
                    _vm._v("Statistics Settings")
                  ]),
                  _vm._l(_vm.user.settings.statistics, function(statistic, i) {
                    return _c(
                      "v-row",
                      {
                        key: "statistics-" + i,
                        attrs: { align: "center", "no-gutters": "" }
                      },
                      [
                        _c("v-col", { attrs: { cols: "auto" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.settingsMap[statistic.name]) + " "
                          )
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass: "ma-0 pa-0",
                              attrs: { color: "primary", "hide-details": "" },
                              on: {
                                change: function($event) {
                                  return _vm.updateSettings()
                                }
                              },
                              model: {
                                value: statistic.value,
                                callback: function($$v) {
                                  _vm.$set(statistic, "value", $$v)
                                },
                                expression: "statistic.value"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c("v-divider", { staticClass: "my-4 secondary" }),
                  _c("strong", { staticClass: "mt-3 mb-3 d-inline-block" }, [
                    _vm._v("Datatables Settings")
                  ]),
                  _vm._l(_vm.settings, function(datatable, i) {
                    return _c(
                      "v-row",
                      {
                        key: "datatables-" + i,
                        attrs: { align: "center", "no-gutters": "" }
                      },
                      [
                        _c("v-col", { attrs: { cols: "auto" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.settingsMap[datatable.name]) + " "
                          )
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass: "ma-0 pa-0",
                              attrs: { color: "primary", "hide-details": "" },
                              on: {
                                change: function($event) {
                                  return _vm.updateSettings()
                                }
                              },
                              model: {
                                value: datatable.value,
                                callback: function($$v) {
                                  _vm.$set(datatable, "value", $$v)
                                },
                                expression: "datatable.value"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }