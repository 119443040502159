var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "div",
        { staticClass: "border-bottom px-3 py-2" },
        [
          _c(
            "v-card-title",
            { staticClass: "align-start p-0" },
            [
              _vm._t("title"),
              _vm.title ? [_vm._v(" " + _vm._s(_vm.title) + " ")] : _vm._e(),
              _c(
                "div",
                { staticClass: "text-subtitle-1 mb-n4" },
                [
                  _vm._t("subtitle"),
                  _vm.subtitle
                    ? [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                    : _vm._e()
                ],
                2
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm.content ? _c("v-card-text", [_vm._t("content")], 2) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }