var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "validation-card" },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          attrs: { "min-height": _vm.minHeight, "max-width": _vm.maxWidth }
        },
        [
          _c("v-card-title", { staticClass: "align-start" }, [
            _vm.hasTitle
              ? _c(
                  "div",
                  { staticClass: "text-h6 v-card--material__title" },
                  [
                    _vm._t("title"),
                    _vm.title
                      ? [_vm._v(" " + _vm._s(_vm.title) + " ")]
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "text-subtitle-1 mb-n4" },
                      [
                        _vm._t("subtitle"),
                        _vm.subtitle
                          ? [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  2
                )
              : _vm._e()
          ]),
          _c("v-card-text", { staticClass: "mt-5" }, [
            _c("p", { staticClass: "text--primary" }, [
              _vm._v(_vm._s(_vm.customerData.full_name))
            ]),
            _c("p", [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.customerData.shipping_address.street1) +
                    " " +
                    _vm._s(_vm.customerData.shipping_address.street2)
                )
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.customerData.shipping_address.city) +
                    ", " +
                    _vm._s(_vm.customerData.shipping_address.state.name) +
                    " " +
                    _vm._s(_vm.customerData.shipping_address.zip)
                )
              ])
            ])
          ]),
          _vm.$slots.actions
            ? [_c("v-card-actions", [_c("v-spacer"), _vm._t("actions")], 2)]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }