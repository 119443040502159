import state from './moduleContractorManagementState.js'
import mutations from './moduleContractorManagementMutations.js'
import actions from './moduleContractorManagementActions.js'
import getters from './moduleContractorManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
