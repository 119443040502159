import state from './modulePaymentManagementState.js'
import mutations from './modulePaymentManagementMutations.js'
import actions from './modulePaymentManagementActions.js'
import getters from './modulePaymentManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
