<template>
  <v-container
    id="billing-view"
  >
    <material-card
      color="primary"
      icon="mdi-wallet"
    >
      <template #title>
        Billing
      </template>

      <v-container fluid>
        <v-row>
          <v-col
            v-for="(payment, index) in payments"
            :key="index"
            cols="3"
          >
            <v-card
              :color="getCardColor(payment)"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title v-text="payment.card_name"></v-list-item-title>
                  <v-list-item-subtitle
                    class="text--primary"
                  >
                    <i :class="getBrandIconClass(payment.card_type)"></i>
                    {{ payment.card_number }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-text="payment.card_expiry_formatted"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-tooltip v-if="!payment.primary" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="makePrimary(payment.id)"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                  <span>Make primary payment method</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="deletePayment(payment)"
                    >
                      mdi-delete-outline
                    </v-icon>
                  </template>
                  <span>Delete payment method</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="toggleNewPaymentModal(true)"
          >
          Add New Payment
        </v-btn>
      </template>
    </material-card>

    <confirmation-modal ref="confirm" />

    <add-payment-modal :isModalActive="addNewPayment" @close-modal="toggleNewPaymentModal"></add-payment-modal>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Billing',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    AddPaymentModal: () => import('@/views/components/AddPaymentModal'),
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      query: {
        model_type: 'contractor',
        model_id: ''
      },
      loading: true,
      selectedPayment: {},
      addNewPayment: false
    }
  },
  computed: {
    ...mapState('paymentManagement', {
      payments: state => state.payments
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    paymentsNotFound () {
      return Object.entries(this.payments).length !== 0
    }
  },
  methods: {
    ...mapActions('paymentManagement', {
      fetch: 'fetchPayments',
      update: 'setPrimary',
      remove: 'removePayment'
    }),
    ...mapActions('contractorManagement', {
      fetchContractor: 'fetchContractor'
    }),
    init () {
      this.query.model_id = this.user.model_id

      if (Object.entries(this.contractor).length === 0) {
        this.loadContractor()
      } else {
        this.fetchPayment()
      }
    },
    loadContractor () {
      this.loading = true

      this.fetchContractor(this.user.model_id)
        .then(() => {
          this.fetchPayment()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
          this.loading = false
        })
    },
    fetchPayments () {
      this.fetch()
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.$toast.error(error.data ? error.data.error : error)
          this.loading = false
        })
    },
    toggleNewPaymentModal (val = false) {
      this.addNewPayment = val
      if (!val) {
        this.fetchPayments()
      }
    },
    async deletePayment (payment) {
      if (payment.primary) {
        this.$toast.error('Unable to delete primary payment method. Please select a new primary payment first.')
      } else if (await this.$refs.confirm.open('Delete Payment Method', 'Are you sure you want to delete payment method? This action is irreversible.', { color: 'red' })) {
        this.remove(payment.id)
          .then(() => {
            this.$toast.success('Payment method deleted.')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    makePrimary (id) {
      this.update({id, payment: {model_type: 'contractor', primary: true}})
        .then(() => {
          this.$toast.success('Payment option now set as primary')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    cardExpired (payment) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const expiry = new Date(`01-${payment.card_expiry_month}-${payment.card_expiry_year}`)
      return today > expiry
    },
    getBrandIconClass (brand) {
      let icon = ''
      brand = brand || 'unknown'
      const cardBrandToIcon = {
        'Visa': 'fab fa-cc-visa',
        'Mastercard': 'fab fa-cc-mastercard',
        'Amex': 'fab fa-cc-amex',
        'Discover': 'fab fa-cc-discover',
        'unknown': 'fa fa-credit-card'
      }
      if (cardBrandToIcon[brand]) {
        icon = cardBrandToIcon[brand]
      }

      return icon
    },
    getCardColor (payment) {
      if (this.cardExpired(payment)) {
        return '#C62828'
      }

      if (payment.primary) {
        return '#90CAF9'
      }

      return 'undefined'
    }
  },
  created () {
    this.fetchPayments()
  }
}
</script>

<style scoped>

</style>
