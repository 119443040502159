<template>
  <div>
    <vuetify-google-autocomplete
      id="autocomplete"
      ref="address"
      placeholder="Enter address for validation"
      v-on:no-results-found="noResults"
      v-on:placechanged="getAddressData"
    >
      <template #append>
        <v-icon small>mdi-map-search</v-icon>
      </template>
    </vuetify-google-autocomplete>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GoogleAutoComplete',
  data () {
    return {
      address: {
        street1: '',
        city: '',
        state_id: '',
        zip: '',
        validated: false
      }
    }
  },
  computed: {
    ...mapState({
      states: state => state.stateOptions
    })
  },
  methods: {
    getAddressData (addressData) {
      const state = this.states.find((s) => s.abbreviation === addressData.administrative_area_level_1)
      this.address.zip = addressData.postal_code
      this.address.street1 = addressData.name
      this.address.city = addressData.locality
      this.address.state_id = state.value
      this.address.validated = true

      this.$emit('google-address', this.address)
    },
    noResults () {
      this.$toast.error(this.$refs.address)
      this.$toast.error(this.$refs.address.autocompleteText)
    }
  },
  mounted () {
    this.$refs.address.clear()
  }
}
</script>

<style scoped>

</style>
