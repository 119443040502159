import { render, staticRenderFns } from "./ScheduledOrderDetails.vue?vue&type=template&id=002d1a31&scoped=true&"
import script from "./ScheduledOrderDetails.vue?vue&type=script&lang=js&"
export * from "./ScheduledOrderDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002d1a31",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VContainer,VOverlay,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/forge/demo.filterondemand.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('002d1a31')) {
      api.createRecord('002d1a31', component.options)
    } else {
      api.reload('002d1a31', component.options)
    }
    module.hot.accept("./ScheduledOrderDetails.vue?vue&type=template&id=002d1a31&scoped=true&", function () {
      api.rerender('002d1a31', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orders/ScheduledOrderDetails.vue"
export default component.exports