import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

Vue.use(VuetifyToast, {
  x: 'bottom', // default
  y: 'bottom', // default
  color: 'info', // default
  icon: '',
  iconColor: '', // default
  classes: ['body-2'],
  timeout: 5000, // default
  dismissable: true, // default
  multiLine: true, // default
  vertical: false, // default
  queueable: true, // default
  showClose: true, // default
  closeText: 'Close', // default
  closeIcon: 'close', // default
  closeColor: '', // default
  slot: [], // default
  shorts: {
    custom: {
      color: 'green',
      icon: 'green',
      iconColor: 'mdi-check'
    }
  },
  property: '$toast' // default
})


const opts = {}

export default new Vuetify(opts)
