export default {
  setReminders (state, reminders) {
    state.reminders = reminders
  },
  addReminder (state, payload) {
    state.reminders.push(Object.assign({}, payload))
  },
  updateReminder (state, payload) {
    const index = state.reminders.findIndex((r) => r.id === payload.id)
    state.reminders.splice(index, 1, payload)
  },
  removeReminder (state, id) {
    const index = state.reminders.findIndex((r) => r.id === id)
    state.reminders.splice(index, 1)
  }
}
