var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-items-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Shipping ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.order.shipments,
                    "single-expand": "",
                    expanded: _vm.expanded,
                    "show-expand": "",
                    "hide-default-footer": "",
                    loading: _vm.loading,
                    "loading-text": "Loading... Please wait"
                  },
                  on: {
                    "update:expanded": function($event) {
                      _vm.expanded = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.carrier",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.shippingCarrierMap[item.carrier]) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "expanded-item",
                        fn: function(ref) {
                          var headers = ref.headers
                          var item = ref.item
                          return [
                            _c(
                              "td",
                              { attrs: { colspan: headers.length } },
                              [
                                _c(
                                  "v-list",
                                  {
                                    staticClass: "m-2",
                                    attrs: { "two-line": "", dense: "" }
                                  },
                                  [
                                    _vm._l(item.details, function(data, i) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          { key: i },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.date
                                                    )
                                                  }
                                                }),
                                                _c("v-list-item-subtitle", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.location
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-list-item-action-text", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.description
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          key: data.date,
                                          staticClass: "m-0 p-0"
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "no-data",
                        fn: function() {
                          return [_vm._v(" No shipping records found. ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }