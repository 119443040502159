<template>
  <div v-if="hasCart && hasUser" id="order-create-view">
    <v-alert type="error">
      <span class="p-2">Reminder: When ordering filters for your customers, please place your order at least 7-10 days prior to your scheduled maintenance visit.</span>
    </v-alert>
    <v-container id="order-create-list-view">

      <material-card
        color="primary"
        icon="mdi-account-multiple"
      >
        <template #title>
          Orders List <span class="subtitle-1 grey--text text--lighten-1">{{ cartCustomerCount }} Customer(s)</span>
        </template>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="cart.customers"
          :loading="loading"
          :single-select="false"
          show-select
          item-key="id"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          loading-text="Loading... Please wait"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-btn
                v-if="selected.length !== cartCustomerCount"
                color="primary"
                dark
                class="mb-2 mr-5"
                @click="selectAllCustomers"
              >
                Select All
              </v-btn>
              <v-btn
                v-else
                color="red"
                dark
                class="mb-2 mr-5"
                @click="selected = []"
              >
                Reset Selections
              </v-btn>
              <span v-if="selected.length > 0" class="subtitle-1 grey--text text--lighten-1">{{ selected.length }} Selected Customer(s)</span>
              <v-spacer></v-spacer>
              <v-btn
                v-if="selected.length > 0"
                color="red"
                dark
                class="mb-2 mr-5"
                @click="removeSelectedCustomers"
              >
                Remove From Cart
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-5"
                @click.stop="addCustomer"
              >
                Add Customer
              </v-btn>
              <v-btn
                color="secondary"
                dark
                @click="$refs.customers_file.click()"
                class="mb-2"
              >
                Upload List
              </v-btn>
              <input
                type="file"
                @change="file_selected"
                style="display: none;"
                ref="customers_file"
                accept=".csv">
            </v-toolbar>
          </template>
          <template v-slot:[`item.customer`]="{ item }">
            <div class="my-3">
              <span class="font-weight-bold">
                {{ item.customer.full_name }}
                <span v-if="item.customer.validation_errors && item.customer.validation_errors.length > 0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="openPendingVerification(item.customer.batch_id)">
                        <v-icon
                          small
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on">
                        mdi-alert-circle
                      </v-icon>
                      </v-btn>
                    </template>
                    <span>Validation error was found!</span>
                  </v-tooltip>
                </span>
              </span><br>
              {{ item.address.street1 }} {{ item.address.street2 }} <br>
              {{ item.address.city }}, {{ item.address.state.name }} {{ item.address.zip }}
            </div>
          </template>

          <template v-slot:[`item.customer.po`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.customer_po"
              @save="savePoNumber(item)"
              >
              {{ item.customer_po === '' ? 'Click to add PO#' : item.customer_po }}
              <template v-slot:input>
                <v-text-field
                  v-model="item.customer_po"
                  label="Edit PO Number"
                  single-line
                  ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.items`]="{ item }">
            <span v-if="item.items.length > 0">
              {{ item.items.length }} Item(s)
            </span>
            <span v-else class="grey--text">No products selected</span>
          </template>

          <template v-slot:[`item.subtotal`]="{ item }">
            <span v-if="item.items.length > 0"><money-format :value="getCustomerCartSubtotal(item)" locale="en" currency-code="USD"></money-format></span>
            <span v-else>-</span>
          </template>

          <template v-slot:[`item.tax`]="{ item }">
            <span v-if="item.items.length > 0"><money-format :value="getCustomerCartTax(item)" locale="en" currency-code="USD"></money-format></span>
            <span v-else>-</span>
          </template>

          <template v-slot:[`item.total`]="{ item }">
            <span v-if="item.items.length > 0"><money-format :value="getCustomerCartTotal(item)" locale="en" currency-code="USD"></money-format></span>
            <span v-else>-</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="addProduct(item)"
                  icon
                  >
                  <v-icon>
                    mdi-cart-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Add products to customer order</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
            <v-btn
              @click="expand(true)"
              v-if="item.items.length > 0 && !isExpanded"
              icon
              >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn
              @click="expand(false)"
              v-if="item.items.length > 0 && isExpanded"
              icon
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table class="m-2" dense>
                <tbody>
                <tr
                  v-for="(cartItem, index) in item.items"
                  :key="index"
                  >
                  <td style="width: 70%;">
                    {{ cartItem.product.name }}
                    <span class="ml-5 grey--text text--lighten-1">
                      from
                      <span class="font-bold">
                        {{ cartItem.product.item.manufacturer.name }}
                      </span>
                    </span>
                    <span class="ml-5 grey--text text--lighten-1">
                      Product ID: {{ cartItem.product.id }}
                    </span>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      outlined
                      dense
                      class="mt-2"
                      min="1"
                      readonly
                      v-model="cartItem.quantity"
                    >
                      <template v-slot:prepend>
                        <v-icon
                          :disabled="disableDecrement"
                          @click="decrementQuantity(cartItem)"
                        >
                          mdi-minus
                        </v-icon>
                      </template>
                      <template v-slot:append-outer>
                        <v-icon
                          :disabled="disableIncrement"
                          @click="incrementQuantity(cartItem)"
                        >
                          mdi-plus
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <td>
                    <money-format :value="parseFloat(cartItem.price)" locale="en" currency-code="USD"></money-format>
                  </td>
                  <td>
                    <v-btn
                      icon
                      small
                      @click="deleteProductFromCart(cartItem)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>

          <template v-slot:no-data>
            No customers added. Please add customers to order list.
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-row class="mt-5">
          <v-col>
            <div class="d-flex justify-start font-weight-bold text-lg-h6">
              <div class="ml-5">
                <span class="mr-5">Orders Total:</span>
              </div>
              <money-format :value="total" locale="en" currency-code="USD"></money-format>
            </div>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              v-if="this.cart.customers.length > 0"
              class="mr-5"
              @click="clearOrderList"
            >
              Clear Order List
            </v-btn>
            <v-btn
              v-if="!paymentAuthorized"
              class="mr-5"
              :disabled="selected.length === 0"
              color="primary"
              @click="openPaymentDialog"
            >
              Select Payment
            </v-btn>
            <v-btn
              v-else
              class="mr-5"
              :disabled="cartInValid"
              color="primary"
              :loading="placingOrders"
              @click="submitOrders"
            >
              Place Orders
            </v-btn>
          </v-col>
        </v-row>
      </material-card>
    </v-container>

<!--    <v-container v-else id="order-create-buttons-view">
      <v-row>
        <v-col cols="6">
          <v-card
            style="cursor: pointer;"
            class="d-flex justify-center align-center"
            height="300"
            @click="addCustomer"
          >
            <div>
              <v-icon
                class="d-flex mt-5"
                size="128"
              >
                mdi-account-circle
              </v-icon>
              <v-card-title class="d-flex justify-center">Add Customer</v-card-title>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            style="cursor: pointer;"
            class="d-flex justify-center align-center"
            height="300"
            @click="$refs.customers_file.click()"
          >
            <input
              type="file"
              @change="file_selected"
              style="display: none;"
              ref="customers_file"
              accept=".csv">
            <div>
              <v-icon
                class="d-flex justify-center align-center mt-5"
                size="128"
              >
                mdi-file-upload
              </v-icon>
              <v-card-title class="d-flex justify-center">Upload Customer List</v-card-title>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>-->

<!--    <customer-validation-modal :isModalActive="validationErrors" @close-modal="toggleValidationModal" :add-to-cart="true" :validation-data="customerValidationErrors"/>-->
    <customer-validation-modal ref="validation"></customer-validation-modal>
    <customers-validation-modal ref="validations"></customers-validation-modal>

    <add-customer-modal ref="addCustomerModal"></add-customer-modal>

    <add-payment-modal :isModalActive="addNewPayment" @close-modal="toggleNewPaymentModal"></add-payment-modal>

    <v-dialog v-model="orderPaymentDialog" persistent max-width="600px">
      <v-card>
        <v-alert
          v-if="paymentError !== ''"
          dense
          dismissible
          type="error"
          >
          {{ paymentError }}
        </v-alert>
        <v-card-text>
          <h1 class="pt-5 text-center font-black">Payment Information</h1>
        </v-card-text>
        <v-card-text>
          <v-select
            :items="paymentMethods"
            v-model="paymentMethod"
            outlined
            dense
            label="Select payment option"
            @change="paymentSelected"
          ></v-select>
        </v-card-text>
        <v-card-text v-if="paymentMethod === 'CC'">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedPayment"
                :items="payments"
                label="Select Payment Method"
                item-value="id"
                dense
                return-object
                outlined
                @change="setPaymentZip"
              >
                <template v-slot:selection="{ item }">
                  {{ item.card_name }}
                </template>

                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ item.card_name }}
                      <v-spacer></v-spacer>
                      {{ item.card_type }} {{ item.card_number }} {{ item.card_expiry_formatted }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>

                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item-content>
                    <v-list-item-subtitle class="ml-5">
                      Payment method not found?
                      <v-btn small text @click="toggleNewPaymentModal(true)">Add New Payment Method</v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-text-field
                v-if="Object.entries(selectedPayment).length > 0"
                v-model="selectedPaymentSecurity"
                outlined
                dense
                label="Security code"
                hint="3 or 4 digit number on back of credit card"
                persistent-hint
                ref="cvv"
                maxlength="4"
                ></v-text-field>
              <v-text-field
                v-if="Object.entries(selectedPayment).length > 0"
                v-model="selectedPaymentZip"
                outlined
                dense
                label="Billing Zip Code"
                persistent-hint
                ref="zip"
                maxlength="5"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closePaymentDialog">Cancel</v-btn>
          <v-btn :loading="addingPaymentLoader" color="blue darken-1" text @click="authorizePayment">Authorize Payment</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="customerUploadListLoadingDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading customers list
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirmation-modal ref="confirm" />
  </div>
</template>
<script>
import MoneyFormat from 'vue-money-format'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'order_list',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    CustomerValidationModal: () => import('../components/CustomerValidationModal'),
    CustomersValidationModal: () => import('../components/CustomersValidationModal'),
    AddCustomerModal: () => import('@/views/components/AddCustomerModal'),
    AddPaymentModal: () => import('@/views/components/AddPaymentModal'),
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    MoneyFormat
  },
  data () {
    return {
      file: '',
      carts: [],
      selectedCart: {},
      validationErrors: false,
      customerValidationErrors: [],
      showAddCartCustomer: false,
      loading: false,
      loadingCarts: false,
      showCartSelection: false,
      customerUploadListLoadingDialog: false,
      selected: [],
      expanded: [],
      cartTotal: 0,
      orderPaymentDialog: false,
      paymentMethod: null,
      paymentMethods: [{text: 'Credit Card', value: 'CC'}],
      selectedPayment: {},
      card: {},
      cardholder: '',
      addNewPayment: false,
      savePaymentMethod: false,
      authorization_data: {},
      addingPaymentLoader: false,
      paymentError: '',
      selectedPaymentSecurity: '',
      selectedPaymentZip: '',
      rules: {
        required: value => !!value || 'Required.'
      },
      placingOrders: false,
      disableIncrement: false,
      disableDecrement: false
    }
  },
  filters: {
    cardNumber (token) {
      return token.slice(-4)
    },
    capitalize (string) {
      if (!string) return ''
      string = string.toString()
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },
  computed: {
    ...mapState('paymentManagement', {
      payments: state => state.payments
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('contractorManagement', {
      settings: state => state.settings,
      contractor: state => state.contractor
    }),
    ...mapState('cartManagement', {
      cart: state => state.cart,
      validation: state => state.validationCustomers
    }),
    ...mapGetters('cartManagement', {
      total: 'cartTotal',
      customerSubtotal: 'customerCartSubtotal',
      customerTax: 'customerCartTax',
      customerTotal: 'customerCartTotal'
    }),
    headers () {
      return [
        {
          text: 'ID',
          value: 'id',
          align: ' d-none'
        },
        {
          text: 'Name',
          value: 'customer'
        },
        {
          text: 'PO#',
          value: 'customer.po'
        },
        {
          text: 'Items',
          value: 'items'
        },
        {
          text: 'Subtotal',
          value: 'subtotal'
        },
        {
          text: 'Sales Tax',
          value: 'tax'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
        {
          text: '',
          value: 'data-table-expand'
        }
      ]
    },
    hasCart () {
      return Object.keys(this.cart).length > 0
    },
    hasUser () {
      return Object.entries(this.user).length > 0
    },
    cartCustomerCount () {
      if (this.hasCart) {
        return this.cart.customers.length
      } else {
        return 0
      }
    },
    paymentAuthorized () {
      if (this.paymentMethod === 'NET30') return true
      return Object.keys(this.selectedPayment).length > 0
    },
    cartInValid () {
      if (this.selected.length === 0 || this.placingOrders) return true

      if (this.settings.po_required) {
        let tempSelected = this.selected
        tempSelected = tempSelected.filter((item) => {
          return (item.customer_po === '')
        })

        return tempSelected.length > 0
      }

      return false
    }
  },
  methods: {
    ...mapActions('paymentManagement', {
      fetch: 'fetchPayments'
    }),
    ...mapActions('orderManagement', {
      createOrders: 'add'
    }),
    ...mapActions('contractorManagement', {
      fetchContractor: 'fetchContractor'
    }),
    ...mapActions('cartManagement', {
      fetchCart: 'getCart',
      createCart: 'createCart',
      previousCarts: 'getPreviousCarts',
      addCartCustomers: 'addCustomersToCart',
      addCartCustomer: 'addCustomerToCart',
      updateCartCustomer: 'updateCartCustomer',
      updateCartItem: 'updateCartProduct',
      clearCart: 'removeCart',
      removeCustomerFromCart: 'removeCustomer',
      removeProductFromCart: 'removeProduct'
    }),
    ...mapMutations('cartManagement', {
      selectCustomer: 'setSelectedCustomer',
      clearSelectedCustomer: 'removeSelectedCustomer',
      selectCart: 'setCart',
      updateCart: 'setCart',
      setValidation: 'setValidationCustomers'
    }),
    init () {
      if (Object.keys(this.contractor).length === 0) {
        this.loadContractor()
      }

      this.selectCustomer({})

      this.fetchPayment()
    },
    addCart () {
      this.createCart()
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    loadCart () {
      this.loading = true
      this.fetchCart(this.cart.id)
        .then(() => {
          this.placingOrders = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchPayment () {
      this.fetch()
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
          this.loading = false
        })
    },
    addCustomer () {
      const options = {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        title: 'Add Customer',
        add: true,
        edit: false,
        addToCart: true,
        allowMultiple: true,
        showAutocomplete: true
      }
      this.$refs.addCustomerModal.open(options)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error)
        })
    },
    toggleNewPaymentModal (val = false) {
      this.addNewPayment = val
    },
    loadContractor () {
      this.fetchContractor(this.user.model_id)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error)
        })
    },
    handleFileUpload (file) {
      this.customerUploadListLoadingDialog = true

      const formData = new FormData()
      formData.append('file', file)
      formData.append('cart_id', this.cart.id)

      this.addCartCustomers(formData)
        .then((response) => {
          response.forEach((customer) => {
            if (customer.validation_errors.length > 0) {
              this.customerValidationErrors.push(customer)
            }
          })

          if (this.customerValidationErrors.length > 0) {
            const options = {
              addToCart: true,
              width: 344,
              height: 200
            }
            this.$refs.validations.open(this.customerValidationErrors, options)
              .then((response) => {
                if (response.success) {
                  this.$toast.success('Customers uploaded.')
                }
                this.loadCart()
              })
              .catch((error) => {
                this.$toast.error(error)
              })
          } else {
            this.loadCart()
            this.$toast.success('Customers uploaded.')
          }
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
        .finally(() => {
          this.customerUploadListLoadingDialog = false
        })
    },
    addProduct (customer) {
      this.selectCustomer(customer)
      this.$router.push({ name: 'products' })
    },
    savePoNumber (customer) {
      this.updateCartCustomer({id: customer.id, customer: {customer_po: customer.customer_po}})
        .then(() => {
          this.$toast.success('Customer PO# updated')
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    deleteProductFromCart (item) {
      this.removeProductFromCart(item.id)
        .then(() => {
          //this.loadCart()
        })
        .catch((error) => {
          this.$toast.error(error)
        })
    },
    selectAllCustomers () {
      this.selected = this.cart.customers
    },
    async clearOrderList () {
      if (await this.$refs.confirm.open('Clear Order List', 'Are you sure you want to clear these orders?', { color: 'danger' })) {
        this.clearCart(this.cart.id)
          .then(() => {
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      }
    },
    removeSelectedCustomers () {
      this.removeCustomerFromCart({cart_id: this.cart.id, customers: this.selected})
      this.selected = []
    },
    incrementQuantity (item) {
      this.disableIncrement = true
      item.quantity = item.quantity + 1
      this.updateCartItem({id: item.id, product: {quantity: item.quantity}})
        .then(() => {
          this.$toast.success(`${item.product.name} quantity updated to ${item.quantity}`)
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.disableIncrement = false
        })
    },
    decrementQuantity (item) {
      this.disableDecrement = true
      item.quantity = item.quantity - 1
      this.updateCartItem({id: item.id, product: {quantity: item.quantity}})
        .then(() => {
          this.$toast.success(`${item.product.name} quantity updated to ${item.quantity}`)
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.disableDecrement = false
        })
    },
    openPaymentDialog () {
      if (this.selected.length > 0) {
        if (this.contractor.settings.credit) {
          this.paymentMethods.push({text: 'Net Terms', value: 'NET30'})
        }

        this.selectedPayment = {}
        this.paymentMethod = null
        this.orderPaymentDialog = true
      } else {
        this.$toast.error('No orders have been selected')
      }
    },
    closePaymentDialog () {
      this.orderPaymentDialog = false
    },
    paymentSelected () {
      if (this.paymentMethod === 'NET30') {
        this.closePaymentDialog()
      }
    },
    setPaymentZip () {
      this.selectedPaymentZip = this.selectedPayment.card_zip
    },
    authorizePayment () {
      let hasError = false

      if (this.selectedPaymentZip.length !== 5) {
        hasError = true
        this.$toast.error('Credit card zip code is incorrect')
      }
      if (this.selectedPaymentSecurity.length < 3) {
        this.$toast.error('Credit card security field is required')
      }
      if (!hasError) {
        this.addingPaymentLoader = false
        this.closePaymentDialog()
      }
    },
    async submitOrders () {
      this.placingOrders = true
      if (await this.$refs.confirm.open('Submit Orders', 'Are you sure you want to submit these orders?', { color: 'danger' })) {
        const orders = {
          cart_id: this.cart.id,
          customers: this.selected,
          payment_method: this.paymentMethod,
          payment: {id: this.selectedPayment.id, cvv: this.selectedPaymentSecurity, zip: this.selectedPaymentZip}
        }
        this.createOrders(orders)
          .then(() => {
            this.loadCart()
            this.clearSelectedCustomer()
            this.$toast.success('Orders created successfully!')
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
            this.loadCart()
            this.clearSelectedCustomer()
          })
      } else {
        this.placingOrders = false
      }
    },
    getCustomerCartSubtotal (customer) {
      if (customer.items.length > 0) {
        return this.customerSubtotal(customer.id)
      } else {
        return 0
      }
    },
    getCustomerCartTax (customer) {
      if (customer.items.length > 0) {
        return this.customerTax(customer.id)
      } else {
        return 0
      }
    },
    getCustomerCartTotal (customer) {
      if (customer.items.length > 0) {
        return this.customerTotal(customer.id)
      } else {
        return 0
      }
    },
    file_selected (input) {
      if (input.target.files && input.target.files[0]) {
        this.handleFileUpload(input.target.files[0])
      }
    },
    openPendingVerification (id) {
      this.$router.push({ name: 'pending', params: { id } })
    },
    showValidation () {
      const options = {
        addToCart: true,
        width: 1000,
        height: 200
      }
      this.$refs.validation.open(this.validation, options)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error)
        })
    }
  },
  created () {
    if (!this.hasCart) {
      this.addCart()
    } else {
      this.loadCart()
    }
    this.init()
  }
}
</script>

<style scoped>

</style>
