import state from './moduleNotificationsState.js'
import mutations from './moduleNotificationsMutations.js'
import actions from './moduleNotificationsActions.js'
import getters from './moduleNotificationsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
