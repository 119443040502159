var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "pa-5" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-btn",
                  { attrs: { fab: "", color: "info", elevation: "0" } },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                  1
                ),
                _c("div", { staticClass: "ml-2 mr-1" }, [
                  _c("h2", { staticClass: "headline font-weight-light" }, [
                    _vm._v(_vm._s(_vm.value))
                  ]),
                  _c("h5", { staticClass: "subtitle-2 font-weight-regular" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }