var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "company-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-office-building" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Company Profile ")]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function() {
                return [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.handleUpdate }
                    },
                    [_vm._v(" UPDATE ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.loading
            ? _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Company Name" },
                                model: {
                                  value: _vm.contractorLocal.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.contractorLocal, "name", $$v)
                                  },
                                  expression: "contractorLocal.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Contact Phone" },
                                model: {
                                  value: _vm.contractorLocal.contact_phone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contractorLocal,
                                      "contact_phone",
                                      $$v
                                    )
                                  },
                                  expression: "contractorLocal.contact_phone"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Contact Email" },
                                model: {
                                  value: _vm.contractorLocal.contact_email,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contractorLocal,
                                      "contact_email",
                                      $$v
                                    )
                                  },
                                  expression: "contractorLocal.contact_email"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Street 1" },
                                model: {
                                  value: _vm.contractorLocal.address.street1,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contractorLocal.address,
                                      "street1",
                                      $$v
                                    )
                                  },
                                  expression: "contractorLocal.address.street1"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Street 2" },
                                model: {
                                  value: _vm.contractorLocal.address.street2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contractorLocal.address,
                                      "street2",
                                      $$v
                                    )
                                  },
                                  expression: "contractorLocal.address.street2"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "City" },
                                model: {
                                  value: _vm.contractorLocal.address.city,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contractorLocal.address,
                                      "city",
                                      $$v
                                    )
                                  },
                                  expression: "contractorLocal.address.city"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "4" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.states,
                                  filter: _vm.statesFilter,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "State"
                                },
                                model: {
                                  value: _vm.contractorLocal.address.state_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contractorLocal.address,
                                      "state_id",
                                      $$v
                                    )
                                  },
                                  expression: "contractorLocal.address.state_id"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Zip Code" },
                                model: {
                                  value: _vm.contractorLocal.address.zip,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contractorLocal.address,
                                      "zip",
                                      $$v
                                    )
                                  },
                                  expression: "contractorLocal.address.zip"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "fontsmall" },
                      [
                        _vm._v(" Default Payment Method "),
                        _c(
                          "v-radio-group",
                          {
                            attrs: { mandatory: "" },
                            model: {
                              value:
                                _vm.contractorLocal.settings.default_payment,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.contractorLocal.settings,
                                  "default_payment",
                                  $$v
                                )
                              },
                              expression:
                                "contractorLocal.settings.default_payment"
                            }
                          },
                          [
                            _c("v-radio", {
                              attrs: {
                                disabled: !_vm.contractorLocal.settings.credit,
                                label: "Net Terms",
                                value: "net30"
                              }
                            }),
                            _c("v-radio", {
                              attrs: { label: "Credit Card", value: "cc" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }