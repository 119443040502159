<template>
  <v-container id="customers-pending-verification-batch-view">
    <material-card
      color="primary"
      icon="mdi-account-check"
    >
      <template #title>
        Customers Pending Verification Batches
      </template>

      <v-data-table
        v-model="selected"
        :single-select="false"
        show-select
        item-key="entered_date"
        :headers="headers"
        :items="items"
        :loading="loading"
        loading-text="Loading Pending Batches... Please wait"
        class="mr-2 ml-2">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <v-menu
              bottom
              left
              min-width="200"
              offset-y
              origin="top right"
              transition="scale-transition"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  :disabled="selected.length === 0"
                  class="mr-5"
                  min-width="0"
                  text
                  outlined
                  color="accent"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Actions
                </v-btn>
              </template>

              <v-list
                :tile="false"
                flat
                nav
              >
                <v-list-item @click="approveSelected">
                  <v-list-item-title>Approve</v-list-item-title>
                </v-list-item>
                <v-list-item @click="approveAddCart">
                  <v-list-item-title>Approve & Add To Cart</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteSelected">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          </v-toolbar>
        </template>
        <template v-slot:[`item.method`]="{ item }">
          {{ methodMap[item.method] }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            icon
            class="mr-2"
            @click="openBatch(item.id)"
          >
            <v-icon>mdi-folder</v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
<!--          <v-btn color="primary" @click="loadBatches">No data found! Click to retry</v-btn>-->
          No pending verification batches found.
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      query: {
        contractor_id: '',
        page: 1,
        limit: 15,
        keyword: '',
        site_id: 3,
        paginated: true,
        load_pending: true
      },
      items: [],
      loading: false,
      selected: [],
      methodMap: {
        UPLOAD: 'Upload',
        MANUAL: 'Manual'
      }
    }
  },
  filters: {
    tag (value) {
      if (value.length > 0) {
        return 'Attention Needed'
      } else {
        return 'Valid'
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    headers () {
      return [
        {
          text: 'Entered Method',
          value: 'method',
          width: 450
        },
        {
          text: 'Date',
          value: 'created_at'
        },
        {
          text: 'Remaining',
          value: 'remaining'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      batches: 'fetchCustomerBatches'
    }),
    loadBatches () {
      this.batches({batch_type: 'approval'})
        .then((response) => {
          this.items = response
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    approveSelected () {
      this.$toast.error(this.selected)
    },
    approveAddCart () {
      this.$toast.error(this.selected)
    },
    deleteSelected () {
      this.$toast.error(this.selected)
    },
    openBatch (id) {
      this.$router.push({ name: 'pending', params: { id } })
    }
  },
  created () {
    this.query.contractor_id = this.user.model_id
    this.loadBatches()
  }
}
</script>
