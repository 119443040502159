import api from './request'

export function addReminder (data) {
  return api({
    url: '/reminders',
    method: 'post',
    data
  })
}

export function getReminder (id) {
  return api({
    url: `/reminders/${id}`,
    method: 'get'
  })
}

export function getReminders (query) {
  return api({
    url: '/reminders',
    method: 'get',
    params: query
  })
}

export function updateReminder (id, data) {
  return api({
    url: `/reminders/${id}`,
    method: 'put',
    data
  })
}

export function unsubscribe (payload) {
  return api({
    url: '/notifications/unsubscribe/all',
    method: 'put',
    data: payload
  })
}

export function deleteReminder (id) {
  return api({
    url: `/reminders/${id}`,
    method: 'delete'
  })
}
