var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasUser
    ? _c(
        "div",
        { staticClass: "dashboard_layout" },
        [
          _c(
            "v-container",
            [
              _c("v-row", [_c("h2", [_vm._v("Dashboard")]), _c("v-spacer")], 1),
              _c(
                "v-row",
                { staticClass: "mb-5" },
                _vm._l(_vm.settings, function(setting, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "12", md: "6", lg: "4" } },
                    [
                      _c("statistics-card", {
                        attrs: {
                          icon: setting.icon,
                          value: setting.value,
                          title: _vm.settingsMap[setting.name]
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "dashboard_data_rows",
                      attrs: { cols: "12", sm: "12", md: "12", lg: "12" }
                    },
                    [
                      _vm.datatables.show_recent_orders
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5" },
                            [
                              _c("order-history", {
                                attrs: { height: _vm.height }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.datatables.show_scheduled_orders
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5" },
                            [
                              _c("scheduled-orders", {
                                attrs: { height: _vm.height, days: 30 }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.datatables.show_unapproved_service_titan
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5" },
                            [
                              _c("pending-order-history", {
                                attrs: {
                                  height: _vm.height,
                                  days: 30,
                                  "service-titan": true,
                                  status: "PENDING_APPROVAL"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          Object.entries(_vm.user).length > 0
            ? _c("Settings", { on: { "update-settings": _vm.initSettings } })
            : _vm._e(),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }