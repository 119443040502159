var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "scheduled-ship-date-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Ship Date ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-form",
                  [
                    _c(
                      "v-menu",
                      {
                        ref: "menu",
                        attrs: {
                          "close-on-content-click": false,
                          "return-value": _vm.selectedDate,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto"
                        },
                        on: {
                          "update:returnValue": function($event) {
                            _vm.selectedDate = $event
                          },
                          "update:return-value": function($event) {
                            _vm.selectedDate = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: "Next Date",
                                          "prepend-icon": "mdi-calendar",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.selectedDate,
                                          callback: function($$v) {
                                            _vm.selectedDate = $$v
                                          },
                                          expression: "selectedDate"
                                        }
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.menu,
                          callback: function($$v) {
                            _vm.menu = $$v
                          },
                          expression: "menu"
                        }
                      },
                      [
                        _c(
                          "v-date-picker",
                          {
                            attrs: {
                              "no-title": "",
                              scrollable: "",
                              min: _vm.minDate
                            },
                            model: {
                              value: _vm.selectedDate,
                              callback: function($$v) {
                                _vm.selectedDate = $$v
                              },
                              expression: "selectedDate"
                            }
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.menu = false
                                  }
                                }
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.menu.save(_vm.selectedDate)
                                  }
                                }
                              },
                              [_vm._v(" OK ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.selectedDate === _vm.nextDate,
                          block: "",
                          color: "primary"
                        },
                        on: { click: _vm.updateNext }
                      },
                      [_vm._v(" Update Ship Date ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }