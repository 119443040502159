// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-2!./layout.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-2!./fonts.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-2!./orders.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-2!./account.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-2!./products.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-3-2!./shopping_cart.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
// Module
exports.push([module.id, "/*p, div {\n    font-family: 'Lora', serif;\n}*/\n", ""]);
// Exports
module.exports = exports;
