import Vue from 'vue'
import AppView from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import store from './store/store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMoment from 'vue-moment'
import vuescroll from 'vuescroll'
import VueMeta from 'vue-meta'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import './css/app.css'
import configPlugin from '@/config'
import '@/filters/filters'
import Utils from './utils/utils.js'
import VueCreditCardValidation from 'vue-credit-card-validation'
Vue.prototype.$utils = Utils
Vue.prototype.$config = configPlugin

Vue.config.productionTip = false

Vue.use(VueMaterial)
Vue.use(VueSweetalert2)
Vue.use(VueMoment)
Vue.use(vuescroll)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VueCreditCardValidation)
Vue.use(configPlugin)
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_GEOCODING_API_KEY
})
import DayJs from '@/plugins/vue-dayjs'
Vue.use(DayJs)

/*import { broadcasting } from '@/api/auth'
import Pusher from 'pusher-js'
import VueEcho from 'vue-echo-laravel'
Pusher.logToConsole = false
Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        broadcasting({
          socket_id: socketId,
          channel_name: channel.name
        })
          .then((response) => {
            callback(false, response)
          })
          .catch((error) => {
            callback(true, error)
          })
      }
    }
  }
})*/

new Vue({
  router,
  store,
  vuetify,
  render: h => h(AppView)
}).$mount('#app')
