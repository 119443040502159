<template>
  <div id="order-items-panel">
    <panel>
      <template #title>
        Order Items
      </template>

      <template #content>
        <v-simple-table
          class="m-2"
          dense
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Name</th>
              <th class="text-left">Case Qty</th>
              <th class="text-left">Price</th>
              <th class="text-left">Quantity</th>
              <th class="text-left">Subtotal</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="scheduledProduct in order.products"
              :key="scheduledProduct.id"
            >
              <td>{{ scheduledProduct.product_id }}</td>
              <td>{{ $utils.productName(scheduledProduct.product) }}</td>
              <td>{{ scheduledProduct.product.purchase_qty }}</td>
              <td><money-format :value="parseFloat(scheduledProduct.price)" locale="en" currency-code="USD"></money-format></td>
              <td>{{ scheduledProduct.quantity }}</td>
              <td><money-format :value="parseFloat(scheduledProduct.line_total)" locale="en" currency-code="USD"></money-format></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider class="m-0 p-0"></v-divider>
        <v-row class="d-flex justify-end mt-2">
          <v-col class="text-right" cols="2">
            Items Subtotal:
          </v-col>
          <v-col class="text-right" cols="2">
            <money-format :value="subtotal" locale="en" currency-code="USD"></money-format>
          </v-col>
        </v-row>
      </template>
    </panel>
  </div>
</template>

<script>
import MoneyFormat from 'vue-money-format'
import { mapState } from 'vuex'

export default {
  name: 'ScheduledOrderItemsPanel',
  components: {
    Panel: () => import('@/components/Panel'),
    MoneyFormat
  },
  data () {
    return {
      statusColorMap: {
        SCHEDULED: 'success',
        PENDING_APPROVAL: 'primary',
        CANCELLED: 'red',
        UNSCHEDULED: 'red'
      }
    }
  },
  filters: {
    cartProduct (val) {
      return val.length > 60 ? `${val.slice(0, 60)}...` : val
    }
  },
  computed: {
    ...mapState({
      orderStatusMap: state => state.orderStatusMap
    }),
    ...mapState('orderManagement', {
      order: state => state.scheduledOrder
    }),
    subtotal () {
      return parseFloat(this.order.products.reduce((subtotal, product) => subtotal + (product.line_total), 0))
    }
  }
}
</script>

<style scoped>

</style>
