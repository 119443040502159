import { addUser, addUsers, addUserWithImage, updateUser, updateUserWithImage, getUser, getUsers, deleteUser } from '../../api/user'

export default {
  add ({commit}, user) {
    return new Promise((resolve, reject) => {
      addUser(user)
        .then((response) => {
          commit('addUser', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addUsers ({commit}, payload) {
    return new Promise((resolve, reject) => {
      addUsers(payload)
        .then((response) => {
          commit('setUsers', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addWithImage ({commit}, user) {
    return new Promise((resolve, reject) => {
      addUserWithImage(user)
        .then((response) => {
          commit('addUser', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update ({commit}, data) {
    const { id, user } = data
    return new Promise((resolve, reject) => {
      updateUser(id, user)
        .then((response) => {
          commit('updateUser', response.data)

          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateWithImage ({commit}, data) {
    const { id, user } = data
    return new Promise((resolve, reject) => {
      updateUserWithImage(id, user)
        .then((response) => {
          const currentUser = JSON.parse(localStorage.getItem('user'))
          if (currentUser.id === id) {
            commit('updateCurrentUser', response.data)
          } else {
            commit('updateUser', response.data)
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUsers ({commit}, query) {
    return new Promise((resolve, reject) => {
      getUsers(query)
        .then((response) => {
          commit('setUsers', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUser (context, id) {
    return new Promise((resolve, reject) => {
      getUser(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete ({commit}, id) {
    return new Promise((resolve, reject) => {
      deleteUser(id)
        .then((response) => {
          commit('removeUser', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
