var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "scheduled-order-actions-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Order Actions ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("v-select", {
                  attrs: {
                    items: _vm.actions,
                    label: "Choose An Action",
                    dense: "",
                    "return-object": ""
                  },
                  on: { change: _vm.performAction },
                  model: {
                    value: _vm.orderAction,
                    callback: function($$v) {
                      _vm.orderAction = $$v
                    },
                    expression: "orderAction"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }