const getSelectedCustomer = () => {
  const local = localStorage.getItem('selected_customer')

  if (local === null) {
    return {}
  }

  return JSON.parse(local)
}

const getCart = () => {
  const local = localStorage.getItem('cart')

  if (local === null) {
    return {}
  }

  return JSON.parse(local)
}

const getValidationCustomers = () => {
  const local = localStorage.getItem('validation_customers')
  if (local === null) {
    return []
  }
  return JSON.parse(local)
}

export default {
  cart: getCart(),
  carts: [],
  selectedCustomer: getSelectedCustomer(),
  validationCustomers: getValidationCustomers()
}
