var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-actions-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Order Actions ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("v-select", {
                  attrs: {
                    items: _vm.actions,
                    label: "Choose An Action",
                    dense: ""
                  },
                  on: { change: _vm.performAction },
                  model: {
                    value: _vm.orderAction,
                    callback: function($$v) {
                      _vm.orderAction = $$v
                    },
                    expression: "orderAction"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("confirmation-modal", { ref: "confirm" }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialogPO,
            callback: function($$v) {
              _vm.dialogPO = $$v
            },
            expression: "dialogPO"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Update PO#")]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { dense: "" },
                    model: {
                      value: _vm.poNumber,
                      callback: function($$v) {
                        _vm.poNumber = $$v
                      },
                      expression: "poNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogPO = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", text: "" },
                      on: { click: _vm.updatePO }
                    },
                    [_vm._v("Update")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("return-request-modal", { ref: "returnRequestModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }