var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasUser
    ? _c(
        "v-container",
        { attrs: { id: "customers-list-view" } },
        [
          _c(
            "material-card",
            {
              attrs: { color: "primary", icon: "mdi-account-multiple" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Customers ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1464443274
              )
            },
            [
              _c("v-data-table", {
                staticClass: "mr-2 ml-2",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.customers,
                  loading: _vm.loading,
                  "items-per-page": _vm.query.limit,
                  page: _vm.query.page,
                  "server-items-length": _vm.total,
                  options: _vm.options,
                  "hide-default-footer": "",
                  "loading-text": "Loading Customers... Please wait"
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c(
                            "v-toolbar",
                            { attrs: { flat: "", color: "white" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Search",
                                  "hide-details": "",
                                  clearable: "",
                                  "append-icon": "mdi-magnify",
                                  dense: ""
                                },
                                on: {
                                  input: _vm.handleSearch,
                                  "click:clear": _vm.clearSearch
                                },
                                model: {
                                  value: _vm.query.keyword,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "keyword", $$v)
                                  },
                                  expression: "query.keyword"
                                }
                              }),
                              _c("v-spacer"),
                              _c("v-checkbox", {
                                staticClass: "mt-5",
                                attrs: { label: "Active Customers Only?" },
                                on: { change: _vm.toggleCustomerStatus },
                                model: {
                                  value: _vm.showActive,
                                  callback: function($$v) {
                                    _vm.showActive = $$v
                                  },
                                  expression: "showActive"
                                }
                              }),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2 mr-5",
                                  attrs: { color: "primary", dark: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openAddCustomer($event)
                                    }
                                  }
                                },
                                [_vm._v(" Add Customer ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: { color: "secondary", dark: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.customers_file.click()
                                    }
                                  }
                                },
                                [_vm._v(" Upload List ")]
                              ),
                              _c("input", {
                                ref: "customers_file",
                                staticStyle: { display: "none" },
                                attrs: { type: "file", accept: ".csv" },
                                on: { change: _vm.file_selected }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.shipping_address",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(item.shipping_address.street1) +
                              " " +
                              _vm._s(item.shipping_address.street2) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(item.shipping_address.city) +
                              ", " +
                              _vm._s(item.shipping_address.state.name) +
                              " " +
                              _vm._s(item.shipping_address.zip) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.validation_errors",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.validation_errors.length === 0
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    color: "green",
                                    "text-color": "white",
                                    small: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { left: "" } },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-thumb-up")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" Verified ")
                                ],
                                1
                              )
                            : _vm._l(item.validation_errors, function(
                                validation,
                                index
                              ) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: index,
                                    staticClass: "ma-2",
                                    attrs: {
                                      color: "red",
                                      "text-color": "white",
                                      small: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { left: "" } },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-alert-box-outline")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" " + _vm._s(validation.error) + " ")
                                  ],
                                  1
                                )
                              })
                        ]
                      }
                    },
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: _vm.getStatusColor(item.status),
                                "text-color": "white",
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("capitalize")(item.status)) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  dark: "",
                                                  icon: "",
                                                  color: "primary"
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-dots-vertical")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  item.validation_errors.length > 0
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openValidation(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-account-check")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("View Validation")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.openCustomer(item.id)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-account-search")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("View Customer")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.updateCustomer(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          item.active
                                            ? _c("v-icon", [
                                                _vm._v("mdi-account-cancel")
                                              ])
                                            : _c("v-icon", [
                                                _vm._v("mdi-account-reactivate")
                                              ])
                                        ],
                                        1
                                      ),
                                      item.active
                                        ? _c("v-list-item-title", [
                                            _vm._v("Deactivate Customer")
                                          ])
                                        : _c("v-list-item-title", [
                                            _vm._v("Activate Customer")
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [_vm._v(" No customers found. ")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              }),
              _c(
                "div",
                {
                  staticClass: "v-data-footer",
                  staticStyle: { "border-top": "thin solid rgba(0, 0, 0, .12)" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "v-data-footer__select" },
                    [
                      _c("span", [_vm._v("Rows per page:")]),
                      _c("v-select", {
                        staticStyle: { margin: "15px 5px 1px 34px" },
                        attrs: { items: _vm.itemsPerPageOptions },
                        on: { change: _vm.loadCustomers },
                        model: {
                          value: _vm.query.limit,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "limit", $$v)
                          },
                          expression: "query.limit"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-pagination", {
                    attrs: {
                      length: _vm.pageCount,
                      "total-visible": "5",
                      "next-icon": "mdi-menu-right",
                      "prev-icon": "mdi-menu-left"
                    },
                    on: { input: _vm.loadCustomers },
                    model: {
                      value: _vm.query.page,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "page", $$v)
                      },
                      expression: "query.page"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("customer-validation-modal", { ref: "customerValidation" }),
          _c("customers-validation-modal", { ref: "validations" }),
          _c("customer-modal", { ref: "addCustomer" }),
          _c("confirmation-modal", { ref: "confirm" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }