export default {
  setOrder (state, order) {
    state.order = order
  },
  setOrders (state, orders) {
    state.orders = orders
  },
  addOrder (state, payload) {
    state.orders.push(Object.assign({}, payload))
  },
  addProduct (state, product) {
    state.products.push(Object.assign({}, product))
  },
  updateOrder (state, payload) {
    const index = state.orders.findIndex((o) => o.id === payload.id)
    state.orders.splice(index, 1, payload)
    state.order = payload
  },
  removeOrder (state, id) {
    const index = state.orders.findIndex((o) => o.id === id)
    state.orders.splice(index, 1)
  },
  removeProduct (state, id) {
    const index = state.products.findIndex((o) => o.product_id === id)
    state.products.splice(index, 1)
  },
  removeProducts (state) {
    state.products = []
  },
  setScheduledOrder (state, order) {
    state.scheduledOrder = order
  },
  setScheduledOrders (state, orders) {
    state.scheduledOrders = orders
  },
  addScheduledOrder (state, payload) {
    state.scheduledOrders.push(Object.assign({}, payload))
  },
  updateScheduledOrder (state, payload) {
    const index = state.scheduledOrders.findIndex((o) => o.id === payload.id)
    state.scheduledOrders.splice(index, 1, payload)
    state.scheduledOrder = payload
  },
  removeScheduledOrder (state, id) {
    const index = state.scheduledOrders.findIndex((o) => o.id === id)
    state.scheduledOrders.splice(index, 1)
  },
  setServiceTitanOrders (state, orders) {
    state.serviceTitanPendingOrders = orders
  },
  updateServiceTitanOrder (state, payload) {
    const st_index = state.serviceTitanPendingOrders.findIndex((o) => o.id === payload.id)
    state.serviceTitanPendingOrders.splice(st_index, 1, payload)
    state.serviceTitanPendingOrders = payload

    const index = state.orders.findIndex((o) => o.id === payload.id)
    state.orders.splice(index, 1, payload)
    state.order = payload
  }
}
