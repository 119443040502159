var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cancel-dialog" } },
    [
      _c(
        "v-dialog",
        {
          style: { zIndex: _vm.options.zIndex },
          attrs: { "max-width": _vm.options.width },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.cancel($event)
            },
            "click:outside": _vm.cancel
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    dark: "",
                    color: _vm.options.color,
                    dense: "",
                    flat: ""
                  }
                },
                [
                  _c("v-toolbar-title", { staticClass: "white--text" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-form",
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          "auto-grow": "",
                          label: "Add note",
                          rows: "3"
                        },
                        model: {
                          value: _vm.note,
                          callback: function($$v) {
                            _vm.note = $$v
                          },
                          expression: "note"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.cancel($event)
                        }
                      }
                    },
                    [_vm._v("CANCEL")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", text: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.accept($event)
                        }
                      }
                    },
                    [_vm._v("SAVE")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }