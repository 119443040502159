const getSettings = () => {
  const local = localStorage.getItem('settings')

  if (local === null) {
    return {}
  }

  return JSON.parse(local)
}

export default {
  contractor: {},
  dashboard: [],
  users: [],
  settings: getSettings()
}
