var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.products,
          search: _vm.search,
          "fixed-header": "",
          loading: _vm.loading,
          "loading-text": "Loading... Please wait"
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "white" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.openAddProduct }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.price",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" $" + _vm._s(item.price) + " ")]
              }
            },
            {
              key: "item.status",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    { attrs: { color: _vm.statusColorMap[item.status] } },
                    [_vm._v(" " + _vm._s(_vm.statusMap[item.status]) + " ")]
                  )
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmDelete(item.id)
                        }
                      }
                    },
                    [_vm._v(" mdi-delete-outline ")]
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [_vm._v(" No assigned products found. ")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }