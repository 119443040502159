var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-items-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Order Items ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("v-simple-table", {
                  staticClass: "m-2",
                  attrs: { dense: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("ID")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Name")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Case Qty")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Price")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Quantity")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Subtotal")
                              ]),
                              _c("th")
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.order.products, function(
                              scheduledProduct
                            ) {
                              return _c("tr", { key: scheduledProduct.id }, [
                                _c("td", [
                                  _vm._v(_vm._s(scheduledProduct.product_id))
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$utils.productName(
                                        scheduledProduct.product
                                      )
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      scheduledProduct.product.purchase_qty
                                    )
                                  )
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("money-format", {
                                      attrs: {
                                        value: parseFloat(
                                          scheduledProduct.price
                                        ),
                                        locale: "en",
                                        "currency-code": "USD"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(_vm._s(scheduledProduct.quantity))
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("money-format", {
                                      attrs: {
                                        value: parseFloat(
                                          scheduledProduct.line_total
                                        ),
                                        locale: "en",
                                        "currency-code": "USD"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("v-divider", { staticClass: "m-0 p-0" }),
                _c(
                  "v-row",
                  { staticClass: "d-flex justify-end mt-2" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [_vm._v(" Items Subtotal: ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [
                        _c("money-format", {
                          attrs: {
                            value: _vm.subtotal,
                            locale: "en",
                            "currency-code": "USD"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }