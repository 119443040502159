var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-img", {
            staticClass: "mt-15",
            attrs: {
              src: require("../../assets/fod_logo.png"),
              "max-height": "250",
              contain: ""
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-5",
              attrs: {
                elevation: "2",
                "min-height": "400",
                "min-width": "500",
                width: "40%"
              }
            },
            [
              _c(
                "div",
                { staticClass: "px-10 py-15" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "loginForm",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.userLogin($event)
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: "Email",
                          rules: _vm.emailRules
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: "Password",
                          rules: _vm.passwordRules,
                          type: _vm.show ? "text" : "password",
                          "append-icon": _vm.show ? "mdi-eye" : "mdi-eye-off"
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.show = !_vm.show
                          }
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "p",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-inherit underline",
                              attrs: { to: { name: "forgot-password" } }
                            },
                            [_vm._v("Forgot Password?")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            type: "submit",
                            block: "",
                            disabled: !_vm.valid,
                            color: "primary"
                          }
                        },
                        [_vm._v(" Sign In ")]
                      ),
                      _c(
                        "p",
                        { staticClass: "text-center" },
                        [
                          _vm._v(" Dont have an account? "),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-inherit underline ml-2",
                              attrs: { to: { name: "request-account" } }
                            },
                            [_vm._v("REQUEST ONE NOW")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("contractor-select-modal", { ref: "contractorSelect" }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }