<template>
  <v-main id="home">
    <v-container fluid>
      <router-view />
    </v-container>
  </v-main>
</template>

<script>

export default {
  name: 'MainView'
}
</script>

<style scoped>

</style>
