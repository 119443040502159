import { addReminder, getReminder, getReminders, updateReminder, deleteReminder } from '@/api/reminder'

// noinspection DuplicatedCode
export default {
  add ({commit}, payload) {
    return new Promise((resolve, reject) => {
      addReminder(payload)
        .then((response) => {
          commit('addReminder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update ({commit}, data) {
    const { id, reminder } = data
    return new Promise((resolve, reject) => {
      updateReminder(id, reminder)
        .then((response) => {
          commit('updateReminder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  show (context, id) {
    return new Promise((resolve, reject) => {
      getReminder(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetch ({commit}, query) {
    return new Promise((resolve, reject) => {
      getReminders(query)
        .then((response) => {
          commit('setReminders', response.data)

          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete ({commit}, id) {
    return new Promise((resolve, reject) => {
      deleteReminder(id)
        .then((response) => {
          commit('removeReminder', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
