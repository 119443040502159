var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-details-view" },
    [
      !_vm.loading
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-card", { attrs: { elevation: "0" } }, [
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "Order# " + _vm._s(_vm.order.id) + " Details"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(
                              "Order Date: " + _vm._s(_vm.order.created_at)
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(
                              "Status: " +
                                _vm._s(_vm.orderStatusMap[_vm.order.status])
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c("span", { staticClass: "subtitle" }, [
                            _vm._v("PO#: " + _vm._s(_vm.order.customer_po))
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("CustomerAddressPanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("OrderItemsPanel")],
                            1
                          ),
                          _vm.order.returns.length > 0
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [_c("OrderReturnsPanel")],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("ShippingPanel")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("OrderActionsPanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("PaymentMethodPanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("OrderNotesPanel", {
                                key: _vm.order.comments.length
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-overlay",
                { attrs: { value: _vm.loading } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }