<template>
  <div id="scheduled-ship-date-panel">
    <panel>
      <template #title>
        Ship Date
      </template>

      <template #content>
        <v-form>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedDate"
                label="Next Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedDate"
              no-title
              scrollable
              :min="minDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(selectedDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn
            :disabled="selectedDate === nextDate"
            block
            color="primary"
            @click="updateNext"
          >
            Update Ship Date
          </v-btn>
        </v-form>
      </template>
    </panel>
    <confirmation-modal ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ScheduledShipDatePanel',
  components: {
    Panel: () => import('@/components/Panel'),
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      menu: false,
      selectedDate: null,
      nextDate: null,
      minDate: null
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.scheduledOrder
    })
  },
  methods: {
    ...mapActions('orderManagement', {
      update: 'updateScheduled'
    }),
    ...mapMutations('orderManagement', {
      loadOrder: 'setScheduledOrder'
    }),
    init () {
      this.selectedDate = this.order.next
      this.nextDate = this.order.next
      this.minDate = this.$moment().add(1, 'days').format('YYYY-MM-DD')
    },
    async updateNext () {
      if (await this.$refs.confirm.open('Update Ship Date', 'Are you sure you want to update the shipping date?', { color: 'red' })) {
        this.update({id: this.order.id, payload: {next: this.selectedDate}})
          .then((response) => {
            this.loadOrder(response.data)
            this.init()
            this.$toast.success('Ship date updated.')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>

</style>
