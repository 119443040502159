import { render, staticRenderFns } from "./ShipDatePanel.vue?vue&type=template&id=2653205f&scoped=true&"
import script from "./ShipDatePanel.vue?vue&type=script&lang=js&"
export * from "./ShipDatePanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2653205f",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDatePicker,VForm,VMenu,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/forge/demo.filterondemand.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2653205f')) {
      api.createRecord('2653205f', component.options)
    } else {
      api.reload('2653205f', component.options)
    }
    module.hot.accept("./ShipDatePanel.vue?vue&type=template&id=2653205f&scoped=true&", function () {
      api.rerender('2653205f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orders/components/scheduled/ShipDatePanel.vue"
export default component.exports