var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "", fixed: "" },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "" } },
        _vm._l(_vm.filterMenuItems, function(item, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              staticClass: "text-decoration-none",
              attrs: { to: item.to, "active-class": "primary white--text" }
            },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                1
              ),
              _c("v-list-item-title", [_vm._v(_vm._s(item.title))])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }