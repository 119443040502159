var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "scheduled-orders-pending-list-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-account-multiple" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Pending Approval Orders ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("scheduled-orders", {
            attrs: { "show-title": false, pending: true }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }