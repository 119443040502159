<template>
  <div id="customer_modal">
    <v-dialog
      v-model="dialog"
      max-width="500px"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
      @click:outside="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">
            {{ options.title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field dense v-model="customer.fname" label="First Name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field dense v-model="customer.lname" label="Last Name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field dense v-model="customer.company_name" label="Company Name"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="customer.email" label="Email"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="customer.account_number" label="Account Number"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="options.showAutocomplete">
                <vuetify-google-autocomplete
                  id="autocomplete"
                  ref="address"
                  placeholder="Enter address for validation"
                  v-on:no-results-found="noResults"
                  v-on:placechanged="getAddressData"
                >
                  <template #append>
                    <v-icon small>mdi-map-search</v-icon>
                  </template>
                </vuetify-google-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="customer.address.street1" label="Street 1"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="customer.address.street2" label="Street 2"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="customer.address.city" label="City"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="customer.address.state_id"
                  :items="states"
                  :filter="statesFilter"
                  item-text="text"
                  item-value="value"
                  label="State"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  dense
                  v-model="customer.address.zip"
                  label="Zip"
                  :rules="zipRules"
                  maxlength="5"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="submit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-modal ref="confirm" />
    <customer-validation-modal ref="customerValidation"></customer-validation-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    CustomerValidationModal: () => import('@/views/components/CustomerValidationModal')
  },
  data () {
    return {
      dialog: false,
      resolve: {},
      reject: null,
      customerData: {},
      options: {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        title: null,
        add: false,
        edit: false,
        addToCart: false,
        allowMultiple: true,
        showAutocomplete: true
      },
      customer: {
        id: null,
        fname: '',
        lname: '',
        company_name: '',
        email: '',
        account_number: '',
        site_id: 3,
        contractor_id: null,
        address: {
          id: null,
          street1: '',
          street2: '',
          city: '',
          state_id: null,
          zip: ''
        }
      },
      zipRules: [v => v.length <= 5 || 'Max 5 characters'],
      loading: false,
      validationErrors: [],
      count: 0
    }
  },
  watch: {
    dialog (val) {
      if (!val) return

      if (Object.entries(this.customerData).length === 0) {
        this.init()
      } else {
        const { id, fname, lname, email, contractor_id, shipping_address } = JSON.parse(JSON.stringify(this.customerData))
        this.customer.id = id
        this.customer.fname = fname
        this.customer.lname = lname
        this.customer.email = email
        this.customer.contractor_id = contractor_id
        this.customer.address.id = shipping_address.id
        this.customer.address.street1 = shipping_address.street1
        this.customer.address.street2 = shipping_address.street2
        this.customer.address.city = shipping_address.city
        this.customer.address.state_id = shipping_address.state_id
        this.customer.address.zip = shipping_address.zip
      }
    }
  },
  computed: {
    ...mapState({
      states: state => state.stateOptions
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('cartManagement', {
      cart: state => state.cart
    })
  },
  methods: {
    ...mapActions('customerManagement', {
      update: 'updateCustomer',
      add: 'addCustomer'
    }),
    ...mapActions('cartManagement', {
      addCartCustomer: 'addCustomerToCart'
    }),
    init () {
      if (Object.entries(this.customerData).length > 0) return

      this.resetCustomer()
    },
    resetCustomer () {
      this.customer = {
        id: null,
        fname: '',
        lname: '',
        email: '',
        account_number: '',
        site_id: 3,
        contractor_id: this.user.model_id,
        address: {
          id: null,
          street1: '',
          street2: '',
          city: '',
          state_id: null,
          zip: ''
        }
      }
    },
    statesFilter (item, queryText) {
      const name = item.text.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    getAddressData (addressData) {
      const state = this.states.find((s) => s.abbreviation === addressData.administrative_area_level_1)
      this.customer.address.zip = addressData.postal_code
      this.customer.address.street1 = addressData.name
      this.customer.address.city = addressData.locality
      this.customer.address.state_id = state.value
    },
    noResults () {
      this.$toast.error(this.$refs.address)
      this.$toast.error(this.$refs.address.autocompleteText)
    },
    open (customer, options) {
      this.options = Object.assign(this.options, options)
      this.customerData = customer
      this.validationErrors = false
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    submit () {
      if (this.options.add) {
        this.addCustomer()
      } else {
        this.editCustomer()
      }
    },
    addCustomerToCart (customer) {
      this.addCartCustomer({cart_id: this.cart.id, customer_id: customer.id, address_id: customer.default_shipping})
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error)
        })
    },
    addCustomer () {
      this.add(this.customer)
        .then((response) => {
          this.count++

          if (response.validation_errors.length > 0) {
            this.validationErrors = true
            this.openValidation(response)
          } else {
            this.finishAddingCustomer(response)
          }
        })
        .catch((error) => {
          this.reject(error.data.error)
        })
    },
    finishAddingCustomer (customer) {
      if (this.options.addToCart) {
        this.addCustomerToCart(customer)
      }

      this.resetCustomer()
      this.$refs.address.clear()
      if (this.options.allowMultiple) {
        this.$refs.confirm.open('Add Another?', 'Do you want to add another customer?', { color: 'red' })
          .then((confirm) => {
            if (!confirm) {
              const message = this.setMessage()
              this.count = 0
              this.resolve({ success: !this.validationErrors, message })
              this.dialog = false
            }

            this.validationErrors = false
          })
      } else {
        const message = this.setMessage()
        this.count = 0
        this.resolve({ success: !this.validationErrors, message })
        this.dialog = false
      }
    },
    openValidation (customer) {
      const options = {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        addToCart: false
      }

      this.$refs.customerValidation.open(customer, options)
        .then((response) => {
          if (response.success) {
            this.validationErrors = false
            this.finishAddingCustomer(response.customer)
          } else {
            this.finishAddingCustomer(customer)
          }
        })
        .catch((error) => { this.$toast.error(error) })
    },
    setMessage () {
      let message = ''

      if (this.validationErrors) {
        message = 'Validation issues discovered. Please verify customer data.'
      } else if (this.count > 0) {
        message = this.count === 1 ? 'Customer Added!' : `${this.count} Customers Added!`
      } else {
        message = 'Customer action cancelled'
      }

      return message
    },
    editCustomer () {
      this.update({ id: this.customer.id, customer: this.customer })
        .then((response) => {
          let message = ''
          if (response.validation_errors.length > 0) {
            response['validation_status'] = 'Pending'
            message = 'Customer edited with validation issues. Please verify new customer data.'
          } else {
            message = 'Customer edited.'
          }

          this.customerData = {}
          this.resetCustomer()
          this.resolve({ success: !this.validationErrors, errors: this.validationErrors, message })
        })
        .catch(error => {
          this.reject(error)
        })
    },
    cancel () {
      this.resolve({ success: true, errors: !this.validationErrors, message: this.setMessage() })
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
