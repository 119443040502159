var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-items-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Order Items ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("v-simple-table", {
                  attrs: { dense: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Name")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Price")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Qty")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Subtotal")
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.order.products, function(item, i) {
                              return _c("tr", { key: i }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.$utils.productName(item.product))
                                  )
                                ]),
                                _c("td", [
                                  _vm._v("$" + _vm._s(item.list_price))
                                ]),
                                _c("td", [_vm._v(_vm._s(item.quantity))]),
                                _c("td", [
                                  _vm._v("$" + _vm._s(item.list_price))
                                ])
                              ])
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("v-divider", { staticClass: "m-0 p-0" }),
                _c(
                  "v-row",
                  { staticClass: "d-flex justify-end mt-2" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [_vm._v(" Items Subtotal: ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [_vm._v(" $" + _vm._s(_vm.order.subtotal) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "d-flex justify-end mt-0" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [_vm._v(" Sales Tax: ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [_vm._v(" $" + _vm._s(_vm.order.tax) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "d-flex justify-end mt-0" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [_vm._v(" Order Total: ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [_vm._v(" $" + _vm._s(_vm.order.total) + " ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }