import { add, show, get, update, destroy, validate } from '../../api/address'

export default {
  addAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      add(payload)
        .then((response) => {
          commit('addAddress', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateAddress ({ commit }, data) {
    const { id, address } = data
    return new Promise((resolve, reject) => {
      update(id, address)
        .then((response) => {
          commit('updateAddress', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAddress (context, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAddresses ({ commit }, query) {
    return new Promise((resolve, reject) => {
      get(query)
        .then((response) => {
          commit('setAddresses', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((response) => {
          commit('removeAddress', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  validateAddress (context, data) {
    return new Promise((resolve, reject) => {
      validate(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
