<template>
  <v-container id="customer-detail-view">
    <div v-if="showData">
      <material-card
        color="primary"
        icon="mdi-account-details"
      >
        <template #title>
          {{ customer.fname }} {{ customer.lname }}
        </template>

        <v-form>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="local.fname"
                  label="First Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="local.lname"
                  label="Last Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="local.company_name"
                  label="Company"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="local.email"
                  label="Email"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="local.account_number"
                  label="Account Number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="local.default_shipping"
                  :items="customer.addresses"
                  label="Select primary address"
                  item-value="id"
                  >
                  <template v-slot:selection="data">
                    {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ data.item.full_name }}
                        <v-spacer></v-spacer>
                        {{ data.item.street1 }} {{ data.item.street2 }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
<!--              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  :disabled="true"
                  v-model="customer.shipping_address.street1"
                  label="Street 1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  :disabled="true"
                  v-model="customer.shipping_address.street2"
                  label="Street 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :disabled="true"
                  v-model="customer.shipping_address.city"
                  label="City"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :disabled="true"
                  v-model="customer.shipping_address.state.name"
                  label="State"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :disabled="true"
                  v-model="customer.shipping_address.zip"
                  label="Zip Code"
                >
                </v-text-field>
              </v-col>-->
            </v-row>
            <div class="d-flex justify-end">
              <v-btn
                class="mr-5"
                color="red"
                outlined
                @click="resetCustomer"
              >
                RESET
              </v-btn>
              <v-btn
                outlined
                color="primary"
                @click="handleUpdate"
              >
                UPDATE
              </v-btn>
            </div>
          </v-container>
        </v-form>
      </material-card>

      <v-card
        class="my-5"
      >
        <v-expansion-panels
          focusable
        >
          <v-expansion-panel>
            <v-expansion-panel-header>Addresses</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Addresses @reload="loadCustomer()" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Order History</v-expansion-panel-header>
            <v-expansion-panel-content>
              <OrderHistory />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Scheduled Orders</v-expansion-panel-header>
            <v-expansion-panel-content>
              <ScheduledOrders />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Filter Change Reminders</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Reminders />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Assigned Products</v-expansion-panel-header>
            <v-expansion-panel-content>
              <AssignedProducts />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
    <div v-else>
      <material-card
        v-if="hasError"
        color="primary"
        icon="mdi-account-details"
        >
        <template #title>
          {{ errorMessage }}
        </template>
      </material-card>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import {mapActions, mapState} from 'vuex'

//const isEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]))

export default {
  name: 'CustomerDetail',
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    OrderHistory: () => import('./components/OrderHistory'),
    Reminders: () => import('./components/Reminders'),
    AssignedProducts: () => import('./components/AssignedProducts'),
    ScheduledOrders: () => import('./components/ScheduledOrders'),
    Addresses: () => import('./components/Addresses')
  },
  data () {
    return {
      loading: true,
      local: {},
      panel: 0,
      hasError: false,
      errorMessage: '',
      zipRules: [v => v.length <= 5 || 'Max 5 characters'],
      addressDialog: false,
      selectedAddress: {}
    }
  },
  computed: {
    ...mapState('customerManagement', {
      customer: state => state.customer
    }),
    ...mapState({
      states: state => state.stateOptions
    }),
    showData () {
      return !this.hasError && !this.loading
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      fetch: 'fetchCustomer',
      update: 'updateCustomer'
    }),
    ...mapActions('addressManagement', {
      validate: 'validateAddress'
    }),
    init () {
      this.loadCustomer()
    },
    statesFilter (item, queryText) {
      const name = item.text.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    loadCustomer () {
      this.fetch(this.$route.params.id)
        .then(() => {
          this.initCustomer()
        })
        .catch(error => {
          this.hasError = true
          this.loading = false
          this.errorMessage = error.data.error
        })
    },
    initCustomer () {
      const obj = {
        fname: this.customer.fname,
        lname: this.customer.lname,
        company_name: this.customer.company_name,
        email: this.customer.email,
        account_number: this.customer.account_number,
        site_id: 3,
        default_shipping: this.customer.default_shipping,
        default_billing: this.customer.default_billing
      }
      Object.assign(this.selectedAddress, this.customer.shipping_address)
      Object.assign(this.local, obj)

      this.loading = false
    },
    selectAddress () {
      if (this.local.default_shipping !== this.selectedAddress.id) {
        this.local.default_shipping = this.selectedAddress.id
      }
    },
    resetCustomer () {
      const obj = {
        fname: this.customer.fname,
        lname: this.customer.lname,
        company_name: this.customer.company_name,
        email: this.customer.email,
        account_number: this.customer.account_number,
        site_id: 3,
        default_shipping: this.customer.default_shipping,
        default_billing: this.customer.default_billing
      }
      Object.assign(this.local, obj)
    },
    handleUpdate () {
      this.update({id: this.customer.id, customer: this.local})
        .then(() => {
          this.$toast.success('Customer updated!')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
