<template>
  <v-container
    fill-height
    fluid
  >
    <v-layout align-center justify-center>
      <v-card
        elevation="2"
        width="500"
      >
        <v-alert
          class="mx-3 my-3"
          v-model="alert"
          dense
          :type="alertType"
        >
          {{ message }}
        </v-alert>
        <v-card-title>
          Reset Password
        </v-card-title>
        <v-card-subtitle>
          Please enter your new password.
        </v-card-subtitle>

        <v-card-text>
          <v-form
            ref="resetPasswordForm"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              dense
              outlined
              label="Email"
              v-model="email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>

            <v-text-field
              dense
              outlined
              :counter="8"
              :rules="[rules.required, rules.password]"
              label="Password"
              v-model="password"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>

            <v-text-field
              dense
              outlined
              :rules="[rules.required, rules.confirm]"
              label="Confirm Password"
              v-model="password_confirm"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="$router.push({name: 'login'})"
          >
            Back to Login
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="submitForm"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ResetPassword',
  data () {
    return {
      valid: true,
      show: false,
      rules: {
        required: value => !!value || 'Required',
        email: value => {
          return  /.+@.+\..+/.test(value) || 'Invalid E-Mail'
        },
        password: v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        confirm: v => (v && v === this.password) || 'Password must match'
      },
      email: '',
      password: '',
      password_confirm: '',
      message: null,
      alert: false,
      alertType: undefined
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('auth/resetPassword', { password: this.password, email: this.email, token: this.$route.params.token })
        .then((response) => {
          this.alertType = 'success'
          this.message = response
          this.alert = true
          //this.$refs.resetPasswordForm.reset()
        })
        .catch((error) => {
          this.alertType = 'error'
          this.message = error.data.error
          this.alert = true
        })
    }
  }
}
</script>

<style scoped>

</style>
