<template>
    <div class="customer-validation-dialog" v-if="Object.entries(customer).length > 0">
      <v-dialog
        v-model="dialog"
        max-width="1000px"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
        @click:outside="cancel"
      >
          <v-card>
            <v-toolbar dark :color="options.color" dense flat>
              <v-toolbar-title class="white--text">Customer Validation</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="ma-5">
              <v-row dense>
                <v-col cols="12" md="12" class="center">
                  <h1 class="font-black">Potential Customer Validation Errors</h1>
                </v-col>
                <v-col cols="12" md="12" class="center red--text font-weight-bold">
                  Error flagged as:
                  <span v-for="(validation, index) in customer.validation_errors" :key="index">{{ validation.error }}<br></span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <!-- Compare existing customer to new customer -->
              <v-row align="center" justify="center" dense>
                <v-col cols="4">
                  <customer-validation-card :customer-data="customer" :min-height="options.height" :max-width="options.width">
                    <template #title>
                      New Customer
                    </template>

                    <template #actions>
                      <v-btn v-if="customer.validation_errors[errorIndex].error === 'Incorrect Address' && !customer.validation_errors[errorIndex].data" text @click="openEditAddress(customer.shipping_address)">Edit</v-btn>
                      <v-btn text @click="selectNewCustomer()">{{ newCustomerButtonText }}</v-btn>
                    </template>
                  </customer-validation-card>
                </v-col>
                <v-col cols="4" v-if="customer.validation_errors[errorIndex].error === 'Incorrect Address' && customer.validation_errors[errorIndex].data">
                  <customer-validation-card :customer-data="customer.validation_errors[errorIndex].data" :min-height="options.height" :max-width="options.width">
                    <template #title>
                      {{ customer.validation_errors[errorIndex].error }}
                    </template>
                    <template #actions>
                      <v-btn text @click="selectSuggestedAddress(errorIndex)">Select Suggested</v-btn>
                    </template>
                  </customer-validation-card>
                </v-col>
                <v-col cols="4" v-if="customer.validation_errors[errorIndex].error !== 'Incorrect Address'">
                  <customer-validation-card :customer-data="customer.validation_errors[errorIndex].associated" :min-height="options.height" :max-width="options.width">
                    <template #title>
                      {{ customer.validation_errors[errorIndex].error }}
                    </template>
                    <template #actions>
                      <v-btn text @click="selectExistingCustomer(errorIndex)">Select</v-btn>
                      <v-btn text @click="mergeCustomers(errorIndex)">Merge</v-btn>
                    </template>
                  </customer-validation-card>
                </v-col>
<!--                <v-col
                  v-for="(validation, validationIndex) in customer.validation_errors"
                  :key="validationIndex"
                  cols="4"
                >
                  <customer-validation-card v-if="validation.error === 'Incorrect Address' && validation.data" :customer-data="validation.data" :min-height="options.height" :max-width="options.width">
                    <template #title>
                      {{ validation.error }}
                    </template>
                    <template #actions>
                      <v-btn text @click="selectSuggestedAddress(validationIndex)">Select Suggested</v-btn>
                    </template>
                  </customer-validation-card>
                  <customer-validation-card v-if="validation.error !== 'Incorrect Address'" :customer-data="validation.associated" :min-height="options.height" :max-width="options.width">
                    <template #title>
                      {{ validation.error }}
                    </template>
                    <template #actions>
                      <v-btn text @click="selectExistingCustomer(validationIndex)">Select</v-btn>
                      <v-btn text @click="mergeCustomers(validationIndex)">Merge</v-btn>
                    </template>
                  </customer-validation-card>
                </v-col>-->
              </v-row>
              <v-row v-if="customer.validation_errors.length > 1" dense>
                <v-col>
                  <v-pagination
                    v-model="page"
                    :length="total"
                    :total-visible="5"
                    @input="selectError"
                    @next="nextError"
                    @previous="previousError"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                :color="validationBtnColor"
                @click="cancel"
              >
                <span>Fix Later</span>
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog
        v-if="editAddress"
        v-model="editAddress"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Update Address</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="invalid">
                <v-col cols="12">
                  <v-alert type="error">
                    Invalid address entered.
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <google-auto-complete @google-address="addressFound"></google-auto-complete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field dense v-model="address.fname" label="First Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field dense v-model="address.lname" label="Last Name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model="address.company" label="Company"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model="address.street1" label="Street 1"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model="address.street2" label="Street 2"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model="address.city" label="City"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="address.state_id"
                    :items="states"
                    :filter="statesFilter"
                    item-text="text"
                    item-value="value"
                    label="State"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    dense
                    v-model="address.zip"
                    label="Zip"
                    :rules="zipRules"
                    maxlength="5"
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="closeEdit"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="update"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <confirmation-modal ref="confirm" />
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    CustomerValidationCard: () => import('@/views/components/CustomerValidationCard'),
    GoogleAutoComplete: () => import('@/views/components/GoogleAutoComplete')
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      options: {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        addToCart: false
      },
      customer: {},
      zipRules: [v => v.length <= 5 || 'Max 5 characters'],
      address: {
        id: null,
        fname: '',
        lname: '',
        company: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: null,
        model_type: 'customer'
      },
      editAddress: false,
      invalid: false,
      errorIndex: 0,
      page: 1,
      total: 0,
      remaining: 0
    }
  },
  computed: {
    ...mapState({
      states: state => state.stateOptions
    }),
    ...mapState('cartManagement', {
      cart: state => state.cart
    }),
    validationComplete () {
      return this.remaining === 0
    },
    validationBtnColor () {
      if (this.validationComplete) {
        return 'success'
      } else {
        return 'red'
      }
    },
    newCustomerButtonText () {
      if (this.customer.validation_errors[this.errorIndex].error === 'Incorrect Address') {
        return 'Keep Address'
      } else {
        return 'Select'
      }
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      approve: 'approveCustomerPending',
      add: 'addCustomer',
      update: 'updateCustomer',
      merge: 'mergeCustomers',
      delete: 'removeCustomer',
      updateValidation: 'updateValidation'
    }),
    ...mapActions('cartManagement', {
      addCustomerToCart: 'addCustomerToCart',
      updateCartCustomer: 'updateCartCustomer',
      removeCartCustomer: 'removeCustomer'
    }),
    ...mapActions('addressManagement', {
      updateAddress: 'updateAddress'
    }),
    init () {
      this.total = this.customer.validation_errors.length
      this.remaining = this.total
    },
    open (customer, options) {
      this.dialog = true
      this.customer = {}
      this.customer = Object.assign(this.customer, customer)
      this.options = Object.assign(this.options, options)
      this.init()
      this.resetDefaultAddress()

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    accept (val) {
      this.resolve(val)
      this.dialog = false
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    },
    async mergeCustomers (validationIndex) {
      const customer = this.customer.validation_errors[validationIndex].associated

      if (await this.$refs.confirm.open('Merge Customers?', 'Are you sure you want to merge these customer accounts?', { color: 'red' })) {
        this.merge({id: this.customer.id, payload: { duplicate_ids: [customer.id] } })
          .then((response) => {
            if (this.options.addToCart) {
              this.addCustomerToCart({id: this.cart.id, customer: {customer_id: response.id, active: true}})
                .then(() => {
                  this.$toast.success('Customer added to cart!')
                  this.resolve({success: true, customer: response})
                  this.dialog = false
                })
                .catch((error) => {
                  this.$toast.error(error.data.error)
                })
            } else {
              this.$toast.success('Customer accounts merged.')
              this.resolve({success: true, customer: response})
              this.dialog = false
            }
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      }
    },
    async deleteAddress (id) {
      if (this.addresses.length > 1) {
        if (await this.$refs.confirm.open('Delete Address?', 'Are you sure you want to delete this address?', { color: 'red' })) {
          this.delete(id)
            .then(() => {
              this.$toast.success('Address deleted!')
            })
            .catch((error) => {
              this.$toast.error(error.data.error)
            })
        }
      } else {
        this.$toast.error('Must create a new address before deleting.')
      }
    },
    async selectNewCustomer () {
      if (this.customer.validation_errors[this.errorIndex].error === 'Incorrect Address' && !this.customer.validation_errors[this.errorIndex].data) {
        if (await this.$refs.confirm.open('Use Invalid Address?', 'Are you sure you want to use this address? The use of an invalid address may cause shipping delays and/or penalties.', { color: 'red' })) {
          this.approve({customer_id: this.customer.id})
            .then((response) => {
              if (this.options.addToCart) {
                this.updateCartCustomer({id: this.cart.id, customer: {customer_id: this.customer.id, active: true}})
                  .then(() => {
                    this.$toast.success('Customer added to cart!')
                    this.resolve({success: true, customer: response})
                    this.dialog = false
                  })
                  .catch((error) => {
                    this.$toast.error(error.data.error)
                  })
              } else {
                this.resolve({success: true, customer: response})
                this.dialog = false
              }
            })
            .catch((error) => {
              this.$toast.error(error.data.error)
            })
        }
      } else {
        this.approve({customer_id: this.customer.id})
          .then((response) => {
            if (this.options.addToCart) {
              this.updateCartCustomer({id: this.cart.id, customer: {customer_id: this.customer.id, active: true}})
                .then(() => {
                  this.$toast.success('Customer added to cart!')
                  this.resolve({success: true, customer: response})
                  this.dialog = false
                })
                .catch((error) => {
                  this.$toast.error(error.data.error)
                })
            } else {
              this.resolve({success: true, customer: response})
              this.dialog = false
            }
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      }
    },
    selectExistingCustomer (validationIndex) {
      const customer = this.customer.validation_errors[validationIndex].associated

      if (this.options.addToCart) {
        this.addCustomerToCart({id: this.cart.id, customer_id: customer.id})
          .then(() => {
            this.$toast.success('Customer added to cart!')
            this.delete(this.customer.id)
              .then(() => {
                this.resolve({success: true, customer})
                this.dialog = false
              })
              .catch((error) => {
                this.$toast.error(error.data.error)
              })
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      } else {
        this.delete(this.customer.id)
          .then(() => {
            this.resolve({success: true, customer})
            this.dialog = false
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      }
    },
    selectSuggestedAddress (validationIndex) {
      const validation = this.customer.validation_errors[validationIndex]

      this.updateValidation(validation)
        .then((response) => {
          this.remaining -= 1
          if (this.validationComplete) {
            if (this.options.addToCart) {
              this.updateCartCustomer({id: this.cart.id, customer: {customer_id: this.customer.id, active: true}})
                .then(() => {
                  this.$toast.success('Customer added to cart!')
                  this.resolve({success: true, customer: response})
                  this.dialog = false
                })
                .catch((error) => {
                  this.$toast.error(error.data.error)
                })
            } else {
              this.resolve({success: true, customer: response})
              this.dialog = false
            }
          } else {
            this.customers.validation_errors[validationIndex] = []
          }
        })
        .catch((error) => {
          this.reject(error)
          this.dialog = false
        })
    },
    statesFilter (item, queryText) {
      const name = item.text.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    addressFound (address) {
      this.address.street1 = address.street1
      this.address.city = address.city
      this.address.state_id = address.state_id
      this.address.zip = address.zip
      this.address.validated = address.validated
    },
    openEditAddress (address) {
      this.address = {
        id: address.id,
        fname: address.fname,
        lname: address.lname,
        company: address.company,
        street1: address.street1,
        street2: address.street2,
        city: address.city,
        state_id: address.state_id,
        zip: address.zip,
        country: 'US',
        validated: address.validated,
        model_id: address.addressable_id,
        model_type: 'customer'
      }
      this.editAddress = true
      this.invalid = false
    },
    closeEdit () {
      this.editAddress = false
    },
    resetDefaultAddress () {
      this.address = {
        id: null,
        fname: '',
        lname: '',
        company: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: this.$route.params.id,
        model_type: 'customer'
      }
    },
    update () {
      this.invalid = false
      this.updateAddress({id: this.address.id, address: this.address})
        .then((response) => {
          if (response.validated) {
            this.customer.shipping_address = response
            this.remaining -= 1
            if (this.validationComplete) {
              if (this.options.addToCart) {
                this.updateCartCustomer({id: this.cart.id, customer: {customer_id: this.customer.id, active: true}})
                  .then(() => {
                    this.closeEdit()
                    this.$toast.success('Customer added to cart!')
                    this.resolve({success: true, customer: response})
                    this.dialog = false
                  })
                  .catch((error) => {
                    this.$toast.error(error.data.error)
                  })
              } else {
                this.closeEdit()
                this.resolve({success: true, customer: response})
                this.dialog = false
              }
            } else {
              this.customers.validation_errors[this.errorIndex] = []
              this.closeEdit()
            }
          } else {
            this.invalid = true
          }
        })
        .catch((error) => {
          this.$toast.error(error.data.error)
        })
    },
    deleteSelectedCustomer (customer_id) {
      return new Promise(resolve => {
        this.delete(customer_id)
          .then(() => {
            resolve({success: true})
          })
          .catch((error) => {
            resolve({success: false, error})
          })
      })
    },
    editCustomer () {
      this.$router.push({name: 'customer_detail', params: {id: this.customer.id}})
    },
    selectError () {
      this.errorIndex = this.page - 1
    },
    nextError () {
      //this.index += 1
      this.errorIndex = this.page - 1
    },
    previousError () {
      //this.index -= 1
      this.errorIndex = this.page - 1
    }
  }
}
</script>
<style>
    .validation_modal {
        width: 100%;
    }
    .validation_form {
        width: 100%;
        border-radius: 20px;
        border: 1px solid black;
        border-collapse: separate;
        border-spacing: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
    }
    .row_padding {
        margin: 10px;
    }
</style>
