<template>
  <v-container id="customers-pending-products-view">
    <material-card
      color="primary"
      icon="mdi-playlist-plus"
    >
      <template #title>
        Customers Pending Products
      </template>

      <v-data-table
        :headers="headers"
        :items="customers"
        :loading="loading"
        :search.sync="query.keyword"
        loading-text="Loading Batches... Please wait"
        class="mr-2 ml-2">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              placeholder="Search"
              hide-details
              v-model="query.keyword"
              clearable
              append-icon="mdi-magnify"
              single-line
              dense />
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.customer`]="{ item }">
          {{ item.customer.fname }} {{ item.customer.lname }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="addProduct(item.customer)"
                icon
              >
                <v-icon>
                  mdi-playlist-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Add products to customer</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="addToCart(item.customer)"
                icon
              >
                <v-icon>
                  mdi-cart-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Add customer to order list</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                @click="$router.push({ name: 'customer_detail', params: { id: item.customer.id } })"
                icon
              >
                <v-icon>mdi-account-search</v-icon>
              </v-btn>
            </template>
            <span>View customer record</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          No customers pending products found.
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'

export default {
  name: 'CustomersPending',
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      toggle: true,
      query: {
        contractor_id: '',
        page: 1,
        limit: 15,
        keyword: '',
        load_contractor: false,
        load_addresses: false,
        batch_date: '',
        load_pending: true
      },
      customers: [],
      loading: false,
      selected: []
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('cartManagement', {
      cart: state => state.cart
    }),
    headers () {
      return [
        { text: 'ID', align: ' d-none', value: 'id' },
        { text: 'Name', value: 'customer', width: 450 },
        { text: 'Date Added', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    },
    hasCart () {
      return Object.entries(this.cart).length > 0
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      update: 'updateCustomer',
      fetch: 'fetchCustomerBatch'
    }),
    ...mapMutations('customerManagement', {
      setCustomer: 'setCustomer'
    }),
    ...mapActions('cartManagement', {
      addCustomerToCart: 'addCustomerToCart'
    }),
    ...mapMutations('cartManagement', {
      setCartCustomer: 'setSelectedCustomer'
    }),
    ...mapMutations('itemManagement', {
      assignProduct: 'setAssignStatus'
    }),
    loadCustomers () {
      this.loading = true

      this.fetch({id: this.$route.params.id, type: {batch_type: 'products'}})
        .then((response) => {
          this.customers = response
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$toast.error(error.data.error)
        })
    },
    addToCart (customer) {
      if (this.hasCart) {
        this.addCustomerToCart({cart_id: this.cart.id, customer_id: customer.id})
          .then((response) => {
            this.setCartCustomer(response)
            this.$router.push({ name: 'products' })
          })
          .catch((error) => {
            this.$toast.error(error.data.error)
          })
      } else {
        this.$toast.error('No cart found. Please select cart option from orders page.')
      }
    },
    addProduct (customer) {
      this.setCustomer(customer)
      this.assignProduct(true)
      this.$router.push({name: 'products'})
    },
    showSuccess () {
      this.$toast.success('Success message')
    },
    showError () {
      this.$toast.error('Error message')
    }
  },
  created () {
    this.loadCustomers()
  }
}
</script>

<style scoped>

</style>
