import api from './request'

export function add () {
  return api({
    url: '/contractor_cart',
    method: 'post'
  })
}

export function show (id) {
  return api({
    url: `/contractor_cart/${id}`,
    method: 'get'
  })
}

export function previous () {
  return api({
    url: '/contractor_cart/previous',
    method: 'get'
  })
}

export function addCustomer (data) {
  return api({
    url: '/contractor_cart_customer',
    method: 'post',
    data
  })
}

export function addProduct (data) {
  return api({
    url: '/contractor_cart_customer_item',
    method: 'post',
    data
  })
}

export function update (id, data) {
  return api({
    url: `/contractor_cart/${id}`,
    method: 'put',
    data
  })
}

export function updateCustomer (id, data) {
  return api({
    url: `/contractor_cart_customer/${id}`,
    method: 'put',
    data
  })
}

export function updateProduct (id, data) {
  return api({
    url: `/contractor_cart_customer_item/${id}`,
    method: 'put',
    data
  })
}

export function destroy (id) {
  return api({
    url: `/contractor_cart/${id}`,
    method: 'delete'
  })
}

export function destroyCustomer (data) {
  return api({
    url: '/contractor_cart_customer/delete',
    method: 'post',
    data
  })
}

export function destroyProduct (id) {
  return api({
    url: `/contractor_cart_customer_item/${id}`,
    method: 'delete'
  })
}

export function upload (data) {
  return api({
    url: '/contractor_cart/upload',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
