var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cancel-dialog" } },
    [
      _c(
        "v-dialog",
        {
          style: { zIndex: _vm.options.zIndex },
          attrs: { "max-width": _vm.options.width },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.cancel($event)
            },
            "click:outside": _vm.cancel
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    dark: "",
                    color: _vm.options.color,
                    dense: "",
                    flat: ""
                  }
                },
                [
                  _c("v-toolbar-title", { staticClass: "white--text" }, [
                    _vm._v("Return Request Form")
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-container",
                    [
                      _c("v-data-table", {
                        staticClass: "mb-4 elevation-1",
                        attrs: {
                          items: _vm.filteredProducts,
                          headers: _vm.headers,
                          "item-key": "id",
                          "show-select": "",
                          dense: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "top",
                            fn: function() {
                              return [
                                _c(
                                  "v-toolbar",
                                  { attrs: { flat: "" } },
                                  [
                                    _c("v-toolbar-title", [
                                      _vm._v("Select Products For Return")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "item.name",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$utils.productName(item.product)
                                    ) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.price",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(" $" + _vm._s(item.list_price) + " ")
                              ]
                            }
                          },
                          {
                            key: "item.return_quantity",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("v-input", {
                                  model: {
                                    value: item.return_quantity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        item,
                                        "return_quantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "item.return_quantity"
                                  }
                                })
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectedProducts,
                          callback: function($$v) {
                            _vm.selectedProducts = $$v
                          },
                          expression: "selectedProducts"
                        }
                      }),
                      _c("v-divider", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedProducts.length > 0,
                            expression: "selectedProducts.length > 0"
                          }
                        ],
                        staticClass: "my-4"
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedProducts.length > 0,
                              expression: "selectedProducts.length > 0"
                            }
                          ],
                          staticClass: "pt-4"
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.reasonOptions,
                              label: "Select a reason for your return request",
                              required: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.selectedReason,
                              callback: function($$v) {
                                _vm.selectedReason = $$v
                              },
                              expression: "selectedReason"
                            }
                          }),
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              "auto-grow": "",
                              label: "Description",
                              hint:
                                "Please provide a brief description of the situation.",
                              "persistent-hint": "",
                              rows: "3",
                              required: ""
                            },
                            model: {
                              value: _vm.returnComment,
                              callback: function($$v) {
                                _vm.returnComment = $$v
                              },
                              expression: "returnComment"
                            }
                          }),
                          _c("v-file-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedReason === 4,
                                expression: "selectedReason === 4"
                              }
                            ],
                            ref: "file-input",
                            attrs: {
                              label: "Choose images...",
                              accept: "image/*",
                              hint:
                                "Please provide image(s) of shipping label and damage (if present). Images are required to speed up the return process.",
                              "persistent-hint": "",
                              multiple: "",
                              "small-chips": ""
                            },
                            on: { change: _vm.preview },
                            model: {
                              value: _vm.rawImages,
                              callback: function($$v) {
                                _vm.rawImages = $$v
                              },
                              expression: "rawImages"
                            }
                          }),
                          _vm.images.length > 0
                            ? _c("div", { staticClass: "mt-4" }, [
                                _c("span", { staticClass: "subtitle" }, [
                                  _vm._v("Preview:")
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-4 mt-4 bg-dark overflow-auto image-preview"
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      _vm._l(_vm.images, function(
                                        image,
                                        index
                                      ) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: index,
                                            staticClass: "d-flex child-flex",
                                            attrs: { cols: "4" }
                                          },
                                          [
                                            _c("v-img", {
                                              staticClass: "grey lighten-2",
                                              attrs: {
                                                src: image.data,
                                                "aspect-ratio": "1"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "placeholder",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "fill-height ma-0",
                                                            attrs: {
                                                              align: "center",
                                                              justify: "center"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-progress-circular",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    "",
                                                                  color:
                                                                    "grey lighten-5"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.cancel($event)
                        }
                      }
                    },
                    [_vm._v("CANCEL")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary darken-1",
                        text: "",
                        disabled: !_vm.isReturnFormValid
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.submit($event)
                        }
                      }
                    },
                    [_vm._v("SUBMIT")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }