import { unsubscribe } from '@/api/reminder'

export default {
  unsubscribeAll (context, payload) {
    return new Promise((resolve, reject) => {
      unsubscribe(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
