var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        "clipped-left": "",
        "clipped-right": "",
        flat: "",
        color: "blue darken-3"
      }
    },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "hidden-md-and-up",
        on: { click: _vm.toggleDrawer }
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "dashboard" } } },
                [
                  _c("v-img", {
                    staticClass: "mr-5",
                    attrs: {
                      src: require("../../../assets/fod_logo.png"),
                      "max-height": "60",
                      "max-width": "242",
                      contain: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.$vuetify.goTo(0)
                      }
                    }
                  })
                ],
                1
              ),
              _c("v-spacer"),
              _vm._l(_vm.links, function(link, i) {
                return _c(
                  "v-btn",
                  _vm._b(
                    {
                      key: i,
                      staticClass: "hidden-sm-and-down text-decoration-none",
                      attrs: { text: "", color: "white" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: link.name })
                        }
                      }
                    },
                    "v-btn",
                    link,
                    false
                  ),
                  [_vm._v(" " + _vm._s(link.text) + " ")]
                )
              }),
              _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    left: "",
                    "min-width": "200",
                    "offset-y": "",
                    origin: "top right",
                    transition: "scale-transition"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var attrs = ref.attrs
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    "min-width": "0",
                                    text: "",
                                    color: "white"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Account ")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-list",
                    { attrs: { tile: false, flat: "", nav: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "company" })
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("Company")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "billing" })
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("Billing")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "users" })
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("Users")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "files" })
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("Files")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "settings" })
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("Settings")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "reports" })
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("Reports")])],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-2 mt-2" }),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.logoutUser } },
                        [_c("v-list-item-title", [_vm._v("Logout")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }