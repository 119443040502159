<template>
<v-footer
  app
  id="main-footer"
  color="primary"
>
  <v-container>
    <v-row align="center">
      <v-col
        class="text-center"
        cols="6"
        md="auto"
        >
        <v-btn text @click="openContact" class="text-decoration-none cursor-pointer text-uppercase text-caption font-weight-regular white--text">
          Contact Us
        </v-btn>
      </v-col>
      <v-spacer class="hidden-sm-and-down" />
      <v-col
        cols="12"
        md="auto"
        >
        <div class="body-1 font-weight-light pt-6 pt-md-0 text-center text-caption white--text">
          &copy; {{ (new Date()).getFullYear() }}, Powered by <img src="../../assets/ff_footer_logo.png" alt="FilterFetch">
        </div>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="showContactDialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Contact Us</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-alert v-if="showContactAlert" :type="contactAlertType">{{ contactAlertMessage }}</v-alert>
          <v-form ref="contactUsForm" v-model="contactFormValid" lazy-validation>
            <v-text-field
              v-model="contact.name"
              :rules="nameRules"
              label="Name"
              disabled
              required></v-text-field>
            <v-text-field
              v-model="contact.email"
              :rules="emailRules"
              label="E-mail"
              disabled
              required></v-text-field>
            <v-textarea
              v-model="contact.message"
              auto-grow
              :counter="500"
              :rules="messageRules"
              label="Message"
              required></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeContact">
          Close
        </v-btn>
        <v-btn
          :loading="sendingContactForm"
          :disabled="!contactFormValid"
          color="blue darken-1"
          text
          @click="sendContact">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MainFooter',
  data () {
    return {
      links: [
        /*{ href: '#', text: 'Privacy Policy' },
        { href: '#', text: 'Terms Of Service' },*/
        { href: '#', text: 'Contact Us' }
      ],
      sendingContactForm: false,
      showContactDialog: false,
      contactFormValid: true,
      contact: {
        name: '',
        message: '',
        email: '',
        site_id: 3,
        type: 'contact'
      },
      showContactAlert: false,
      contactAlertType: '',
      contactAlertMessage: '',
      nameRules: [v => !!v || 'Name is required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      messageRules: [
        v => !!v || 'Message is required',
        v => (v && v.length <= 500) || 'Message cannot be more than 500 characters',
        v => (v && v.length >= 10) || 'Message must be more than 10 characters'
      ]
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    })
  },
  methods: {
    ...mapActions({
      send: 'sendContactUs'
    }),
    openContact () {
      this.contact.name = this.user.name
      this.contact.email = this.user.email
      this.contact.message = ''
      this.showContactDialog = true
    },
    closeContact () {
      this.showContactDialog = false
      this.contact.message = ''
    },
    sendContact () {
      if (this.$refs.contactUsForm.validate()) {
        this.sendingContactForm = true
        this.send(this.contact)
          .then((response) => {
            this.contactAlertType = 'success'
            this.contactAlertMessage = response.message
            this.showContactAlert = true
            this.contact.message = ''
          })
          .catch((error) => {
            this.contactAlertType = 'error'
            this.contactAlertMessage = error.data ? error.data.error : error
            this.showContactAlert = true
          })
          .finally(() => {
            this.sendingContactForm = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
