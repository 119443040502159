<template>
  <v-app>
    <Header />

    <drawer />

    <v-main id="home">
      <v-container fluid>
        <router-view :key="$route.path" />
      </v-container>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
export default {
  components: {
    Footer: () => import('@/layouts/components/MainFooter'),
    Header: () => import('@/layouts/components/navbar/Header'),
    Drawer: () => import('@/layouts/components/sidenav/Drawer')
  }
}
</script>

<style scoped>

</style>
