import { render, staticRenderFns } from "./OrderReturnsPanel.vue?vue&type=template&id=6eae22d6&scoped=true&"
import script from "./OrderReturnsPanel.vue?vue&type=script&lang=js&"
export * from "./OrderReturnsPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eae22d6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VChip,VDataTable,VIcon,VOverlay,VProgressCircular,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/home/forge/demo.filterondemand.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6eae22d6')) {
      api.createRecord('6eae22d6', component.options)
    } else {
      api.reload('6eae22d6', component.options)
    }
    module.hot.accept("./OrderReturnsPanel.vue?vue&type=template&id=6eae22d6&scoped=true&", function () {
      api.rerender('6eae22d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orders/components/OrderReturnsPanel.vue"
export default component.exports