<template>
  <div class="order-details-view">
    <v-container v-if="!loading">
      <v-row>
        <v-col>
          <v-card elevation="0">
            <div>
              <span class="title">Order# {{ order.id }} Details</span> <br>
              <span class="subtitle-2">Order Date: {{ order.created_at }}</span> <br>
              <span class="subtitle-2">Status: {{ orderStatusMap[order.status] }}</span> <br>
              <span class="subtitle">PO#: {{ order.customer_po }}</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <v-row>
            <v-col cols="12">
              <CustomerAddressPanel />
            </v-col>

            <v-col cols="12">
              <OrderItemsPanel />
            </v-col>

            <v-col v-if="order.returns.length > 0" cols="12">
              <OrderReturnsPanel />
            </v-col>

            <v-col cols="12">
              <ShippingPanel />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <OrderActionsPanel />
            </v-col>

            <v-col cols="12">
              <PaymentMethodPanel />
            </v-col>

            <v-col cols="12">
              <OrderNotesPanel :key="order.comments.length" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'OrderDetails',
  components: {
    CustomerAddressPanel: () => import('./components/CustomerAddressPanel'),
    OrderItemsPanel: () => import('./components/OrderItemsPanel'),
    OrderActionsPanel: () => import('./components/OrderActionsPanel'),
    OrderNotesPanel: () => import('./components/OrderNotesPanel'),
    PaymentMethodPanel: () => import('./components/PaymentMethodPanel'),
    ShippingPanel: () => import('./components/ShippingPanel'),
    OrderReturnsPanel: () => import('./components/OrderReturnsPanel')
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    }),
    ...mapState({
      orderStatusMap: state => state.orderStatusMap
    }),
    editable () {
      return this.order.status === 'PENDING'
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      fetch: 'fetch'
    }),
    ...mapActions({
      'fetchStates': 'fetchStates'
    }),
    loadStates () {
      this.fetchStates().then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    loadOrder () {
      this.fetch(this.$route.params.id)
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  },
  created () {
    this.loadStates()
    this.loadOrder()
  }
}
</script>

<style scoped>

</style>
