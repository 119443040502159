import api from './request'

export function login (data) {
  return api({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export function broadcasting (data) {
  return api({
    url: '/broadcasting/auth',
    method: 'post',
    data
  })
}

export function getUser () {
  return api({
    url: '/user',
    method: 'get'
  })
}

export function logout () {
  return api({
    url: '/auth/apiLogout',
    method: 'post'
  })
}

export function forgot (email) {
  return api({
    url: '/auth/forgot',
    method: 'post',
    data: email
  })
}

export function reset (data) {
  return api({
    url: '/auth/reset',
    method: 'post',
    data
  })
}

export function requestAccount (data) {
  return api({
    url: '/auth/request-account',
    method: 'post',
    data
  })
}

export function getUserSettings (id) {
  return api({
    url: `/users/settings/${id}`,
    method: 'get'
  })
}

export function updateUserSettings (id, data) {
  return api({
    url: `/users/settings/${id}`,
    method: 'put',
    data
  })
}
