<template>
  <div id="order-address-panel">
    <panel>
      <template #title>
        Customer Address - <span class="px-2 subtitle text-decoration-none"><router-link :to="{ name: 'customer_detail', params: { id: order.customer.id } }">View Customer Record</router-link></span>
      </template>

      <template #content>
        <v-form>
          <v-container fluid>
            <v-row dense>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  :disabled="!editable"
                  v-model="addressLocal.fname"
                  label="First Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  :disabled="!editable"
                  v-model="addressLocal.lname"
                  label="Last Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  :disabled="!editable"
                  v-model="addressLocal.street1"
                  label="Street 1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  :disabled="!editable"
                  v-model="addressLocal.street2"
                  label="Street 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :disabled="!editable"
                  v-model="addressLocal.city"
                  label="City"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-autocomplete
                  :disabled="!editable"
                  v-model="addressLocal.state_id"
                  :items="states"
                  :filter="statesFilter"
                  item-text="name"
                  item-value="id"
                  label="State"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :disabled="!editable"
                  v-model="addressLocal.zip"
                  label="Zip Code"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <div v-if="editable" class="d-flex justify-end">
              <v-btn
                :disabled="!isDirty"
                color="primary"
                @click="updateAddress"
              >
                UPDATE
              </v-btn>
            </div>
          </v-container>
        </v-form>
      </template>
    </panel>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CustomerAddressPanel',
  components: {
    Panel: () => import('@/components/Panel')
  },
  data () {
    return {
      defaultAddress: {},
      addressLocal: {}
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    }),
    ...mapState('addressManagement', {
      address: state => state.address
    }),
    ...mapState({
      states: state => state.states
    }),
    editable () {
      return this.order.status === 'PENDING' || this.order.status === 'PENDING_APPROVAL' || this.order.status === 'UNPAID'
    },
    isDirty () {
      return JSON.stringify(this.defaultAddress) !== JSON.stringify(this.addressLocal)
    }
  },
  methods: {
    ...mapActions('addressManagement', {
      fetch: 'fetchAddress',
      update: 'updateAddress'
    }),
    loadAddress () {
      this.fetch(this.order.shipping_address_id)
        .then((response) => {
          this.defaultAddress = {
            id: response.id,
            fname: response.fname,
            lname: response.lname,
            street1: response.street1,
            street2: response.street2,
            city: response.city,
            state_id: response.state_id,
            zip: response.zip,
            country: response.country,
            validated: response.validated,
            model_type: 'customer'
          }
          this.addressLocal = Object.assign({}, this.defaultAddress)
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    statesFilter (item, queryText) {
      const name = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    updateAddress () {
      this.update({id: this.addressLocal.id, address: this.addressLocal})
        .then(() => {
          this.$toast.success('Address updated!')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  },
  created () {
    this.loadAddress()
  }
}
</script>

<style scoped>

</style>
