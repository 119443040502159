<template>
  <div id="payment-method-panel">
    <panel :content="!isNet30">
      <template #title>
        Payment Method
      </template>

      <div v-if="!isNet30">
        <div class="v-card__subtitle pb-0">{{ order.transactions[0].payment.card_name }}</div>
      </div>
      <div v-else>
        <div class="v-card__subtitle">Net30</div>
      </div>
      <template v-if="!isNet30" #content>
        <div>{{ order.transactions[0].payment.card_type }}: {{ order.transactions[0].payment.card_number }}</div>
        <div>{{ order.transactions[0].payment.card_expiry_formatted }}</div>
      </template>
    </panel>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PaymentMethodPanel',
  components: {
    Panel: () => import('@/components/Panel')
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    }),
    isNet30 () {
      return this.order.payment_method === 'NET30'
    }
  }
}
</script>

<style scoped>

</style>
