var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-summary-component" } },
    [
      _vm.selectedCustomer
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                app: "",
                clipped: "",
                fixed: "",
                right: "",
                width: "325"
              },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-space-between": "",
                    column: "",
                    "fill-height": ""
                  }
                },
                [
                  _c(
                    "v-list",
                    { attrs: { flat: "", subheader: "" } },
                    [
                      _c(
                        "div",
                        [
                          _c("v-subheader", [
                            _vm._v("Select Order List Customer")
                          ]),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.cart.customers,
                                      label: "Select Customer",
                                      "item-value": "id",
                                      dense: "",
                                      "return-object": "",
                                      outlined: ""
                                    },
                                    on: { change: _vm.selectCustomer },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.customer.fname) +
                                                  " " +
                                                  _vm._s(item.customer.lname) +
                                                  " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-subtitle",
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.customer.fname
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            item.customer.lname
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("v-spacer"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.customer
                                                              .shipping_address
                                                              .street1
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            item.customer
                                                              .shipping_address
                                                              .street2
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.customer
                                                              .shipping_address
                                                              .city
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            item.customer
                                                              .shipping_address
                                                              .state
                                                              .abbreviation
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            item.customer
                                                              .shipping_address
                                                              .zip
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "append-item",
                                          fn: function() {
                                            return [
                                              _c("v-divider", {
                                                staticClass: "mb-2"
                                              }),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-subtitle",
                                                    { staticClass: "ml-5" },
                                                    [
                                                      _vm._v(
                                                        " Customer not found? "
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            text: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.addNewCustomer
                                                          }
                                                        },
                                                        [_vm._v("Add Customer")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3287540743
                                    ),
                                    model: {
                                      value: _vm.selectedCustomer,
                                      callback: function($$v) {
                                        _vm.selectedCustomer = $$v
                                      },
                                      expression: "selectedCustomer"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-subheader", [_vm._v("Order Summary")]),
                      _c("v-divider", { staticClass: "p-0 m-0" }),
                      _vm.showOrderSummary
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-list-item",
                                { attrs: { "three-line": "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.cartCustomer.customer.fname
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.cartCustomer.customer.lname
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.cartCustomer.customer
                                              .shipping_address.street1
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.cartCustomer.customer
                                                .shipping_address.street2
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.cartCustomer.customer
                                              .shipping_address.city
                                          ) +
                                            ", " +
                                            _vm._s(
                                              _vm.cartCustomer.customer
                                                .shipping_address.state.name
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.cartCustomer.customer
                                                .shipping_address.zip
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "p-0 m-0" }),
                              _vm.cartCustomer.items.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _vm._l(_vm.cartCustomer.items, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "v-list-item",
                                          { key: index },
                                          [
                                            _c("v-list-item-content", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.quantity) +
                                                  " x " +
                                                  _vm._s(
                                                    _vm._f("cartProduct")(
                                                      item.product.name
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { size: "small" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeItems(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("mdi-delete")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      _c("v-divider", {
                                        staticClass: "p-0 m-0"
                                      }),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c("v-list-item-content", [
                                            _vm._v(" SubTotal: "),
                                            _c("br"),
                                            _vm._v(" Sales Tax: "),
                                            _c("br"),
                                            _vm.cartCustomer.delivery_fee > 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    "Retail Delivery Fees: "
                                                  ),
                                                  _c("br")
                                                ])
                                              : _vm._e(),
                                            _vm._v(" Total: ")
                                          ]),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("money-format", {
                                                attrs: {
                                                  value: _vm.subtotal,
                                                  locale: "en",
                                                  "currency-code": "USD"
                                                }
                                              }),
                                              _c("money-format", {
                                                attrs: {
                                                  value: parseFloat(_vm.tax),
                                                  locale: "en",
                                                  "currency-code": "USD"
                                                }
                                              }),
                                              _vm.cartCustomer.delivery_fee > 0
                                                ? _c("money-format", {
                                                    attrs: {
                                                      value: parseFloat(
                                                        _vm.cartCustomer
                                                          .delivery_fee
                                                      ),
                                                      locale: "en",
                                                      "currency-code": "USD"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _c("money-format", {
                                                attrs: {
                                                  value: _vm.total,
                                                  locale: "en",
                                                  "currency-code": "USD"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.assignedProducts.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mb-2 mt-2",
                                          attrs: { outlined: "" }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(
                                              "Currently Assigned Products"
                                            )
                                          ]),
                                          _c(
                                            "v-list",
                                            { attrs: { flat: "", dense: "" } },
                                            [
                                              _vm._l(
                                                _vm.assignedProducts,
                                                function(product, index) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      { key: product.id },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  product.name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  icon: "",
                                                                  color:
                                                                    "primary",
                                                                  disabled: _vm.productInCart(
                                                                    product.id
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addToCart(
                                                                      product
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-cart-plus"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    !_vm.isLast(
                                                      _vm.assignedProducts,
                                                      index
                                                    )
                                                      ? _c("v-divider", {
                                                          key: index,
                                                          staticClass:
                                                            "ml-4 mr-4"
                                                        })
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.filteredScheduledOrders.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mb-2 mt-2",
                                          attrs: { outlined: "" }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(
                                              "Currently Scheduled Products"
                                            )
                                          ]),
                                          _c(
                                            "v-list",
                                            { attrs: { flat: "", dense: "" } },
                                            [
                                              _vm._l(
                                                _vm.filteredScheduledOrders,
                                                function(product, index) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      { key: product.id },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  product.name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    !_vm.isLast(
                                                      _vm.filteredScheduledOrders,
                                                      index
                                                    )
                                                      ? _c("v-divider", {
                                                          key: index,
                                                          staticClass:
                                                            "ml-4 mr-4"
                                                        })
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.showOrderSummary
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column align-end" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-3",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    outlined: "",
                                    width: "300"
                                  },
                                  on: { click: _vm.backToOrders }
                                },
                                [_vm._v(" Back To Order List ")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("add-customer-modal", { ref: "addCustomerModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }