<template>
  <div>
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      persistent
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-select
          class="mt-5"
          v-model="selectedContractor"
          :items="contractors"
          label="Select Customer"
          item-value="id"
          item-text="name"
          dense
          outlined
        >
        </v-select>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
<!--          <v-btn color="red darken-1" text @click.native="cancel">CANCEL</v-btn>-->
          <v-btn color="primary darken-1" text @click.native="accept" :disabled="!selectedContractor">SELECT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ContractorSelectModal',
  data () {
    return {
      selectedContractor: null,
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200
      }
    }
  },
  computed: {
    ...mapState('contractorManagement', {
      contractors: state => state.contractors
    })
  },
  methods: {
    open (title, options) {
      this.selectedContractor = null
      this.dialog = true
      this.title = title
      this.options = Object.assign(this.options, options)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    accept () {
      this.resolve(this.selectedContractor)
      this.dialog = false
    },
    cancel () {
      this.reject()
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
