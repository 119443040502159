var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "products-list-view" } },
    [
      _c(
        "div",
        { attrs: { id: "product-filter-component" } },
        [
          _c(
            "v-navigation-drawer",
            {
              attrs: { app: "", clipped: "", fixed: "", permanent: "" },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-space-between": "",
                    column: "",
                    "fill-height": ""
                  }
                },
                [
                  _c(
                    "v-list",
                    { attrs: { flat: "", subheader: "" } },
                    [
                      _c("v-subheader", [_vm._v("Category")]),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.categoryOptions,
                                  label: "Select Category",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: ""
                                },
                                on: { change: _vm.selectCategory },
                                model: {
                                  value: _vm.query.category_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "category_id", $$v)
                                  },
                                  expression: "query.category_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.query.category_id !== 15
                        ? _c(
                            "div",
                            [
                              _c("v-subheader", [_vm._v("Size")]),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Length",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          items: _vm.lengthOptions
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.dirty = true
                                          }
                                        },
                                        model: {
                                          value: _vm.query.length,
                                          callback: function($$v) {
                                            _vm.$set(_vm.query, "length", $$v)
                                          },
                                          expression: "query.length"
                                        }
                                      }),
                                      _c("v-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: "Width",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          items: _vm.widthOptions
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.dirty = true
                                          }
                                        },
                                        model: {
                                          value: _vm.query.width,
                                          callback: function($$v) {
                                            _vm.$set(_vm.query, "width", $$v)
                                          },
                                          expression: "query.width"
                                        }
                                      }),
                                      _c("v-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: "Thickness",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          items: _vm.thicknessOptions
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.dirty = true
                                          }
                                        },
                                        model: {
                                          value: _vm.query.thickness,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.query,
                                              "thickness",
                                              $$v
                                            )
                                          },
                                          expression: "query.thickness"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-subheader", [_vm._v("Manufacturer")]),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.sortedManufacturers,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Manufacturer",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: ""
                                },
                                on: {
                                  change: function($event) {
                                    _vm.dirty = true
                                  }
                                },
                                model: {
                                  value: _vm.query.mfg_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "mfg_id", $$v)
                                  },
                                  expression: "query.mfg_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.query.category_id !== 15
                        ? _c(
                            "div",
                            [
                              _c("v-subheader", [_vm._v("Merv Rating")]),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.mervOptions,
                                          label: "Select Merv Rating",
                                          outlined: "",
                                          multiple: "",
                                          "hide-details": "",
                                          dense: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.dirty = true
                                          }
                                        },
                                        model: {
                                          value: _vm.query.mervs,
                                          callback: function($$v) {
                                            _vm.$set(_vm.query, "mervs", $$v)
                                          },
                                          expression: "query.mervs"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dirty ? _c("v-divider") : _vm._e(),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _vm.dirty
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", outlined: "" },
                                      on: { click: _vm.handleFilter }
                                    },
                                    [_vm._v(" SEARCH ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _vm.dirty
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "red", outlined: "" },
                                      on: { click: _vm.resetFilter }
                                    },
                                    [_vm._v(" RESET FILTER ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        color: "primary",
                        "justify-end": "",
                        exact: "",
                        outlined: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.showCustomRequest = true
                        }
                      }
                    },
                    [_vm._v(" REQUEST CUSTOM FILTER ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            [
              _c("v-data-table", {
                staticClass: "mr-2 ml-2",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.products,
                  search: _vm.query.keyword,
                  loading: _vm.loading,
                  "items-per-page": _vm.query.limit,
                  page: _vm.query.page,
                  "server-items-length": _vm.total,
                  options: _vm.options
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "", color: "white" } },
                          [
                            _c("v-toolbar-title", [_vm._v("Products")]),
                            _c("v-divider", {
                              staticClass: "mx-4",
                              attrs: { inset: "", vertical: "" }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                placeholder: "Search",
                                "hide-details": "",
                                clearable: "",
                                "append-icon": "mdi-magnify",
                                dense: ""
                              },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleSearch($event)
                                },
                                "click:clear": _vm.clearSearch
                              },
                              model: {
                                value: _vm.query.keyword,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "keyword", $$v)
                                },
                                expression: "query.keyword"
                              }
                            }),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.image",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("v-img", {
                          attrs: {
                            src: item.image,
                            height: 90,
                            width: 90,
                            contain: ""
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "primary--text",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.viewProduct(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$utils.itemName(item)))]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.mfg_name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$utils.productMfgName(item.mfg_name)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.list_price",
                    fn: function(ref) {
                      var item = ref.item
                      return [_vm._v(" $" + _vm._s(item.list_price) + " ")]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.assignProduct
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: { icon: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.assignProductToCustomer(item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-account-plus")])],
                              1
                            )
                          : _vm._e(),
                        _vm.showAddToCartButton
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "",
                                  color: "primary",
                                  disabled: _vm.isDisabled
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addSchedule(item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-cart-plus")])],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.showSummary
        ? _c("order-summary", { on: { "add-cart-item": _vm.addFromSummary } })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.orderScheduleDialog,
            callback: function($$v) {
              _vm.orderScheduleDialog = $$v
            },
            expression: "orderScheduleDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("h1", { staticClass: "pt-5 text-center font-black" }, [
                  _vm._v("Schedule Shipment")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      label: "Quantity",
                      outlined: "",
                      min: "1"
                    },
                    model: {
                      value: _vm.quantity,
                      callback: function($$v) {
                        _vm.quantity = $$v
                      },
                      expression: "quantity"
                    }
                  }),
                  _c(
                    "v-menu",
                    {
                      ref: "menu",
                      attrs: {
                        "close-on-content-click": false,
                        "return-value": _vm.shipDate,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto"
                      },
                      on: {
                        "update:returnValue": function($event) {
                          _vm.shipDate = $event
                        },
                        "update:return-value": function($event) {
                          _vm.shipDate = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: "Ship Date",
                                        "append-icon": "mdi-calendar",
                                        outlined: "",
                                        readonly: ""
                                      },
                                      model: {
                                        value: _vm.shipDate,
                                        callback: function($$v) {
                                          _vm.shipDate = $$v
                                        },
                                        expression: "shipDate"
                                      }
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.shippingMenu,
                        callback: function($$v) {
                          _vm.shippingMenu = $$v
                        },
                        expression: "shippingMenu"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          scrollable: "",
                          min: _vm.minDate
                        },
                        on: {
                          input: function($event) {
                            return _vm.$refs.menu.save(_vm.shipDate)
                          }
                        },
                        model: {
                          value: _vm.shipDate,
                          callback: function($$v) {
                            _vm.shipDate = $$v
                          },
                          expression: "shipDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-select", {
                    attrs: {
                      items: _vm.frequencyOptions,
                      outlined: "",
                      label: "Select shipment frequency",
                      "menu-props": { maxHeight: 350 }
                    },
                    model: {
                      value: _vm.frequency,
                      callback: function($$v) {
                        _vm.frequency = $$v
                      },
                      expression: "frequency"
                    }
                  }),
                  _vm.frequency < 0
                    ? _c("v-text-field", {
                        attrs: {
                          type: "number",
                          label: "Custom frequency (days)",
                          outlined: ""
                        },
                        model: {
                          value: _vm.customFrequency,
                          callback: function($$v) {
                            _vm.customFrequency = $$v
                          },
                          expression: "customFrequency"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeOrderScheduleDialog }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.validAddToCart,
                        color: "blue darken-1",
                        text: ""
                      },
                      on: { click: _vm.addToCart }
                    },
                    [_vm._v("Add")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showCustomRequest,
            callback: function($$v) {
              _vm.showCustomRequest = $$v
            },
            expression: "showCustomRequest"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Request Custom Filter")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        "close-text": "Close",
                        dark: "",
                        dismissible: "",
                        type: _vm.alertType
                      },
                      model: {
                        value: _vm.customAlert,
                        callback: function($$v) {
                          _vm.customAlert = $$v
                        },
                        expression: "customAlert"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "customRequestForm",
                      model: {
                        value: _vm.customRequestValid,
                        callback: function($$v) {
                          _vm.customRequestValid = $$v
                        },
                        expression: "customRequestValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Length", type: "number", min: "0" },
                        model: {
                          value: _vm.customFilter.length,
                          callback: function($$v) {
                            _vm.$set(_vm.customFilter, "length", $$v)
                          },
                          expression: "customFilter.length"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Width", type: "number", min: "0" },
                        model: {
                          value: _vm.customFilter.width,
                          callback: function($$v) {
                            _vm.$set(_vm.customFilter, "width", $$v)
                          },
                          expression: "customFilter.width"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Thickness", type: "number", min: "0" },
                        model: {
                          value: _vm.customFilter.thickness,
                          callback: function($$v) {
                            _vm.$set(_vm.customFilter, "thickness", $$v)
                          },
                          expression: "customFilter.thickness"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          items: ["8", "11", "13"],
                          label: "Merv Rating",
                          multiple: ""
                        },
                        model: {
                          value: _vm.customFilter.merv,
                          callback: function($$v) {
                            _vm.$set(_vm.customFilter, "merv", $$v)
                          },
                          expression: "customFilter.merv"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: { click: _vm.closeRequest }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.customRequestValid,
                        color: "primary",
                        text: "",
                        loading: _vm.customRequestLoader
                      },
                      on: { click: _vm.submitCustomRequest }
                    },
                    [_vm._v(" Submit ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      Object.entries(_vm.selectedProduct).length > 0
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "900", height: "600" },
              model: {
                value: _vm.showProductDetails,
                callback: function($$v) {
                  _vm.showProductDetails = $$v
                },
                expression: "showProductDetails"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-container",
                    { staticClass: "p-5" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-img", {
                                staticClass: "p-2",
                                attrs: {
                                  contain: "",
                                  src: _vm.selectedProduct.image
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-card-title", [
                                    _c("span", { staticClass: "h6" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$utils.itemName(
                                            _vm.selectedProduct
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.selectedProduct.list_price
                                              )
                                          )
                                        ]
                                      ),
                                      _vm._v(" from "),
                                      _c(
                                        "span",
                                        { staticClass: "primary--text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$utils.productMfgName(
                                                _vm.selectedProduct.mfg_name
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "6",
                                        sm: "6",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          dense: "",
                                          outlined: "",
                                          label: "Qty",
                                          min: "1"
                                        },
                                        model: {
                                          value: _vm.quantity,
                                          callback: function($$v) {
                                            _vm.quantity = $$v
                                          },
                                          expression: "quantity"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "6",
                                        sm: "6",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [
                                      _vm.assignProduct
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                size: "medium",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.assignProductToCustomer(
                                                    _vm.selectedProduct
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Assign Product ")]
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                size: "medium",
                                                color: "primary",
                                                disabled: !_vm.showAddToCartButton
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addSchedule(
                                                    _vm.selectedProduct
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" ADD TO ORDER ")]
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "border-top" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "4", md: "4" } },
                                    [
                                      _c("strong", [_vm._v("Case Quantity")]),
                                      _c(
                                        "p",
                                        { staticClass: "text--secondary" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedProduct.purchase_qty
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "4", md: "4" } },
                                    [
                                      _c("strong", [_vm._v("Product ID")]),
                                      _c(
                                        "p",
                                        { staticClass: "text--secondary" },
                                        [_vm._v(_vm._s(_vm.selectedProduct.id))]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "4", md: "4" } },
                                    [
                                      _c("strong", [_vm._v("Part Number")]),
                                      _c(
                                        "p",
                                        { staticClass: "text--secondary" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedProduct.mfg_pn)
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c("p", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.selectedProduct.description
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red", outlined: "" },
                          on: { click: _vm.closeProduct }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }