import api from '@/api/request'

// noinspection DuplicatedCode
export default {
  fetch ({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('/reports', { params: payload })
        .then((response) => {
          commit('setReports', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  run (context, payload) {
    const { id, data } = payload
    return new Promise((resolve, reject) => {
      api.get(`/reports/run/${id}`, { params: data })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
