<template>
  <div v-if="hasUser">
    <v-data-table
      :headers="headers"
      :items="orders"
      :search="search"
      fixed-header
      :height="height"
      item-key="id"
      :loading="loading"
      loading-text="Loading... Please wait"
      :single-select="false"
      show-select
      v-model="selected"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="showTitle">Service Titan Pending Approval</v-toolbar-title>
          <v-divider v-if="showTitle" class="mx-4" inset vertical/>
          <v-text-field
            placeholder="Search"
            hide-details
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            dense/>
          <v-divider class="mx-4" inset vertical />
          <v-menu
            ref="scheduledMenu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                class="mt-4"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="setDates"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                color="primary"
                dark
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list
              :tile="false"
              flat
              nav
            >
              <v-list-item @click="approveOrders">
                <v-list-item-icon>
                  <v-icon>mdi-cart-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Approve Selected</v-list-item-title>
              </v-list-item>
              <v-list-item @click="cancelOrders">
                <v-list-item-icon>
                  <v-icon>mdi-cancel</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Cancel Selected</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>

      <template v-slot:[`item.customer_po`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.customer_po"
          @save="savePO(item)"
        >
          <span v-if="item.customer_po">{{ item.customer_po }}</span>
          <span v-else>Click to add PO#</span>
          <template v-slot:input>
            <v-text-field
              v-model="item.customer_po"
              label="Add PO#"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.customer`]="{ item }">
        <span class="font-weight-bold">{{ item.customer.full_name }}</span><br>
        {{ item.shipping_address.street1 }} {{ item.shipping_address.street2 }} <br>
        {{ item.shipping_address.city }}, {{ item.shipping_address.state.name }} {{ item.shipping_address.zip }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        ${{ item.total }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              class="mb-2 mr-5"
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="approveOrder(item)">
              <v-list-item-icon>
                <v-icon>mdi-cart-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Approve</v-list-item-title>
            </v-list-item>
            <v-list-item @click="cancelOrder(item.id)">
              <v-list-item-icon>
                <v-icon>mdi-cancel</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cancel</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openOrder(item.id)">
              <v-list-item-icon>
                <v-icon>mdi-eye-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>View Order Details</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openCustomer(item.customer_id)">
              <v-list-item-icon>
                <v-icon>mdi-account-search</v-icon>
              </v-list-item-icon>
              <v-list-item-title>View Customer</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        No pending orders found.
      </template>
    </v-data-table>

    <confirmation-modal ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PendingOrderHistory',
  props: {
    height: {
      type: Number,
      default: undefined
    },
    days: {
      type: Number,
      default: 7
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    serviceTitan: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: 'PENDING'
    }
  },
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      query: {
        paginated: false,
        site_id: 3,
        start: '',
        end: '',
        contractor_id: null,
        load_customer: true,
        load_shipping_address: true,
        status: 'PENDING_APPROVAL'
      },
      fullwidth: 0,
      loading: true,
      search: '',
      selected: [],
      dateFormat: 'MM/DD/YYYY',
      maxDate: undefined,
      menu: false,
      dates: []
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('orderManagement', {
      orders: state => state.serviceTitanPendingOrders
    }),
    ...mapState({
      orderStatusMap: state => state.orderStatusMap
    }),
    ...mapState('contractorManagement', {
      contractorSettings: state => state.settings
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: true
        },
        {
          text: 'Archived ID',
          value: 'order_id',
          align: ' d-none'
        },
        {
          text: 'Order Date',
          value: 'created_at'
        },
        {
          text: 'Customer',
          value: 'customer'
        },
        {
          text: 'Address',
          value: 'shipping_address',
          align: ' d-none'
        },
        {
          text: 'PO#',
          value: 'customer_po'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Created By',
          value: 'created_by'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    dateRangeText () {
      return `${this.query.start} - ${this.query.end}`
    },
    hasUser () {
      return Object.entries(this.user).length > 0
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      fetch: 'fetchServiceTitanOrders',
      update: 'update'
    }),
    init () {
      this.query.contractor_id = this.user.model_id

      const startDate = this.$moment().subtract(this.days, 'days').startOf('days')
      const endDate = this.$moment().endOf('days')

      this.maxDate = this.$moment().add(1, 'days').format('YYYY-MM-DD')
      this.dates = [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]

      this.query.start = startDate.format(this.dateFormat)
      this.query.end = endDate.format(this.dateFormat)

      this.fetchOrders()
    },
    setDates () {
      this.menu = false
      this.query.start = this.$moment(this.dates[0]).format(this.dateFormat)
      this.query.end = this.$moment(this.dates[1]).format(this.dateFormat)
      this.fetchOrders()
    },
    fetchOrders () {
      this.fetch(this.query)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
          this.loading = false
        })
    },
    savePO (order) {
      this.update({id: order.id, payload: { customer_po: order.customer_po }})
        .then(() => {
          this.$toast.success('PO# Updated!')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    openOrder (id) {
      this.$router.push({name: 'order_details', params: {id}})
    },
    openCustomer (id) {
      this.$router.push({name: 'customer_detail', params: {id}})
    },
    async approveOrder (order) {
      if (!order.customer_po && this.contractorSettings.po_required) {
        this.$toast.error('PO# is required before approval')
      } else if (await this.$refs.confirm.open('Approve Order', 'Are you sure you want to approve this order?', { color: 'success' })) {
        this.update({id: order.id,  payload: { status: 'PENDING' }})
          .then(() => {
            this.fetchOrders()
            this.$toast.success('Order has been approved!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    async cancelOrder (order_id) {
      if (await this.$refs.confirm.open('Cancel Order', 'Are you sure you want to cancel this order?', { color: 'red' })) {

        this.update({id: order_id, payload: { status: 'CANCELLED' }})
          .then(() => {
            this.fetchOrders()
            this.$toast.success('Order has been cancelled!')
          })
          .catch((error) => {
            this.$toast.error(error)
          })
      }
    },
    async approveOrders () {
      if (await this.$refs.confirm.open('Approve Orders', 'Are you sure you want to approve these orders?', { color: 'success' })) {
        this.selected.forEach((order) => {
          this.update({id: order.id, payload: { status: 'PENDING' }})
            .then(() => {
              this.$toast.success('Orders have been approved!')
            })
            .catch((error) => {
              this.$toast.error(error)
            })
        })
        this.fetchOrders()
      }
    },
    async cancelOrders () {
      if (await this.$refs.confirm.open('Cancel Orders', 'Are you sure you want to cancel these orders?', { color: 'red' })) {
        this.selected.forEach((order) => {
          this.update({id: order.id, payload: { status: 'CANCELLED' }})
            .then(() => {
              this.$toast.success('Orders have been cancelled!')
            })
            .catch((error) => {
              this.$toast.error(error)
            })
        })
        this.fetchOrders()
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.vue-daterange-picker ::v-deep .reportrange-text {
  border: none;
  border-bottom: 1px solid #ccc !important;
  min-height: 48px;
  min-width: 350px;
  line-height: 38px;
}
</style>
