export default {
  setManufacturers (state, payload) {
    state.manufacturers = payload
    localStorage.setItem('manufacturers', JSON.stringify(state.manufacturers))
  },
  setDimensions (state, dimensions) {
    state.lengthOptions = dimensions.length
    state.widthOptions = dimensions.width
    state.thicknessOptions = dimensions.thickness
  },
  setProducts (state, products) {
    state.products = products
    localStorage.setItem('products', JSON.stringify(state.products))
    state.inProgress = false
  },
  setProgress (state, progress) {
    state.inProgress = progress
  },
  setAssignStatus (state, assign) {
    state.assignProduct = assign
  },
  setProduct (state, product) {
    state.selectedProduct = product
  }
}

