import state from './moduleItemManagementState.js'
import mutations from './moduleItemManagementMutations.js'
import actions from './moduleItemManagementActions.js'
import getters from './moduleItemManagementGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
