import axios from 'axios'
import store from '../store/store'

// Create axios instance
const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true
})

// Request interceptor
api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('access_token')
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error.response)
  }
)

api.interceptors.response.use(
  response => {
    return response.data
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch('auth/logout')
    }

    return Promise.reject(error.response)
  }
)

export default api
