<template>
  <v-container
    id="reports-view"
  >

    <material-card
      color="primary"
      icon="mdi-chart-bar"
    >
      <template #title>
        Reports
      </template>

      <v-form v-if="!loading">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                v-model="selectedReportId"
                :items="reports"
                item-text="public_name"
                item-value="id"
                label="Select Report"
                @change="selectReport"
              >
              </v-autocomplete>
            </v-col>

            <v-col v-if="showDateSelector" cols="12" sm="4" md="4">
              <v-menu
                ref="scheduledMenu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  no-title
                  scrollable
                  range
                  :max="maxDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="setDates"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col v-if="formValid" cols="12" sm="4" md="4">
              <v-btn
                small
                class="mt-4 ml-2"
                color="primary"
                :loading="reportLoading"
                @click="run"
              >
                RUN REPORT
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-data-table
        v-if="showReport"
        :headers="tableHeaders"
        :items="reportData"
        fixed-header
        :loading="reportLoading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-btn
              v-if="reportData.length > 0"
              color="primary"
              @click="exportToExcel"
            >
              EXPORT
            </v-btn>
          </v-toolbar>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      fullWidth: 0,
      toggle: true,
      loading: true,
      reportLoading: false,
      showReport: false,
      showDateSelector: false,
      reportData: null,
      reportHeaders: [],
      tableHeaders: [],
      selectedReport: null,
      selectedReportId: null,
      dateFormat: 'MM/DD/YYYY',
      maxDate: undefined,
      menu: false,
      dates: [],
      params: {
        contractor_id: null,
        site_id: null
      },
      start: '',
      end: '',
      search: '',
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      datesSelected: false
    }
  },
  computed: {
    ...mapState('reportManagement', {
      reports: state => state.reports
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    dateRangeText () {
      return `${this.start} - ${this.end}`
    },
    formValid () {
      if (this.showDateSelector) {
        return this.datesSelected
      }

      return this.selectedReport && this.params.contractor_id && this.params.site_id
    }
  },
  methods: {
    ...mapActions('reportManagement', {
      fetchReports: 'fetch',
      runReport: 'run'
    }),
    init () {
      this.params.site_id = 3
      this.params.contractor_id = this.user.model_id
      this.loadReports()
    },
    loadReports () {
      this.loading = true

      this.fetchReports({ active: true, target: 'contractor', public: true, site_access: 3, paginated: false })
        .then(() => {})
        .catch((error) => {
          this.showError(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectReport () {
      this.resetReport()

      const index = this.reports.findIndex((report) => report.id === this.selectedReportId)
      this.selectedReport = this.reports[index]

      if (this.selectedReport.options) {
        const startDate = this.$moment().subtract(7, 'days').startOf('days')
        const endDate = this.$moment().endOf('days')

        this.maxDate = this.$moment().format('YYYY-MM-DD')
        this.dates = [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]

        this.start = startDate.format(this.dateFormat)
        this.end = endDate.format(this.dateFormat)

        this.showDateSelector = this.selectedReport.options.includes('daterange')
        this.datesSelected = this.showDateSelector
      }
    },
    setDates () {
      this.menu = false
      this.start = this.$moment(this.dates[0]).format(this.dateFormat)
      this.end = this.$moment(this.dates[1]).format(this.dateFormat)
      this.datesSelected = true
    },
    resetReport () {
      this.showReport = false
      this.showDateSelector = false
      this.datesSelected = false
      this.reportData = null
      this.reportHeaders = []
    },
    run () {
      this.reportLoading = true

      this.reportHeaders = []

      const obj = {}
      this.selectedReport.options.forEach((key) => {
        if (key === 'daterange') {
          const start = this.$dayjs(this.start).format('MM/DD/YYYY')
          const end = this.$dayjs(this.end).format('MM/DD/YYYY')
          obj[key] = { start, end }
        } else {
          obj[key] = this.params[key]
        }
      })

      this.runReport({ id: this.selectedReport.id, data: obj })
        .then((response) => {
          this.reportData = response
          if (response.length > 0) {
            Object.keys(response[0]).forEach((key) => {
              if (key !== 'Site' && key !== 'Technician') {
                this.reportHeaders.push(key)
                this.tableHeaders.push({
                  text: key,
                  value: key
                })
              }
            })
          }
        })
        .catch((error) => {
          this.showError(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.showReport = true
          this.reportLoading = false
        })
    },
    exportToExcel () {
      import('@/plugins/Export2Excel').then(excel => {
        const data = this.formatJson(this.reportHeaders, this.reportData)

        excel.export_json_to_excel({
          header: this.reportHeaders,
          data,
          filename: this.selectedReport.name,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.vue-daterange-picker ::v-deep .reportrange-text {
  border: none;
  border-bottom: 1px solid #ccc !important;
  min-height: 48px;
  min-width: 350px;
  line-height: 38px;
}
</style>
