export default {
  setComments (state, comments) {
    state.comments = comments
  },
  addComment (state, comment) {
    state.comments.push(Object.assign({}, comment))
  },
  updateComment (state, payload) {
    const index = state.comments.findIndex((u) => u.id === payload.id)
    state.comments.splice(index, 1, payload)
  },
  removeComment (state, id) {
    const index = state.comments.findIndex((u) => u.id === id)
    state.comments.splice(index, 1)
  }
}
