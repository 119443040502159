import api from './request'

export function addUsers (data) {
  return api({
    url: '/users/uploadList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function addUser (data) {
  return api({
    url: '/users',
    method: 'post',
    data
  })
}

export function addUserWithImage (data) {
  return api({
    url: '/users',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function getUser (id) {
  return api({
    url: `/users/${id}`,
    method: 'get'
  })
}

export function getUsers (query) {
  return api({
    url: '/users',
    method: 'get',
    params: query
  })
}

export function updateUser (id, data) {
  return api({
    url: `/users/${id}`,
    method: 'put',
    data
  })
}

export function updateUserWithImage (id, data) {
  return api({
    url: `/users/${id}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function deleteUser (id) {
  return api({
    url: `/users/${id}`,
    method: 'delete'
  })
}
