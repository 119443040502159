var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vuetify-google-autocomplete", {
        ref: "address",
        attrs: {
          id: "autocomplete",
          placeholder: "Enter address for validation"
        },
        on: {
          "no-results-found": _vm.noResults,
          placechanged: _vm.getAddressData
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c("v-icon", { attrs: { small: "" } }, [
                  _vm._v("mdi-map-search")
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }