<template>
  <div v-if="customers.length > 0" class="customer-validation-dialog">
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
      @click:outside="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">Customer Validation</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="ma-5">
          <v-row dense>
            <v-col cols="12" md="12" class="center">
              <h1 class="font-black">Potential Customer Validation Errors</h1>
            </v-col>
            <v-col cols="12" md="12" class="center red--text font-weight-bold">
              Error flagged as:
              <span v-for="(validation, index) in customers[index].validation_errors" :key="index">{{ validation.error }}<br></span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <!-- Compare existing customer to new customer -->
          <v-row align="center" justify="center" dense>
            <v-col cols="4">
              <customer-validation-card :customer-data="customers[index]" :min-height="options.height" :max-width="options.width">
                <template #title>
                  New Customer
                </template>

                <template #actions>
                  <v-btn v-if="customers[index].validation_errors.length > 0" text @click="selectNewCustomer(index)">Select</v-btn>
<!--                  <v-btn text @click="editNewCustomer(index)">Edit</v-btn>-->
                </template>
              </customer-validation-card>
            </v-col>
            <v-col
              v-for="(validation, validationIndex) in customers[index].validation_errors"
              :key="validationIndex"
              cols="4"
            >
              <customer-validation-card v-if="validation.error !== 'Incorrect Address'" :customer-data="validation.associated" :min-height="options.height" :max-width="options.width">
                <template #title>
                  {{ validation.error }}
                </template>
                <template #actions>
                  <v-btn text @click="selectExistingCustomer(index, validationIndex)">Select</v-btn>
                  <v-btn text @click="mergeCustomers(index, validationIndex)">Merge</v-btn>
                </template>
              </customer-validation-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-pagination
                v-model="page"
                :length="total"
                :total-visible="5"
                @input="selectCustomer"
                @next="nextCustomer"
                @previous="previousCustomer"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="validationComplete"
            text
            color="success"
            @click="accept"
          >
            All Done!
          </v-btn>
          <v-btn
            v-else
            text
            color="red"
            @click="cancel"
          >
            Fix Later
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <customer-modal @edit-customer="editCustomer" @close-modal="toggleEditCustomerModal" :customer-data="customer" :is-modal-active="showEditCustomer"/>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CustomerValidationCard: () => import('../components/CustomerValidationCard'),
    CustomerModal: () => import('../customer/components/CustomerModal')
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      options: {
        color: 'primary',
        width: 344,
        height: 200,
        zIndex: 200,
        addToCart: false
      },
      index: 0,
      page: 1,
      total: 0,
      remaining: 0,
      customer: {},
      customers: [],
      showEditCustomer: false
    }
  },
  computed: {
    ...mapState({
      states: state => state.states
    }),
    ...mapState('cartManagement', {
      cart: state => state.cart
    }),
    validationComplete () {
      return this.remaining === 0
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      approve: 'approveCustomerPending',
      add: 'addCustomer',
      update: 'updateCustomer',
      delete: 'removeCustomer'
    }),
    ...mapActions('cartManagement', {
      addCustomerToCart: 'addCustomerToCart',
      updateCartCustomer: 'updateCartCustomer',
      removeCartCustomer: 'removeCustomer'
    }),
    init () {
      this.total = this.customers.length
      this.remaining = this.total
    },
    open (customers, options) {
      this.dialog = true
      this.customers = customers
      this.options = Object.assign(this.options, options)
      this.init()

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    accept () {
      this.resolve({ success: true })
      this.dialog = false
    },
    cancel () {
      this.resolve({ success: false })
      this.dialog = false
    },
    toggleEditCustomerModal (val = false) {
      this.showEditCustomer = val
    },
    editNewCustomer (index) {
      this.customer = this.customers[index].customer
      this.toggleEditCustomerModal(true)
    },
    editCustomer (customer) {
      this.update({id: customer.id, customer})
        .then((response) => {
          this.customers[this.index].fname = response.fname
          this.customers[this.index].lname = response.lname
          this.customers[this.index].email = response.email
          this.customers[this.index].shipping_address.street1 = response.shipping_address.street1
          this.customers[this.index].shipping_address.street2 = response.shipping_address.street2
          this.customers[this.index].shipping_address.city = response.shipping_address.city
          this.customers[this.index].shipping_address.state = response.shipping_address.state
          this.customers[this.index].shipping_address.zip = response.shipping_address.zip
          this.toggleEditCustomerModal()
          this.$toast.success('Customer updated!')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    mergeCustomers (index, validationIndex) {
      this.delete(this.customers[index].id)
        .then(() => {
          this.$toast.success('Customers have been merged.')
          const customer = this.customers[index].validation_errors[validationIndex].associated

          if (this.options.addToCart) {
            this.addCustomerToCart({cart_id: this.cart.id, customer_id: customer.id, address_id: customer.default_shipping})
              .then(() => {})
              .catch((error) => {
                this.$toast.error(error.data ? error.data.error : error)
              })
          }

          this.remaining -= 1

          if (this.validationComplete) {
            this.accept()
          } else {
            this.customers[index] = customer
          }
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    selectNewCustomer (index) {
      const customer = this.customers[index]

      this.approve({customer_id: customer.id})
        .then(() => {
          if (this.options.addToCart) {
            this.updateCartCustomer({id: this.cart.id, customer: {customer_id: customer.id, active: true}})
              .then(() => {
                this.$toast.success('Customer added to cart!')
              })
              .catch((error) => {
                this.$toast.error(error.data ? error.data.error : error)
              })
          }

          this.remaining -= 1

          if (this.validationComplete) {
            this.accept()
          } else {
            this.customers[index].validation_errors = []
          }
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    selectExistingCustomer (index, validationIndex) {
      const customer = this.customers[index].validation_errors[validationIndex].associated

      if (this.options.addToCart) {
        this.addCustomerToCart({cart_id: this.cart.id, customer_id: customer.id, address_id: customer.default_shipping})
          .then(() => {
            this.deleteSelectedCustomer(this.customers[index])
            this.$toast.success('Customer added to cart!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      } else {
        this.deleteSelectedCustomer(this.customers[index])
      }

      this.remaining -= 1

      if (this.validationComplete) {
        this.accept()
      } else {
        this.customers[index].validation_errors = []
      }
    },
    deleteSelectedCustomer (customer) {
      this.delete(customer.id)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    selectCustomer () {
      this.index = this.page - 1
    },
    nextCustomer () {
      //this.index += 1
      this.index = this.page - 1
    },
    previousCustomer () {
      //this.index -= 1
      this.index = this.page - 1
    }
  }
}
</script>
<style>
.validation_modal {
  width: 100%;
}
.validation_form {
  width: 100%;
  border-radius: 20px;
  border: 1px solid black;
  border-collapse: separate;
  border-spacing: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}
.row_padding {
  margin: 10px;
}
</style>
