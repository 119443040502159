var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("h1", { staticClass: "pt-5 text-center font-black" }, [
                  _vm._v("Add Customer")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      loading: _vm.isLoading,
                      items: _vm.customers,
                      "search-input": _vm.search,
                      flat: "",
                      "hide-details": "",
                      "item-text": "full_name",
                      "item-value": "id",
                      "return-object": "",
                      "prepend-icon": "mdi-magnify"
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.search = $event
                      },
                      "update:search-input": function($event) {
                        _vm.search = $event
                      },
                      change: _vm.customerSelected
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function(data) {
                          return [
                            _vm._v(" " + _vm._s(data.item.full_name) + " ")
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function(data) {
                          return [
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-subtitle",
                                  [
                                    _vm._v(
                                      " " + _vm._s(data.item.full_name) + " "
                                    ),
                                    _c("v-spacer"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.item.shipping_address.street1
                                        ) +
                                        " " +
                                        _vm._s(
                                          data.item.shipping_address.street2
                                        ) +
                                        ", " +
                                        _vm._s(
                                          data.item.shipping_address.city
                                        ) +
                                        ", " +
                                        _vm._s(
                                          data.item.shipping_address.state.name
                                        ) +
                                        " " +
                                        _vm._s(data.item.shipping_address.zip) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "append-item",
                        fn: function() {
                          return [
                            _c("v-divider", { staticClass: "mb-2" }),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-subtitle",
                                  { staticClass: "ml-5" },
                                  [
                                    _vm._v(" Customer not found? "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { small: "", text: "" },
                                        on: { click: _vm.newCustomer }
                                      },
                                      [_vm._v("Create Customer")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.customer,
                      callback: function($$v) {
                        _vm.customer = $$v
                      },
                      expression: "customer"
                    }
                  })
                ],
                1
              ),
              _vm.showAddressSelect
                ? _c(
                    "v-card-text",
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.addresses,
                          label: "Select shipping address",
                          "item-value": "id",
                          flat: "",
                          "hide-details": "",
                          "prepend-icon": "mdi-home"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.street1) +
                                      " " +
                                      _vm._s(data.item.street2) +
                                      ", " +
                                      _vm._s(data.item.city) +
                                      ", " +
                                      _vm._s(data.item.state.name) +
                                      " " +
                                      _vm._s(data.item.zip) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-subtitle",
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.full_name) +
                                              " "
                                          ),
                                          _c("v-spacer"),
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.street1) +
                                              " " +
                                              _vm._s(data.item.street2) +
                                              ", " +
                                              _vm._s(data.item.city) +
                                              ", " +
                                              _vm._s(data.item.state.name) +
                                              " " +
                                              _vm._s(data.item.zip) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "append-item",
                              fn: function() {
                                return [
                                  _c("v-divider", { staticClass: "mb-2" }),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "ml-5" },
                                        [
                                          _vm._v(" Address not found? "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { small: "", text: "" },
                                              on: { click: _vm.newAddress }
                                            },
                                            [_vm._v("Create Address")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3767832760
                        ),
                        model: {
                          value: _vm.addressId,
                          callback: function($$v) {
                            _vm.addressId = $$v
                          },
                          expression: "addressId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "blue darken-1" },
                      on: { click: _vm.newCustomer }
                    },
                    [_vm._v("ADD CUSTOMER")]
                  ),
                  _vm.settings.st_active
                    ? _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "blue darken-1" },
                          on: { click: _vm.enterJobNumber }
                        },
                        [_vm._v("ADD BY JOB NUMBER")]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("EXIT")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.addCustomer }
                    },
                    [_vm._v("ADD")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("customer-modal", { ref: "addCustomer" })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.showEnterJobNumber,
            callback: function($$v) {
              _vm.showEnterJobNumber = $$v
            },
            expression: "showEnterJobNumber"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v("Add Customer By Job Number")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { dense: "", label: "Job Number" },
                                model: {
                                  value: _vm.jobNumber,
                                  callback: function($$v) {
                                    _vm.jobNumber = $$v
                                  },
                                  expression: "jobNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showEnterJobNumber = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "blue darken-1",
                        text: "",
                        loading: _vm.loading,
                        disabled: _vm.loading
                      },
                      on: { click: _vm.addCustomerByJobNumber }
                    },
                    [_vm._v(" Add ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.showNewAddressForm,
            callback: function($$v) {
              _vm.showNewAddressForm = $$v
            },
            expression: "showNewAddressForm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [_vm._v("Add Address")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("vuetify-google-autocomplete", {
                                ref: "address",
                                attrs: {
                                  id: "autocomplete",
                                  placeholder: "Enter address for validation"
                                },
                                on: {
                                  "no-results-found": _vm.noResults,
                                  placechanged: _vm.getAddressData
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-map-search")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { dense: "", label: "Street 1" },
                                model: {
                                  value: _vm.address.street1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "street1", $$v)
                                  },
                                  expression: "address.street1"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { dense: "", label: "Street 2" },
                                model: {
                                  value: _vm.address.street2,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "street2", $$v)
                                  },
                                  expression: "address.street2"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { dense: "", label: "City" },
                                model: {
                                  value: _vm.address.city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "city", $$v)
                                  },
                                  expression: "address.city"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.states,
                                  filter: _vm.statesFilter,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: "State",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.address.state_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "state_id", $$v)
                                  },
                                  expression: "address.state_id"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  label: "Zip",
                                  rules: _vm.zipRules,
                                  maxlength: "5",
                                  counter: ""
                                },
                                model: {
                                  value: _vm.address.zip,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "zip", $$v)
                                  },
                                  expression: "address.zip"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.closeAdd }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.addAddress }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }