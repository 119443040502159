import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('../layouts/main/Main.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/dashboard/Dashboard'),
        name: 'dashboard',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/products',
        component: () => import('../views/products/Products'),
        name: 'products',
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/account/Account.vue'),
    children: [
      {
        path: '/account/company',
        component: () => import('../views/account/CompanyProfile'),
        name: 'company',
        meta: {
          requiresAuth: true,
          parent: 'account'
        }
      },
      {
        path: '/account/billing',
        component: () => import('../views/account/Billing'),
        name: 'billing',
        meta: {
          requiresAuth: true,
          parent: 'account'
        }
      },
      {
        path: '/account/users',
        component: () => import('../views/account/Users'),
        name: 'users',
        meta: {
          requiresAuth: true,
          parent: 'account'
        }
      },
      {
        path: '/account/files',
        component: () => import('../views/account/Files'),
        name: 'files',
        meta: {
          requiresAuth: true,
          parent: 'account'
        }
      },
      {
        path: '/account/settings',
        component: () => import('../views/account/Settings'),
        name: 'settings',
        meta: {
          requiresAuth: true,
          parent: 'account'
        }
      },
      {
        path: '/account/reports',
        component: () => import('../views/account/Reports'),
        name: 'reports',
        meta: {
          requiresAuth: true,
          parent: 'account'
        }
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/customer/Customers.vue'),
    children: [
      {
        path: '/customers/list',
        component: () => import('../views/customer/List'),
        name: 'customers',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      },
      {
        path: '/customers/pending/verification',
        component: () => import('../views/customer/CustomersPendingVerificationBatch'),
        name: 'pending_batch',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      },
      {
        path: '/customers/pending/verification/batch/:id',
        component: () => import('../views/customer/CustomersPendingVerification'),
        name: 'pending',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      },
      {
        path: '/customers/pending/products',
        component: () => import('../views/customer/CustomersPendingProductsBatch'),
        name: 'customer_no_products_batch',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      },
      {
        path: '/customers/pending/products/batch/:id',
        component: () => import('../views/customer/CustomersPendingProducts'),
        name: 'customer_no_products',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      },
      {
        path: '/customers/pending/schedule',
        component: () => import('../views/customer/CustomersPendingScheduleBatch'),
        name: 'customer_no_schedule_batch',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      },
      {
        path: '/customers/pending/schedule/batch/:id',
        component: () => import('../views/customer/CustomersPendingSchedule'),
        name: 'customer_no_schedule',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      },
      {
        path: '/customers/:id',
        component: () => import('../views/customer/CustomerDetail'),
        name: 'customer_detail',
        meta: {
          requiresAuth: true,
          parent: 'customers'
        }
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/orders/Orders.vue'),
    children: [
      {
        path: '/orders/list',
        component: () => import('../views/orders/List'),
        name: 'orders',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/open',
        component: () => import('../views/orders/Open'),
        name: 'open-orders',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/scheduled/approved',
        component: () => import('../views/orders/OrdersScheduled'),
        name: 'scheduled_orders',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/scheduled/pending',
        component: () => import('../views/orders/OrdersScheduledPending'),
        name: 'scheduled_orders_pending',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/batches',
        component: () => import('../views/orders/OrdersBatches'),
        name: 'orders_batches',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/service-titan/pending',
        component: () => import('../views/orders/OrdersPending'),
        name: 'service_titan_orders_pending',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/create',
        component: () => import('../views/orders/Create'),
        name: 'create',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/scheduled/:id',
        component: () => import('../views/orders/ScheduledOrderDetails'),
        name: 'scheduled_order_details',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      },
      {
        path: '/orders/:id',
        component: () => import('../views/orders/OrderDetails'),
        name: 'order_details',
        meta: {
          requiresAuth: true,
          parent: 'orders'
        }
      }
    ]
  },
  {
    path: '',
    component: () => import('../layouts/full-page/FullPage.vue'),
    children: [
      {
        path: '/auth/login',
        name: 'login',
        component: () => import('../views/auth/Login.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '/auth/request-account',
        name: 'request-account',
        component: () => import('../views/auth/RequestAccount.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '/auth/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/auth/ForgotPassword.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '/auth/reset-password/:token',
        name: 'reset-password',
        component: () => import('../views/auth/ResetPassword.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '/notifications/unsubscribe/:hash',
        name: 'unsubscribe',
        component: () => import('../views/auth/ResetPassword.vue'),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/auth/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      return next()
    }

    next({ path: '/auth/login', query: {redirect: to.fullPath } })
  } else {
    return next()
  }
})

export default router
