<template>
  <div id="order-items-panel">
    <panel>
      <template #title>
        Shipping
      </template>

      <template #content>
        <v-data-table
          :headers="headers"
          :items="order.shipments"
          single-expand
          :expanded.sync="expanded"
          show-expand
          hide-default-footer
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.carrier`]="{ item }">
            {{ shippingCarrierMap[item.carrier] }}
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-list
                class="m-2"
                two-line
                dense
              >
                <template v-for="(data, i) in item.details">
                  <v-list-item :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="data.date"></v-list-item-title>
                      <v-list-item-subtitle v-text="data.location"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="data.description"></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider class="m-0 p-0" :key="data.date" />
                </template>
              </v-list>
            </td>
          </template>

          <template v-slot:no-data>
            No shipping records found.
          </template>
        </v-data-table>
      </template>
    </panel>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ShippingPanel',
  components: {
    Panel: () => import('@/components/Panel')
  },
  data () {
    return {
      loading: false,
      date: new Date().toISOString().substr(0, 10),
      expanded: []
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    }),
    ...mapState({
      shippingCarrierMap: state => state.shippingCarrierMap
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: ' d-none',
          value: 'id',
          sortable: true
        },
        {
          text: 'Date Shipped',
          value: 'created_at'
        },
        {
          text: 'Tracking#',
          value: 'tracking'
        },
        {
          text: 'Shipping Details',
          value: 'details',
          align: ' d-none'
        },
        {
          text: 'Carrier',
          value: 'carrier'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
