import api from './request'

export function add (data) {
  return api({
    url: '/payments',
    method: 'post',
    data
  })
}

export function show () {
  return api({
    url: '/contractors/payment',
    method: 'get'
  })
}

export function get (data) {
  return api({
    url: '/payments',
    method: 'get',
    params: data
  })
}

export function update (id, data) {
  return api({
    url: `/payments/${id}`,
    method: 'put',
    data
  })
}

export function destroy (id) {
  return api({
    url: `/payments/${id}`,
    method: 'delete'
  })
}

export function primary (id, data) {
  return api({
    url: `/payments/${id}/setPrimary`,
    method: 'put',
    data
  })
}

export function authorize (data) {
  data.site_id = 3
  return api({
    url: '/payments/authorize',
    method: 'post',
    data
  })
}

export function capture (data) {
  return api({
    url: '/payments/capture',
    method: 'post',
    data
  })
}

export function test (data) {
  return api({
    url: '/payments/test',
    method: 'post',
    data
  })
}
