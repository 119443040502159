<template>
  <div id="order-items-panel">
    <panel>
      <template #title>
        Return Requests
      </template>

      <template #content>
        <v-data-table
          :headers="headers"
          :items="returns"
          single-expand
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          dense
        >
          <template v-slot:item.status="{ item }">
            <v-chip class="ma-2" :color="statusColorMap[item.status]">{{ statusMap[item.status] }}</v-chip>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding-left: 0; padding-right: 0;">
              <v-simple-table class="rounded-0" style="background-color: #1976d2; color: white;" dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left" style="color: white">Product ID</th>
                    <th class="text-left" style="color: white">Name</th>
                    <th class="text-left" style="color: white">Qty</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(product, i) in item.products"
                    :key="i"
                    style="pointer-events: none"
                  >
                    <td>{{ product.product_id }}</td>
                    <td>{{ $utils.productName(product.product) }}</td>
                    <td>{{ product.quantity }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon v-if="item.status === 'PENDING'" small @click="deleteReturn(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </template>
    </panel>

    <confirmation-modal ref="confirm" />

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'OrderReturnsPanel',
  components: {
    Panel: () => import('@/components/Panel'),
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      overlay: false,
      returns: [],
      expanded: [],
      headers: [
        { text: 'Return ID', align: 'start', sortable: false, value: 'id' },
        { text: 'Status', align: 'start', sortable: false, value: 'status' },
        { text: 'Requested By', align: 'start', sortable: false, value: 'created_by' },
        { text: 'Requested At', align: 'start', sortable: false, value: 'created_at' },
        { text: '', align: 'end', sortable: false, value: 'actions' }
      ],
      statusMap: {
        PENDING: 'Pending',
        IN_PROGRESS: 'In Progress',
        APPROVED: 'Approved',
        COMPLETE: 'Complete',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled'
      },
      statusColorMap: {
        PENDING: 'warning',
        IN_PROGRESS: 'info',
        APPROVED: 'success',
        COMPLETE: 'primary',
        REJECTED: 'error',
        CANCELLED: 'error'
      },
      dialogDelete: false,
      selectedReturnId: null
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    })
  },
  watch: {
    dialogDelete (val) {
      if (!val) {
        this.closeDelete()
      }
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      deleteReturnRequest: 'deleteReturnRequest'
    }),
    initialize () {
      this.returns = this.order.returns
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.selectedReturnId = null
        this.initialize()
      })
    },
    async deleteReturn (id) {
      if (await this.$refs.confirm.open('Cancel Return Request', 'Are you sure you want to cancel this return?', { color: 'red' })) {
        this.overlay = true
        this.deleteReturnRequest(id)
          .then(() => {
            this.closeDelete()
            this.$toast.success('Return request has been cancelled!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
          .finally(() => {
            this.overlay = false
          })
      }
    }
  },
  created () {
    this.initialize()
  }
}
</script>

<style scoped>

</style>
