<template>
  <div v-if="hasUser" id="scheduled-orders-table">
    <v-data-table
      v-model="selected"
      show-select
      :single-select="singleSelect"
      :headers="headers"
      :items="orders"
      :search="search"
      fixed-header
      :height="height"
      item-key="id"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="showTitle">Scheduled Orders</v-toolbar-title>
          <v-divider v-if="showTitle" class="mx-4" inset vertical/>
          <v-text-field
            placeholder="Search"
            hide-details
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            dense/>
          <v-divider class="mx-4" inset vertical />
          <v-menu
            ref="scheduledMenu"
            v-model="scheduledMenu"
            :close-on-content-click="false"
            :return-value.sync="scheduledDates"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                class="mt-4"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="scheduledDates"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="scheduledMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="setDates"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title style="cursor: pointer" @click="approveSelected">Approve</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title style="cursor: pointer" @click="snoozeSelected">Snooze</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title style="cursor: pointer" @click="cancelSelected">Cancel</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>

      <template v-slot:[`item.customer`]="{ item }">
        {{ item.customer.fname }} {{ item.customer.lname}}<br>
        {{ item.customer.shipping_address.street1 }} {{ item.customer.shipping_address.street2 }} <br>
        {{ item.customer.shipping_address.city }}, {{ item.customer.shipping_address.state.name }} {{ item.customer.shipping_address.zip }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="statusColorMap[item.status]">
          {{ statusMap[item.status] }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ name: 'scheduled_order_details', params: { id: item.id } })"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>View/Edit Scheduled Order</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="snoozeSchedule(item.id)">
              mdi-alarm
            </v-icon>
          </template>
          <span>Delay Scheduled Order 30 Days</span>
        </v-tooltip>
        <v-tooltip
          v-if="item.status !== 'CANCELLED'"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="cancelSchedule(item.id)">
              mdi-cancel
            </v-icon>
          </template>
          <span>Cancel Scheduled Order</span>
        </v-tooltip>
        <v-tooltip
          v-else
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="approveSchedule(item.id)">
              mdi-cancel
            </v-icon>
          </template>
          <span>Approve Scheduled Order</span>
        </v-tooltip>
      </template>
      <template v-if="isEnabled('no-data')" v-slot:no-data>
        No scheduled orders found.
      </template>
    </v-data-table>

    <confirmation-modal ref="confirm" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    height: {
      type: Number,
      default: undefined
    },
    days: {
      type: Number,
      default: 7
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    pending: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      query: {
        paginated: false,
        site_id: 3,
        status: 'all',
        start: '',
        end: '',
        contractor_id: null,
        load_customer: true
      },
      selected: [],
      singleSelect: false,
      scheduled_orders: [],
      search: '',
      loading: false,
      statusColorMap: {
        SCHEDULED: 'success',
        PENDING_APPROVAL: 'primary',
        CANCELLED: 'red'
      },
      dateFormat: 'MM/DD/YYYY',
      scheduledMenu: false,
      scheduledDates: []
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('orderManagement', {
      orders: state => state.scheduledOrders
    }),
    ...mapState({
      statusMap: state => state.orderStatusMap
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: true
        },
        {
          text: 'Scheduled Date',
          value: 'next'
        },
        {
          text: 'Customer',
          value: 'customer'
        },
        {
          text: 'Address',
          value: 'address',
          align: ' d-none'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: 'Created By',
          value: 'created_by'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    isLoading () {
      return this.isEnabled('progress')
    },
    dateRangeText () {
      return `${this.query.start} - ${this.query.end}`
    },
    hasUser () {
      return Object.entries(this.user).length > 0
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      fetchOrders: 'fetchScheduledOrders',
      update: 'updateScheduled',
      snooze: 'snoozeScheduled'
    }),
    init () {
      const startDate = this.$moment().startOf('days')
      const endDate = this.$moment().add(this.days, 'days').endOf('days')

      this.scheduledDates = [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]

      this.query.start = startDate.format(this.dateFormat)
      this.query.end = endDate.format(this.dateFormat)

      this.query.contractor_id = this.user.model_id
      this.query.status = this.pending ? 'PENDING_APPROVAL' : 'SCHEDULED'

      this.loadOrders()
    },
    setDates () {
      this.scheduledMenu = false
      this.query.start = this.$moment(this.scheduledDates[0]).format(this.dateFormat)
      this.query.end = this.$moment(this.scheduledDates[1]).format(this.dateFormat)
      this.loadOrders()
    },
    loadOrders () {
      this.loading = true
      this.fetchOrders(this.query)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async approveSelected () {
      if (await this.$refs.confirm.open('Approve Orders', 'Are you sure you want to approve these orders?', { color: 'red' })) {
        for (let i = 0; i < this.selected.length; i++) {
          this.update({id: this.selected[i].id, payload: {status: 'SCHEDULED'}})
            .catch((error) => {
              this.$toast.error(error.data ? error.data.error : error)
            })
        }
        this.selected = []
        this.loadOrders()
        this.$toast.success('Approval action complete')
      }
    },
    async snoozeSelected () {
      if (await this.$refs.confirm.open('Snooze Orders', 'Are you sure you want to delay these orders 30 days?', { color: 'red' })) {
        for (let i = 0; i < this.selected.length; i++) {
          this.snooze(this.selected[i].id)
            .then(() => {})
            .catch((error) => {
              this.$toast.error(error.data ? error.data.error : error)
            })
        }
        this.selected = []
        this.loadOrders()
        this.$toast.success('Snooze action complete')
      }
    },
    async cancelSelected () {
      if (await this.$refs.confirm.open('Cancel Orders', 'Are you sure you want to cancel these orders?', { color: 'red' })) {
        for (let i = 0; i < this.selected.length; i++) {
          this.update({id: this.selected[i].id, payload: {status: 'CANCELLED'}})
            .then(() => {})
            .catch((error) => {
              this.$toast.error(error.data ? error.data.error : error)
            })
        }
        this.selected = []
        this.loadOrders()
        this.$toast.success('Cancel action complete')
      }
    },
    async cancelSchedule (id) {
      if (await this.$refs.confirm.open('Cancel Order', 'Are you sure you want to cancel this order?', { color: 'red' })) {
        this.update({id, payload: {status: 'CANCELLED'}})
          .then(() => {
            this.$toast.success('Scheduled order cancelled!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    async approveSchedule (id) {
      if (await this.$refs.confirm.open('Approve Order', 'Are you sure you want to approve this order?', { color: 'red' })) {
        this.update({id, payload: {status: 'SCHEDULED'}})
          .then(() => {
            this.$toast.success('Order has been approved!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    async snoozeSchedule (id) {
      if (await this.$refs.confirm.open('Snooze Order', 'Are you sure you want to delay this order 30 days?', { color: 'red' })) {
        this.snooze(id)
          .then(() => {
            this.loadOrders()
            this.$toast.success('Scheduled order snoozed for 30 days!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    isEnabled (slot) {
      const records = this.orders.length

      if (slot === 'no-data' && records === 0) {
        return true
      }

      return slot === 'body' && records > 0
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.vue-daterange-picker ::v-deep .reportrange-text {
  border: none;
  border-bottom: 1px solid #ccc !important;
  min-height: 48px;
  min-width: 350px;
  line-height: 38px;
}
</style>
