<template>
  <v-container
    fill-height
    fluid
  >
    <v-layout align-center justify-center>
      <v-card
        elevation="2"
        min-height="400"
        min-width="500"
        width="40%"
      >
        <v-card-title>
          Request an account
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <p>Please fill out the information below and our Customer Service team will reach out shortly.</p>

          <v-form
            ref="requestForm"
            v-model="valid"
            lazy-validation
          >
            <!-- Company Info -->
            <v-text-field
              dense
              label="Company Name"
              outlined
              v-model="company.name"
            ></v-text-field>
            <v-text-field
              dense
              label="Company Website"
              outlined
              v-model="company.website"
            ></v-text-field>

            <!-- Contact Info -->
            <v-text-field
              dense
              label="Contact Name"
              outlined
              v-model="company.contact_name"
              clearable
            ></v-text-field>
            <v-text-field
              dense
              outlined
              label="Contact Email"
              v-model="company.contact_email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              dense
              label="Contact Phone"
              outlined
              v-model="company.contact_phone"
              clearable
            ></v-text-field>

            <!-- Address -->
            <v-text-field
              dense
              label="Street Address"
              outlined
              v-model="company.address.street1"
            ></v-text-field>
            <v-text-field
              dense
              label="PO Box/Apt/Suite#"
              outlined
              v-model="company.address.street2"
            ></v-text-field>
            <v-text-field
              dense
              label="City"
              outlined
              v-model="company.address.city"
            ></v-text-field>
            <v-select
              dense
              outlined
              v-model="company.address.state"
              :items="states"
              item-text="text"
              item-value="abbreviation"
              label="State"
              single-line
            ></v-select>
            <v-text-field
              dense
              label="Zip Code"
              outlined
              v-model="company.address.zip"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-3">
          <v-btn
            text
            color="primary"
            @click="$router.push({name: 'login'})"
          >
            Back to Login
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="submitForm"
          >
            Request Account
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
<!--  <div>
    <v-row justify="center">
      <v-card
        class="mt-5"
        elevation="2"
        min-height="400"
        min-width="500"
        width="40%"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">Request an Account</v-list-item-title>
            <v-list-item-subtitle>
              Please fill out the information below and our Customer Service team will reach out shortly.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <div class="px-5 py-5">
          <v-form
            ref="requestForm"
            v-model="valid"
            lazy-validation
            @submit="submitForm"
          >
            &lt;!&ndash; Company Info &ndash;&gt;
            <v-text-field
              dense
              label="Company Name"
              outlined
              v-model="company.name"
            ></v-text-field>
            <v-text-field
              dense
              label="Company Website"
              outlined
              v-model="company.website"
            ></v-text-field>

            &lt;!&ndash; Contact Info &ndash;&gt;
            <v-text-field
              dense
              label="Contact Name"
              outlined
              v-model="company.contact_name"
              clearable
            ></v-text-field>
            <v-text-field
              dense
              outlined
              label="Contact Email"
              v-model="company.contact_email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              dense
              label="Contact Phone"
              outlined
              v-model="company.contact_phone"
              clearable
            ></v-text-field>

            &lt;!&ndash; Address &ndash;&gt;
            <v-text-field
              dense
              label="Street Address"
              outlined
              v-model="company.address.street1"
            ></v-text-field>
            <v-text-field
              dense
              label="PO Box/Apt/Suite#"
              outlined
              v-model="company.address.street2"
            ></v-text-field>
            <v-text-field
              dense
              label="City"
              outlined
              v-model="company.address.city"
            ></v-text-field>
            <v-select
              dense
              outlined
              v-model="company.address.state"
              :items="states"
              item-text="text"
              item-value="abbreviation"
              label="State"
              single-line
              ></v-select>
            <v-text-field
              dense
              label="Zip Code"
              outlined
              v-model="company.address.zip"
            ></v-text-field>

            <v-divider></v-divider>

            <v-btn
              type="submit"
              block
              :disabled="!valid"
              class="mb-3"
              color="primary"
            >
              Request Account
            </v-btn>
          </v-form>
        </div>
      </v-card>
    </v-row>
  </div>-->
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      valid: true,
      show: false,
      company: {
        name: '',
        website: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        address: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: ''
        }
      },
      rules: {
        required: value => !!value || 'Required',
        email: value => {
          return  /.+@.+\..+/.test(value) || 'Invalid E-Mail'
        }

      }
    }
  },
  computed: {
    ...mapState({
      states: state => state.stateOptions
    })
  },
  methods: {
    submitForm () {
      //TODO - Submit form
      this.$toast.success('Request submitted. Someone from our customer service team will reach out to you shortly.')
    }
  }
}
</script>

<style scoped>
  .v-btn,
  .v-card {
    border-radius: 4px
  }
  .v-card__title {
    text-transform: uppercase
  }
</style>
