var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "customers-pending-schedule-batch-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-calendar-plus" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Customers Pending Schedule Batches ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-data-table", {
            staticClass: "mr-2 ml-2",
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              loading: _vm.loading,
              "loading-text": "Loading Batches... Please wait"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.method",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.methodMap[item.method]) + " ")
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.openBatch(item.id)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-folder")])],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "no-data",
                  fn: function() {
                    return [_vm._v(" No pending schedule batches found. ")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }