var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "customers-pending-verification-batch-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-account-check" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Customers Pending Verification Batches ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-data-table", {
            staticClass: "mr-2 ml-2",
            attrs: {
              "single-select": false,
              "show-select": "",
              "item-key": "entered_date",
              headers: _vm.headers,
              items: _vm.items,
              loading: _vm.loading,
              "loading-text": "Loading Pending Batches... Please wait"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "white" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                left: "",
                                "min-width": "200",
                                "offset-y": "",
                                origin: "top right",
                                transition: "scale-transition"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var attrs = ref.attrs
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-5",
                                              attrs: {
                                                disabled:
                                                  _vm.selected.length === 0,
                                                "min-width": "0",
                                                text: "",
                                                outlined: "",
                                                color: "accent",
                                                elevation: "2"
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" Actions ")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { tile: false, flat: "", nav: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    { on: { click: _vm.approveSelected } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Approve")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { on: { click: _vm.approveAddCart } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Approve & Add To Cart")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { on: { click: _vm.deleteSelected } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Delete")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.method",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.methodMap[item.method]) + " ")
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.openBatch(item.id)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-folder")])],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "no-data",
                  fn: function() {
                    return [_vm._v(" No pending verification batches found. ")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }