var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "service-titan-pending-orders-list-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-account-multiple" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Pending Service Titan Orders ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("pending-order-history", {
            attrs: {
              "show-title": false,
              days: 30,
              service_titan: true,
              status: _vm.status
            }
          })
        ],
        1
      ),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }