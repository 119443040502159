<template>
  <div>
    <v-dialog v-model="isModalActiveLocal" persistent max-width="600px">
      <v-form
        ref="paymentMethodForm"
        >
        <v-card>
          <v-card-title>
            <span class="pt-5 headline">Add Payment Method</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <iframe
                      ref="tokenIFrame"
                      width="100%"
                      style="height: 50px"
                      id="tokenFrame"
                      name="tokenFrame"
                      :src="hostedURL"
                      frameborder="0"
                      scrolling="0"
                    ></iframe>
                  </v-col>
                  <v-col cols="12 mt-4">
                    <v-select
                      v-model="brand"
                      :items="cardTypes"
                      label="Card Type"
                      outlined
                      single-line
                      dense
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Cardholder Name"
                      outlined
                      dense
                      v-model="cardholder"
                      required
                    ></v-text-field>
                    <input type="hidden" id="cardToken" v-model="cardToken"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      ref="cardExpInput"
                      label="Expiration Date"
                      outlined
                      dense
                      v-model="cardExpiration"
                      v-cardformat:formatCardExpiry
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      ref="cardCvcInput"
                      label="CVV"
                      outlined
                      dense
                      v-model="cvc"
                      v-cardformat:formatCardCVC
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      ref="cardZipInput"
                      label="Billing Zip Code"
                      outlined
                      dense
                      v-model="postalCode"
                      v-cardformat:restrictNumeric
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn
              v-if="validated"
              color="blue darken-1"
              :loading="addingPaymentLoader"
              :disabled="addingPaymentLoader"
              text
              @click="storeCard">
              Add Payment Method
            </v-btn>
            <v-btn
              v-else
              color="blue darken-1"
              :loading="addingPaymentLoader"
              :disabled="!formValid"
              text
              @click="authorizeCard">
              Validate Credit Card
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddPaymentModal',
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      cardNumber: null,
      cardExpiration: null,
      brand: '',
      cvc: null,
      postalCode: null,
      cardholder: null,
      primary: false,
      cardTypes: [
        { text: 'Visa', value: 'visa' },
        { text: 'Mastercard', value: 'mastercard' },
        { text: 'American Express', value: 'amex' },
        { text: 'Discover', value: 'discover' }
      ],
      authorization_data: {
        model_type: 'contractor',
        model_id: null,
        card_name: '',
        card_number: '',
        card_expiry: '',
        card_type: '',
        card_token: '',
        zip: '',
        primary: false
      },
      addingPaymentLoader: false,
      cardToken: '',
      validated: false
    }
  },
  filters: {
    cardNumber (token) {
      return token.slice(-4)
    },
    capitalize (string) {
      if (!string) return ''
      string = string.toString()
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    primary (value) {
      if (value) return 'Primary'

      return ''
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    isModalActiveLocal: {
      get () {
        return this.isModalActive
      },
      set (val) {
        if (!val) {
          this.$emit('close-modal')
        }
      }
    },
    hostedURL () {
      const baseURL = 'https://fts.cardconnect.com/itoke/ajax-tokenizer.html'
      const css = 'input{font-size: 16px; border-radius: 4px; border: 1px solid grey; min-height: 35px; width: 98%; padding-left: 10px; font-family: Roboto, sans-serif; font-weight: 350;}'
      const inputs = '&placeholder=Card Number&formatinput=true&enhancedresponse=true'
      return encodeURI(`${baseURL}?css=${css}${inputs}`)
    },
    formValid () {
      return this.cardToken !== '' && this.cardExpiration && this.cvc && this.postalCode && this.cardholder && this.brand !== ''
    },
    valid () {
      return this.$refs.paymentMethodForm.validate()
    }
  },
  methods: {
    ...mapActions('paymentManagement', {
      authorize: 'authorizePayment',
      add: 'addPayment',
      fetch:'fetchPayments'
    }),
    authorizeCard () {
      this.addingPaymentLoader = true

      const obj = {
        cc_number: this.cardToken,
        cvv: this.cvc,
        expiration: this.cardExpiration,
        zip: this.postalCode,
        name: this.cardholder,
        type: this.brand,
        amount: 0
      }

      this.authorize(obj)
        .then((response) => {
          this.authorization_data = {
            ...this.authorization_data,
            card_name: this.cardholder,
            card_number: response.account,
            card_expiry: this.cardExpiration,
            card_type: this.brand,
            card_token: response.card_token,
            zip: this.postalCode,
            primary: false
          }

          this.$toast.success('Credit card validation successful')
          this.validated = true
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.addingPaymentLoader = false
        })
    },
    close () {
      this.authorization_data = {}
      this.validated = false
      this.addingPaymentLoader = false
      this.cardToken = ''
      this.cardholder = ''
      this.cvc = null
      this.brand = ''
      this.cardExpiration = null
      this.postalCode = null
      this.$refs.paymentMethodForm.resetValidation()
      this.$emit('close-modal')
    },
    storeCard () {
      this.addingPaymentLoader = true
      this.authorization_data.model_id = this.user.model_id

      this.add(this.authorization_data)
        .then(() => {
          this.cardNumber = ''
          this.cardExpiration = ''
          this.brand = ''
          this.postalCode = ''
          this.cardholder = ''

          this.fetch()

          this.$emit('add-payment')
          this.close()
          this.$toast.success('Payment stored!')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.addingPaymentLoader = false
        })
    },
    tokenizationEvent (event) {
      if (event.origin === 'https://fts.cardconnect.com') {
        const token = JSON.parse(event.data)
        if (token.errorCode === '0') {
          this.cardToken = token.message
        }
      }
    },
    resetAuthorizationData () {
      this.authorization_data = {
        model_type: 'contractor',
        model_id: null,
        card_name: '',
        card_number: '',
        card_expiry: '',
        card_type: '',
        card_token: '',
        zip: '',
        primary: false
      }
    }
  },
  mounted () {
    window.addEventListener('message', this.tokenizationEvent)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.resetAuthorizationData)
  }
}
</script>

<style scoped>

</style>
