import { render, staticRenderFns } from "./OrderHistory.vue?vue&type=template&id=012b3996&scoped=true&"
import script from "./OrderHistory.vue?vue&type=script&lang=js&"
export * from "./OrderHistory.vue?vue&type=script&lang=js&"
import style0 from "./OrderHistory.vue?vue&type=style&index=0&id=012b3996&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012b3996",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VChip,VDataTable,VDatePicker,VDivider,VEditDialog,VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VMenu,VSpacer,VTextField,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/forge/demo.filterondemand.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('012b3996')) {
      api.createRecord('012b3996', component.options)
    } else {
      api.reload('012b3996', component.options)
    }
    module.hot.accept("./OrderHistory.vue?vue&type=template&id=012b3996&scoped=true&", function () {
      api.rerender('012b3996', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/OrderHistory.vue"
export default component.exports