<template>
  <v-container id="orders-list-view">
    <material-card
      color="primary"
      icon="mdi-cart"
    >
      <template #title>
        Open Orders
      </template>

      <v-data-table
        :headers="headers"
        :items="carts"
        :items-per-page="15"
        :loading="loadingCarts"
        loading-text="Loading Open Orders... Please wait"
        class="mr-2 ml-2">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2 mr-5"
              @click.stop="addCart"
            >
              New Cart
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.customers`]="{ item }">
          {{ item.customers.length }} remaining
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="openCart(item.id)">
                mdi-cart-arrow-up
              </v-icon>
            </template>
            <span>Open cart</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="deleteCart(item.id)">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete cart</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          No open orders found.
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'Open',
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      search: '',
      loadingCarts: false
    }
  },
  computed: {
    ...mapState('cartManagement', {
      cart: state => state.cart,
      carts: state => state.carts
    }),
    headers () {
      return [
        { text: 'ID', align: ' d-none', value: 'id' },
        { text: 'Date Added', value: 'created_at' },
        { text: 'Customers Remaining', value: 'customers' },
        { text: 'Added By', value: 'created_by' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    ...mapActions('cartManagement', {
      createCart: 'createCart',
      previousCarts: 'getPreviousCarts',
      removeCart: 'removeCart'
    }),
    ...mapMutations('cartManagement', {
      selectCart: 'setCart'
    }),
    init () {
      this.loadPreviousCarts()
    },
    loadPreviousCarts () {
      this.loadingCarts = true
      this.previousCarts()
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loadingCarts = false
        })
    },
    addCart () {
      this.createCart()
        .then(() => {
          this.$router.push({name: 'create'})
        })
        .catch((error) => {
          this.$toast.error(error)
        })
    },
    openCart (id) {
      const cart = this.carts.find((cart) => cart.id === id)
      this.selectCart(cart)
      this.$router.push({name: 'create'})
    },
    deleteCart (id) {
      this.removeCart(id)
        .then(() => {
          this.$toast.success('Cart deleted')
        })
        .catch((error) => {
          this.$toast.error(error)
        })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>

</style>
