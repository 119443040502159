<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orders"
      :search="search"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="showToolbar">Order History</v-toolbar-title>
          <v-divider v-if="showToolbar" class="mx-4" inset vertical/>
          <v-text-field
            placeholder="Search"
            hide-details
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            dense/>
        </v-toolbar>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        ${{ item.total }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="statusColorMap[item.status]">
          {{ orderStatusMap[item.status] }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="openOrder(item.id)"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>Open order record</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        No recent orders found.
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'

export default {
  props: {
    showToolbar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      search: '',
      statusColorMap: {
        PENDING: 'primary',
        PROCESSING: 'warning',
        SHIPPED: 'success',
        UNPAID: 'warning',
        REFUNDED: 'red',
        PENDING_APPROVAL: 'primary',
        CANCELLED: 'red'
      },
      orders: []
    }
  },
  computed: {
    ...mapState({
      orderStatusMap: state => state.orderStatusMap
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: true
        },
        {
          text: 'Order Date',
          value: 'created_at'
        },
        {
          text: 'PO#',
          value: 'customer_po'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      fetch: 'fetchOrders'
    }),
    fetchOrders () {
      const obj = {
        customer_id: this.$route.params.id
      }
      this.fetch(obj)
        .then((response) => {
          this.orders = response
          this.loading = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
          this.loading = false
        })
    },
    openOrder (id) {
      this.$router.push({ name: 'order_details', params: { id } })
    }
  },
  created () {
    this.fetchOrders()
  }
}
</script>

<style lang="scss" scoped>

</style>
