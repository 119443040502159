var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-notes-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(" Order Notes "),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.addNote($event)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-plus")])],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _vm.comments.length > 0
                  ? _c(
                      "v-list",
                      {
                        attrs: {
                          dense: "",
                          "two-line": "",
                          "min-height": "150",
                          "max-height": "300"
                        }
                      },
                      [
                        _c(
                          "vue-scroll",
                          { attrs: { ops: _vm.ops } },
                          [
                            _vm._l(_vm.comments, function(comment, i) {
                              return [
                                _c(
                                  "v-list-item",
                                  { key: "comment-" + i },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticStyle: {
                                            "white-space": "normal"
                                          },
                                          domProps: {
                                            textContent: _vm._s(comment.comment)
                                          }
                                        }),
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v(
                                            _vm._s(comment.created_by) +
                                              " - " +
                                              _vm._s(comment.created_at)
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider", {
                                  key: "comment-divider-" + comment.id,
                                  staticClass: "p-0 m-0"
                                })
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("comment-modal", { ref: "comment" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }