<template>
  <div id="scheduled-payment-method-panel">
    <panel :content="!isNet30">
      <template #title>
        Payment Method
      </template>

      <div v-if="!isNet30">
        <div class="v-card__subtitle pb-0">{{ this.order.transaction.payment.card_name }}</div>
      </div>
      <div v-else>
        <div class="v-card__subtitle">Net30</div>
      </div>
      <template v-if="!isNet30" #content>
        <div>{{ this.order.transaction.payment.card_type }}: {{ this.order.transaction.payment.card_number }}</div>
        <div>{{ this.order.transaction.payment.card_expiry_formatted }}</div>
      </template>
    </panel>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ScheduledPaymentMethodPanel',
  components: {
    Panel: () => import('@/components/Panel')
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.scheduledOrder
    }),
    isNet30 () {
      return this.order.transaction.type === 'NET30'
    }
  }
}
</script>

<style scoped>

</style>
