import { add, addCustomers, get, getBatchDetails, getBatch, getOrders, updateValidation,
  show, update, destroy, getOrderHistory, getScheduledOrders, getReminders, search, getTaxRate,
  getAssignedProducts, addAssignedProduct, removeAssignedProduct, approvePending, merge } from '@/api/customer'

export default {
  addCustomer ({commit}, customer) {
    return new Promise((resolve, reject) => {
      add(customer)
        .then((response) => {
          commit('addCustomer', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addCustomers (context, payload) {
    return new Promise((resolve, reject) => {
      addCustomers(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCustomer ({commit}, data) {
    const { id, customer } = data
    return new Promise((resolve, reject) => {
      update(id, customer)
        .then((response) => {
          commit('updateCustomer', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  mergeCustomers (context, data) {
    const { id, payload } = data
    return new Promise((resolve, reject) => {
      merge(id, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateValidation ({commit}, data) {
    return new Promise((resolve, reject) => {
      updateValidation(data)
        .then((response) => {
          commit('updateCustomer', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCustomers ({commit}, data) {
    return new Promise((resolve, reject) => {
      get(data)
        .then((response) => {
          if (response.data !== undefined) {
            commit('setCustomers', response.data)
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchOrders (context, data) {
    return new Promise((resolve, reject) => {
      getOrders(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCustomerBatch ({commit}, data) {
    const { id, type } = data
    return new Promise((resolve, reject) => {
      getBatchDetails(id, type)
        .then((response) => {
          commit('setPendingCustomers', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCustomerBatches (context, data) {
    return new Promise((resolve, reject) => {
      getBatch(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  approveCustomerPending (context, data) {
    return new Promise((resolve, reject) => {
      approvePending(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchReminders (context, id) {
    return new Promise((resolve, reject) => {
      getReminders(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchOrderHistory (context, id) {
    return new Promise((resolve, reject) => {
      getOrderHistory(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchScheduledOrders (context, id) {
    return new Promise((resolve, reject) => {
      getScheduledOrders(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addProduct (context, data) {
    return new Promise((resolve, reject) => {
      addAssignedProduct(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeProduct (context, data) {
    return new Promise((resolve, reject) => {
      removeAssignedProduct(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAssignedProducts (context, id) {
    return new Promise((resolve, reject) => {
      getAssignedProducts(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCustomer ({commit}, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((response) => {
          commit('setCustomer', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeCustomer ({commit}, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((response) => {
          commit('removeCustomer', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchCustomers (context, data) {
    return new Promise((resolve, reject) => {
      search(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCustomerTaxRate (context, data) {
    return new Promise((resolve, reject) => {
      getTaxRate(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
