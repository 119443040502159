import api from './request'

export function add (data) {
  return api({
    url: '/comments',
    method: 'post',
    data
  })
}

export function show (id) {
  return api({
    url: `/comments/${id}`,
    method: 'get'
  })
}

export function get (data) {
  return api({
    url: '/comments',
    method: 'get',
    params: data
  })
}

export function update (id, data) {
  return api({
    url: `/comments/${id}`,
    method: 'post',
    data
  })
}

export function destroy (id) {
  return api({
    url: `/comments/${id}`,
    method: 'delete'
  })
}
