export default {
  setUsers (state, users) {
    state.users = users
  },
  setUserSettings (state, settings) {
    state.dashboard = settings
  },
  addUser (state, user) {
    state.users.push(Object.assign({}, user))
  },
  updateUser (state, payload) {
    const userIndex = state.users.findIndex((u) => u.id === payload.id)
    state.users.splice(userIndex, 1, payload)
  },
  removeUser (state, userId) {
    const userIndex = state.users.findIndex((u) => u.id === userId)
    state.users.splice(userIndex, 1)
  },
  updateCurrentUser (state, payload) {
    const userIndex = state.users.findIndex((u) => u.id === payload.id)
    state.users.splice(userIndex, 1, payload)
  }
}
