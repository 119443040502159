import { getProducts, showProduct, getMfg, getDimensions, requestCustom } from '@/api/product'

export default {
  fetchDimensions ({commit}) {
    return new Promise((resolve, reject) => {
      getDimensions()
        .then((response) => {
          commit('setDimensions', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchManufacturers ({commit}, payload) {
    return new Promise((resolve, reject) => {
      getMfg(payload)
        .then((response) => {
          commit('setManufacturers', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProduct (context, id) {
    return new Promise((resolve, reject) => {
      showProduct(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProducts (context, payload) {
    return new Promise((resolve, reject) => {
      getProducts(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  requestCustomFilter (context, payload) {
    return new Promise((resolve, reject) => {
      requestCustom(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
