var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduled-order-details-view" },
    [
      !_vm.loading
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-card", { attrs: { elevation: "0" } }, [
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "Scheduled Order# " +
                                _vm._s(_vm.$route.params.id) +
                                " Details"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(
                              "Status: " +
                                _vm._s(_vm.orderStatusMap[_vm.order.status])
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("CustomerAddressPanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("OrderItemsPanel")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("OrderActionsPanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("ShipDatePanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("OrderFrequencyPanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("PaymentMethodPanel")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("OrderNotesPanel")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-overlay",
                { attrs: { value: _vm.loading } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }