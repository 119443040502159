<template>
  <div id="order-notes-panel">
    <panel>
      <template #title>
        Order Notes
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="addNote"
          >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <template #content>
        <v-list
          v-if="comments.length > 0"
          dense
          two-line
          min-height="150"
          max-height="300"
        >
          <vue-scroll :ops="ops">
            <template v-for="(comment, i) in comments">
              <v-list-item :key="`comment-${i}`">
                <v-list-item-content>
                  <v-list-item-title style="white-space: normal;" v-text="comment.comment"></v-list-item-title>
                  <div class="caption">{{ comment.created_by }} - {{ comment.created_at }}</div>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="p-0 m-0" :key="`comment-divider-${comment.id}`"></v-divider>
            </template>
          </vue-scroll>
        </v-list>
      </template>
    </panel>
<!--    <comment-modal :is-modal-active="dialogComment" :close-modal="toggleCommentModal" :add-comment="addNote" />-->

    <comment-modal ref="comment"></comment-modal>
  </div>
</template>

<script>
import vueScroll from 'vuescroll'

import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Panel: () => import('@/components/Panel'),
    CommentModal: () => import('@/views/components/CommentModal'),
    vueScroll
  },
  data () {
    return {
      ops: {
        vuescroll: {
          sizeStrategy: 'number'
        },
        scrollPanel: {},
        rail: {},
        bar: {}
      }
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.order
    }),
    ...mapState('commentManagement', {
      comments: state => state.comments
    })
  },
  methods: {
    ...mapActions('commentManagement', {
      add: 'addComment',
      get: 'fetchComments',
      remove: 'removeComment'
    }),
    loadComments () {
      this.get({model_id: this.$route.params.id, type: 'order', internal: false})
        .then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    addNote () {
      this.$refs.comment.open('Add Note')
        .then((note) => {
          const newNote = {
            model_type: 'order',
            model_id: this.$route.params.id,
            reason_id: 5,
            internal: false,
            comment: note
          }
          this.add(newNote)
            .then(() => {
              this.$toast.success('New comment added!')
            })
            .catch((error) => {
              this.$toast.error(error.data ? error.data.error : error)
            })
        })
        .catch(() => {})
    }
  },
  created () {
    this.loadComments()
  }
}
</script>

<style scoped>

</style>
