import { add, get, show, update, destroy, getBatch, addScheduled, getScheduled, showScheduled,
  updateScheduled, snoozeScheduled, addScheduledProduct, createReturn, deleteReturn, exportBatch } from '@/api/order'

export default {
  add (context, order) {
    return new Promise((resolve, reject) => {
      add(order)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update ({commit}, data) {
    const { id, payload } = data
    return new Promise((resolve, reject) => {
      update(id, payload)
        .then((response) => {
          commit('updateOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateServiceTitan ({commit}, data) {
    const { id, payload } = data
    return new Promise((resolve, reject) => {
      update(id, payload)
        .then((response) => {
          commit('updateServiceTitanOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetch ({commit}, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((response) => {
          commit('setOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchOrders ({commit}, data) {
    return new Promise((resolve, reject) => {
      get(data)
        .then((response) => {
          if (response.data !== undefined) {
            commit('setOrders', response.data)
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchServiceTitanOrders ({commit}, data) {
    return new Promise((resolve, reject) => {
      get(data)
        .then((response) => {
          if (response.data !== undefined) {
            commit('setServiceTitanOrders', response.data)
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete ({commit}, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((response) => {
          commit('updateOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchBatches (context, data) {
    return new Promise((resolve, reject) => {
      getBatch(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  exportBatch (context, id) {
    return new Promise((resolve, reject) => {
      exportBatch(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addScheduled ({commit}, order) {
    return new Promise((resolve, reject) => {
      addScheduled(order)
        .then((response) => {
          commit('addScheduledOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateScheduled ({commit}, data) {
    const { id, payload } = data
    return new Promise((resolve, reject) => {
      updateScheduled(id, payload)
        .then((response) => {
          commit('updateScheduledOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  snoozeScheduled ({commit}, id) {
    return new Promise((resolve, reject) => {
      snoozeScheduled(id)
        .then((response) => {
          commit('updateScheduledOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addScheduledProduct ({commit}, data) {
    return new Promise((resolve, reject) => {
      addScheduledProduct(data)
        .then((response) => {
          commit('updateScheduledOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchScheduledOrder ({commit}, id) {
    return new Promise((resolve, reject) => {
      showScheduled(id)
        .then((response) => {
          commit('setScheduledOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchScheduledOrders ({commit}, data) {
    return new Promise((resolve, reject) => {
      getScheduled(data)
        .then((response) => {
          commit('setScheduledOrders', response.data)

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createReturnRequest ({commit}, data) {
    return new Promise((resolve, reject) => {
      createReturn(data)
        .then((response) => {
          commit('setOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteReturnRequest ({commit}, id) {
    return new Promise((resolve, reject) => {
      deleteReturn(id)
        .then((response) => {
          commit('setOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
