<template>
  <div>
    <v-row justify="center">
      <v-img
        class="mt-15"
        src="../../assets/fod_logo.png"
        max-height="250"
        contain
      ></v-img>
    </v-row>
    <v-row justify="center">
      <v-card
        class="mt-5"
        elevation="2"
        min-height="400"
        min-width="500"
        width="40%"
        >
        <div class="px-10 py-15">
          <v-form
            ref="loginForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="userLogin"
          >
            <v-text-field
              outlined
              label="Email"
              v-model="email"
              :rules="emailRules"
            ></v-text-field>

            <v-text-field
              outlined
              label="Password"
              v-model="password"
              :rules="passwordRules"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>

            <p class="text-right">
              <router-link :to="{name: 'forgot-password'}" class="text-inherit underline">Forgot Password?</router-link>
            </p>

            <v-btn
              type="submit"
              block
              :disabled="!valid"
              class="mb-3"
              color="primary"
            >
              Sign In
            </v-btn>

            <p class="text-center">
              Dont have an account? <router-link :to="{name: 'request-account'}" class="text-inherit underline ml-2">REQUEST ONE NOW</router-link>
            </p>
          </v-form>
        </div>
      </v-card>
    </v-row>

    <contractor-select-modal ref="contractorSelect"></contractor-select-modal>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ContractorSelectModal: () => import('@/views/components/ContractorSelectModal')
  },
  data () {
    return {
      valid: true,
      show: false,
      email: '',
      password: '',
      checkbox: false,
      redirect: '/',
      otherQuery: {},
      loading: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Invalid email address'
      ],
      passwordRules: [v => !!v || 'Password is required']
    }
  },
  computed: {
    ...mapState({
      site_id: state => state.site_id
    })
  },
  watch: {
    $route: {
      handler (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('contractorManagement', {
      fetchContractor: 'fetchContractor'
    }),
    ...mapActions('auth', {
      login: 'login'
    }),
    validate () {
      this.$refs.loginForm.validate()
    },
    userLogin () {
      this.loading = true

      const payload = {
        email: this.email,
        password: this.password,
        remember: this.checkbox,
        site_id: this.site_id
      }

      this.login(payload)
        .then((response) => {
          /*if (response.data.user.user_type === 'am') {
            this.loadContractors()
          } else {
            this.loadContractor(response.data.user.model_id)
          }*/
          this.loadContractor(response.data.user.model_id)
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    getOtherQuery (query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    loadContractor (id) {
      this.fetchContractor(id)
        .then(() => {
          this.loading = false
          this.$router.push({path: this.redirect || '/', query: this.otherQuery})
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  }
}
</script>

<style scoped>
  .form {
    width: 40%;
    min-width: 500px;
    min-height: 400px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .h-70 {
    height: 70px;
  }

  .h-86 {
    height: 86px;
  }

  .padding_top {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .brand_title {
    font-size: 30px;
    color: green;
  }

  .login button {
    width: 100%;
  }
</style>
