export default {
  setCustomer (state, customer) {
    state.customer = customer
  },
  setCustomers (state, customers) {
    state.customers = customers
  },
  setPendingCustomers (state, customers) {
    state.pendingCustomers = customers
  },
  addCustomer (state, payload) {
    state.customers.push(Object.assign({}, payload))
  },
  setValidationCustomers (state, payload) {
    localStorage.setItem('validation_customers', JSON.stringify(payload))
  },
  updateCustomer (state, payload) {
    const index = state.customers.findIndex((o) => o.id === payload.id)
    state.customers.splice(index, 1, payload)
  },
  removeCustomer (state, id) {
    const index = state.customers.findIndex((o) => o.id === id)
    state.customers.splice(index, 1)
  }
}
