var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "users-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-account-group" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Users ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-data-table", {
            staticClass: "mr-2 ml-2",
            attrs: {
              headers: _vm.headers,
              items: _vm.users,
              search: _vm.search,
              "loading-text": "Loading Users... Please wait"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "white" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Search",
                              "hide-details": "",
                              clearable: "",
                              "append-icon": "mdi-magnify",
                              dense: ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                disabled: !_vm.user.admin_access,
                                color: "primary",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.createDialog = true
                                }
                              }
                            },
                            [_vm._v(" Add User ")]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            small: "",
                            icon: "",
                            disabled: !_vm.canEdit(item.id)
                          },
                          on: {
                            click: function($event) {
                              return _vm.editUser(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            icon: "",
                            disabled: !_vm.canDelete(item.id)
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirmDelete(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-delete")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("confirmation-modal", { ref: "confirm" }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.createDialog,
            callback: function($$v) {
              _vm.createDialog = $$v
            },
            expression: "createDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("Create User")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "newUserForm",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.newUserValid,
                        callback: function($$v) {
                          _vm.newUserValid = $$v
                        },
                        expression: "newUserValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Name",
                          rules: _vm.nameRules,
                          required: ""
                        },
                        model: {
                          value: _vm.newUser.name,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "name", $$v)
                          },
                          expression: "newUser.name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.emailRules,
                          label: "Email",
                          required: ""
                        },
                        model: {
                          value: _vm.newUser.email,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "email", $$v)
                          },
                          expression: "newUser.email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          counter: 8,
                          rules: _vm.passwordRules,
                          label: "Password",
                          type: _vm.show ? "text" : "password",
                          "append-icon": _vm.show ? "mdi-eye" : "mdi-eye-off"
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.show = !_vm.show
                          }
                        },
                        model: {
                          value: _vm.newUser.password,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "password", $$v)
                          },
                          expression: "newUser.password"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          counter: 8,
                          rules: _vm.passwordRules.concat(
                            _vm.newPasswordConfirmationRule
                          ),
                          label: "Confirm Password",
                          type: _vm.show ? "text" : "password",
                          "append-icon": _vm.show ? "mdi-eye" : "mdi-eye-off"
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.show = !_vm.show
                          }
                        },
                        model: {
                          value: _vm.newUser.password_confirm,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "password_confirm", $$v)
                          },
                          expression: "newUser.password_confirm"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: { label: "Is Admin?" },
                        model: {
                          value: _vm.newUser.admin_access,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "admin_access", $$v)
                          },
                          expression: "newUser.admin_access"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: { label: "Is Active?" },
                        model: {
                          value: _vm.newUser.active,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "active", $$v)
                          },
                          expression: "newUser.active"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeAdd }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "blue darken-1",
                        text: "",
                        disabled: !_vm.newUserValid
                      },
                      on: { click: _vm.add }
                    },
                    [_vm._v("Add User")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.editDialog,
            callback: function($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("Edit User")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "editUserForm",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.editUserValid,
                        callback: function($$v) {
                          _vm.editUserValid = $$v
                        },
                        expression: "editUserValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Name",
                          rules: _vm.nameRules,
                          required: ""
                        },
                        model: {
                          value: _vm.editedUser.name,
                          callback: function($$v) {
                            _vm.$set(_vm.editedUser, "name", $$v)
                          },
                          expression: "editedUser.name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.emailRules,
                          label: "Email",
                          required: ""
                        },
                        model: {
                          value: _vm.editedUser.email,
                          callback: function($$v) {
                            _vm.$set(_vm.editedUser, "email", $$v)
                          },
                          expression: "editedUser.email"
                        }
                      }),
                      this.user.admin_access
                        ? _c(
                            "div",
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Is Admin?" },
                                model: {
                                  value: _vm.editedUser.admin_access,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editedUser,
                                      "admin_access",
                                      $$v
                                    )
                                  },
                                  expression: "editedUser.admin_access"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-checkbox", {
                        attrs: { label: "Is Active?" },
                        model: {
                          value: _vm.editedUser.active,
                          callback: function($$v) {
                            _vm.$set(_vm.editedUser, "active", $$v)
                          },
                          expression: "editedUser.active"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: { label: "Reset Password?" },
                        model: {
                          value: _vm.resetPassword,
                          callback: function($$v) {
                            _vm.resetPassword = $$v
                          },
                          expression: "resetPassword"
                        }
                      }),
                      _vm.resetPassword
                        ? _c("v-text-field", {
                            attrs: {
                              counter: 8,
                              rules: _vm.passwordRules,
                              label: "Password",
                              type: _vm.show ? "text" : "password",
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off"
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.show = !_vm.show
                              }
                            },
                            model: {
                              value: _vm.editedUser.password,
                              callback: function($$v) {
                                _vm.$set(_vm.editedUser, "password", $$v)
                              },
                              expression: "editedUser.password"
                            }
                          })
                        : _vm._e(),
                      _vm.resetPassword
                        ? _c("v-text-field", {
                            attrs: {
                              counter: 8,
                              rules: _vm.passwordRules.concat(
                                _vm.editPasswordConfirmationRule
                              ),
                              label: "Confirm Password",
                              type: _vm.show ? "text" : "password",
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off"
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.show = !_vm.show
                              }
                            },
                            model: {
                              value: _vm.editedUser.password_confirm,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editedUser,
                                  "password_confirm",
                                  $$v
                                )
                              },
                              expression: "editedUser.password_confirm"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeEdit }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "blue darken-1",
                        text: "",
                        disabled: !_vm.editUserValid
                      },
                      on: { click: _vm.edit }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "300" },
          model: {
            value: _vm.usersUploadListLoadingDialog,
            callback: function($$v) {
              _vm.usersUploadListLoadingDialog = $$v
            },
            expression: "usersUploadListLoadingDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" Uploading users list "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }