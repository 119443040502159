var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "payment-method-panel" } },
    [
      _c(
        "panel",
        {
          attrs: { content: !_vm.isNet30 },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [_vm._v(" Payment Method ")]
                },
                proxy: true
              },
              !_vm.isNet30
                ? {
                    key: "content",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.order.transactions[0].payment.card_type
                            ) +
                              ": " +
                              _vm._s(
                                _vm.order.transactions[0].payment.card_number
                              )
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.order.transactions[0].payment
                                .card_expiry_formatted
                            )
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          !_vm.isNet30
            ? _c("div", [
                _c("div", { staticClass: "v-card__subtitle pb-0" }, [
                  _vm._v(_vm._s(_vm.order.transactions[0].payment.card_name))
                ])
              ])
            : _c("div", [
                _c("div", { staticClass: "v-card__subtitle" }, [
                  _vm._v("Net30")
                ])
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }