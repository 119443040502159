<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="addresses"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            @click="openAddAddress"
          >
            New Address
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.fname`]="{ item }">
        {{ item.full_name }}
      </template>

      <template v-slot:[`item.street1`]="{ item }">
        {{ item.street1 }} {{ item.street2 }} <br>
        {{ item.city }}, {{ item.state.name }} {{ item.zip }}
      </template>

      <template v-slot:[`item.validated`]="{ item }">
        <v-chip
          :color="getValidatedColor(item.validated)">
          {{ item.validated ? 'True' : 'False' }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="openEditAddress(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit address</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="addresses.length > 1"
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="deleteAddress(item.id)"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <span>Delete address</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        No scheduled orders found.
      </template>
    </v-data-table>

    <v-dialog
      v-model="createNewAddress"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ address.id ? 'Update' : 'Add' }} Address</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <google-auto-complete @google-address="addressFound"></google-auto-complete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field dense v-model="address.fname" label="First Name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field dense v-model="address.lname" label="Last Name"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="address.company" label="Company"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="address.street1" label="Street 1"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="address.street2" label="Street 2"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="address.city" label="City"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="address.state_id"
                  :items="states"
                  :filter="statesFilter"
                  item-text="text"
                  item-value="value"
                  label="State"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  dense
                  v-model="address.zip"
                  label="Zip"
                  :rules="zipRules"
                  maxlength="5"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeAdd"
            >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addAddress"
            >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-modal ref="confirm" />
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'

export default {
  props: {
    showToolbar: {
      type: Boolean,
      default: false
    },
    customer_id: {
      type: Number,
      default: undefined
    },
    contractor_id: {
      type: Number,
      default: undefined
    }
  },
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal'),
    GoogleAutoComplete: () => import('@/views/components/GoogleAutoComplete')
  },
  data () {
    return {
      expanded: [],
      loading: true,
      zipRules: [v => v.length <= 5 || 'Max 5 characters'],
      address: {
        id: null,
        fname: '',
        lname: '',
        company: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: null,
        model_type: 'customer'
      },
      createNewAddress: false
    }
  },
  computed: {
    ...mapState('addressManagement', {
      addresses: state => state.addresses
    }),
    ...mapState({
      states: state => state.stateOptions
    }),
    headers () {
      return [
        {
          text: 'ID',
          align: ' d-none',
          value: 'id',
          sortable: true
        },
        {
          text: 'Name',
          value: 'fname'
        },
        {
          text: 'Address',
          value: 'street1'
        },
        {
          text: 'Validated',
          value: 'validated'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('addressManagement', {
      add: 'addAddress',
      fetch: 'fetchAddresses',
      update: 'updateAddress',
      delete: 'delete'
    }),
    init () {
      this.loadAddresses()
    },
    loadAddresses () {
      this.fetch({model_id: this.$route.params.id, model_type: 'customer'})
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
          this.loading = false
        })
    },
    statesFilter (item, queryText) {
      const name = item.text.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    addressFound (address) {
      this.address.street1 = address.street1
      this.address.city = address.city
      this.address.state_id = address.state_id
      this.address.zip = address.zip
      this.address.validated = address.validated
    },
    async deleteAddress (id) {
      if (this.addresses.length > 1) {
        if (await this.$refs.confirm.open('Delete Address?', 'Are you sure you want to delete this address?', { color: 'red' })) {
          this.delete(id)
            .then(() => {
              this.$toast.success('Address deleted!')
            })
            .catch((error) => {
              this.$toast.error(error.data ? error.data.error : error)
            })
        }
      } else {
        this.$toast.error('Must create a new address before deleting.')
      }
    },
    openEditAddress (address) {
      this.address = {
        id: address.id,
        fname: address.fname,
        lname: address.lname,
        company: address.company,
        street1: address.street1,
        street2: address.street2,
        city: address.city,
        state_id: address.state_id,
        zip: address.zip,
        country: 'US',
        validated: address.validated,
        model_id: address.addressable_id,
        model_type: 'customer'
      }
      this.createNewAddress = true
    },
    closeAdd () {
      this.createNewAddress = false
    },
    openAddAddress () {
      this.resetDefault()
      this.createNewAddress = true
    },
    addAddress () {
      if (this.address.id) {
        this.update({id: this.address.id, address: this.address})
          .then(() => {
            this.$emit('reload')
            this.closeAdd()
            this.$toast.success('Address updated!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      } else {
        this.add(this.address)
          .then(() => {
            this.$emit('reload')
            this.closeAdd()
            this.$toast.success('Address created!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    resetDefault () {
      this.address = {
        id: null,
        fname: '',
        lname: '',
        street1: '',
        street2: '',
        city: '',
        state_id: '',
        zip: '',
        country: 'US',
        validated: false,
        model_id: this.$route.params.id,
        model_type: 'customer'
      }
    },
    getValidatedColor (valid) {
      if (valid) {
        return 'green'
      } else {
        return 'red'
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

</style>
