import { render, staticRenderFns } from "./OrderHistory.vue?vue&type=template&id=7b686c70&scoped=true&"
import script from "./OrderHistory.vue?vue&type=script&lang=js&"
export * from "./OrderHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b686c70",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VDataTable,VDivider,VIcon,VTextField,VToolbar,VToolbarTitle,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/forge/demo.filterondemand.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b686c70')) {
      api.createRecord('7b686c70', component.options)
    } else {
      api.reload('7b686c70', component.options)
    }
    module.hot.accept("./OrderHistory.vue?vue&type=template&id=7b686c70&scoped=true&", function () {
      api.rerender('7b686c70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customer/components/OrderHistory.vue"
export default component.exports