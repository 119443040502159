<template>
  <v-container
    id="company-view"
    >

    <material-card
      color="primary"
      icon="mdi-office-building"
    >
      <template #title>
        Company Profile
      </template>

      <v-form v-if="!loading">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="contractorLocal.name"
                label="Company Name">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="contractorLocal.contact_phone"
                label="Contact Phone">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="contractorLocal.contact_email"
                label="Contact Email">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="contractorLocal.address.street1"
                label="Street 1">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="contractorLocal.address.street2"
                label="Street 2">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="contractorLocal.address.city"
                label="City">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                v-model="contractorLocal.address.state_id"
                :items="states"
                :filter="statesFilter"
                item-text="name"
                item-value="id"
                label="State"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="contractorLocal.address.zip"
                label="Zip Code"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid>
          <div class="fontsmall">
            Default Payment Method
            <v-radio-group v-model="contractorLocal.settings.default_payment" mandatory>
              <v-radio :disabled="!contractorLocal.settings.credit" label="Net Terms" value="net30"></v-radio>
              <v-radio label="Credit Card" value="cc"></v-radio>
            </v-radio-group>
          </div>
        </v-container>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="handleUpdate"
        >
          UPDATE
        </v-btn>
      </template>
    </material-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      fullWidth: 0,
      toggle: true,
      pay_method: '',
      contractorLocal: {
        id: undefined,
        name: '',
        contact_phone: '',
        contact_email: '',
        settings: {},
        address: {
          id: undefined,
          name: '',
          street1: '',
          street2: '',
          city: '',
          state_id: undefined,
          zip: ''
        }
      },
      loading: true,
      disable_net30: true
    }
  },
  computed: {
    ...mapState('contractorManagement', {
      contractor: state => state.contractor
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState({
      states: state => state.states
    })
  },
  methods: {
    ...mapActions('contractorManagement', {
      fetch: 'fetchContractor',
      update: 'updateContractor'
    }),
    ...mapActions({
      'fetchStates': 'fetchStates'
    }),
    statesFilter (item, queryText) {
      const name = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1
    },
    loadStates () {
      this.fetchStates().then(() => {})
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    loadContractor () {
      this.loading = true

      this.fetch(this.user.model_id)
        .then(() => {
          this.setCompanyData()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    setCompanyData () {
      const shipIndex = this.contractor.addresses.findIndex((a) => a.id === this.contractor.default_shipping)

      this.contractorLocal = {
        id: this.contractor.id,
        name: this.contractor.name,
        contact_phone: this.contractor.contact_phone,
        contact_email: this.contractor.contact_email,
        settings: this.contractor.settings,
        address: {
          id: this.contractor.addresses[shipIndex].id,
          street1: this.contractor.addresses[shipIndex].street1,
          street2: this.contractor.addresses[shipIndex].street2,
          city: this.contractor.addresses[shipIndex].city,
          state_id: this.contractor.addresses[shipIndex].state_id,
          zip: this.contractor.addresses[shipIndex].zip
        }
      }

      if (this.contractorLocal.settings.credit) {
        this.disable_net30 = false
      }

      this.loading = false
    },
    handleUpdate () {
      const obj = {
        name: this.contractorLocal.name,
        contact_email: this.contractorLocal.contact_email,
        contact_phone: this.contractorLocal.contact_phone,
        settings: this.contractorLocal.settings,
        address: {
          id: this.contractorLocal.address.id,
          street1: this.contractorLocal.address.street1,
          street2: this.contractorLocal.address.street2,
          city: this.contractorLocal.address.city,
          state_id: this.contractorLocal.address.state_id,
          zip: this.contractorLocal.address.zip
        }
      }
      this.update({id: this.contractorLocal.id, contractor: obj})
        .then(() => {
          this.$toast.success('Company Update Successful!')
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    }
  },
  created () {
    this.loadStates()
    this.loadContractor()
  }
}
</script>
