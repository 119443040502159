var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "scheduled-order-actions-panel" } },
    [
      _c("panel", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Order Actions ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("v-select", {
                  attrs: {
                    items: _vm.frequencyOptions,
                    "menu-props": { maxHeight: 350 }
                  },
                  model: {
                    value: _vm.frequency,
                    callback: function($$v) {
                      _vm.frequency = $$v
                    },
                    expression: "frequency"
                  }
                }),
                _vm.frequency < 0
                  ? _c("v-text-field", {
                      attrs: {
                        type: "number",
                        label: "Custom frequency (days)",
                        outlined: ""
                      },
                      model: {
                        value: _vm.customFrequency,
                        callback: function($$v) {
                          _vm.customFrequency = $$v
                        },
                        expression: "customFrequency"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.frequency === _vm.originalFrequency,
                      block: "",
                      color: "primary"
                    },
                    on: { click: _vm.updateFrequency }
                  },
                  [_vm._v(" Update Ship Date ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }