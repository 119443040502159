var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "customers-pending-schedule-view" } },
    [
      _vm.errorMessages.length > 0
        ? _c(
            "v-alert",
            { attrs: { prominent: "", type: "error" } },
            _vm._l(_vm.errorMessages, function(message, index) {
              return _c("span", { key: index }, [
                _vm._v(" " + _vm._s(message) + " "),
                _c("br")
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-calendar-plus" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Customers Pending Schedule ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-data-table", {
            staticClass: "mr-2 ml-2",
            attrs: {
              "item-key": "id",
              headers: _vm.headers,
              items: _vm.customers,
              loading: _vm.loading,
              "loading-text": "Loading Customers... Please wait",
              search: _vm.search,
              "single-expand": true,
              expanded: _vm.expanded,
              "show-expand": ""
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "white" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Search",
                              "hide-details": "",
                              clearable: "",
                              "append-icon": "mdi-magnify",
                              "single-line": "",
                              dense: ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _c("v-spacer")
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.customer",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(item.customer.fname) +
                            " " +
                            _vm._s(item.customer.lname)
                        )
                      ]),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(item.customer.shipping_address.street1) +
                          " " +
                          _vm._s(item.customer.shipping_address.street2) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(item.customer.shipping_address.city) +
                          ", " +
                          _vm._s(item.customer.shipping_address.state.name) +
                          " " +
                          _vm._s(item.customer.shipping_address.zip) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.products",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.filterProducts(item.products).length) +
                          " Unscheduled Products "
                      )
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.createSchedule(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-calendar-plus")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "customer_detail",
                                params: { id: item.customer.id }
                              })
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-account-search")])],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "expanded-item",
                  fn: function(ref) {
                    var headers = ref.headers
                    var item = ref.item
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c("v-simple-table", {
                            staticClass: "m-2",
                            attrs: { dense: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("ID")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Name")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Mfg")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Part#")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Case Qty")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Price")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Status")]
                                          ),
                                          _c("th")
                                        ])
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(item.products, function(
                                          product
                                        ) {
                                          return _c("tr", { key: product.id }, [
                                            _c("td", [
                                              _vm._v(_vm._s(product.id))
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(product.name))
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(product.mfg_name))
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(product.mfg_pn))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(product.purchase_qty)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(product.price))
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color:
                                                        _vm.statusColorMap[
                                                          product.status
                                                        ]
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.orderStatusMap[
                                                            product.status
                                                          ]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      icon: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeAssignedProduct(
                                                          item.customer_id,
                                                          product.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-delete-forever-outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "no-data",
                  fn: function() {
                    return [_vm._v(" No customers pending schedule found. ")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.createNewSchedule,
            callback: function($$v) {
              _vm.createNewSchedule = $$v
            },
            expression: "createNewSchedule"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-stepper",
                {
                  attrs: { "alt-labels": "", elevation: "0" },
                  model: {
                    value: _vm.currentStep,
                    callback: function($$v) {
                      _vm.currentStep = $$v
                    },
                    expression: "currentStep"
                  }
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            complete: _vm.currentStep > 1,
                            editable: "",
                            step: "1"
                          }
                        },
                        [_vm._v(" Product Selection ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            step: "2",
                            editable: "",
                            complete: _vm.currentStep > 2
                          }
                        },
                        [_vm._v(" Schedule Selection ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            step: "3",
                            editable: "",
                            complete: _vm.currentStep > 3
                          }
                        },
                        [_vm._v(" Address Selection ")]
                      ),
                      _c("v-divider"),
                      _c("v-stepper-step", { attrs: { step: "4" } }, [
                        _vm._v(" Select Payment ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          dense: "",
                                          items: _vm.products,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: "Select Assigned Product",
                                          "return-object": ""
                                        },
                                        model: {
                                          value: _vm.product,
                                          callback: function($$v) {
                                            _vm.product = $$v
                                          },
                                          expression: "product"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          type: "number",
                                          label: "Quantity"
                                        },
                                        model: {
                                          value: _vm.selectedQuantity,
                                          callback: function($$v) {
                                            _vm.selectedQuantity = $$v
                                          },
                                          expression: "selectedQuantity"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: { click: _vm.selectProduct }
                                        },
                                        [_vm._v(" Add Product ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.selectedProducts.length > 0
                                    ? _c(
                                        "v-card",
                                        { attrs: { outlined: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-list",
                                                _vm._l(
                                                  _vm.selectedProducts,
                                                  function(item, index) {
                                                    return _c(
                                                      "v-list-item",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "cartProduct"
                                                                  )(item.name)
                                                                ) +
                                                                " X " +
                                                                _vm._s(
                                                                  item.qty
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeSelection(
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete-forever-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.selectedProducts.length === 0,
                                        color: "primary"
                                      },
                                      on: { click: _vm.scheduleSelection }
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.frequencyOptions,
                                      label: "Frequency"
                                    },
                                    model: {
                                      value: _vm.scheduledOrder.frequency,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scheduledOrder,
                                          "frequency",
                                          $$v
                                        )
                                      },
                                      expression: "scheduledOrder.frequency"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.scheduledOrder.next,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          return _vm.$set(
                                            _vm.scheduledOrder,
                                            "next",
                                            $event
                                          )
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.scheduledOrder,
                                            "next",
                                            $event
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label: "Next Date",
                                                        "append-icon":
                                                          "mdi-calendar",
                                                        readonly: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.scheduledOrder
                                                            .next,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.scheduledOrder,
                                                            "next",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scheduledOrder.next"
                                                      }
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.nextDateMenu,
                                        callback: function($$v) {
                                          _vm.nextDateMenu = $$v
                                        },
                                        expression: "nextDateMenu"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: {
                                          "no-title": "",
                                          scrollable: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$refs.menu.save(
                                              _vm.scheduledOrder.next
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.scheduledOrder.next,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scheduledOrder,
                                              "next",
                                              $$v
                                            )
                                          },
                                          expression: "scheduledOrder.next"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.currentStep = 3
                                        }
                                      }
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "mb-5",
                                    attrs: {
                                      items: _vm.addresses,
                                      label: "Select shipping address",
                                      "item-value": "id",
                                      flat: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.street1) +
                                                " " +
                                                _vm._s(data.item.street2) +
                                                ", " +
                                                _vm._s(data.item.city) +
                                                ", " +
                                                _vm._s(data.item.state.name) +
                                                " " +
                                                _vm._s(data.item.zip) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-subtitle",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.full_name
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("v-spacer"),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.street1
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          data.item.street2
                                                        ) +
                                                        ", " +
                                                        _vm._s(data.item.city) +
                                                        ", " +
                                                        _vm._s(
                                                          data.item.state.name
                                                        ) +
                                                        " " +
                                                        _vm._s(data.item.zip) +
                                                        " "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "append-item",
                                        fn: function() {
                                          return [
                                            _c("v-divider", {
                                              staticClass: "mb-2"
                                            }),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-subtitle",
                                                  { staticClass: "ml-5" },
                                                  [
                                                    _vm._v(
                                                      " Address not found? "
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          text: ""
                                                        },
                                                        on: {
                                                          click: _vm.newAddress
                                                        }
                                                      },
                                                      [_vm._v("Create Address")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ]),
                                    model: {
                                      value: _vm.scheduledOrder.ship_address_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scheduledOrder,
                                          "ship_address_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scheduledOrder.ship_address_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.currentStep = 4
                                        }
                                      }
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.paymentMethods,
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: "Select payment option",
                                      dense: "",
                                      "single-line": ""
                                    },
                                    on: { change: _vm.selectPayment },
                                    model: {
                                      value: _vm.scheduledOrder.payment_method,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scheduledOrder,
                                          "payment_method",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scheduledOrder.payment_method"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.selectPaymentOption
                                ? _c(
                                    "v-card-text",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.payments,
                                          label: "Select Payment Method",
                                          "item-value": "id",
                                          dense: "",
                                          flat: ""
                                        },
                                        on: {
                                          change: _vm.paymentMethodSelected
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.card_name) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.card_name
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("v-spacer"),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.card_type
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.card_number
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.card_expiry_formatted
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "append-item",
                                              fn: function() {
                                                return [
                                                  _c("v-divider", {
                                                    staticClass: "mb-2"
                                                  }),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        { staticClass: "ml-5" },
                                                        [
                                                          _vm._v(
                                                            " Payment method not found? "
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.toggleNewPaymentModal(
                                                                    true
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Add New Payment Method"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          102068775
                                        ),
                                        model: {
                                          value: _vm.scheduledOrder.payment_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scheduledOrder,
                                              "payment_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scheduledOrder.payment_id"
                                        }
                                      }),
                                      _vm.showPaymentAddressSelection
                                        ? _c("v-autocomplete", {
                                            staticClass: "mt-5 mb-5",
                                            attrs: {
                                              items: _vm.addresses,
                                              label: "Select shipping address",
                                              "item-value": "id",
                                              flat: "",
                                              dense: "",
                                              "hide-details": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function(data) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.street1
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            data.item.street2
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            data.item.city
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            data.item.state.name
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            data.item.zip
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item",
                                                  fn: function(data) {
                                                    return [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .full_name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("v-spacer"),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .street1
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .street2
                                                                  ) +
                                                                  ", " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .city
                                                                  ) +
                                                                  ", " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .state
                                                                      .name
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .zip
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "append-item",
                                                  fn: function() {
                                                    return [
                                                      _c("v-divider", {
                                                        staticClass: "mb-2"
                                                      }),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            {
                                                              staticClass:
                                                                "ml-5"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Address not found? "
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    text: ""
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.newAddress
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Create Address"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              3767832760
                                            ),
                                            model: {
                                              value:
                                                _vm.scheduledOrder
                                                  .bill_address_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scheduledOrder,
                                                  "bill_address_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scheduledOrder.bill_address_id"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.addSchedule }
                                    },
                                    [_vm._v(" Add ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.showNewAddressForm,
            callback: function($$v) {
              _vm.showNewAddressForm = $$v
            },
            expression: "showNewAddressForm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [_vm._v("Add Address")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("vuetify-google-autocomplete", {
                                ref: "address",
                                attrs: {
                                  id: "autocomplete",
                                  placeholder: "Enter address for validation"
                                },
                                on: {
                                  "no-results-found": _vm.noResults,
                                  placechanged: _vm.getAddressData
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-map-search")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { dense: "", label: "Street 1" },
                                model: {
                                  value: _vm.address.street1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "street1", $$v)
                                  },
                                  expression: "address.street1"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { dense: "", label: "Street 2" },
                                model: {
                                  value: _vm.address.street2,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "street2", $$v)
                                  },
                                  expression: "address.street2"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { dense: "", label: "City" },
                                model: {
                                  value: _vm.address.city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "city", $$v)
                                  },
                                  expression: "address.city"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.states,
                                  filter: _vm.statesFilter,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: "State",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.address.state_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "state_id", $$v)
                                  },
                                  expression: "address.state_id"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  label: "Zip",
                                  rules: _vm.zipRules,
                                  maxlength: "5",
                                  counter: ""
                                },
                                model: {
                                  value: _vm.address.zip,
                                  callback: function($$v) {
                                    _vm.$set(_vm.address, "zip", $$v)
                                  },
                                  expression: "address.zip"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showNewAddressForm = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.addAddress }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("add-payment-modal", {
        attrs: { isModalActive: _vm.addNewPayment },
        on: { "close-modal": _vm.toggleNewPaymentModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }