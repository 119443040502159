import { get } from '../api/state'
import { contactUs } from '../api/contact'

export default {
  fetchStates ({ commit }) {
    return new Promise((resolve, reject) => {
      get()
        .then((response) => {
          commit('setStates', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendContactUs (context, payload) {
    return new Promise((resolve, reject) => {
      contactUs(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
