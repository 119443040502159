import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import state from './state'
import actions from './actions'
import getters from './getters'

import moduleAuth from './auth/moduleAuth.js'
import moduleCartManagement from './cart-management/moduleCartManagement'
import moduleItemManagement from './item-management/moduleItemManagement'
import moduleUserManagement from './user-management/moduleUserManagement'
import moduleOrderManagement from './order-management/moduleOrderManagement'
import moduleReportManagement from './report-management/moduleReportManagement'
import moduleAddressManagement from './address-management/moduleAddressManagement'
import moduleCommentManagement from './comment-management/moduleCommentManagement'
import modulePaymentManagement from './payment-management/modulePaymentManagement'
import moduleReminderManagement from './reminder-management/moduleReminderManagement'
import moduleCustomerManagement from './customer-management/moduleCustomerManagement'
import moduleContractorManagement from './contractor-management/moduleContractorManagement'
import moduleNotificationManagement from './notifications/moduleNotifications'

Vue.use(Vuex)
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth: moduleAuth,
    cartManagement: moduleCartManagement,
    itemManagement: moduleItemManagement,
    userManagement: moduleUserManagement,
    orderManagement: moduleOrderManagement,
    reportManagement: moduleReportManagement,
    addressManagement: moduleAddressManagement,
    commentManagement: moduleCommentManagement,
    paymentManagement: modulePaymentManagement,
    reminderManagement: moduleReminderManagement,
    customerManagement: moduleCustomerManagement,
    contractorManagement: moduleContractorManagement,
    notificationManagement: moduleNotificationManagement
  }
})
