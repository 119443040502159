<template>
  <div id="scheduled-order-address-panel">
    <panel v-if="!loading">
      <template #title>
        Customer Address - <span class="px-2 subtitle text-decoration-none"><router-link :to="{ name: 'customer_detail', params: { id: order.customer.id } }">View Customer Record</router-link></span>
      </template>

      <template #content>
        <v-form>
          <v-container fluid>
            <v-row dense>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  readonly
                  :disabled="true"
                  v-model="addressLocal.fname"
                  label="First Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  readonly
                  :disabled="true"
                  v-model="addressLocal.lname"
                  label="Last Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  readonly
                  :disabled="true"
                  v-model="addressLocal.street1"
                  label="Street 1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  readonly
                  :disabled="true"
                  v-model="addressLocal.street2"
                  label="Street 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  readonly
                  :disabled="true"
                  v-model="addressLocal.city"
                  label="City"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  readonly
                  :disabled="true"
                  v-model="addressLocal.state.name"
                  label="State"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  readonly
                  :disabled="true"
                  v-model="addressLocal.zip"
                  label="Zip Code"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <span class="text-sm-caption float-right">*To edit customer address, go to customer record page.</span>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
    </panel>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ScheduledCustomerAddressPanel',
  components: {
    Panel: () => import('@/components/Panel')
  },
  data () {
    return {
      loading: true,
      dirty: false,
      addressLocal: {}
    }
  },
  computed: {
    ...mapState('orderManagement', {
      order: state => state.scheduledOrder
    }),
    ...mapState('addressManagement', {
      address: state => state.address
    })
  },
  methods: {
    ...mapActions('addressManagement', {
      fetch: 'fetchAddress'
    }),
    loadAddress () {
      this.fetch(this.order.customer.shipping_address.id)
        .then((response) => {
          this.addressLocal = Object.assign({}, response)
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.loadAddress()
  }
}
</script>

<style scoped>

</style>
