var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.orders,
          search: _vm.search,
          "single-expand": "",
          expanded: _vm.expanded,
          "show-expand": "",
          loading: _vm.loading,
          "loading-text": "Loading... Please wait"
        },
        on: {
          "update:expanded": function($event) {
            _vm.expanded = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "white" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.openAddSchedule }
                        },
                        [_c("v-icon", [_vm._v("mdi-calendar-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.status",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    { attrs: { color: _vm.statusColorMap[item.status] } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.orderStatusMap[item.status]) + " "
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$router.push({
                                              name: "scheduled_order_details",
                                              params: { id: item.id }
                                            })
                                          }
                                        }
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-pencil ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Edit scheduled order")])]
                  ),
                  item.status !== "CANCELLED"
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.snoozeSchedule(
                                                  item.id
                                                )
                                              }
                                            }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-alarm ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Snooze scheduled order 30 days")])]
                      )
                    : _vm._e(),
                  item.status !== "CANCELLED"
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.cancelOrder(item.id)
                                              }
                                            }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-delete-outline ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Cancel scheduled order")])]
                      )
                    : _vm._e(),
                  item.status === "CANCELLED"
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.activateOrder(
                                                  item.id
                                                )
                                              }
                                            }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-check ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Activate scheduled order")])]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c("v-simple-table", {
                        staticClass: "m-2",
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("ID")
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Name")
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Mfg")
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Part#")
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Case Qty")
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Order Qty")
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Price")
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(item.products, function(lineItem) {
                                      return _c("tr", { key: lineItem.id }, [
                                        _c("td", [
                                          _vm._v(_vm._s(lineItem.product_id))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$utils.productName(
                                                lineItem.product
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$utils.productMfgName(
                                                lineItem.product.item
                                                  .manufacturer.name
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(lineItem.product.item.mfg_pn)
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              lineItem.product.purchase_qty
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(lineItem.quantity))
                                        ]),
                                        _c("td", [
                                          _vm._v("$" + _vm._s(lineItem.price))
                                        ])
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [_vm._v(" No scheduled orders found. ")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.createNewSchedule,
            callback: function($$v) {
              _vm.createNewSchedule = $$v
            },
            expression: "createNewSchedule"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-stepper",
                {
                  attrs: { "alt-labels": "", elevation: "0" },
                  model: {
                    value: _vm.currentStep,
                    callback: function($$v) {
                      _vm.currentStep = $$v
                    },
                    expression: "currentStep"
                  }
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            complete: _vm.currentStep > 1,
                            editable: "",
                            step: "1"
                          }
                        },
                        [_vm._v(" Product Selection ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            step: "2",
                            editable: "",
                            complete: _vm.currentStep > 2
                          }
                        },
                        [_vm._v(" Schedule Selection ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            step: "3",
                            editable: "",
                            complete: _vm.currentStep > 3
                          }
                        },
                        [_vm._v(" Shipping Address ")]
                      ),
                      _c("v-divider"),
                      _c("v-stepper-step", { attrs: { step: "4" } }, [
                        _vm._v(" Select Payment ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          dense: "",
                                          items: _vm.products,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: "Select Assigned Product",
                                          "return-object": ""
                                        },
                                        model: {
                                          value: _vm.product,
                                          callback: function($$v) {
                                            _vm.product = $$v
                                          },
                                          expression: "product"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          type: "number",
                                          label: "Quantity"
                                        },
                                        model: {
                                          value: _vm.selectedQuantity,
                                          callback: function($$v) {
                                            _vm.selectedQuantity = $$v
                                          },
                                          expression: "selectedQuantity"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: { click: _vm.selectProduct }
                                        },
                                        [_vm._v(" Add Product ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.selectedProducts.length > 0
                                    ? _c(
                                        "v-card",
                                        { attrs: { outlined: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-list",
                                                _vm._l(
                                                  _vm.selectedProducts,
                                                  function(item, index) {
                                                    return _c(
                                                      "v-list-item",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.quantity
                                                                ) +
                                                                " x " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "truncate"
                                                                  )(
                                                                    item.name,
                                                                    60
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  size: "small"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeSelection(
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete-forever-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.selectedProducts.length === 0,
                                        color: "primary"
                                      },
                                      on: { click: _vm.scheduleSelection }
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.frequencyOptions,
                                      label: "Frequency",
                                      "menu-props": { maxHeight: 350 }
                                    },
                                    on: { change: _vm.selectFrequency },
                                    model: {
                                      value: _vm.selectedFrequency,
                                      callback: function($$v) {
                                        _vm.selectedFrequency = $$v
                                      },
                                      expression: "selectedFrequency"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.customFrequency
                                ? _c(
                                    "v-card-text",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: "Custom frequency (days)",
                                          outlined: ""
                                        },
                                        model: {
                                          value: _vm.scheduledOrder.frequency,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scheduledOrder,
                                              "frequency",
                                              $$v
                                            )
                                          },
                                          expression: "scheduledOrder.frequency"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.scheduledOrder.next,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          return _vm.$set(
                                            _vm.scheduledOrder,
                                            "next",
                                            $event
                                          )
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.scheduledOrder,
                                            "next",
                                            $event
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label: "Next Date",
                                                        "append-icon":
                                                          "mdi-calendar",
                                                        readonly: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.scheduledOrder
                                                            .next,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.scheduledOrder,
                                                            "next",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scheduledOrder.next"
                                                      }
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.nextDateMenu,
                                        callback: function($$v) {
                                          _vm.nextDateMenu = $$v
                                        },
                                        expression: "nextDateMenu"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: {
                                          "no-title": "",
                                          scrollable: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$refs.menu.save(
                                              _vm.scheduledOrder.next
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.scheduledOrder.next,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scheduledOrder,
                                              "next",
                                              $$v
                                            )
                                          },
                                          expression: "scheduledOrder.next"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.currentStep = 3
                                        }
                                      }
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.customer.addresses,
                                      label: "Select shipping address",
                                      "item-value": "id"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.street1) +
                                                " " +
                                                _vm._s(data.item.street2) +
                                                ", " +
                                                _vm._s(data.item.city) +
                                                ", " +
                                                _vm._s(data.item.state.name) +
                                                " " +
                                                _vm._s(data.item.zip) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-subtitle",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.full_name
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("v-spacer"),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.street1
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          data.item.street2
                                                        ) +
                                                        ", " +
                                                        _vm._s(data.item.city) +
                                                        ", " +
                                                        _vm._s(
                                                          data.item.state.name
                                                        ) +
                                                        " " +
                                                        _vm._s(data.item.zip) +
                                                        " "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.scheduledOrder.ship_address_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scheduledOrder,
                                          "ship_address_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scheduledOrder.ship_address_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.currentStep = 4
                                        }
                                      }
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.paymentMethods,
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: "Select payment option",
                                      dense: "",
                                      "single-line": ""
                                    },
                                    on: { change: _vm.selectPayment },
                                    model: {
                                      value: _vm.scheduledOrder.payment_method,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scheduledOrder,
                                          "payment_method",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scheduledOrder.payment_method"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.selectPaymentOption
                                ? _c(
                                    "v-card-text",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.payments,
                                          label: "Select Payment Method",
                                          "item-value": "id",
                                          dense: "",
                                          "single-line": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.card_name) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.card_name
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("v-spacer"),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.card_type
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.card_number
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.card_expiry_formatted
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "append-item",
                                              fn: function() {
                                                return [
                                                  _c("v-divider", {
                                                    staticClass: "mb-2"
                                                  }),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        { staticClass: "ml-5" },
                                                        [
                                                          _vm._v(
                                                            " Payment method not found? "
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.toggleNewPaymentModal(
                                                                    true
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Add New Payment Method"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          102068775
                                        ),
                                        model: {
                                          value: _vm.scheduledOrder.payment_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scheduledOrder,
                                              "payment_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scheduledOrder.payment_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.contractor.addresses,
                                      label: "Select billing address",
                                      "item-value": "id"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.street1) +
                                                " " +
                                                _vm._s(data.item.street2) +
                                                ", " +
                                                _vm._s(data.item.city) +
                                                ", " +
                                                _vm._s(data.item.state.name) +
                                                " " +
                                                _vm._s(data.item.zip) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-subtitle",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.full_name
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("v-spacer"),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.street1
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          data.item.street2
                                                        ) +
                                                        ", " +
                                                        _vm._s(data.item.city) +
                                                        ", " +
                                                        _vm._s(
                                                          data.item.state.name
                                                        ) +
                                                        " " +
                                                        _vm._s(data.item.zip) +
                                                        " "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.scheduledOrder.bill_address_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.scheduledOrder,
                                          "bill_address_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scheduledOrder.bill_address_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.addSchedule }
                                    },
                                    [_vm._v(" Add ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.closeAdd }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("add-payment-modal", {
        attrs: { isModalActive: _vm.addNewPayment },
        on: {
          "add-payment": _vm.loadPayment,
          "close-modal": _vm.toggleNewPaymentModal
        }
      }),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }