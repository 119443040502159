<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    flat
    color="blue darken-3"
    >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="toggleDrawer"
      />

    <v-container fluid>
      <v-row align="center">
        <router-link :to="{name: 'dashboard'}">
          <v-img
            :src="require('../../../assets/fod_logo.png')"
            class="mr-5"
            max-height="60"
            max-width="242"
            contain
            @click="$vuetify.goTo(0)"
            />
        </router-link>

        <v-spacer />

        <v-btn
          v-for="(link, i) in links"
          :key="i"
          v-bind="link"
          class="hidden-sm-and-down text-decoration-none"
          text
          color="white"
          @click="$router.push({ name: link.name })"
          >
          {{ link.text }}
        </v-btn>

        <v-menu
          bottom
          left
          min-width="200"
          offset-y
          origin="top right"
          transition="scale-transition"
          >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              color="white"
              v-bind="attrs"
              v-on="on"
              >
              Account
            </v-btn>
          </template>

          <v-list
            :tile="false"
            flat
            nav
            >
            <v-list-item @click="$router.push({ name: 'company' })">
              <v-list-item-title>Company</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'billing' })">
              <v-list-item-title>Billing</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'users' })">
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'files' })">
              <v-list-item-title>Files</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'settings' })">
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'reports' })">
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item>
            <v-divider class="mb-2 mt-2" />

            <v-list-item @click="logoutUser">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Header',
  data () {
    return {
      links: [
        { href: '/customers/list', name: 'customers', text: 'Customers' },
        { href: '/orders/open', name: 'open', text: 'Orders' },
        { href: '/products', name: 'products', text: 'Products' }
      ],
      options: [
        {
          name: 'Company',
          route: '/account/company',
          icon: 'fas fa-building'
        },
        {
          name: 'Billing',
          route: '/account/billing',
          icon: 'fas fa-wallet'
        },
        {
          name: 'Users',
          route: '/account/users',
          icon: 'fas fa-users-cog'
        },
        {
          name: 'Integrations',
          route: '/account/integrations',
          icon: 'fas fa-toolbox'
        },
        {
          name: 'Settings',
          route: '/account/settings',
          icon: 'fas fa-cog'
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn'
    })
  },
  methods: {
    ...mapActions('auth', {
      logout: 'logout',
      getUser: 'getInfo'
    }),
    ...mapMutations(['toggleDrawer']),
    onClick (e, item) {
      e.stopPropagation()

      if (item.to || !item.href) return

      this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
    },
    openOrders () {
      this.$router.push({ name: 'pending' })
    },
    openProducts () {
      this.$router.push({ name: 'products' })
    },
    openCustomers () {
      this.$router.push({ name: 'customers' })
    },
    loadUser () {
      if (Object.entries(this.user).length === 0) {
        this.getUser()
      }
    },
    logoutUser () {
      this.logout().then(() => {
        this.$router.push('/auth/login')
      })
    }
  },
  created () {
    this.loadUser()
  }
}
</script>

<style scoped>

</style>
