<template>
  <v-container
    fill-height
    fluid
  >
    <v-layout align-center justify-center>
      <v-card
        elevation="2"
        width="500"
      >
        <v-alert
          class="mx-3 my-3"
          v-model="alert"
          dense
          :type="alertType"
        >
          {{ message }}
        </v-alert>
        <v-card-title>
          Recover your password
        </v-card-title>
        <v-card-subtitle>
          Please enter your email address and we'll send you instructions on how to reset your password.
        </v-card-subtitle>

        <v-card-text>
          <v-form
            ref="forgotPasswordForm"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              dense
              outlined
              label="Email"
              v-model="email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="$router.push({name: 'login'})"
          >
            Back to Login
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="submitForm"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      valid: true,
      show: false,
      rules: {
        required: value => !!value || 'Required',
        email: value => {
          return  /.+@.+\..+/.test(value) || 'Invalid E-Mail'
        }
      },
      email: '',
      message: null,
      alert: false,
      alertType: undefined
    }
  },
  methods: {
    ...mapActions('auth', {
      forgot: 'forgotPassword'
    }),
    submitForm () {
      this.$store.dispatch('auth/forgotPassword', {email: this.email, site_id: 3})
        .then(() => {
          this.alertType = 'success'
          this.message = 'Reset request submitted.'
          this.alert = true
          this.$refs.forgotPasswordForm.reset()
        })
        .catch((error) => {
          this.alertType = 'error'
          this.message = error.data.error
          this.alert = true
        })
    }
  }
}
</script>

<style scoped>

</style>
