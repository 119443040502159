<template>
  <v-container id="customers-pending-products-batch-view">
    <material-card
      color="primary"
      icon="mdi-playlist-plus"
    >
      <template #title>
        Customers Pending Products Batches
      </template>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        loading-text="Loading Customers Without Products Batches... Please wait"
        class="mr-2 ml-2">
        <template v-slot:[`item.method`]="{ item }">
          {{ methodMap[item.method] }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            icon
            class="mr-2"
            @click="openBatch(item.id)"
          >
            <v-icon>mdi-folder</v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          No pending products batches found.
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MaterialCard: () => import('@/components/MaterialCard')
  },
  data () {
    return {
      query: {
        contractor_id: '',
        page: 1,
        limit: 15,
        keyword: '',
        site_id: 3,
        paginated: false,
        load_without: true
      },
      items: [],
      loading: false,
      methodMap: {
        UPLOAD: 'Upload',
        MANUAL: 'Manual'
      }
    }
  },
  filters: {
    tag (value) {
      if (value.length > 0) {
        return 'Attention Needed'
      } else {
        return 'Valid'
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    headers () {
      return [
        {
          text: 'Entered Method',
          value: 'method',
          width: 450
        },
        {
          text: 'Date',
          value: 'created_at'
        },
        {
          text: 'Remaining',
          value: 'remaining'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('customerManagement', {
      batches: 'fetchCustomerBatches'
    }),
    loadBatches () {
      this.batches({batch_type: 'products'})
        .then((response) => {
          this.items = response
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    openBatch (id) {
      this.$router.push({ name: 'customer_no_products', params: { id } })
    }
  },
  created () {
    this.query.contractor_id = this.user.model_id
    this.loadBatches()
  }
}
</script>
