<template>
  <div id="cancel-dialog">
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
      @click:outside="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">Return Request Form</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pa-4">
          <v-container>
            <v-data-table
              v-model="selectedProducts"
              :items="filteredProducts"
              :headers="headers"
              item-key="id"
              show-select
              class="mb-4 elevation-1"
              dense
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Select Products For Return</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:item.name="{ item }">
                {{ $utils.productName(item.product) }}
              </template>

              <template v-slot:item.price="{ item }">
                ${{ item.list_price }}
              </template>

              <template v-slot:item.return_quantity="{ item }">
                <v-input v-model.number="item.return_quantity" />
              </template>

            </v-data-table>

            <v-divider v-show="selectedProducts.length > 0" class="my-4" />

            <div v-show="selectedProducts.length > 0" class="pt-4">
              <v-select
                v-model="selectedReason"
                :items="reasonOptions"
                label="Select a reason for your return request"
                required
                dense
              >
              </v-select>

              <v-textarea
                outlined
                auto-grow
                v-model="returnComment"
                label="Description"
                hint="Please provide a brief description of the situation."
                persistent-hint
                rows="3"
                required
              ></v-textarea>

              <v-file-input
                v-show="selectedReason === 4"
                ref="file-input"
                label="Choose images..."
                v-model="rawImages"
                accept="image/*"
                hint="Please provide image(s) of shipping label and damage (if present). Images are required to speed up the return process."
                persistent-hint
                multiple
                small-chips
                @change="preview"
              />

              <div v-if="images.length > 0" class="mt-4">
                <span class="subtitle">Preview:</span>
                <div class="p-4 mt-4 bg-dark overflow-auto image-preview">
                  <v-row>
                    <v-col
                      v-for="(image, index) in images"
                      :key="index"
                      class="d-flex child-flex"
                      cols="4"
                      >
                      <v-img
                        :src="image.data"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click.native="cancel">CANCEL</v-btn>
          <v-btn color="primary darken-1" text @click.native="submit" :disabled="!isReturnFormValid">SUBMIT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-modal ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      valid: false,
      orderLocal: {
        order: null,
        products: null
      },
      selectedProducts: [],
      selectAll: false,
      selectedReason: null,
      reasonOptions: [
        {value: null, text: 'Please Select an Option'},
        {value: 16, text: 'Received incorrect Item'},
        {value: 17, text: 'Ordered Wrong'},
        {value: 4, text: 'Damaged'},
        {value: 18, text: 'No Longer Wants'},
        {value: 5, text: 'Other'}
      ],
      returnComment: '',
      images: [],
      rawImages: [],
      headers: [
        { text: 'Product', align: 'start', sortable: false, value: 'name' },
        { text: 'Price', align: 'start', sortable: false, value: 'list_price' },
        { text: 'Qty', align: 'start', sortable: false, value: 'quantity' },
        { text: 'Qty for return', align: 'start', sortable: false, value: 'return_quantity' }
      ],
      showError: false,
      errorMsg: '',
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      options: {
        color: 'primary',
        width: 800,
        zIndex: 200
      }
    }
  },
  watch: {
    selectedProducts () {
      if (this.showError && this.selectedProducts.length > 0) {
        this.showError = false
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('orderManagement', {
      order: state => state.order
    }),
    isReturnFormValid () {
      if (this.selectedProducts.length === 0) {
        return false
      }

      const noQtyArr = this.selectedProducts.filter((p) => !p.return_quantity > 0)
      if (noQtyArr.length > 0) {
        return false
      }

      if (this.selectedReason === 4 && this.images.length === 0) {
        return false
      }

      return this.selectedReason !== '' && this.returnComment !== ''
    },
    filteredProducts () {
      const returns = this.orderLocal.order.returns
      const tempProducts = this.orderLocal.products.map((p) => ({
        ...p,
        'return_quantity': p.quantity
      }))

      //remove products that already have return requests.
      returns.forEach((r) => {
        r.products.forEach((p) => {

          for (let i = 0; i < p.quantity; i++) {
            const index = tempProducts.findIndex((j) => j.id === p.order_product_id)
            tempProducts[index].quantity--
          }
        })
      })


      return tempProducts.filter((p) => p.quantity > 0)
    }
  },
  methods: {
    ...mapActions('orderManagement', {
      createReturnRequest: 'createReturnRequest'
    }),
    open () {
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    accept () {
      this.resolve()
      this.dialog = false
    },
    cancel () {
      this.reject()
      this.dialog = false
    },
    isChecked (id) {
      return this.selectedProducts.findIndex((i) => i.id === id) > -1
    },
    toggleSelectAll () {
      if (this.selectAll) {
        this.selectedProducts = []
        this.filteredProducts.forEach((p) => {
          this.selectedProducts.push(p)
        })
        return
      }
      this.selectedProducts = []
    },
    toggleRowSelect (item) {
      const index = this.selectedProducts.findIndex((i) => i.id === item.id)
      if (index === -1) {
        this.selectedProducts.push(item)
        return
      }

      this.selectedProducts.splice(index, 1)
    },
    submit () {
      //make sure at least one product was selected
      if (this.selectedProducts.length === 0) {
        this.showError = true
        this.errorMsg = 'Please select at least one product to return.'
        return
      }

      //make sure there was a quantity selected for the product(s)
      const noQtyArr = this.selectedProducts.filter((p) => !p.return_quantity > 0)
      if (noQtyArr.length > 0) {
        this.showError = true
        this.errorMsg = 'Please select a quantity for the number of items you are wanting to return.'
        return
      }
      //set the quantity to be the selected 'return_quantity'
      this.selectedProducts.forEach((p) => {
        p.quantity = p.return_quantity
      })

      //set and send to jsa
      const obj = {
        reason_id: this.selectedReason,
        comment: this.returnComment,
        order_id: this.orderLocal.order.id,
        source: this.user.role,
        items: this.selectedProducts,
        images: this.images
      }

      this.createReturnRequest(obj)
        .then(() => {
          this.accept()
        })
        .catch((error) => {
          this.$toast.danger(error.data.error)
        })
    },
    preview () {
      this.rawImages.forEach((file) => {
        const reader = new FileReader()
        reader.onload = e => {
          this.images.push({filename: file.name, data: e.target.result, type: file.type})
        }
        reader.readAsDataURL(file)
      })
    },
    resetImages () {
      this.$refs['file-input'].reset()
      this.images = []
    },
    removeImage (index) {
      const image = this.images[index]
      this.images.splice(index, 1)
      const rawIndex = this.rawImages.findIndex((item) => item.name === image.filename)
      if (rawIndex > -1) {
        this.rawImages.splice(rawIndex, 1)
      }
    },
    resetForm () {
      //set order & products
      this.orderLocal.order = this.order
      this.orderLocal.products = this.order.products

      //set form
      this.selectedProducts = []
      this.returnComment = ''
      this.selectedReason = null
      this.images = []
    }
  },
  created () {
    this.resetForm()
  }
}
</script>

<style scoped>

</style>
