var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.reminders,
          search: _vm.search,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait"
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "white" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.openAddReminder }
                        },
                        [_c("v-icon", [_vm._v("mdi-calendar-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.$utils.itemName(item)) + " ")]
              }
            },
            {
              key: "item.frequency",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-edit-dialog",
                    {
                      attrs: { large: "", "return-value": item.frequency },
                      on: {
                        "update:returnValue": function($event) {
                          return _vm.$set(item, "frequency", $event)
                        },
                        "update:return-value": function($event) {
                          return _vm.$set(item, "frequency", $event)
                        },
                        save: function($event) {
                          return _vm.saveFrequency(item)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "input",
                            fn: function() {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.frequencyOptions,
                                    label: "Frequency"
                                  },
                                  model: {
                                    value: item.frequency,
                                    callback: function($$v) {
                                      _vm.$set(item, "frequency", $$v)
                                    },
                                    expression: "item.frequency"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_vm._v(" " + _vm._s(item.frequency) + " days ")]
                  )
                ]
              }
            },
            {
              key: "item.next",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-edit-dialog",
                    {
                      attrs: { large: "", "return-value": item.next_date },
                      on: {
                        "update:returnValue": function($event) {
                          return _vm.$set(item, "next_date", $event)
                        },
                        "update:return-value": function($event) {
                          return _vm.$set(item, "next_date", $event)
                        },
                        save: function($event) {
                          return _vm.saveNextDate(item)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "input",
                            fn: function() {
                              return [
                                _c(
                                  "v-menu",
                                  {
                                    ref: "menu",
                                    attrs: {
                                      "close-on-content-click": false,
                                      "return-value": item.next_date,
                                      transition: "scale-transition",
                                      "offset-y": "",
                                      "min-width": "auto"
                                    },
                                    on: {
                                      "update:returnValue": function($event) {
                                        return _vm.$set(
                                          item,
                                          "next_date",
                                          $event
                                        )
                                      },
                                      "update:return-value": function($event) {
                                        return _vm.$set(
                                          item,
                                          "next_date",
                                          $event
                                        )
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label: "Next Date",
                                                        "prepend-icon":
                                                          "mdi-calendar",
                                                        readonly: ""
                                                      },
                                                      model: {
                                                        value: item.next_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "next_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.next_date"
                                                      }
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.nextDateMenu,
                                      callback: function($$v) {
                                        _vm.nextDateMenu = $$v
                                      },
                                      expression: "nextDateMenu"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-date-picker",
                                      {
                                        attrs: {
                                          "no-title": "",
                                          scrollable: ""
                                        },
                                        model: {
                                          value: item.next_date,
                                          callback: function($$v) {
                                            _vm.$set(item, "next_date", $$v)
                                          },
                                          expression: "item.next_date"
                                        }
                                      },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.menu = false
                                              }
                                            }
                                          },
                                          [_vm._v(" Cancel ")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.menu.save(
                                                  item.next_date
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" OK ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_vm._v(" " + _vm._s(item.next_date) + " ")]
                  )
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          return _vm.editReminder(item)
                        }
                      }
                    },
                    [_vm._v(" mdi-pencil ")]
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteReminder(item.id)
                        }
                      }
                    },
                    [_vm._v(" mdi-delete-outline ")]
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [_vm._v(" No filter change reminders found. ")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialogAdd,
            callback: function($$v) {
              _vm.dialogAdd = $$v
            },
            expression: "dialogAdd"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Add Reminder")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.products,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Assigned Product",
                                  "single-line": ""
                                },
                                model: {
                                  value: _vm.editedReminder.product_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editedReminder,
                                      "product_id",
                                      $$v
                                    )
                                  },
                                  expression: "editedReminder.product_id"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.frequencyOptions,
                                  label: "Frequency"
                                },
                                model: {
                                  value: _vm.editedReminder.frequency,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editedReminder,
                                      "frequency",
                                      $$v
                                    )
                                  },
                                  expression: "editedReminder.frequency"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value":
                                      _vm.editedReminder.next_date,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto"
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      return _vm.$set(
                                        _vm.editedReminder,
                                        "next_date",
                                        $event
                                      )
                                    },
                                    "update:return-value": function($event) {
                                      return _vm.$set(
                                        _vm.editedReminder,
                                        "next_date",
                                        $event
                                      )
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Next Date",
                                                    "append-icon":
                                                      "mdi-calendar",
                                                    readonly: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedReminder
                                                        .next_date,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.editedReminder,
                                                        "next_date",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedReminder.next_date"
                                                  }
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.nextDateMenu,
                                    callback: function($$v) {
                                      _vm.nextDateMenu = $$v
                                    },
                                    expression: "nextDateMenu"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "", scrollable: "" },
                                    on: {
                                      input: function($event) {
                                        return _vm.$refs.menu.save(
                                          _vm.editedReminder.next_date
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.editedReminder.next_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedReminder,
                                          "next_date",
                                          $$v
                                        )
                                      },
                                      expression: "editedReminder.next_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.closeAdd }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", text: "" },
                      on: { click: _vm.addReminder }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit Reminder")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.frequencyOptions,
                                  label: "Frequency"
                                },
                                model: {
                                  value: _vm.editedReminder.frequency,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editedReminder,
                                      "frequency",
                                      $$v
                                    )
                                  },
                                  expression: "editedReminder.frequency"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value":
                                      _vm.editedReminder.next_date,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto"
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      return _vm.$set(
                                        _vm.editedReminder,
                                        "next_date",
                                        $event
                                      )
                                    },
                                    "update:return-value": function($event) {
                                      return _vm.$set(
                                        _vm.editedReminder,
                                        "next_date",
                                        $event
                                      )
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Next Date",
                                                    "append-icon":
                                                      "mdi-calendar",
                                                    readonly: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedReminder
                                                        .next_date,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.editedReminder,
                                                        "next_date",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedReminder.next_date"
                                                  }
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.nextDateMenu,
                                    callback: function($$v) {
                                      _vm.nextDateMenu = $$v
                                    },
                                    expression: "nextDateMenu"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "", scrollable: "" },
                                    on: {
                                      input: function($event) {
                                        return _vm.$refs.menu.save(
                                          _vm.editedReminder.next_date
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.editedReminder.next_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedReminder,
                                          "next_date",
                                          $$v
                                        )
                                      },
                                      expression: "editedReminder.next_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", text: "" },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }