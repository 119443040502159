import api from './request'

export function get (data) {
  return api({
    url: '/contractors/list',
    method: 'get',
    params: data
  })
}

export function show (id) {
  return api({
    url: `/contractors/${id}`,
    method: 'get'
  })
}

export function update (id, data) {
  return api({
    url: `/contractors/${id}`,
    method: 'put',
    data
  })
}

export function showDashboard (id, data) {
  return api({
    url: `/contractors/${id}/dashboard`,
    method: 'get',
    params: data
  })
}

export function updateWithImage (id, data) {
  return api({
    url: `/contractors/${id}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function updateSettings (id, data) {
  return api({
    url: `/contractors/settings/${id}`,
    method: 'put',
    data
  })
}
