var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { attrs: { app: "", id: "main-footer", color: "primary" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center",
                  attrs: { cols: "6", md: "auto" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-decoration-none cursor-pointer text-uppercase text-caption font-weight-regular white--text",
                      attrs: { text: "" },
                      on: { click: _vm.openContact }
                    },
                    [_vm._v(" Contact Us ")]
                  )
                ],
                1
              ),
              _c("v-spacer", { staticClass: "hidden-sm-and-down" }),
              _c("v-col", { attrs: { cols: "12", md: "auto" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "body-1 font-weight-light pt-6 pt-md-0 text-center text-caption white--text"
                  },
                  [
                    _vm._v(
                      " © " + _vm._s(new Date().getFullYear()) + ", Powered by "
                    ),
                    _c("img", {
                      attrs: {
                        src: require("../../assets/ff_footer_logo.png"),
                        alt: "FilterFetch"
                      }
                    })
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.showContactDialog,
            callback: function($$v) {
              _vm.showContactDialog = $$v
            },
            expression: "showContactDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [_vm._v("Contact Us")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _vm.showContactAlert
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.contactAlertType } },
                            [_vm._v(_vm._s(_vm.contactAlertMessage))]
                          )
                        : _vm._e(),
                      _c(
                        "v-form",
                        {
                          ref: "contactUsForm",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.contactFormValid,
                            callback: function($$v) {
                              _vm.contactFormValid = $$v
                            },
                            expression: "contactFormValid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.nameRules,
                              label: "Name",
                              disabled: "",
                              required: ""
                            },
                            model: {
                              value: _vm.contact.name,
                              callback: function($$v) {
                                _vm.$set(_vm.contact, "name", $$v)
                              },
                              expression: "contact.name"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.emailRules,
                              label: "E-mail",
                              disabled: "",
                              required: ""
                            },
                            model: {
                              value: _vm.contact.email,
                              callback: function($$v) {
                                _vm.$set(_vm.contact, "email", $$v)
                              },
                              expression: "contact.email"
                            }
                          }),
                          _c("v-textarea", {
                            attrs: {
                              "auto-grow": "",
                              counter: 500,
                              rules: _vm.messageRules,
                              label: "Message",
                              required: ""
                            },
                            model: {
                              value: _vm.contact.message,
                              callback: function($$v) {
                                _vm.$set(_vm.contact, "message", $$v)
                              },
                              expression: "contact.message"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeContact }
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.sendingContactForm,
                        disabled: !_vm.contactFormValid,
                        color: "blue darken-1",
                        text: ""
                      },
                      on: { click: _vm.sendContact }
                    },
                    [_vm._v(" Send ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }