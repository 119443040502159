var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.orders,
          search: _vm.search,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait"
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "white" } },
                    [
                      _vm.showToolbar
                        ? _c("v-toolbar-title", [_vm._v("Order History")])
                        : _vm._e(),
                      _vm.showToolbar
                        ? _c("v-divider", {
                            staticClass: "mx-4",
                            attrs: { inset: "", vertical: "" }
                          })
                        : _vm._e(),
                      _c("v-text-field", {
                        attrs: {
                          placeholder: "Search",
                          "hide-details": "",
                          clearable: "",
                          "append-icon": "mdi-magnify",
                          dense: ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.total",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" $" + _vm._s(item.total) + " ")]
              }
            },
            {
              key: "item.status",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    { attrs: { color: _vm.statusColorMap[item.status] } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.orderStatusMap[item.status]) + " "
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openOrder(item.id)
                                          }
                                        }
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-eye-outline ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Open order record")])]
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [_vm._v(" No recent orders found. ")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }