<template>
  <div v-if="hasUser" class="dashboard_layout">
    <v-container>
      <v-row>
        <h2>Dashboard</h2>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="mb-5">
        <v-col
          v-for="(setting, i) in settings"
          :key="i"
          cols="12"
          md="6"
          lg="4"
          >
            <statistics-card :icon="setting.icon" :value="setting.value" :title="settingsMap[setting.name]" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" class="dashboard_data_rows">
          <v-card class="mb-5" v-if="datatables.show_recent_orders">
            <order-history :height="height"></order-history>
          </v-card>

          <v-card class="mb-5" v-if="datatables.show_scheduled_orders">
            <scheduled-orders :height="height" :days="30" />
          </v-card>

          <v-card class="mb-5" v-if="datatables.show_unapproved_service_titan">
            <pending-order-history :height="height" :days="30" :service-titan="true" status="PENDING_APPROVAL" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Settings v-if="Object.entries(user).length > 0" @update-settings="initSettings" />

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    OrderHistory: () => import('../components/OrderHistory'),
    Settings: () => import('./components/Settings'),
    ScheduledOrders: () => import('../components/ScheduledOrders'),
    StatisticsCard: () => import('@/components/StatisticsCard'),
    PendingOrderHistory: () => import('@/views/components/PendingOrderHistory')
  },
  data () {
    return {
      height: 341,
      showStats: false,
      settingsMap: {
        orders_mtd: 'Orders MTD',
        orders_wtd: 'Orders WTD',
        orders_ytd: 'Orders YTD',
        customers_added: 'Customers Added',
        scheduled_orders: 'Scheduled Orders',
        pending_customers: 'Customers Pending Approval',
        wtd_order_dollars: 'WTD Order $',
        reminders_scheduled: 'Reminders Scheduled',
        customers_without_products: 'Customers w/o Products',
        show_recent_orders: 'Show recent order history',
        show_scheduled_orders: 'Show scheduled orders that ship soon'
      },
      statistics: [],
      settings: [],
      datatables: {
        show_recent_orders: false,
        show_scheduled_orders: false,
        show_unapproved_service_titan: false
      },
      overlay: true
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('contractorManagement', {
      contractorSettings: 'settings'
    }),
    hasUser () {
      return Object.entries(this.user).length > 0
    }
  },
  methods: {
    ...mapActions('contractorManagement', {
      fetchDashboard: 'fetchDashboard'
    }),
    initSettings () {
      this.settings = []

      Object.keys(this.user.settings.statistics).forEach((key) => {
        if (this.user.settings.statistics[key].value) {
          const statName = this.user.settings.statistics[key].name
          const index = this.statistics.findIndex((c) => c.name === statName)
          if (index > -1) {
            this.settings.push(this.statistics[index])
          }
        }
      })

      Object.keys(this.user.settings.datatables).forEach((key) => {
        this.datatables[this.user.settings.datatables[key].name] = this.user.settings.datatables[key].value
      })

      this.overlay = false
    },
    loadDashboard () {
      this.fetchDashboard({id: this.user.model_id, data: {site_id: 3}})
        .then((response) => {
          this.statistics = response.statistics
          this.initSettings()
        })
        .catch((error) => {
          this.overlay = false
          this.$toast.error(`Error! ${error.data ? error.data.error : error}`)
        })
    }
  },
  created () {
    this.loadDashboard()
  }
}
</script>

<style>

</style>
