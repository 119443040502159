export default {
  setContractor (state, contractor) {
    state.contractor = contractor
    state.settings = state.contractor.settings
    localStorage.setItem('settings', JSON.stringify(state.contractor.settings))
  },
  setDashboard (state, payload) {
    state.dashboard = payload
  },
  updateContractor (state, payload) {
    state.contractor = payload
    state.settings = state.contractor.settings
    localStorage.setItem('settings', JSON.stringify(state.contractor.settings))
  },
  setUsers (state, users) {
    state.users = users
  },
  addUser (state, user) {
    state.users.push(Object.assign({}, user))
  },
  updateUser (state, payload) {
    const userIndex = state.users.findIndex((u) => u.id === payload.id)
    state.users[userIndex] = payload
  },
  removeUser (state, userId) {
    const userIndex = state.users.findIndex((u) => u.id === userId)
    state.users.splice(userIndex, 1)
  },
  updateCurrentUser (state, payload) {
    localStorage.setItem('user', JSON.stringify(payload))
  }
}
