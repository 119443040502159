<template>
  <div class="validation-card">
    <v-card class="mx-auto" :min-height="minHeight" :max-width="maxWidth">
      <v-card-title class="align-start">
        <div
          v-if="hasTitle"
          class="text-h6 v-card--material__title"
        >
          <slot name="title" />

          <template v-if="title">
            {{ title }}
          </template>

          <div class="text-subtitle-1 mb-n4">
            <slot name="subtitle" />

            <template v-if="subtitle">
              {{ subtitle }}
            </template>
          </div>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <p class="text--primary">{{ customerData.full_name }}</p>
        <p>
          <span>{{ customerData.shipping_address.street1 }} {{ customerData.shipping_address.street2 }}</span>
          <br>
          <span>{{ customerData.shipping_address.city }}, {{ customerData.shipping_address.state.name }} {{ customerData.shipping_address.zip }}</span>
        </p>
      </v-card-text>

      <template v-if="$slots.actions">
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="actions" />
        </v-card-actions>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CustomerValidationCard',
  props: {
    customerData: {
      type: Object,
      default: () => {}
    },
    subtitle: String,
    title: String,
    type: String,
    minHeight: Number,
    maxWidth: Number
  },
  computed: {
    hasTitle () {
      return !!(
        this.title ||
        this.subtitle ||
        this.$slots.title ||
        this.$slots.subtitle
      )
    }
  }
}
</script>

<style scoped>

</style>
