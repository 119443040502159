<template>
  <v-container
    id="billing-view"
  >
    <material-card
      color="primary"
      icon="mdi-cogs"
    >
      <template #title>
        Settings
      </template>

      <v-form v-if="!loading">
        <v-container fluid>
          <v-row dense>
            <v-col cols="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="settings.approval_required"
                    label="Require Scheduled Order Approval?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="settings.po_required"
                    label="Require PO#?"
                  ></v-checkbox>
                </v-col>
                <v-col v-if="settings.po_required" cols="6" sm="12" md="6">
                  <v-select
                    :items="poOptions"
                    v-model="settings.default_po"
                    label="Default PO#"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="settings.credit">
                  <v-checkbox
                    v-model="settings.st_integration"
                    label="Service Titan Integration"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_id"
                    label="Service Titan ID">
                  </v-text-field>
                </v-col>
<!--                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_notifications.name"
                    label="Notification Alerts Name">
                  </v-text-field>
                </v-col>-->
                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_notification_email"
                    label="Notification Alerts Email">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_api_key"
                    label="Service Titan API Key">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_technician_id"
                    label="Service Titan Default Technician ID">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_vendor_id"
                    label="Service Titan Vendor ID">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_sku_id"
                    label="Service Titan SKU ID">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="settings.st_integration">
                  <v-text-field
                    v-model="settings.st_order_type_id"
                    label="Service Titan Order Type ID">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="updateSettings"
        >
          UPDATE
        </v-btn>
      </template>
    </material-card>

    <confirmation-modal ref="confirm" />
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
  components: {
    MaterialCard: () => import('@/components/MaterialCard'),
    ConfirmationModal: () => import('@/components/ConfirmationModal')
  },
  data () {
    return {
      settings: {},
      loading: true,
      poOptions: [
        { text: 'Customer Last Name', value: 'last_name'},
        { text: 'Customer Last Name and Date', value: 'name_date'},
        { text: 'No Default', value: 'no'}
      ]
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('contractorManagement', {
      contractor: state => state.contractor
    })
  },
  methods: {
    ...mapActions('contractorManagement', {
      fetch: 'fetchContractor',
      update: 'updateSettings'
    }),
    initSettings () {
      this.settings = Object.assign({}, this.contractor.settings)
      this.loading = false
    },
    loadContractor () {
      this.loading = true

      this.fetch(this.user.model_id)
        .then(() => {
          this.initSettings()
        })
        .catch((error) => {
          this.$toast.error(error.data ? error.data.error : error)
        })
    },
    async updateSettings () {
      if (await this.$refs.confirm.open('Update Settings', 'Are you sure you want to update your settings? This action can cause order processing issues.', { color: 'red' })) {
        const base = Object.assign({}, this.contractor.settings)
        const diff = this.difference(this.settings, base)

        this.update({id: this.user.model_id, settings: diff})
          .then(() => {
            this.$toast.success('Settings updated successfully!')
          })
          .catch((error) => {
            this.$toast.error(error.data ? error.data.error : error)
          })
      }
    },
    difference (object, base) {
      function changes (object, base) {
        return _.transform(object, function (result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value
          }
        })
      }
      return changes(object, base)
    }
  },
  created () {
    this.loadContractor()
  }
}
</script>
<style/>
