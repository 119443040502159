import api from './request'

export function add (data) {
  return api({
    url: '/orders/create_multiple',
    method: 'post',
    data
  })
}

export function show (id) {
  return api({
    url: `/orders/${id}`,
    method: 'get'
  })
}

export function get (data) {
  return api({
    url: '/orders',
    method: 'get',
    params: data
  })
}

export function update (id, data) {
  return api({
    url: `/orders/${id}`,
    method: 'put',
    data
  })
}

export function destroy (id) {
  return api({
    url: `/orders/${id}`,
    method: 'delete'
  })
}

export function getBatch (query) {
  return api({
    url: '/order_batches',
    method: 'get',
    params: query
  })
}

export function exportBatch (id) {
  return api({
    url: `/order_batches/export/${id}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function addScheduled (data) {
  return api({
    url: '/scheduled_orders',
    method: 'post',
    data
  })
}

export function showScheduled (id) {
  return api({
    url: `/scheduled_orders/${id}`,
    method: 'get'
  })
}

export function getScheduled (data) {
  return api({
    url: '/scheduled_orders',
    method: 'get',
    params: data
  })
}

export function updateScheduled (id, data) {
  return api({
    url: `/scheduled_orders/${id}`,
    method: 'put',
    data
  })
}

export function snoozeScheduled (id) {
  return api({
    url: `/scheduled_orders/snooze/${id}`,
    method: 'get'
  })
}

export function approveScheduled (data) {
  return api({
    url: '/scheduled_orders/approve',
    method: 'post',
    data
  })
}

export function addScheduledProduct (data) {
  return api({
    url: '/scheduled_orders/addProduct',
    method: 'post',
    data
  })
}

export function createReturn (data) {
  return api({
    url: '/order_returns',
    method: 'post',
    data
  })
}


export function deleteReturn (id) {
  return api({
    url: `/order_returns/${id}`,
    method: 'delete'
  })
}

export function destroyScheduled (id) {
  return api({
    url: `/scheduled_orders/${id}`,
    method: 'delete'
  })
}
