var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "billing-view" } },
    [
      _c(
        "material-card",
        {
          attrs: { color: "primary", icon: "mdi-cogs" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Settings ")]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function() {
                return [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.updateSettings }
                    },
                    [_vm._v(" UPDATE ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.loading
            ? _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "Require Scheduled Order Approval?"
                                        },
                                        model: {
                                          value: _vm.settings.approval_required,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings,
                                              "approval_required",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "settings.approval_required"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: { label: "Require PO#?" },
                                        model: {
                                          value: _vm.settings.po_required,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings,
                                              "po_required",
                                              $$v
                                            )
                                          },
                                          expression: "settings.po_required"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.settings.po_required
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "6",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.poOptions,
                                              label: "Default PO#"
                                            },
                                            model: {
                                              value: _vm.settings.default_po,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "default_po",
                                                  $$v
                                                )
                                              },
                                              expression: "settings.default_po"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.settings.credit
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              label: "Service Titan Integration"
                                            },
                                            model: {
                                              value:
                                                _vm.settings.st_integration,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_integration",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "settings.st_integration"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _vm.settings.st_integration
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Service Titan ID"
                                            },
                                            model: {
                                              value: _vm.settings.st_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_id",
                                                  $$v
                                                )
                                              },
                                              expression: "settings.st_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.settings.st_integration
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Notification Alerts Email"
                                            },
                                            model: {
                                              value:
                                                _vm.settings
                                                  .st_notification_email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_notification_email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "settings.st_notification_email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.settings.st_integration
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Service Titan API Key"
                                            },
                                            model: {
                                              value: _vm.settings.st_api_key,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_api_key",
                                                  $$v
                                                )
                                              },
                                              expression: "settings.st_api_key"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.settings.st_integration
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Service Titan Default Technician ID"
                                            },
                                            model: {
                                              value:
                                                _vm.settings.st_technician_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_technician_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "settings.st_technician_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.settings.st_integration
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Service Titan Vendor ID"
                                            },
                                            model: {
                                              value: _vm.settings.st_vendor_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_vendor_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "settings.st_vendor_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.settings.st_integration
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Service Titan SKU ID"
                                            },
                                            model: {
                                              value: _vm.settings.st_sku_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_sku_id",
                                                  $$v
                                                )
                                              },
                                              expression: "settings.st_sku_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.settings.st_integration
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Service Titan Order Type ID"
                                            },
                                            model: {
                                              value:
                                                _vm.settings.st_order_type_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "st_order_type_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "settings.st_order_type_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("confirmation-modal", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }