import { show, showDashboard, update, updateWithImage, updateSettings } from '@/api/contractor'

// noinspection DuplicatedCode
export default {
  updateContractor ({commit}, payload) {
    const { id, contractor } = payload
    return new Promise((resolve, reject) => {
      update(id, contractor)
        .then((response) => {
          commit('updateContractor', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateContractorWithImage ({commit}, data) {
    const { id, contractor } = data
    return new Promise((resolve, reject) => {
      updateWithImage(id, contractor)
        .then((response) => {
          commit('updateContractor', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSettings ({commit}, data) {
    const { id, settings } = data
    return new Promise((resolve, reject) => {
      updateSettings(id, settings)
        .then((response) => {
          commit('updateContractor', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchContractor ({commit}, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((response) => {
          commit('setContractor', response.data)

          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDashboard (context, payload) {
    const { id, data } = payload
    return new Promise((resolve, reject) => {
      showDashboard(id, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
